import environment from "../../../env.js"

export const getUniversalLanguageFullName = ($dictionary) => {
  try {
    return $dictionary.universal.languageCode;
  } catch (e) {
    console.error(e)
    return {};
  }
}
export const sortLanguageCodeByFullName = (languageCodeList = [], dictionaryMap = {}) => {
  return languageCodeList.sort((a, b) => dictionaryMap[a].localeCompare(dictionaryMap[b]));
}
export const getLanguageCodeListByEnv = ($settings = { inputLang: [], testLang: [], betaLang: [] }) => {
  const isDevEnv = environment.env === "dev";
  const hostname = window.location.hostname;
  const subdomain = hostname.slice(0, hostname.indexOf("."));
  const isBetaEnv = subdomain.includes("beta");

  let languageCodeList = [];
  if (isDevEnv) {
    languageCodeList = [...$settings.inputLang, ...$settings.testLang];
  } else if (isBetaEnv) {
    languageCodeList = [...$settings.inputLang, ...$settings.betaLang];
  } else { // Prod env
    languageCodeList = $settings.inputLang;
  }
  return languageCodeList;
}
export const getLanguageOptionList = (
  languageCodeList = [], // String[]
  dictionaryMap = {}
) => {
  const list = languageCodeList
    .map((language) => ({
      code: language,
      name: dictionaryMap[language] || language,
    }))
  return list;
}
export const isAllowedLanguageDownload = (languageCode = "") => {
  return environment.backendType === "ali"
    ? [
      "ur-pk",
      "km-kh",
      "ti",
      "th-th",
      "he-il",
      "pa-guru-in",
      "am-et",
      "ne-np",
      "ta-in",
      "bn-bd",
      "hi-in",
      "lo-la",
      "my-mm",
    ].indexOf(languageCode) === -1
    : ["km-kh", "ti", "th-th", "he-il", "am-et", "ne-np", "ta-in", "bn-bd", "hi-in", "lo-la", "my-mm"].indexOf(
      languageCode
    ) === -1;
};  

export const isLanguageRightToLefMark = (languageCode) => ["he-il", "ur-pk", "fa-ir", "ar-ar", "ps-af"].includes(languageCode);

export const getLanguageFullEnglishName = ($dictionary) => {
  try {
    return $dictionary["en-us"].languageCode;
  } catch (e) {
    console.error(e);
    return {};
  }
};
