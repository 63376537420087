<template>
  <div class="playerControls">
    <div class="playerControls__icon-action" @click="playOrPauseVideo">
      <v-progress-circular v-if="isLoading" :size="iconSize" color="white" indeterminate></v-progress-circular>
      <div v-else>
        <button>
          <icon-base color="white" :width="iconSize" :height="iconSize">
            <d-icon-play v-if="!isPlaying" />
            <d-icon-pause v-else class="pause-btn" />
          </icon-base>
        </button>
      </div>
    </div>
    <div class="playerControls__toolbar">
      <div class="playerControls__slider">
        <v-slider
          class="my-0"
          hide-details
          :value="videoCurrentTime"
          :min="0"
          :max="videoDuration"
          :step="0.01"
          track-color="#ACACAC"
          thumb-color="white"
          :disabled="videoDuration === 0"
          @change="onSliderChange"
          @mousedown="temporaryPauseVideo"
          @mouseup="keepPlayingVideo"
        />
        <div class="caption white--text text-no-wrap ml-3">
          <span class="white--text">{{ displayCurrentTime }}</span>
          <span class="grey--text mx-1">/</span>
          <span class="white--text">{{ displayEndTime }}</span>
        </div>
      </div>
      <div
        v-if="!getter_is_gen2"
        class="playerControls__btn"
        @click="$emit('setIsSD', { value: !isSD })"
        :class="{
          'playerControls__btn--disabled': !hasSD || isLoading,
        }"
      >
        <component :is="qualitySwitchIcon" />
      </div>
      <v-menu
        transition="slide-y-transition"
        top
        left
        offset-y
        open-on-hover
        open-on-click
        close-on-click
        close-on-content-click
      >
        <div slot="activator" class="playerControls__btn">
          <d-icon-speed-gauge />
        </div>
        <div class="playerControls__options" dark dense>
          <div
            class="playerControls__option"
            :class="{
              'playerControls__option--selected': option.value === playerSpeed,
            }"
            v-for="option in speedOptions"
            :key="option.value"
            @click="$emit('setPlayerSpeed', { value: option.value })"
          >
            <div class="playerControls__option-status">
              <icon-base :color="option.value === playerSpeed ? '#ffffff' : '#9397A6'" width="8" height="8">
                <d-icon-done />
              </icon-base>
            </div>
            {{ option.label }}
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script>
import DPlayerSettingSpeed from "@/components/DWorkflowPlayer/setting/DPlayerSettingSpeed.vue";
import DIconDone from "@/components/icons/DIconDone.vue";
import DIconHD from "@/components/icons/DIconHD.vue";
import DIconSD from "@/components/icons/DIconSD.vue";
import DIconSpeedGauge from "@/components/icons/DIconSpeedGauge.vue";
import DIconPlay from "@/components/icons/DIconPlay.vue";
import DIconPause from "@/components/icons/DIconPause.vue";
import IconBase from "@/components/IconBase.vue";
import { toTimeFormat } from "@/js/video-player/video-player-time.js";
import { mapGetters } from "vuex";

export default {
  name: "PlayerControls",
  props: {
    isPlaying: Boolean,
    isLoading: Boolean,
    isSD: Boolean,
    hasSD: Boolean,
    poster: String,
    signedSrc: Object,
    videoCurrentTime: Number,
    videoDuration: Number,
    iconSize: Number,
    playerSpeed: Number,
  },
  mounted() {},
  data() {
    return {
      wasPlaying: false,
    };
  },
  components: {
    DIconDone,
    DIconHD,
    DIconSD,
    DIconSpeedGauge,
    DIconPlay,
    DIconPause,
    DPlayerSettingSpeed,
    IconBase,
  },
  computed: {
    ...mapGetters(["getter_is_gen2"]),
    displayCurrentTime() {
      return this.videoCurrentTime ? toTimeFormat(this.videoCurrentTime) : "00:00";
    },
    displayEndTime() {
      return this.videoDuration ? toTimeFormat(this.videoDuration) : "00:00";
    },
    qualitySwitchIcon() {
      if (!this.hasSD) return "DIconHD";
      return this.isSD ? "DIconSD" : "DIconHD";
    },
    speedOptions() {
      const speeds = [1, 1.5, 2];
      return speeds.map((speed) => ({
        value: speed,
        label: speed === 1 ? this.$t("player.normal") : `${speed}x`,
      }));
    },
  },
  methods: {
    playOrPauseVideo() {
      if (this.isPlaying) {
        this.$emit("pauseVideo");
        return;
      }
      this.$emit("playVideo");
    },
    onSliderChange(currentTime) {
      this.$emit("setVideoCurrentTime", currentTime);
    },
    temporaryPauseVideo() {
      if (this.isPlaying) {
        this.wasPlaying = true;
        this.$emit("pauseVideo");
      }
    },
    keepPlayingVideo() {
      if (this.wasPlaying) {
        this.$emit("playVideo");
        this.wasPlaying = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.playerControls {
  --slider-height: 32px;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), 60%, rgba(0, 0, 0, 0));
  &__icon-action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - var(--slider-height));
    &:hover .pause-btn {
      display: block;
    }
    .pause-btn {
      display: none;
    }
  }

  &__toolbar {
    height: var(--slider-height);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 18px;
    gap: 14px;
  }

  &__slider {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__btn {
    cursor: pointer;
    opacity: 0.6;
    display: flex;
    width: 24px;
    &:hover {
      opacity: 1;
    }
  }

  &__btn--disabled {
    pointer-events: none;
  }

  &__options {
    min-width: 80px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 14px;
    background: rgba(21, 22, 24, 0.95);
    border-radius: 4px;
    margin-bottom: 4px;
  }

  &__option {
    cursor: pointer;
    color: #9397a6;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    display: flex;
    align-items: center;
    &:hover > .playerControls__option-status {
      opacity: 1;
    }
  }

  &__option-status {
    width: 10px;
    opacity: 0;
    margin-right: 4px;
  }

  &__option--selected {
    color: #ffffff;
    .playerControls__option-status {
      opacity: 1 !important;
    }
  }
}
</style>
