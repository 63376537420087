import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

function getServiceBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].deephowApiServer
    : environment.service[environment.env]["china"].deephowApiServer;
}

const notificationsServer = axios.create({
  baseURL: getServiceBaseUrl(),
});

const apiWrapper = apiWrapperGenerator(notificationsServer);

notificationsServer.interceptors.request.use(
  async config => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  error => Promise.resolve(error)
);

function getLanguageCode({ vueInstanceRef }) {
  return vueInstanceRef.$i18n.locale;
}

function getDomain({ vueInstanceRef }) {
  return vueInstanceRef.$baseUrl;
}

export async function notifySkillHasAssignedToUsersOrTeams({ playlistId, teams, users, vueInstanceRef }) {
  const requestBody = {
    playlistId,
    type: "assign-skill",
    languageCode: getLanguageCode({ vueInstanceRef }),
    baseUrl: getDomain({ vueInstanceRef }),
  };

  const hasTemasData = Array.isArray(teams) && teams.length > 0;
  if (hasTemasData) {
    requestBody.teams = teams;
  }

  const hasUsersData = Array.isArray(users) && users.length > 0;
  if (hasUsersData) {
    requestBody.users = users;
  }

  try {
    const { data } = await notificationsServer.post("v1/notifications/skill", requestBody);
    return data;
  } catch (e) {
    console.error(e);
  }
}

export async function notifySkillHasChangedPublishedState({ playlistId, isPublished, vueInstanceRef }) {
  const requestBody = {
    playlistId,
    type: isPublished ? "publish-skill" : "unpublish-skill",
    languageCode: getLanguageCode({ vueInstanceRef }),
    baseUrl: getDomain({ vueInstanceRef }),
  };

  try {
    const { data } = await notificationsServer.post("v1/notifications/skill/publish", requestBody);
    return data;
  } catch (e) {
    console.error(e);
  }
}

export async function notifyUsersOfNotYetCompletedSkill({ playlistId, users, vueInstanceRef }) {
  const requestBody = {
    playlistId,
    type: "remind-skill",
    languageCode: getLanguageCode({ vueInstanceRef }),
    baseUrl: getDomain({ vueInstanceRef }),
    users,
  };

  try {
    const { data } = await notificationsServer.post("v1/notifications/skill/reminder", requestBody);
    return data;
  } catch (e) {
    console.error(e);
  }
}

export function notifyWorkflowReviewRequest({ workflowId, vueInstanceRef, users }) {
  const payload = {
    workflowId,
    type: "review-workflow",
    languageCode: getLanguageCode({ vueInstanceRef }),
    baseUrl: getDomain({ vueInstanceRef }),
    users,
  };
  return apiWrapper("post", "/v1/notifications/workflow/review", payload);
}

export async function notifySharedWorkflowToUsersOrTeams({
  workflowId,
  message,
  users,
  teams,
  vueInstanceRef,
}) {
  const requestBody = {
    workflowId,
    type: "share-workflow",
    languageCode: getLanguageCode({ vueInstanceRef }),
    baseUrl: getDomain({ vueInstanceRef }),
    message,
    users,
    teams
  };

  try {
    const { data } = await notificationsServer.post(
      "v1/notifications/workflow/share",
      requestBody
    );
    return data;
  } catch (e) {
    console.error(e);
  }
}

// Get Notification List: by default it gets the latest 500 from the past 30 days
export const postNotificationList = () => apiWrapper("post", `/v1/notifications`);

export function notifyWorkflowReactionRequest({ workflowId, vueInstanceRef }) {
  const payload = {
    workflowId,
    type: "workflow-reaction",
    languageCode: getLanguageCode({ vueInstanceRef }),
    baseUrl: getDomain({ vueInstanceRef }),
  };
  return apiWrapper("post", "/v1/notifications/workflow/reaction", payload);
}
