import { render, staticRenderFns } from "./DProgressBar.vue?vue&type=template&id=23b84b50&scoped=true&"
import script from "./DProgressBar.vue?vue&type=script&lang=js&"
export * from "./DProgressBar.vue?vue&type=script&lang=js&"
import style0 from "./DProgressBar.vue?vue&type=style&index=0&id=23b84b50&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b84b50",
  null
  
)

export default component.exports