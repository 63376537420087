<template>
  <div class="downloading-popup" :class="{'downloading-popup-share':showBuilderShare}">
    <div
      v-for="(video, idx) in downloadVideoList"
      :key="idx"
      class="downloading-one-video"
      :class="{'show-downloading-video': showDownload[idx]==true }"
    >
      <div class="download-icon vertical-center">
        <v-progress-circular
          :rotate="-90"
          :size="32"
          :width="1"
          :value="video.progress"
          color="primary"
          class="progress-circular"
        >
          <span class="progress-num">{{Math.floor(video.progress)}}%</span>
        </v-progress-circular>
      </div>
      <div class="download-text">
        <p class="download-title">Downloading</p>
        <p
          class="download-message text-no-wrap text-truncate"
        >{{getStepNum(idx)}}-{{video.title}}</p>
      </div>
      <button class="close-btn" @click="hideThisDownload(idx)">
        <icon-base class="close-btn-icon" color="#8D909F" width="16" height="16">
          <d-icon-close2/>
        </icon-base>
        <span class="close-btn-text">Hide</span>
      </button>
    </div>
  </div>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconClose2 from "../icons/DIconClose2.vue";

export default {
  name: "DDownload",
  props: {
    downloadVideoList: {
      type: Array
    },
    downloadVideoListWatch: {
      type: Array
    },
    showBuilderShare: {
      type: Boolean
    }
  },
  components: {
    IconBase,
    DIconClose2
  },
  watch: {
    downloadVideoListWatch: function() {
      this.showDownloadingVideo();
    }
  },
  created() {},
  updated() {},
  data: function() {
    return {
      closeAlert: false,
      showDownload: []
    };
  },
  methods: {
    showDownloadingVideo() {
      for (let i = 0; i < this.downloadVideoListWatch.length; i++) {
        if (this.downloadVideoListWatch[i] === true) {
          this.showDownload[i] = true;
          this.showDownload.splice();
        } else {
          this.showDownload[i] = false;
          this.showDownload.splice();
        }
      }
    },
    hideThisDownload(idx) {
      this.showDownload[idx] = false;
      this.showDownload.splice();
    },
    getStepNum(index) {
      index++;
      if (index < 10) {
        return "0" + index.toString();
      } else {
        return index.toString();
      }
    }
  },
  computed: {},
  mounted() {}
};
</script>

<style scoped>
.downloading-popup {
  pointer-events: none;
  position: fixed;
  top: 74px;
  /* top: 386px; */
  right: 20px;
  width: 454px;
  /* width: 400px; */
  text-align: left;
  z-index: 10;
  transition: 0.3s;
}
.downloading-popup-share {
  top: 4px;
  transition: 0.3s;
}
.downloading-one-video {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  pointer-events: none;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #2c2d32;
  opacity: 0;
  transition: 0.3s;
}
.show-downloading-video {
  height: 70px;
  pointer-events: auto;
  opacity: 1;
  margin-bottom: 4px;
  transition: 0.3s;
}
.download-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
  /* padding-left: 16px; */
  padding-left: 12px;
  padding-top: 4px;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.download-text {
  /* position: absolute; */
  position: relative;
  /* left: 56px; */
  top: 0;
  padding: 16px 16px 0 56px;
}
.download-title {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin: 0;
}
.download-message {
  color: #8d909f;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
.close-btn-icon {
  display: block;
}
.close-btn-text {
  display: none;
  color: #8d909f;
  font-size: 8px;
}
.close-btn:hover .close-btn-icon {
  display: none;
}
.close-btn:hover .close-btn-text {
  display: block;
}
.progress-num {
  font-size: 8px;
}
</style>
