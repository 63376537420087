<template>
  <div class="tab-div">
    <div row wrap class="tab-title-row">
      <button class="back-btn" @click="$emit('close-single-team')" autotest="back-to-all-teams-btn">
        <icon-base color="#52545D" height="24" width="24">
          <d-icon-arrow-back />
        </icon-base>
      </button>
      <p class="tab-title" autotest="team-name-display">{{ teamName }}</p>
      <v-spacer></v-spacer>
      <p v-if="reachedMaxUsers" class="max-users-msg">{{ $t("admin.max1000UsersPerTeam") }}</p>
      <v-btn
        round
        depressed
        class="text-capitalize white--text body-2 btn-width"
        color="dBlue"
        :disabled="reachedMaxUsers"
        @click="showAssignUserPopup = true"
        autotest="team-add-user-btn"
        >{{ $t("admin.addUser") }}</v-btn
      >
    </div>
    <div class="table-search-row">
      <div>
        <d-table-search v-model="searchUser" :placeholder="$t('analytics.searchUser')" autotest="user-search-bar">
          <div v-if="searchUser.length == 0" class="filter-div">
            <user-status-filter
              autotest="team-user-status-filter"
              :statusDictionary="statusDictionary"
              :filterStatus="filterStatus"
              :currentStatus="currentStatus"
              @changeCurrentStatus="changeCurrentStatus"
            ></user-status-filter>
          </div>
        </d-table-search>
      </div>
    </div>
    <div class="table-div">
      <d-data-table
        local-search
        initial-sort
        :headers="tableHeader"
        :items="searchedUserData || filteredUsersData"
        :loading="isLoading"
        :placeholder="$t('admin.searchForUsers')"
        searchAutotest="team-user-search-bar"
        class="d-data-table"
      >
        <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
        <template slot="headerCell" slot-scope="props">
          <span class="head-text">{{ props.header.text }}</span>
        </template>
        <template slot="items" slot-scope="props">
          <td class="text-xs-left" :style="{ width: '30%' }">
            <div class="user-name-td">
              <d-avatar :size="40" :userId="props.item.id" />
              <!-- <span class="ml-3">{{props.item.displayName}}</span> -->
              <!-- <div class="user-name">
                  <p class="user-name-text">
                    <span class="text-no-wrap text-truncate">{{ props.item.displayName }}</span>
                    <br />
                    <span
                      class="text-no-wrap text-truncate email-or-id"
                    >{{showIdOrEmail(props.item)}}</span>
                  </p>
              </div>-->
              <div class="user-name">
                <div class="user-name-text">
                  <span class="text-no-wrap text-truncate" autotest="team-user-name-display">{{
                    props.item.displayName
                  }}</span>
                  <br />
                  <div class="text-no-wrap text-truncate email-or-id link-text" autotest="team-user-email-display">
                    {{ showIdOrEmail(props.item) }}
                  </div>

                  <div class="tooltip-div">{{ showIdOrEmail(props.item) }}</div>
                </div>
              </div>
            </div>
          </td>
          <td class="text-xs-left" :style="{ width: '30%' }" autotest="team-user-date-display">
            {{ props.item.dateAdded }}
          </td>
          <td class="text-xs-left" :style="{ width: '30%' }" autotest="team-user-status-display">
            {{ !props.item.disabled ? statusDictionary.active : statusDictionary.inactive }}
          </td>
          <td class="text-xs-right text-no-wrap">
            <button
              @click="(editUserData = props.item), (showDeleteUser = !showDeleteUser)"
              autotest="team-user-remove-btn"
            >
              <icon-base class="table-btn" height="20" width="20">
                <d-icon-remove />
              </icon-base>
            </button>
          </td>
        </template>
        <template slot="no-data">
          <span>{{ $t("admin.noUsersAddedYet") }}</span>
        </template>
        <template slot="no-results">
          <span>{{ $t("analytics.msgNoSearchResults") }}</span>
        </template>
      </d-data-table>
    </div>
    <div v-if="showAssignUserPopup">
      <admin-assign-users
        :excludedUsers="usersData"
        :groupId="groupId"
        :groupDictionary="groupDictionary"
        @close-share="showAssignUserPopup = false"
        @add-users="addUsers($event)"
      />
    </div>
    <d-confirm-popup
      v-model="showDeleteUser"
      :title="$t('admin.removeUser')"
      :cancelBtn="$t('all.cancel')"
      :actionBtn="$t('admin.remove')"
      @click-action-btn="removeUser()"
      :autotest="{
        close: 'team-remove-user-popup-close-btn',
        action: 'team-remove-user-popup-remove-btn',
        cancel: 'team-remove-user-popup-cancel-btn',
      }"
    >
      <template slot="icon">
        <icon-base width="120" height="120">
          <d-icon-teams />
        </icon-base>
      </template>
      <template slot="content">
        <span v-html="$t('admin.popupMsgRemoveUser', { name: editUserData.displayName })"></span>
      </template>
    </d-confirm-popup>
  </div>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import IconBase from "@/components/IconBase.vue";
import DIconArrowBack from "../icons/DIconArrowBack.vue";
import DIconRemove from "../icons/DIconRemove.vue";
import DTableSearch from "@/components/ui_components/DTableSearch.vue";
import AdminAssignUsers from "@/components/DAdmin/AdminAssignUsers.vue";
import DConfirmPopup from "@/components/DPopup/DConfirmPopup.vue";
import DIconTeams from "@/components/icons/colored/DIconTeams.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import environment from "../../../env.js";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import UserStatusFilter from "@/components/UserStatusFilter.vue";
import { addTeamUsers } from "@/server/teams-server";
import { mapMutations } from "vuex";

export default {
  name: "AdminTeamUsers",
  props: {
    groupDictionary: Object,
    // teamName: String, // should change to passing teamID with real data
  },
  watch: {},
  created() {
    this.teamId = this.$route.params.id;
    this.groupId = this.$route.params.group;
    this.teamName = this.$route.params.name;
    this.getTeamUsersData().catch((err) => {
      alert("error loading team users");
    });
  },
  mounted() {},
  data() {
    return {
      showAssignUserPopup: false,
      tableHeader: [
        {
          text: this.$t("all.name"),
          align: "left",
          sortable: true,
          value: "displayName",
          autotest: "team-user-sort",
        },
        {
          text: this.$t("admin.dateAdded"),
          align: "left",
          sortable: true,
          value: "dateAdded",
          autotest: "team-date-sort",
        },
        {
          text: this.$t("admin.status"),
          align: "left",
          sortable: true,
          value: "status",
          autotest: "team-user-status-display",
        },
        {
          text: "",
          sortable: false,
        },
      ],
      isLoading: false,
      // searchInput: "",
      searchUser: "",
      usersData: [],
      teamId: "",
      groupId: "",
      teamName: "",
      showDeleteUser: false,
      editUserData: {},
      maxUsersNum: 1000,
      filterStatus: ["active", "inactive"],
      currentStatus: "active",
      statusDictionary: {
        active: this.$t("admin.active"),
        inactive: this.$t("admin.inactive"),
      },
    };
  },
  components: {
    IconBase,
    DTableSearch,
    DIconArrowBack,
    DIconRemove,
    AdminAssignUsers,
    DConfirmPopup,
    DIconTeams,
    DDataTable,
    DAvatar,
    UserStatusFilter,
  },
  mixins: [MixinDB, MixinUser],
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    showIdOrEmail(user) {
      if (user.employeeId) {
        return user.employeeId;
      } else {
        return user.email;
      }
    },
    removeUser() {
      const self = this;
      return new Promise(function (resolve, reject) {
        const write = {
          type: "arrayRemove",
          collection: "users",
          id: self.editUserData.id,
          field: "teams",
          value: self.teamId,
        };
        const write2 = {
          type: "counter",
          collection: "teams",
          id: self.teamId,
          field: "totalUsers",
          value: "-1",
        };
        self
          .batchWrites([write, write2])
          .then(() => {
            for (let i = 0; i < self.usersData.length; i++) {
              if (self.usersData[i].id == self.editUserData.id) {
                self.usersData.splice(i, 1);
              }
            }
            const message = self.$t("admin.alertSuccessfullyRemovedUserFromTeam");
            self.MUTATE_ALERT_INFO({ message, type: "success" });
            resolve();
          })
          .catch((err) => {
            const message = self.$t("admin.alertFailedRemovedUserFromTeam");
            self.MUTATE_ALERT_INFO({ message, type: "error" });
            reject();
          });
      });
    },
    getTeamUsersData() {
      const self = this;
      self.isLoading = true;
      return new Promise(function (resolve, reject) {
        self
          .fetchTeamUsers()
          .then((users) => {
            users.forEach((user) => {
              if (user.teamTimestamp && user.teamTimestamp[self.teamId]) {
                if (environment.backendType != "ali") {
                  const timestamp = user.teamTimestamp[self.teamId];
                  if (timestamp._seconds) {
                    user.dateAdded = new Date(timestamp._seconds * 1000).toLocaleDateString();
                  } else {
                    user.dateAdded = timestamp.toDate().toLocaleDateString();
                  }
                } else {
                  user.dateAdded = new Date(user.teamTimestamp[self.teamId] * 1000).toLocaleDateString();
                }
              }
              self.usersData.push(user);
            });
            self.isLoading = false;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchTeamUsers() {
      const self = this;
      return new Promise(function (resolve, reject) {
        const comparisons = [
          {
            field: "organization",
            comparison: "==",
            value: self.$organization,
          },
          { field: "teams", comparison: "array-contains", value: self.teamId },
        ];
        self
          .getDocumentByQuery("users", comparisons, [
            {
              type: "limit",
              value: 1000,
            },
          ])
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async addUsers(users) {
      const userIds = users.map((user) => user.id);
      const teamId = this.teamId;

      const { ok, data } = await addTeamUsers({ teamId, users: userIds });
      if (ok) {
        let hasError = false;
        let hasWarning = false;
        const timeAddedUsers = [];

        data.item.updatedUsers.forEach((user, idx) => {
          switch (user.status) {
            case "error":
              hasError = true;
              break;
            case "warning":
              hasWarning = true;
              break;
            default:
              users[idx].dateAdded = new Date().toLocaleDateString();
              timeAddedUsers.push(users[idx]);
              break;
          }
        });
        this.usersData.unshift(...timeAddedUsers);

        if (hasError) {
          const message = this.$t("admin.alertFailedAddedUserToTeam");
          return this.MUTATE_ALERT_INFO({ message, type: "error" });
        }
        if (hasWarning) {
          const message = this.$t("admin.alertWarningAddedUserToTeamAlready");
          return this.MUTATE_ALERT_INFO({ message, type: "alert" });
        }
        const message = this.$t("admin.alertSuccessfullyAddedUserToTeam");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } else {
        const message = this.$t("admin.alertFailedAddedUserToTeam");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
    changeCurrentStatus(status) {
      this.currentStatus = status;
    },
  },
  computed: {
    reachedMaxUsers() {
      if (this.usersData.length > this.maxUsersNum) {
        return true;
      }
      return false;
    },
    searchedUserData() {
      if (!this.searchUser) {
        return false;
      }
      return this.usersData.filter((user) => {
        for (const header of this.tableHeader) {
          if (
            user[header.value] &&
            user[header.value].toString().toLowerCase().includes(this.searchUser.toLowerCase())
          ) {
            return true;
          }
        }
      });
    },
    filteredUsersData() {
      if (this.searchUser) {
        return false;
      }
      if (this.currentStatus === "active") {
        return this.usersData.filter((user) => !user.disabled);
      }
      return this.usersData.filter((user) => user.disabled);
    },
  },
};
</script>

<style scoped>
.tab-div {
  position: fixed;
  height: calc(100% - 134px);
  top: 134px;
  left: 0;
  width: 100%;
}
.table-search-row {
  position: relative;
  width: 100%;
  padding: 0 28px;
}
.filter-div {
  position: relative;
}
.back-btn {
  padding: 12px 10px 10px 0;
  height: 44px;
  margin-left: -6px;
  margin-top: 2px;
}
.tab-title-row {
  position: relative;
  height: 90px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 28px 0 28px;
}
.tab-title {
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  text-align: left;
  line-height: 32px;
  height: 34px;
  margin-top: 8px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.drop-list-arrow {
  position: relative;
  top: -4px;
}
.drop-list-arrow {
  position: relative;
  top: -4px;
}
.workspace-btn {
  cursor: pointer;
}
.btn-width {
  width: 140px;
  text-transform: none !important;
}
/* table */
.table-div {
  position: fixed;
  top: 294px;
  left: 0;
  width: 100%;
  height: calc(100% - 304px);
  padding: 0 28px;
}
.d-data-table {
  position: relative;
  height: 100%;
  width: 100%;
}

/*** analytics-table ***/
.table-shadow {
  position: relative;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
}
.analytics-table ::v-deep thead tr:first-child {
  height: 40px !important;
  background-color: #eaeaea;
}
.analytics-table ::v-deep table {
  color: #78767a;
  font-family: Roboto;
  font-size: 16px;
  line-height: 18px;
}
.analytics-table ::v-deep td {
  position: relative;
  font-size: 16px;
  height: 70px;
}
.head-text {
  text-transform: uppercase;
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
.table-btn:hover {
  color: #2d2e32;
}
.user-name-td {
  position: relative;
  width: 100%;
  display: flex;
}
.user-name {
  position: relative;
  width: calc(100% - 50px);
}
.user-name-text {
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  display: inline-block;
  margin: 2px 0 0 10px;
  width: 100%;
}
.email-or-id {
  color: #919191;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  max-width: 500px;
  word-break: break-all;
  margin-top: 4px;
}
.tooltip-div {
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  position: absolute;
  visibility: hidden;
  background-color: #fff;
  z-index: 1;
  top: 42px;
  padding: 4px 8px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.5s ease;
}
.link-text:hover + .tooltip-div {
  visibility: visible;
  transition: opacity 0.5s ease;
  opacity: 1;
  transition-delay: 1s;
}
.max-users-msg {
  font-size: 10px;
  line-height: 48px;
  color: grey;
}
@media screen and (max-width: 959px) {
  .tab-title-row {
    padding: 30px 20px 0 20px;
  }
  .table-search-row {
    padding: 0 20px;
  }
  .table-div {
    padding: 0 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1903px) {
}
</style>
