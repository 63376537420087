<template>
  <admin-workspace-teams
    v-if="showTeamList"
    :drawerOpenPopupStyle="drawerOpenPopupStyle"
    :workspaceId="workspaceId"
    :workspaceDictionary="workspaceDictionary"
    :currentWorkspace="currentWorkspace"
    @enterTeam="enterTeam($event)"
  ></admin-workspace-teams>

  <admin-workspace-team-users
    v-else
    :drawerOpenPopupStyle="drawerOpenPopupStyle"
    @backToTeams="showTeamList = true"
    :workspaceDictionary="workspaceDictionary"
    :team="team"
  ></admin-workspace-team-users>
</template>

<script script>
import AdminWorkspaceTeams from "@/components/DAdmin/workspaceGroup/AdminWorkspaceTeams.vue";
import AdminWorkspaceTeamUsers from "@/components/DAdmin/workspaceGroup/AdminWorkspaceTeamUsers.vue";
export default {
  name: "AdminWorkspaceMain",
  components: {
    AdminWorkspaceTeams,
    AdminWorkspaceTeamUsers,
  },
  props: {
    drawerOpenPopupStyle: Object,
    workspaceId: String,
    workspaceDictionary: Object,
    currentWorkspace: Object,
  },
  data() {
    return {
      showTeamList: true,
      team: {},
    };
  },
  methods: {
    enterTeam(team) {
      this.team = team;
      this.showTeamList = false;
    },
  },
};
</script>

<style scoped></style>
