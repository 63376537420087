import axios from "axios";
import environment from "@/../env.js";

function getBaseUrl() {
  return environment.backendType === "firebase" ?
    environment.service[environment.env].admin :
    environment.service[environment.env]['china'].admin
}
const adminServer = axios.create({
  baseURL: getBaseUrl(),
});
const fixedToken = "rG5kXk0CDbhgF4RBlNoV";

/**
 * For global version
 * Get token for employee ID user to sign in
 * @param {*} adminUrl 
 * @param {*} param1 
 * @returns 
 */
export const getEmployeeIdLoginToken = async (adminUrl, { employeeId, password, clientKey }) => {
  if (!clientKey) {
    alert("client key is required for employee id login.");
    return;
  }
  const employeeIdMPObj = password ? { employeeIdMP: password } : {};
  const data = {
    token: fixedToken,
    employeeId,
    clientKey,
    ...employeeIdMPObj
  };
  let ok = true;
  let resData = {};
  let errorMessage;
  try {
    const url = `${adminUrl}admin/gettoken`;
    const axios_result = await axios.post(url, data);
    resData = axios_result.data;
  } catch (e) {
    ok = false;
    const errorCode = e.response.status
    const message = e.response.data
    errorMessage = message.includes("No DB user for clientKey-employeeId")
      ? INVALID_EMPLOYEE_ID_MSG : message;
    console.error(`Failed to get disposable pwd with status code: ${errorCode} - ${message}`);
  } finally {
    return { ok, data: resData, errorMessage };
  }
};

/**
 * For China version
 * When a user sign in with an employee ID in APAC, 
 * get the user email and temporary (disposable) password, and complete the login process
 * @param {*} param
 * @returns { ok: Boolean, data: { password: String, shared: Boolean } }
 * is also valid for “shared” account login in APAC
 */
export const getDisposablePwd = async ({ clientKey = "", employeeId = "", employeeIdMP = "" }) => {
  let ok = true;
  let data = {};
  let errorMessage;
  try {
    const response = await adminServer.post(`/admin/getDisposablePwd`, {
      tokenId: fixedToken,
      clientKey,
      employeeId,
      employeeIdMP
    });
    data = response.data;
  } catch (e) {
    ok = false;
    const errorCode = e.response.status
    const message = e.response.data;
    errorMessage = message.includes("No DB user for clientKey-employeeId")
      ? INVALID_EMPLOYEE_ID_MSG : message;
    console.error(`Failed to get disposable pwd with status code: ${errorCode} - ${message}`);
  } finally {
    return { ok, data, errorMessage };
  }
};

export const MASTER_PWD_INCORRECT_MSG = "Master password is incorrect.";
export const INVALID_EMPLOYEE_ID_MSG = "Invalid employee ID.";