<template>
  <section class="user-invite-create">
    <v-card width="466" color="#FFFFFF" class="popup-box fullscreen-center">
      <button class="close-btn" @click="$emit('close-popup')" :autotest="getAutotest('close')">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="title-div">
        <icon-base width="100" height="100">
          <d-icon-teams />
        </icon-base>
        <div>
          <div class="popup-title">
            <span v-if="showPopupType == 'create'">{{ $t("admin.createTeam") }}</span>
            <span v-if="showPopupType == 'edit'">{{ $t("admin.editTeam") }}</span>
          </div>
          <div>
            <v-form v-model="formValid" @submit.prevent autocomplete="off" :style="{ margin: '42px 0 72px 0' }">
              <v-text-field
                autofocus
                v-model="teamName"
                class="create-input"
                :label="$t('admin.teamName') + '*'"
                :rules="[rules.required, rules.noAllSpace, rules.teamNameAllowed]"
                autocomplete="chrome-off"
                @input="madeChanges = true"
                :autotest="getAutotest('name-input')"
              ></v-text-field>

              <v-menu
                v-if="showPopupType == 'create'"
                class="dropdown-btn-menu mt-4"
                transition="slide-y-transition"
                offset-y
              >
                <div slot="activator" class="dropdown-btn text-xs-left">
                  <v-text-field
                    ref="inputLanguageCode"
                    v-model="groupTextField"
                    class="input-language-code"
                    :rules="[rules.required]"
                    autotest="create-team-popup-workspace-dropdown"
                  >
                    <template slot="label">
                      <span class="input-lable-text">{{ $t("new.addToWorkspace") }}</span>
                    </template>
                  </v-text-field>
                  <v-icon small color="#8d909f" class="dropdown-arrow pt-2">keyboard_arrow_down</v-icon>
                </div>
                <d-workspaces-drop-menu v-model="selectedGroupId" :groupList="userGroupList" />
              </v-menu>
            </v-form>
          </div>
          <v-card-actions class="btn-div">
            <v-spacer></v-spacer>
            <v-btn
              v-if="showPopupType == 'create'"
              depressed
              round
              class="body-2 white--text text-capitalize btn-width"
              color="#4689F3"
              :disabled="disabled.create"
              @click="createTeam()"
              autotest="create-team-popup-create-btn"
              >{{ $t("all.create") }}</v-btn
            >
            <v-btn
              v-if="showPopupType == 'edit'"
              depressed
              round
              class="body-2 white--text text-capitalize btn-width"
              color="#4689F3"
              :disabled="disabled.edit"
              @click="saveTeam()"
              autotest="edit-team-popup-save-btn"
              >{{ $t("all.save") }}</v-btn
            >
          </v-card-actions>
        </div>
      </div>
    </v-card>
    <!-- Team already in the system. -->
    <d-alert v-model="isExistingTeam" type="alert" :message="$t('admin.alertTeamAlreadyInSystem')"></d-alert>

    <!-- Failed to edit Team. -->
    <d-alert v-model="isEditFailed" type="alert" :message="$t('admin.alertFailedEditTeam')"></d-alert>

    <div class="popup-close" @click="$emit('close-popup')"></div>
  </section>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconClose2 from "../icons/DIconClose2.vue";
import DIconTeams from "../icons/colored/DIconTeams.vue";
import DAlert from "../ui_components/DAlert.vue";
import DWorkspacesDropMenu from "@/components/DSkills/DWorkspacesDropMenu.vue";
import MixinDB from "@/components/MixinDB.vue";
import { updateTeam } from "@/server/teams-server.js";
import { checkAdminCommonCharacters } from "@/js/character-validator/character-validator.js";

export default {
  name: "AdminTeamPopup",
  props: {
    showPopupType: String,
    teamsData: Array,
    editTeamData: Object,
    currentGroupId: String,
    groupDictionary: Object,
    userGroupList: Array,
  },
  watch: {
    selectedGroupId: function () {
      this.groupTextField = this.groupDictionary[this.selectedGroupId];
    },
  },
  components: {
    IconBase,
    DIconClose2,
    DIconTeams,
    DAlert,
    DWorkspacesDropMenu,
  },
  mixins: [MixinDB],
  data() {
    return {
      teamName: "",
      formValid: false,
      madeChanges: false,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        teamNameAllowed: () => !this.teamNameNotAllowed || this.$t("rules.invalidName"),
      },
      isExistingTeam: false,
      isEditFailed: false,
      groupTextField: "",
      selectedGroupId: "",
      showPrivateIcon: false,
    };
  },
  computed: {
    disabled() {
      const isDisabled = !this.formValid;
      const isEditDisabled = isDisabled || this.teamName == this.editTeamData.name;
      return {
        create: isDisabled,
        edit: isEditDisabled,
      };
    },
    teamNameNotAllowed() {
      return checkAdminCommonCharacters(this.teamName);
    },
  },
  created() {
    if (this.showPopupType == "edit") {
      this.teamName = this.editTeamData.name;
    }
    for (const group of this.userGroupList) {
      if (group.private) {
        this.showPrivateIcon = true;
        break;
      }
    }
  },
  mounted() {
    if (this.showPopupType == "create" && this.currentGroupId.toLowerCase() != "all") {
      this.selectedGroupId = this.currentGroupId;
      this.groupTextField = this.groupDictionary[this.currentGroupId];
    }
  },
  methods: {
    getAutotest(tag) {
      if (this.showPopupType === "create") {
        switch (tag) {
          case "close":
            return "create-team-popup-close-btn";
          case "name-input":
            return "create-team-popup-name-input";
          default:
            return "";
        }
      }
      if (this.showPopupType === "edit") {
        switch (tag) {
          case "close":
            return "edit-team-popup-close-btn";
          case "name-input":
            return "edit-team-popup-name-input";
          default:
            return "";
        }
      }
    },
    createTeam() {
      if (this.checkExistingTeam()) {
        this.isExistingTeam = true;
        return;
      }
      this.$emit("add-team", {
        teamName: this.$escapeHtml(this.teamName),
        groupId: this.selectedGroupId,
      });
      this.$emit("close-popup");
    },
    async saveTeam() {
      if (this.checkExistingTeam()) {
        this.isExistingTeam = true;
        return;
      }
      const { ok } = await updateTeam({
        teamId: this.editTeamData.id,
        name: this.teamName,
      });
      if (ok) {
        this.$emit("update-team", {
          id: this.editTeamData.id,
          name: this.teamName,
        });
        this.$emit("close-popup");
      } else {
        this.isEditFailed = true;
      }
    },
    checkExistingTeam() {
      const existingData = this.teamsData.find(
        (item) =>
          item.name.toLowerCase() === this.teamName.toLowerCase() &&
          (item.group === this.selectedGroupId || item.group === this.editTeamData.group)
        // allow same name in different group
      );
      return existingData != null && existingData.id != this.editTeamData.id;
    },
  },
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.popup-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 28px 20px 0 20px;
}
.popup-title {
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-top: 12px;
}

.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.no-text-transform {
  text-transform: none !important;
}
.btn-div {
  margin-top: 32px;
}
.btn-width {
  width: 120px;
}
.dropdown-btn-menu {
  width: 100%;
}
.dropdown-btn {
  position: relative;
  width: 100%;
  left: 0;
  height: 46px;
}
.dropdown-text {
  position: relative;
  top: -2px;
  padding: 0;
  font-size: 16px;
  line-height: 19px;
}
.dropdown-arrow {
  position: absolute;
  right: 0;
  top: 20px;
}
.input-language-code {
  pointer-events: none;
}
</style>
