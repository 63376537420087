<template>
  <section class="user-privacy-policy">
    <!-- desktop -->
    <div v-if="!isMobileDevice" class="privacy-policy-popup fullscreen-center">
      <button class="dh-close-privacy-policy" @click="$emit('close-policy')">
        <icon-base class="close-btn" width="24" height="24">
          <d-icon-close2 />
        </icon-base>
      </button>
      <div class="popup-content">
        <div class="content" v-html="policyText"></div>
      </div>
    </div>

    <!-- mobile -->
    <div v-else class="mobile-fullscreen">
      <div>
        <button class="dh-close-privacy-policy-mobile" @click="$emit('close-policy-mobile')">
          <icon-base color="#FFFFFF" width="16" height="16">
            <d-icon-arrow-back />
          </icon-base>
        </button>
      </div>
      <div class="mobile-popup-content">
        <div class="content" v-html="policyText"></div>
      </div>
    </div>

    <div v-if="!isMobileDevice" class="close-bg" @click="$emit('close-policy')"></div>
  </section>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";

export default {
  name: "UserPrivacyPolicyPopup",
  props: {
    isMobileDevice: Boolean,
    isTabletDevice: Boolean,
    popupContent: String
  },
  created() {},
  mounted() {
    this.getPolicyContent();
  },
  updated() {},
  data() {
    return {
      policyText: "",
    };
  },
  components: {
    IconBase,
    DIconClose2,
    DIconArrowBack,
  },
  methods: {
    getPolicyContent() {
      let url;
      switch (this.popupContent) {
        case "user-agreement-zh":
          url = this.$download.userAgreementZh;
          break;
        case "privacy-policy-zh":
          url = this.$download.privacyPolicyZh;
          break;
        case "privacy-policy-en":
          url = this.$download.privacyPolicyEn;
          break;
        case "abi-upload-policy-global":
          url = this.$download.abiUploadPolicyGlobal;
          break;
        case "abi-upload-policy-china":
          url = this.$download.abiUploadPolicyChina;
          break;
        default:
          url = this.$download.privacyPolicyEn;
      }
      const self = this;
      fetch(url)
        .then(function (response) {
          // The API call was successful!
          return response.text();
        })
        .then(function (html) {
          //get html string
          self.policyText = html;
        })
        .catch(function (err) {
          // There was an error
          self.policyText = "Failed to load Content.";
        });
    },
  },
};
</script>

<style scoped>
.user-privacy-policy {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 998;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.privacy-policy-popup {
  max-width: 1000px;
  width: calc(100% - 20px);
  margin: 0 auto;
  padding: 0 44px 0 0;
  background-color: #17181a;
  height: calc(100% - 200px);
}
.mobile-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  background-color: #1e2023;
}
.popup-content {
  position: relative;
  margin: 72px 0 48px 0;
  padding: 0 72px 72px 72px;
  height: calc(100% - 144px);
  overflow-y: scroll;
}
.mobile-popup-content {
  position: absolute;
  top: 100px;
  padding: 0 32px 64px 32px;
  height: calc(100% - 100px);
  overflow-y: scroll;
}
.popup-content::-webkit-scrollbar {
  width: 3px;
  background-color: rgba(255, 255, 255, 0.1);
}
.popup-content::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #4d4d4d;
}
.mobile-title {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  text-align: center;
}
.dh-close-privacy-policy-mobile {
  position: fixed;
  top: 39px;
  left: 14px;
  z-index: 1;
}
.content {
  position: relative;
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: left;
}
.content ::v-deep p {
  margin: 0;
}
.content ::v-deep div {
  background-color: #17181a;
  position: relative;
  width: 100%!important;
  margin: 0!important;
}
.mobile-popup-content .content ::v-deep div {
  background-color: #1e2023;
}
.content ::v-deep div p {
  padding: 0;
  width: 100%;
  margin-bottom: 18px;
  font-size: 16px;
  line-height: 28px;
}
.content ::v-deep div p span {
  font-size: 16px;
  line-height: 28px;
}
.content ::v-deep .p0{
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  left: 0;
  text-align: left;
}
.content ::v-deep strong{
  color: #ffffff;
  font-size: 24px;
  line-height: 32px;
}
.close-btn {
  position: absolute;
  right: 34px;
  top: 32px;
  z-index: 1;
  color: #8D909F;
}
.close-btn:hover {
  color: #ffffff;
}
.close-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>