export const namespaced = true;
import { fetchWorkflowReviewers } from "@/server/api-server";

export const state = {
  reviewers: [],
};

export const mutations = {
  STORE_REVIEWERS(state, payload = []) {
    state.reviewers = payload;
  },
};

export const getters = {
  getters_get_sub_category_by_id: state => subcategoryId =>
    state.subCategories.find(({ id }) => id === subcategoryId) || {},
  getters_get_reviewer: state => categoryId => state.categories.find(({ id }) => id === categoryId) || {},
};

export const actions = {
  async fetchReviewers({ commit }, workflowId) {
    const { ok, data } = await fetchWorkflowReviewers(workflowId);
    const result = ok ? data.item : [];
    commit("STORE_REVIEWERS", result);
  },
};
