<template>
  <div class="scroll-wrap">
    <div class="scroll-div">
      <div class="popup-div content-center">
        <div class="user-avatar">
          <d-avatar :size="130" :userId="formData.id" class="avatar-pic" />
          <div v-if="showReviewerIcon && isReviewer" class="avatar-icon reviewer-icon">
            <icon-base width="32" height="32" viewBox="0 0 20 20" color="white">
              <d-icon-reviewer />
            </icon-base>
          </div>
          <div v-else-if="isOrgAdmin" class="avatar-icon org-icon">
            <icon-base width="24" height="24" color="white">
              <d-icon-org-admin />
            </icon-base>
          </div>
          <div v-if="formData.shared" class="avatar-icon shared-icon">
            <icon-base width="24" height="24" color="white">
              <d-icon-shared-user />
            </icon-base>
          </div>
          <div class="active-tag" autotest="edit-user-popup-status-display">
            <span v-if="isActive" class="text-no-wrap px-3">
              <div class="green-dot"></div>
              {{ $t("admin.active") }}
            </span>
            <span v-else class="text-no-wrap px-3">
              <div class="red-dot"></div>
              {{ $t("admin.inactive") }}
            </span>
          </div>
        </div>
        <div class="popup-bg">
          <div class="name-div">
            <d-tooltip-show-all :text="formData.displayName" maxWidth="500">
              <span class="name-text" autotest="edit-user-popup-name-display">{{ formData.displayName }}</span>
            </d-tooltip-show-all>
            <div class="email-text">
              <d-tooltip-show-all v-if="isEmailUser" :text="formData.email" maxWidth="500">
                <span class="email-text" autotest="edit-user-popup-email-display">{{ formData.email }}</span>
              </d-tooltip-show-all>

              <d-tooltip-show-all v-else :text="employeeId" maxWidth="500">
                <span class="email-text" autotest="edit-user-popup-email-display">{{ employeeId }}</span>
              </d-tooltip-show-all>
            </div>

            <button class="btn-close" @click="$emit('closeUserEditor')" autotest="edit-user-popup-close-btn">
              <icon-base color="#FFFFFF" width="18" height="18">
                <d-icon-close-light />
              </icon-base>
            </button>
            <!-- only org admin can active/inactive user -->
            <div class="switch-box" v-if="isShowSwitchBtn">
              <div class="switch-item">
                <div>{{ $t("admin.userActive") }}</div>
                <v-switch
                  :disabled="disableActiveSwitch"
                  v-model="isActive"
                  color="#0EF28F"
                  class="switch-btn"
                  @change="madeChanges = true"
                  autotest="edit-user-popup-active-toggle"
                ></v-switch>
              </div>
            </div>
          </div>
          <div class="tabs-row">
            <d-tabs
              v-model="currentTab"
              :tabNames="tabNames"
              light
              autoWidth
              height="48"
              :autotest="['edit-user-popup-profile-tab']"
            ></d-tabs>
          </div>
          <div class="form-div">
            <v-form v-model="formValid">
              <v-layout row>
                <v-flex xs6 px-4>
                  <d-text-field
                    v-model="fullName"
                    autofocus
                    :label="$t('all.namePerson') + '*'"
                    :rules="[rules.required, rules.noAllSpace, rules.displayNameAllowed]"
                    :disabled="!canEditData"
                    autocomplete="chrome-off"
                    @input="madeChanges = true"
                    autotest="edit-user-popup-name-input"
                  ></d-text-field>
                </v-flex>
                <v-flex xs6 px-4>
                  <d-text-field
                    v-if="isEmailUser"
                    v-model="email"
                    :label="$t('all.email') + '*'"
                    disabled
                    autotest="edit-user-popup-email-input"
                  ></d-text-field>
                  <d-text-field
                    v-else-if="formData.shared"
                    v-model="employeeId"
                    :label="$t('admin.sharedAccountId') + '*'"
                    disabled
                    autotest="edit-user-popup-email-input"
                  ></d-text-field>
                  <d-text-field
                    v-else
                    v-model="employeeId"
                    :label="$t('all.employeeId') + '*'"
                    disabled
                    autotest="edit-user-popup-email-input"
                  ></d-text-field>
                </v-flex>
              </v-layout>
              <!-- Org Admin -->
              <v-layout v-if="isOrgAdmin" style="height: 200px" mt-4>
                <v-flex xs12 px-4>
                  <div class="content-box">
                    <div class="role-label">
                      {{ $t("admin.belongsToWorkspaces") }} ({{ userRoles["admin-organization"].length }})
                    </div>
                    <div class="workspaces-box" autotest="edit-user-popup-workspaces-admin-display">
                      <div
                        v-for="(workspaceId, idx) in userRoles['admin-organization']"
                        :key="idx"
                        class="workspace-name"
                        autotest="edit-user-popup-workspace-label"
                      >
                        {{ getGroupName(workspaceId) }}
                      </div>
                    </div>
                  </div>
                </v-flex>
              </v-layout>

              <!-- Shared Account -->
              <v-layout v-else-if="formData.shared" style="height: 200px" mt-4>
                <v-flex xs12 px-4>
                  <div class="content-box">
                    <div class="role-label">{{ $t("all.viewer") }} ({{ userRoles.viewer.length }})</div>
                    <div class="workspaces-box" autotest="edit-user-popup-workspaces-admin-display">
                      <div
                        v-for="(workspaceId, idx) in userRoles.viewer"
                        :key="idx"
                        class="workspace-name"
                        autotest="edit-user-popup-workspace-label"
                      >
                        {{ getGroupName(workspaceId) }}
                      </div>
                    </div>
                  </div>
                </v-flex>
              </v-layout>

              <!-- Other Roles -->
              <v-layout v-else style="height: 200px" mt-4>
                <v-flex xs4 px-4>
                  <div class="content-box">
                    <div class="role-label">{{ $t("all.workspaceAdmin") }} ({{ userRoles["admin-group"].length }})</div>
                    <div class="workspaces-box" autotest="edit-user-popup-workspaces-admin-display">
                      <div
                        v-for="(workspaceId, idx) in userRoles['admin-group']"
                        :key="idx"
                        class="workspace-name"
                        autotest="edit-user-popup-workspace-label"
                      >
                        {{ getGroupName(workspaceId) }}
                      </div>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs4 px-4>
                  <div class="content-box">
                    <div class="role-label">{{ $t("all.publisher") }} ({{ userRoles.publisher.length }})</div>
                    <div class="workspaces-box" autotest="edit-user-popup-publisher-display">
                      <div
                        v-for="(workspaceId, idx) in userRoles.publisher"
                        :key="idx"
                        class="workspace-name"
                        autotest="edit-user-popup-workspace-label"
                      >
                        {{ getGroupName(workspaceId) }}
                      </div>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs4 px-4>
                  <div class="content-box">
                    <div class="role-label">{{ $t("all.viewer") }} ({{ userRoles.viewer.length }})</div>
                    <div class="workspaces-box" autotest="edit-user-popup-viewer-display">
                      <div
                        v-for="(workspaceId, idx) in userRoles.viewer"
                        :key="idx"
                        class="workspace-name"
                        autotest="edit-user-popup-workspace-label"
                      >
                        {{ getGroupName(workspaceId) }}
                      </div>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-form>
          </div>

          <v-card-actions class="btn-div">
            <v-spacer></v-spacer>
            <v-btn
              depressed
              round
              outline
              class="body-2 text-capitalize btn-width"
              color="#4689f4"
              @click="$emit('closeUserEditor')"
              autotest="edit-user-popup-cancel-btn"
              >{{ $t("all.cancel") }}</v-btn
            >
            <v-btn
              v-show="canEditData"
              :disabled="!madeChanges || !formValid"
              depressed
              round
              class="body-2 text-capitalize white--text btn-width ml-3"
              color="#4689f4"
              @click="saveChanges()"
              autotest="edit-user-popup-save-btn"
              >{{ $t("all.save") }}</v-btn
            >
          </v-card-actions>
        </div>
      </div>
    </div>
    <d-alert v-model="alert.show" :type="alert.type" :message="alert.msg" upper></d-alert>
  </div>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import IconBase from "@/components/IconBase.vue";
import DIconCloseLight from "@/components/icons/player/DIconCloseLight.vue";
import DIconOrgAdmin from "@/components/icons/DIconOrgAdmin.vue";
import DIconSharedUser from "@/components/icons/DIconSharedUser.vue";
import DIconReviewer from "@/components/icons/DIconReviewer.vue";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import DTabs from "@/components/ui_components/DTabs.vue";
import DTextField from "@/components/ui_components/DTextField.vue";
import DTooltipShowAll from "@/components/ui_components/DTooltipShowAll.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import { isReviewer } from "@/js/user-role/user-role.js";
import { checkDisplayNameCharacters } from "@/js/character-validator/character-validator.js";

export default {
  name: "AdminFormUserEditor",
  props: {
    formData: Object,
    roleOptions: Array,
    userRole: String,
    groupId: String,
    parent: String,
    groupDictionary: Object,
    showReviewerIcon: Boolean,
  },
  data() {
    return {
      fullName: "",
      employeeId: "",
      role: "",
      email: "",
      isEmailUser: false,
      isActive: false,
      group: "",
      groupAdded: [],
      formValid: false,
      madeChanges: false,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        displayNameAllowed: () => !this.displayNameNotAllowed || this.$t("rules.invalidName"),
      },
      isReviewer: false,
      isOrgAdmin: false,
      currentTab: 0,
      tabNames: [this.$t("admin.profile")],
      canEditData: false,
      userRoles: {
        "admin-organization": [],
        "admin-group": [],
        viewer: [],
        publisher: [],
      },
      alert: {
        show: false,
        type: "info",
        msg: "",
      },
    };
  },
  mixins: [MixinDB, MixinUser, MixinAdmin],
  components: {
    IconBase,
    DIconCloseLight,
    DIconOrgAdmin,
    DIconSharedUser,
    DIconReviewer,
    DAvatar,
    DTabs,
    DTextField,
    DTooltipShowAll,
    DAlert,
  },
  created() {
    this.setUpForm();
    /** get permission to check enableWorkflowReview */
    const { enableWorkflowReview = false } = this.getCustomizedPermissions();
    const reviewGroups = this.formData.reviewGroups || [];
    const roles = this.formData.roles;
    this.isReviewer = isReviewer({ enableWorkflowReview, groupId: this.groupId, reviewGroups, roles });
    /** check user is org admin */
    this.isOrgAdmin = this.isOrgAdmin = this.formData.role === "admin-organization";
    this.getEditDataRight();
    this.getUserRoles();
  },
  mounted() {},
  computed: {
    isShowSwitchBtn() {
      const isUserSelf = this.formData.id === this.$userProfile.id;
      const isWorkspaceRole = this.userRole === "admin-group";
      const isUserBelongToOtherWorkspace = !!this.formData.groups.find(
        (group) => group !== this.$defaultGroup && group !== this.groupId
      );
      const isUserOrgAdmin = this.formData.role === "admin-organization";
      return this.userRole === "admin-organization"
        ? !isUserSelf
        : !isUserSelf && !isUserBelongToOtherWorkspace && isWorkspaceRole && !isUserOrgAdmin;
    },
    disableActiveSwitch() {
      return this.formData.status === "invited";
    },
    displayNameNotAllowed() {
      return checkDisplayNameCharacters(this.fullName);
    },
  },
  methods: {
    getEditDataRight() {
      if (this.userRole === "admin-organization") {
        this.canEditData = true;
        return;
      }
      if (this.userRole === "admin-group") {
        // workpsace admin can not edit organization admin's data
        this.canEditData = this.formData.role !== "admin-organization";
      }
    },
    getUserRoles() {
      const roles = this.formData.roles;
      for (var key in roles) {
        if (roles.hasOwnProperty(key)) {
          this.userRoles[roles[key]].push(key);
        }
      }
    },
    getGroupName(id) {
      if (this.groupDictionary[id] === "general") {
        return "General";
      }
      return this.groupDictionary[id] || "";
    },
    async disableUser(disabled) {
      try {
        const idToken = await this.getIdToken();
        const groupIdObj = this.userRole === "admin-group" ? { groupId: this.groupId } : {};
        const data = {
          idToken,
          organization: this.$organization,
          email: this.email,
          disabled: disabled,
          ...groupIdObj,
        };
        await this.sendAdminRequest("change", "disable-user", data);
      } catch (e) {
        console.error(e);
      }
    },
    async saveChanges() {
      let saveSuccess = true;
      try {
        // edit user name
        this.madeChanges = false;
        if (this.formData.displayName != this.fullName) {
          let data = {};
          if (this.userRole === "admin-organization") {
            data = {
              email: this.email,
              displayName: this.fullName,
            };
          }
          if (this.userRole === "admin-group") {
            data = {
              email: this.email,
              displayName: this.fullName,
              groupId: this.groupId,
            };
          }
          await this.sendAdminRequest("change", "user-name-change", data);
          await this.$store.dispatch("updateUser", {
            user: this.formData,
            update: {
              displayName: this.fullName,
              status: this.isActive ? "active" : "inactive",
            },
            level: "org",
            action: "update",
          });
        }
        // active/inactive user
        if (this.userRole === "admin-organization" || this.userRole === "admin-group") {
          let disabled;
          if (this.formData.status === "active" && !this.isActive) {
            disabled = true; // inactive user
          }
          if (this.formData.status !== "active" && this.isActive) {
            disabled = false; // active user
          }
          if (typeof disabled === "boolean") {
            await this.disableUser(disabled);
            await this.$store.dispatch("updateUser", {
              user: this.formData,
              update: {
                displayName: this.fullName,
                disabled,
                status: disabled ? "inactive" : "active",
              },
              action: "update",
            });
          }
        }
        //update Search users
        this.$emit("update-found-user", {
          user: this.formData,
          update: {
            displayName: this.fullName,
            status: this.isActive ? "active" : "inactive",
          },
        });
      } catch (e) {
        saveSuccess = false;
      } finally {
        // show alert
        if (saveSuccess) {
          this.alert.msg = this.$t("editor.alertIsSaved");
          this.alert.type = "success";
        } else {
          this.alert.msg = this.$t("admin.alertFailedSaveChanges");
          this.alert.type = "error";
        }
        this.alert.show = true;
        setTimeout(() => {
          this.$emit("closeUserEditor");
        }, 1000);
      }
    },
    setUpForm() {
      this.fullName = this.formData.displayName;
      if (this.formData.status == "active" || this.formData.status == "激活") {
        this.isActive = true;
      }
      this.employeeId = this.formData.employeeId;
      this.email = this.formData.email;
      if (this.formData.employeeId == "-" || !this.formData.passwordless) {
        this.isEmailUser = true;
      } else {
        this.isEmailUser = false;
      }
      if (this.formData.groups) {
        //need to get latest data from db because the user could have been updated from another place
        this.getDocument("users", this.formData.id)
          .then((user) => {
            this.groupAdded = user.groups;
          })
          .catch((err) => {
            this.groupAdded = this.formData.groups.slice();
          });
      }
    },
    getNameInitials(name) {
      if (name.length > 0) {
        let initials = "";
        const nameArray = name.split(" ");
        initials = nameArray[0].substring(0, 1).toUpperCase();
        if (nameArray.length > 1) {
          initials += nameArray[nameArray.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      }
    },
  },
};
</script>
<style scoped>
.scroll-wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
}
.scroll-wrap:hover::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
}
.scroll-div {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 760px;
  min-width: 960px;
}
.content-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.popup-div {
  position: relative;
  height: 714px;
  max-width: 1088px;
  width: 90%;
  overflow: hidden;
}
.popup-bg {
  position: relative;
  top: 40px;
  height: 674px;
  width: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #f9f9f9;
  border-radius: 4px;
  overflow: hidden;
}
.user-avatar {
  position: absolute;
  left: calc(50% - 65px);
  top: 0;
  z-index: 1;
  height: 130px;
  width: 130px;
}
.avatar-pic {
  border: 1px solid #dadada;
  border-radius: 50%;
}
.avatar-icon {
  position: absolute;
  right: -8px;
  top: 0;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  padding-top: 7px;
}
.reviewer-icon {
  padding-top: 5px;
  background: #4552ae;
}
.org-icon {
  background-color: #ffbf18;
}
.shared-icon {
  padding-top: 8px;
  background-color: #a9a9a9;
}
.active-tag {
  position: absolute;
  bottom: -12px;
  box-sizing: border-box;
  height: 33px;
  min-width: 98px;
  border: 1px solid #e7e7e7;
  border-radius: 19px;
  background-color: #ffffff;
  padding-top: 3px;
  transform: translate(-50%, 0);
  left: 50%;
}
.active-tag span {
  color: #3f3f3f;
  font-size: 16px;
  line-height: 24px;
}
.red-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: #e03535;
  margin: 0 4px 1px 0;
}
.green-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: #0ef28f;
  margin: 0 4px 1px 0;
}
.name-div {
  position: relative;
  height: 180px;
  width: 100%;
  background: linear-gradient(180deg, #2876be 0%, #4689f3 100%);
  padding-top: 116px;
}

.name-text {
  color: #ffffff;
  height: 30px;
  font-size: 24px;
  line-height: 30px;
  margin: 0 auto;
}
.email-text {
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  margin: 3px auto 0 auto;
  max-width: 70%;
}
.btn-close {
  position: absolute;
  right: 26px;
  top: 26px;
}
.switch-box {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 24px;
  height: 64px;
  display: flex;
}
.switch-item {
  position: relative;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}
.switch-btn {
  position: absolute;
  left: calc(50% - 20px);
  margin: 0;
  padding: 0;
}
.tabs-row {
  position: relative;
  height: 48px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #dadada;
  display: flex;
  padding: 0 24px;
}
.form-div {
  position: relative;
  width: 100%;
  height: 358px;
  padding: 48px 0 0 0;
}
.content-box {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: left;
}
.role-label {
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
}
.input-box {
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 12px;
  height: 40px;
}
.input-box input {
  position: relative;
  height: 100%;
  width: 100%;
  color: #3f3f3f;
  font-size: 16px;
  line-height: 16px;
}
.input-box input:focus {
  outline-width: 0;
  outline: 0;
}
.workspaces-box {
  height: 120px;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 16px 0 0 16px;
  overflow: auto;
}
.workspaces-box:hover::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.workspaces-box:hover::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
}
.workspace-name {
  height: 30px;
  border-radius: 15px;
  background-color: rgba(70, 137, 243, 0.2);
  color: #52545d;
  font-size: 16px;
  line-height: 14px;
  display: inline-block;
  padding: 8px 12px;
  margin: 0 6px 8px 0;
  max-width: calc(100% - 16px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-width {
  min-width: 160px;
}
.btn-div {
  position: absolute;
  width: 100%;
  height: 88px;
  bottom: 0;
  left: 0;
  padding: 24px;
  border-top: 1px solid #e5e5e5;
  background-color: #ffffff;
}
</style>
