<template>
  <section>
    <admin-setting-permission-item
      :allow="allowSharedAccount"
      :title="$t('admin.sharedAccount')"
      :subtitle="$t('admin.allowsSharedAccountMsg')"
      autotest="shared-account-toggle"
      @click-switch="showConfirmation = true"
    />

    <d-change-permission-confirmation
      v-if="showConfirmation"
      :title="$t('admin.sharedAccount')"
      :content="allowSharedAccount ? $t('admin.disableSharedAccountMsg') : $t('admin.enableSharedAccountMsg')"
      :style="drawerOpenPopupStyle"
      @close-popup="showConfirmation = false"
      @change-permission="changeSharedAccountLogin()"
    ></d-change-permission-confirmation>
  </section>
</template>

<script>
import AdminSettingPermissionItem from "@/components/DAdmin/Settings/AdminSettingPermissionItem.vue";
import DChangePermissionConfirmation from "@/components/ui_components/DChangePermissionConfirmation.vue";
import { isEnableSharedAccountFn } from "@/js/user/share-account.js";
import { updateOrganizationSettings } from "@/server/employeeid-login-server.js";
import { mapMutations } from "vuex";

export default {
  name: "AdminSettingSharedAccount",
  props: {
    drawerOpenPopupStyle: Object,
  },
  components: {
    AdminSettingPermissionItem,
    DChangePermissionConfirmation,
  },
  watch: {},
  created() {
    this.checkIsEnableSharedAccount();
  },
  mounted() {},
  data() {
    return {
      allowSharedAccount: true,
      showConfirmation: false,
    };
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    async changeSharedAccountLogin() {
      this.showConfirmation = false;
      const toEnable = !this.allowSharedAccount;
      const updateData = { sharedAccountLogin: toEnable };

      try {
        await updateOrganizationSettings({
          organization: this.$organization,
          updateData,
        });

        this.allowSharedAccount = toEnable;
        const message = toEnable
          ? this.$t("admin.alertEnableSharedAccountSuccess")
          : this.$t("admin.alertDisableSharedAccountSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } catch (error) {
        const message = toEnable
          ? this.$t("admin.alertEnableSharedAccountFailed")
          : this.$t("admin.alertDisableSharedAccountFailed");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
    async checkIsEnableSharedAccount() {
      const response = await isEnableSharedAccountFn({
        clientKey: this.$clientKey,
      });
      this.allowSharedAccount = response.isEnabled;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
