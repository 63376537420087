import environment from "../../../env.js";
const StudioRoute = environment.workspaceGroup
  ? [
      {
        path: "/editorexplorer",
        name: "EditorExplorer",
        component: () => import("@/components/DStudioWorkspaceGroup/RedirectToDefaultWorkspaceGroup"),
        meta: { auth: true },
      },
      {
        path: "/editor/workspaceGroup/:workspaceGroupId",
        name: "StudioWorkspaceGroup",
        component: () => import("@/components/DStudioWorkspaceGroup/StudioWorkspaceGroup"),
        meta: { auth: true },
      },
      {
        path: "/editor/workspace/:workspaceId",
        name: "StudioWorkspace",
        component: () => import("@/components/DStudioWorkspace/StudioWorkspace"),
        meta: { auth: true },
      },
    ]
  : [
      {
        path: "/editorexplorer",
        name: "EditorExplorer",
        component: () => import("@/components/EditorExplorer"),
        meta: { auth: true },
        props: true,
      },
    ];

const EditorRoute = [
  ...StudioRoute,
  {
    path: "/EditorMain/:workflowId",
    name: "EditorMain2.5",
    props: true,
    component: () => import("../../components/DEditor2.0/EditorMain"),
    redirect: { name: "Transcription" },
    meta: { auth: true },
    children: [
      {
        path: "Transcription",
        name: "Transcription",
        props: true,
        meta: { order: 1 },
        component: () => import("../../components/DEditor2.0/Transcription"),
      },
      {
        path: "Segmentation",
        name: "Segmentation",
        props: true,
        meta: { order: 2 },
        component: () => import("../../components/DEditor2.0/Segmentation"),
      },
    ],
  },
];

export default EditorRoute;
