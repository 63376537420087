<template>
  <div class="d-text-field">
    <div 
      v-if="inputText || !placeholder"
      class="field-label"
      :class="{'field-label--isLabelOnBorder': isLabelOnBorder, 'field-label--dark': dark}"
    >{{ label }}</div>
    <div class="input-box" :class="{'input-box--dark': dark}">
      <div v-if="chip" class="input-box--chip">
        {{inputText}}
      </div>
      <v-text-field
        v-else
        v-model="inputText"
        solo
        flat
        single-line
        background-color="transparent"
        :dark="dark"
        class="input-box-field"
        :class="{'input-readonly':readonly}"
        :label="''"
        :rules="rules"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :append-icon="appendIcon"
        :type="type"
        :placeholder="placeholder"
        :readonly="readonly"
        @input="$emit('input', inputText)"
        @change="$emit('change')"
        @click:append="$emit('click-append')"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "DTextField",
  props: {
    value: String, //for v-model value
    label: String,
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hint: String,
    autocomplete: String,
    appendIcon: String,
    type: String,
    placeholder: String,
    dark: {
      type: Boolean,
      default: false
    },
    isLabelOnBorder: {
      type: Boolean,
      default: false
    },
    chip: {
      type: Boolean,
      default: false
    }
  },
  watch: {},
  created() {
    this.inputText = this.value;
  },
  mounted() {},
  data() {
    return {
      inputText: "",
    };
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.d-text-field {
  position: relative;
  width: 100%;
  text-align: left;
  padding-bottom: 24px;
}
.field-label {
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
  &--isLabelOnBorder {
    position: absolute;
    z-index: 1;
    top: -0.6rem;
    left: 1rem;
    padding: 0 0.5rem;
    color: #9f9f9f;
    background-color: #ffffff;
    font-weight: 400;
  }
  &--dark {
    color: #9397A6;
    background: #282424;
  }
}
.input-box {
  position: relative;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 2px 12px 0 12px;
  height: 40px;
  &--dark{
    border: 1px solid #33353A;
    background: #242424;
  }
  &--chip{
    background-color: #9397A6;
    position: absolute;
    max-width: calc(100% - 16px);
    background: #DAE7FC;
    border-radius: 16px;
    height: 24px;
    top: 7.5px;
    left: 8px;
    padding: 4px 12px 0 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #52545D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.input-box-field {
  position: absolute;
  left: 0;
  top: -4px;
  width: 100%;
  margin: 0;
}
.input-box-field ::v-deep .v-text-field__details {
  margin-top: -10px;
}
.input-readonly ::v-deep input {
  color: rgba(147, 151, 166, 0.5)!important;
}

</style>
