import axios from "axios";
import environment from "../../env";
import authChoice from "../../auth";

const settingsServer = axios.create({
  baseURL:
    environment.backendType === "firebase"
      ? environment.service[environment.env].admin
      : environment.service[environment.env]["china"].admin,
});
settingsServer.interceptors.request.use(
  async config => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  error => Promise.resolve(error)
);

export async function updateCompanyLogo({ organization, logo, watermark }) {
  try {
    const requestBody = {
      logo,
      watermark,
    };
    const { data } = await settingsServer.put(`/v1/organization-settings/${organization}/logo`, requestBody);
    return { ...data, ok: data.success };
  } catch (error) {
    console.log(error);
    return { ok: false, error, message: error.message };
  }
}

export async function fetchCompanyLogo({ organization }) {
  try {
    const { data } = await settingsServer.get(`/v1/organization-settings/${organization}/logo`);
    return { ...data, ok: data.success };
  } catch (error) {
    console.log(error);
    return { ok: false, error, message: error.message };
  }
}

export async function deleteCompanyLogo({ organization }) {
  try {
    const { data } = await settingsServer.delete(`/v1/organization-settings/${organization}/logo`);
    return { ...data, ok: data.success };
  } catch (error) {
    console.log(error);
    return { ok: false, error, message: error.message };
  }
}
