<template>
  <v-dialog v-model="value" class="PDFPopup" persistent fullscreen>
    <div class="PDFPopup__control" :style="{height: controlHeight}" v-if="isShowControl">
      <button
        @click="closePopup"
        :style="{transform: `translateY(${buttonTranslateY}%)`}"
      >
        <icon-base iconColor="rgb(241, 241, 241)" width="20" height="20">
          <d-icon-close2 />
        </icon-base>
      </button>
    </div>
    <iframe v-if="PDFLink" :src="PDFLink" class="PDFPopup__iframe" @load="load" />
  </v-dialog>
</template>

<script>
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import IconBase from "../IconBase";
import { getBrowserVersion } from "../../js/getBrowserVersion";

export default {
  name: "DPDFPopup",
  components: { DIconClose2, IconBase },
  mounted() {
    const { browser } = getBrowserVersion();
    switch (browser) {
      case "Firefox":
        this.controlHeight = "28px";
        this.buttonTranslateY = 17;
        break;
      case "Chrome":
        this.controlHeight = "44px";
        this.buttonTranslateY = 50;
        break;
    }
    window.onpopstate = this.closePopup;
  },
  beforeDestroy() {
    window.onpopstate = null;
  },
  data() {
    return {
      controlHeight: "40px",
      buttonTranslateY: 50,
      isShowControl: false,
    };
  },
  methods: {
    closePopup() {
      if (location.pathname.match(/\/PDFPopup/)) {
        history.back();
      }
      this.$emit("cleanPDFLink");
      this.$emit("input", false);
      this.isShowControl = false;
    },
    load() {
      if (this.PDFLink) {
        this.isShowControl = true;
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          history.pushState({}, null, "/PDFPopup/" + this.$route.params.id);
        }
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      require: true,
    },
    PDFLink: {
      type: String,
      require: true,
    },
  },
};
</script>

<style scoped lang="scss">
.PDFPopup {
  position: relative;
  &__control {
    position: fixed;
    z-index: 10;
    font-size: 16px;
    width: 140px;
    height: 40px;
    right: 0;
    top: 2px;
    background-color: rgb(50, 54, 57);

    button {
      transform: translateY(50%);
      position: absolute;
      right: 30px;
      padding-top: 2px;
    }
  }

  &__iframe {
    width: 100%;
    height: calc(100vh - 6px);
    border: none;
  }
}
</style>
