<template>
  <section class="complianceWireExport">
    <p class="mb-4 complianceWireExport__formTitle">
      {{ $t("player.settings") }}
    </p>
    <v-form autocomplete="off">
      <v-flex xs12 class="mb-4">
        <d-text-field
          @input="$emit('setCode', $event)"
          :value="code"
          :label="$t('builder.code')"
          isLabelOnBorder
          class="complianceWireExport__formText"
        />
      </v-flex>

      <v-flex xs12 class="mb-4">
        <v-menu
          v-if="ulOrganizations.length > 0"
          transition="slide-y-transition"
          bottom
          left
          offset-y
          open-on-click
          class="complianceWireExport__formDropdownMenu"
        >
          <button @click.prevent slot="activator" class="complianceWireExport__formButton">
            <span class="complianceWireExport__formDropdownLabel">{{ $t("builder.organization") }}</span>
            <span class="complianceWireExport__formDropdownValue">{{ `${selectedUlOrganization}` }}</span>
            <v-spacer></v-spacer>
            <v-icon small color="#8D909F" class="sort-by-arrow">keyboard_arrow_down</v-icon>
          </button>
          <v-list dark dense>
            <v-list-tile
              v-for="organization in ulOrganizations"
              @click="setUlOrganization(organization)"
              :key="organization"
              :class="{ active: selectedUlOrganization === organization }"
              class="complianceWireExport__formList"
            >
              <v-list-tile-title class="body-1">
                {{ `${organization}` }}
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-flex>

      <v-flex xs12 class="mb-4">
        <v-menu
          v-if="ulOrganizations.length > 0"
          v-model="showMenu"
          :close-on-content-click="true"
          transition="slide-y-transition"
          offset-y
          min-width="auto"
          dark
          class="complianceWireExport__formDatePickerMenu"
        >
          <template slot="activator">
            <d-text-field
              v-model="approvalDate"
              :label="$t('builder.approvalDate')"
              :key="approvalDate"
              dark
              isLabelOnBorder
              disabled
              class="complianceWireExport__formText"
            >
            </d-text-field>
            <v-icon small color="#8D909F" class="sort-by-arrow complianceWireExport__formDatePickerIcon"
              >keyboard_arrow_down</v-icon
            >
          </template>
          <v-date-picker
            @input="setApprovalDate"
            v-model="approvalDate"
            :min="today"
            color="#4689f4"
            no-title
            scrollable
          >
          </v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
      </v-flex>
    </v-form>
  </section>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconArrowForward from "@/components/icons/DIconArrowForward.vue";
import DTextField from "@/components/ui_components/DTextField.vue";
import { getUlOrganizations } from "@/server/ul-integration-server";
import { mapActions } from "vuex";

export default {
  name: "DShareComplianceWireExport",
  components: {
    IconBase,
    DIconArrowForward,
    DTextField,
  },
  props: {
    code: String,
    workflowId: String,
  },
  data() {
    return {
      showMenu: false,
      ulOrganizations: [],
      selectedUlOrganization: "",
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
      approvalDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
    };
  },
  mounted() {
    this.getOrgPermissionsSettings({ organization: this.$organization });
    this.getUlOrganizationsList();
  },
  methods: {
    ...mapActions("permission", ["getOrgPermissionsSettings"]),
    async getUlOrganizationsList() {
      this.$emit("setComplianceWireExportStatus", "loading");

      try {
        const { data } = await getUlOrganizations();

        this.ulOrganizations = data;
        this.setUlOrganization(data[0]);

        this.$emit("setComplianceWireProcessingResult");
      } catch (error) {
        this.$emit("setComplianceWireProcessingResult", {
          type: "error",
          message: "Organization list loading failed",
        });
      }
    },
    setUlOrganization(organization) {
      this.selectedUlOrganization = organization;
      this.$emit("setUlOrganization", organization);
    },
    setApprovalDate() {
      this.$emit("setApprovalDate", this.approvalDate);
    },
  },
};
</script>

<style scoped lang="scss">
.complianceWireExport {
  background-color: #121314;
  padding: 1rem 2.3rem;
  margin-top: 36px;
  border-radius: 4px;
  height: 100%;

  &__formTitle {
    font-size: 14px;
    line-height: 24px;
    text-align: start;
    color: #9397a6;
    text-transform: uppercase;
  }

  &__formText {
    padding-bottom: 0;

    ::v-deep {
      .input-box {
        border: 1px solid #323338 !important;
        background-color: #17181a !important;

        input {
          color: #fff !important;
        }
      }

      input::placeholder {
        color: rgba(147, 151, 166, 0.5) !important;
      }

      .field-label {
        background-color: #17181a !important;
      }

      .v-text-field__prefix {
        color: #8d909f !important;
        padding-right: 0;
      }
    }
  }

  &__formDropdownMenu {
    margin-bottom: 24px;
    display: block;
  }

  &__formButton {
    align-items: center;
    width: 100%;
    border: 1px solid #323338;
    background-color: #17181a;
    display: flex;
    height: 40px;
    position: relative;
    border-radius: 4px;
    padding: 0px 12px;
  }

  &__formDropdownLabel {
    position: absolute;
    z-index: 99;
    bottom: 26px;
    left: 1rem;
    padding: 0 0.5rem;
    color: #9f9f9f;
    background-color: #17181a !important;
    font-weight: 400;
    cursor: text;
  }

  &__formDropdownValue {
    color: #fff !important;
    line-height: 20px;
    padding: 8px 0;
  }

  &__formList {
    &:hover {
      background-color: #52545d !important;
    }

    &.active {
      color: #4689f3;
    }
  }

  &__formDatePickerMenu {
    display: block;
    position: relative;
  }

  &__formDatePickerIcon {
    position: absolute;
    right: 1rem;
  }
}
</style>
