<template>
  <section :style="drawerOpenPopupStyle" class="addSharedPopup" @click.stop="$emit('closePopup')">
    <v-card color="#FFFFFF" class="popup" @click.stop.prevent>
      <button class="popup__close-btn" @click="$emit('closePopup')" autotest="invite-user-popup-close-btn">
        <icon-base color="#4A4A4A" width="18" height="18">
          <d-icon-close-light />
        </icon-base>
      </button>
      <div>
        <d-icon-shared-account class="mt-5" />
        <div class="popup__title mt-4">{{ $t("admin.addASharedAccount") }}</div>
        <div class="popup__subtitle mt-2">{{ $t("admin.addASharedAccountMsg") }}</div>
      </div>

      <v-form v-model="formValid" class="popup__form">
        <v-layout row wrap pt-5 pb-4>
          <v-flex xs6 pl-5 pr-3>
            <d-text-field
              v-model="fullName"
              autofocus
              :label="$t('admin.accountName') + '*'"
              :rules="[rules.required, rules.noAllSpace]"
              autocomplete="chrome-off"
              @input="madeChanges = true"
              autotest="add-shared-account-popup-name-input"
            ></d-text-field>
          </v-flex>
          <v-flex xs6 pl-3 pr-5>
            <d-text-field
              v-model="employeeId"
              :label="$t('admin.accountId') + '*'"
              :rules="[rules.required, rules.validID, rules.noWhiteSpace, rules.max100]"
              autotest="add-shared-account-popup-id-input"
            ></d-text-field>
          </v-flex>
          <v-flex xs12 px-5 pt-3>
            <d-text-field
              v-model="workspaceName"
              :label="$t('all.workspace') + '*'"
              :rules="[rules.required]"
              chip
              disabled
              autotest="add-shared-account-popup-workspace-input"
            ></d-text-field>
          </v-flex>
        </v-layout>
      </v-form>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          depressed
          round
          outline
          class="body-2 text-capitalize dBlue--text popup__btn mr-2"
          color="#4689f4"
          @click="$emit('closePopup')"
          autotest="add-shared-account-popup-cancel-btn"
          >{{ $t("all.cancel") }}</v-btn
        >
        <v-btn
          depressed
          round
          class="body-2 text-capitalize white--text popup__btn"
          color="#4689f4"
          :disabled="!formValid || isAddmingUser"
          @click="createSharedUser()"
          autotest="add-shared-account-popup-add-btn"
          >{{ $t("all.add") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <d-alert v-model="alert.show" :type="alert.type" :message="alert.msg"></d-alert>
  </section>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import IconBase from "@/components/IconBase.vue";
import DIconCloseLight from "@/components/icons/player/DIconCloseLight.vue";
import DIconSharedAccount from "@/components/icons/colored/DIconSharedAccount.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import DTextField from "@/components/ui_components/DTextField.vue";
import { addSharedAccountUser } from "@/js/user-role/user-role.js";

export default {
  name: "AdminSharedAccountPopup",
  props: {
    showForm: String,
    workspaceDictionary: Object,
    // workspaceData: Object,
    workspaceId: String,
    workspaceName: String,
    workspaceLocal: String,
    drawerOpenPopupStyle: Object,
  },
  watch: {},
  data() {
    return {
      fullName: "",
      email: "",
      groupOptions: [],
      group: "",
      groupAddedName: "",
      employeeId: "",
      formValid: false,
      rules: {
        max100: (v) => (v && v.length <= 100) || this.$t("rules.max100"),
        noWhiteSpace: (value) => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        max: (v) => (v && v.length <= 16) || this.$t("rules.maxPassword"),
        min: (v) => (v && v.length >= 6) || this.$t("rules.min6"),
        validID: (value) => {
          const pattern = /^(?!.*@).*$/;
          const startWithShared = /^shared\..*/;
          return (!startWithShared.test(value.toLowerCase()) && pattern.test(value)) || this.$t("auth.alertInvalidId");
        },
        groupRequired: (v) => JSON.stringify(v) != "{}" || this.$t("rules.required"),
        required: (value) => !!value || this.$t("rules.required"),
      },
      alert: {
        show: false,
        type: "info",
        msg: "",
      },
      isAddmingUser: false,
    };
  },
  mixins: [MixinDB, MixinUser, MixinAdmin],
  components: {
    IconBase,
    DIconCloseLight,
    DIconSharedAccount,
    DAlert,
    DTextField,
  },
  created() {},
  mounted() {},
  methods: {
    createSharedUser() {
      this.isAddmingUser = true;
      const groupLocale = this.workspaceLocal;
      addSharedAccountUser({
        mixinUserRef: this,
        mixinDbRef: this,
        vueInstanceRef: this,
        formData: {
          employeeId: this.employeeId,
          groupId: this.workspaceId,
          role: "viewer",
          fullName: this.fullName,
        },
        groupLocale: groupLocale,
        showAlertFn: (alert) => {
          this.showAlert(alert);
        },
      });
    },
    resetForm() {
      this.fullName = "";
      this.employeeId = "";
    },
    showAlert(alert = { msg: "", type: "" }) {
      this.isAddmingUser = false;
      if (alert.msg) {
        this.alert.type = alert.type;
        this.alert.msg = alert.msg;
        this.alert.show = true;
        if (alert.msg === this.$t("admin.alertSuccessfullyCreatedShared")) {
          setTimeout(() => {
            this.$emit("closePopup");
          }, 3000);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.addSharedPopup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  .popup {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 80%;
    max-width: 856px;
    min-width: 600px;
    &__close-btn {
      position: absolute;
      right: 28px;
      top: 28px;
    }
    &__title {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #3f3f3f;
    }
    &__subtitle {
      padding: 0 64px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #78767a;
    }
    &__form {
      width: 100%;
      margin: 40px 0 0 0;
      background: #f9f9f9;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
    }
    &__btn {
      min-width: 160px;
    }
  }
}
</style>
