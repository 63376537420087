<!-- TODO: Integrate with navigator's breadcrumb. -->
<template>
  <div class="WorkspaceGroupBreadcrumb" :class="[size, mode]">
    <div v-if="isShowOrganization || isShowWorkspaceGroup" class="WorkspaceGroupBreadcrumb__text organization">
      <template v-if="isShowOrganization">
        <icon-base>
          <d-icon-organization />
        </icon-base>
        <span class="WorkspaceGroupBreadcrumb__text--workspace">{{ $t("all.organization") }}</span>
      </template>

      <template v-if="isShowWorkspaceGroup">
        <icon-base>
          <d-icon-workspace-group />
        </icon-base>
        <span class="WorkspaceGroupBreadcrumb__text--workspace">{{ workspaceGroupName }}</span>
      </template>
    </div>
    <div v-else class="WorkspaceGroupBreadcrumb__text">
      <span class="WorkspaceGroupBreadcrumb__text--clickable" @click="clickBack">
        <icon-base v-if="!hideArrow">
          <d-icon-arrow-back />
        </icon-base>
        {{ workspaceGroupName }} </span
      >/<span class="WorkspaceGroupBreadcrumb__text--workspace"> {{ workspaceName }}</span>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconOrganization from "@/components/icons/DIconOrganization.vue";
import DIconWorkspaceGroup from "@/components/icons/DIconWorkspaceGroup.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";

export default {
  name: "WorkspaceGroupBreadcrumb",
  components: {
    IconBase,
    DIconOrganization,
    DIconWorkspaceGroup,
    DIconArrowBack,
  },
  props: {
    size: {
      type: String,
      default: "medium", // small, medium, large
    },
    mode: {
      type: String,
      default: "dark", // dark, light
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    isShowOrganization: {
      type: Boolean,
      default: false,
    },
    isShowWorkspaceGroup: {
      type: Boolean,
      default: false,
    },
    workspaceGroupName: {
      type: String,
      default: "",
    },
    workspaceName: {
      type: String,
      default: "",
    },
    toPath: {
      type: String,
      default: "",
    },
  },
  methods: {
    clickBack() {
      if (this.toPath) {
        this.$router.push(this.toPath);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.WorkspaceGroupBreadcrumb {
  &.small {
    --icon-size: 16px;
    --icon-margin-right: 8px;
    --icon-margin-bottom: -3px;
    --title-size: 14px;
    --title-font-weight: 400;
  }
  &.medium {
    --icon-size: 18px;
    --icon-margin-right: 12px;
    --icon-margin-bottom: -3px;
    --title-size: 16px;
    --title-font-weight: 400;
  }
  &.large {
    --icon-size: 26px;
    --icon-margin-right: 12px;
    --icon-margin-bottom: -5px;
    --title-size: 24px;
    --title-font-weight: 600;
  }

  &.dark {
    --title-color: #ffffff;
  }
  &.light {
    --title-color: #454545;
  }

  height: var(--icon-size);
  width: 100%;
  display: flex;
  align-items: center;

  &__text {
    font-size: var(--title-size);
    font-weight: 400;
    color: #9397a6;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    svg {
      margin-inline-end: var(--icon-margin-right);
      margin-bottom: var(--icon-margin-bottom);
      height: var(--icon-size);
      width: var(--icon-size);
      color: #c4c4c4;
    }
    &.organization {
      svg {
        color: #4a8cf1;
      }
    }
    &--clickable {
      cursor: pointer;
    }
    &--workspace {
      font-weight: var(--title-font-weight);
      color: var(--title-color);
    }
  }
}
</style>
