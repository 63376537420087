export async function getTeamUsersProgress({
  organization,
  teamId,
  mixinDbRef,
  mixinAnalyticsRef,
  playlistId
}) {
  let teamUsers = [];
  try {
    teamUsers = await fetchTeamUsers({
      organization,
      teamId,
      mixinDbRef
    });

    const getSkillPlaylistProgressPromises = teamUsers.map(user => {
      return mixinAnalyticsRef.getSkillPlaylistProgress(organization, user.id)
      .catch((err) => {
          console.error(err)
      });
    });
    const skillPlaylistProgressList = await Promise.all(
      getSkillPlaylistProgressPromises
    );

    teamUsers.forEach(teamUser => {
      teamUser.progressPercentage = getTeamUserProgressPercentage({
        skillPlaylistProgressList,
        teamUserID: teamUser.id,
        playlistId
      });
      teamUser.teamId = teamId;
    });
  } catch (e) {
    console.error(e);
  }

  return teamUsers || [];
}

function getTeamUserProgressPercentage({
  skillPlaylistProgressList,
  teamUserID,
  playlistId
}) {
  let progressPercentage = 0;
  skillPlaylistProgressList.forEach(skillPlaylistProgress => {
    const isInvalidUserData = !skillPlaylistProgress ||
                              !skillPlaylistProgress.user;
    if(isInvalidUserData){
        return;
    }
    const skillUser =
      skillPlaylistProgress.user.id === teamUserID
        ? skillPlaylistProgress
        : null;
    if (!skillUser) {
      return;
    }

    const skillProgress = skillUser.skills.find(
      skill => skill.playlist.id === playlistId
    );

    if (!skillProgress) {
      return;
    }

    progressPercentage = skillProgress.progress;
  });

  return progressPercentage || 0;
}

export async function fetchTeamUsers({ organization, teamId, mixinDbRef }) {
  const comparisons = [
    {
      field: "organization",
      comparison: "==",
      value: organization
    },
    { field: "teams", comparison: "array-contains", value: teamId }
  ];
  const teamUsers = await mixinDbRef.getDocumentByQuery("users", comparisons, [
    {
      type: "limit",
      value: 1000
    }
  ]);

  return teamUsers;
}
export const getTeamData = ({ id = "", result: [{ data = {} }], status = "" }) => {
  if (status !== "teamSkills added") return null

  return data;
}
