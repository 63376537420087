import { fabric } from "fabric";
import {
  ANNOTATION_COLOR,
  ANNOTATION_LINE_WIDTH_VALUE,
  ANNOTATION_TYPE,
} from "../../constants/annotationStatus";
import {
  getArrowDefaultConfig,
  getTextDefaultConfig,
  AnnotationUtils,
} from "./AnnotationUtils";
import { cloneDeep, floor } from "lodash-es";
import store from "../../store";

export class AnnotationFabric extends AnnotationUtils {
  constructor(config = { canvasId, sourceCanvas, isHideCanvas }) {
    super();
    this.config(config);
  }

  [ANNOTATION_TYPE.SELECT]() {
    this.Fabric.isDrawingMode = false;
  }

  [ANNOTATION_TYPE.TEXT](data) {
    const config = getTextDefaultConfig(data);
    if (config.isRedraw) {
      this.Fabric.add(new fabric.IText(config.text, config));
    } else {
      this.bindTextMouseEvent(config);
    }
  }

  [ANNOTATION_TYPE.PEN]({
    lineWidth = ANNOTATION_LINE_WIDTH_VALUE.NORMAL,
    color = ANNOTATION_COLOR.RED,
  }) {
    this.Fabric.freeDrawingBrush.color = color;
    this.Fabric.freeDrawingBrush.width = lineWidth;
  }

  [ANNOTATION_TYPE.ARROW](data) {
    const config = getArrowDefaultConfig(data);
    if (config.isRedraw) {
      this.Fabric.add(
        new fabric.Arrow(
          [config.startX, config.startY, config.endX, config.endY],
          config
        )
      );
    } else {
      this.bindArrowMouseEvent(config);
    }
  }

  reDrawPen(data) {
    if (data.path) {
      const path = new fabric.Path(data.path, {
        ...data,
        isRedraw: true,
      });
      this.Fabric.add(path);
    }
  }

  toggleDrawMode(isDrawingMode = false) {
    this.Fabric.isDrawingMode = isDrawingMode;
  }

  toggleAnnotationEvent(isActive = true) {
    this.Fabric.getObjects().forEach((item) => (item.evented = isActive));
    this.Fabric.requestRenderAll();
  }

  unselect() {
    this.Fabric.discardActiveObject();
    this.Fabric.requestRenderAll();
  }

  deleteDataArray(deletedId) {
    const object = this.Fabric.getItemById(deletedId);
    if (object) {
      this.Fabric.remove(object);
    } else {
      console.error(`object (${object}) or deleteId (${deletedId}) is invalid`);
    }
  }

  selectDataArray(selectedId) {
    const target = this.Fabric.getItemById(selectedId);
    if (!target) return null;
    this.Fabric.setActiveObject(target);
    this.Fabric.requestRenderAll();
  }

  updateSelectedStyle({ id, key, value }) {
    const target = this.Fabric.getItemById(id);
    if (!target) return null;
    target.set({ [key]: value });
    this.Fabric.requestRenderAll();
  }

  changeIsHideCanvas(isHideCanvas) {
    this.isHideCanvas = isHideCanvas;
    const items = this.Fabric.getObjects();
    items.forEach((item) => {
      item.opacity = +!isHideCanvas;
    });
    this.Fabric.requestRenderAll();
  }

  detectIsAnnotationShowUp(currentTime, stepId) {
    if (this.isHideCanvas || !stepId) return;
    const items = this.Fabric.getObjects();
    const { elements: annotationFromVuex } = store.getters[
      "annotation/getters_get_annotation_by_id"
    ](stepId);

    if (!items || !annotationFromVuex) {
      console.error(`items or annotationFromVuex is invalid`);
      return;
    }

    items.forEach((item) => {
      const { endTime, startTime } = annotationFromVuex.find(
        ({ id }) => id === item.id
      );
      const isShowUp =
        endTime >= currentTime && Math.floor(startTime) <= currentTime;
      item.opacity = +isShowUp;
      item.selectable = isShowUp;
    });
    this.Fabric.requestRenderAll();
  }

  handleResize({ width, height }) {
    let _height = height;
    const ORIGINAL_CANVAS_RECTANGLE_PERCENTAGE = 1.77;
    if (floor(width / height, 2) !== ORIGINAL_CANVAS_RECTANGLE_PERCENTAGE) {
      _height = width / ORIGINAL_CANVAS_RECTANGLE_PERCENTAGE;
    }
    this.Fabric.setWidth(width);
    this.Fabric.setHeight(_height);
    this.canvasHeight = _height;
    this.canvasWidth = width;
  }

  loadData(elements) {
    this.Fabric.clear();
    if (Array.isArray(elements)) {
      elements.forEach((element) => {
        const _element = this.isPlayMode
          ? this._calculatePlayModePercentage(element)
          : element;
        if (element.type !== ANNOTATION_TYPE.PEN) {
          this[element.type]({
            ..._element,
            isRedraw: true,
          });
        } else {
          this.reDrawPen(_element);
        }
      });
    }
  }

  _calculatePlayModePercentage(element) {
    const {
      top,
      left,
      originalCanvasHeight,
      originalCanvasWidth,
      scaleX,
      scaleY,
    } = element;

    const topPercentage = top / originalCanvasHeight;
    const leftPercentage = left / originalCanvasWidth;
    const resultTop = topPercentage * this.canvasHeight;
    const resultLeft = leftPercentage * this.canvasWidth;
    const windowHeightPercentage = this.canvasHeight / originalCanvasHeight;
    const windowWidthPercentage = this.canvasWidth / originalCanvasWidth;

    return cloneDeep({
      ...element,
      top: resultTop,
      left: resultLeft,
      scaleY: (scaleY || 1) * windowWidthPercentage,
      scaleX: (scaleX || 1) * windowHeightPercentage,
    });
  }

  _createArrow() {
    fabric.Arrow = fabric.util.createClass(fabric.Line, {
      type: ANNOTATION_TYPE.ARROW,

      initialize: function(element, options) {
        options || (options = {});
        this.callSuper("initialize", element, options);
      },

      toObject: function() {
        return fabric.util.object.extend(this.callSuper("toObject"));
      },

      _render: function(ctx) {
        this.callSuper("_render", ctx);

        // do not render if width/height are zeros or object is not visible
        if ((this.width === 0 && this.height === 0) || !this.visible) return;
        ctx.save();
        const xDiff = this.x2 - this.x1;
        const yDiff = this.y2 - this.y1;
        const angle = Math.atan2(yDiff, xDiff);
        ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
        ctx.rotate(angle);
        ctx.beginPath();
        //move 10px in front of line to start the arrow, so it does not have the square line end showing in front (0,0)
        ctx.moveTo(10, 0);
        ctx.lineTo(-10, 10);
        ctx.lineTo(-10, -10);
        ctx.closePath();
        ctx.fillStyle = this.stroke;
        ctx.fill();

        ctx.restore();
      },

      clipTo: function(ctx) {
        this._render(ctx);
      },
    });
    fabric.Arrow.fromObject = function(object, callback) {
      callback &&
        callback(
          new fabric.Arrow([object.x1, object.y1, object.x2, object.y2], object)
        );
    };
  }

  _changeSelectOutline() {
    fabric.Object.prototype.setControlsVisibility({
      bl: true, // bottom left
      br: true, // bottom right
      mb: false, // bottom middle
      ml: false, // middle left
      mr: false, // middle right
      mt: false, // top middle
      tl: true, // top left
      tr: true, // top right
      mtr: false, // middle top rotate
    });
    fabric.Object.prototype.borderColor = "#979797";
    fabric.Object.prototype.cornerSize = 5;
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "#fff";
  }

  _addCustomizedFunction() {
    fabric.Canvas.prototype.getItemById = function(id) {
      return this.getObjects().find((item) => item.id === id);
    };
  }

  _checkBoundaries(target) {
    if (!target) {
      console.error(`This target is invalid`);
      return;
    }
    const { width, height } = target.getBoundingRect();
    if (target.top < 0 || target.left < 0) {
      target.top = Math.max(target.top, 0);
      target.left = Math.max(target.left, 0);
    }
    const LEFT_MAXIMUM = target.canvas.width - width - 5;
    const TOP_MAXIMUM = target.canvas.height - height - 5;
    if (target.top >= TOP_MAXIMUM || target.left >= LEFT_MAXIMUM) {
      target.top = Math.min(TOP_MAXIMUM, target.top);
      target.left = Math.min(LEFT_MAXIMUM, target.left);
    }
    target.setCoords();
  }

  _addInterceptionForCheckBoundaries() {
    this.Fabric.on("object:moving", event => {
      const { target } = event;
      this._checkBoundaries(target);
    });

    const MAXIMUM = { left: 0, top: 0, scaleX: 0, scaleY: 0, width: 0, height: 0 };
    const keys = Object.keys(MAXIMUM);
    this.Fabric.on("object:scaling", event => {
      const { target } = event;
      if (!target) {
        return;
      }
      target.setCoords();
      const newObject = target.getBoundingRect();
      if (
        newObject.width + newObject.left >= target.canvas.width ||
        newObject.height + newObject.top >= target.canvas.height ||
        newObject.top < 0 ||
        newObject.left < 0
      ) {
        keys.forEach(key => {
          target[key] = MAXIMUM[key];
        });
      } else {
        keys.forEach(key => {
          MAXIMUM[key] = target[key];
        });
      }
    });

    this.Fabric.on("object:added", event => {
      const { target } = event;
      this._checkBoundaries(target);
    });

    this.Fabric.on("text:changed", event => {
      const { target } = event;
      this._checkBoundaries(target);
    });
  }

  config(config) {
    this._createArrow();
    this._changeSelectOutline();
    this._addCustomizedFunction();

    this.sourceCanvas = config.sourceCanvas;
    this.canvasWidth = config.sourceCanvas.clientWidth;
    this.canvasHeight = config.sourceCanvas.clientHeight;
    fabric.Object.prototype.lockScalingFlip = true;
    this.Fabric = new fabric.Canvas(config.canvasId, {
      width: this.canvasWidth,
      height: this.canvasHeight,
    });
    this._addInterceptionForCheckBoundaries();
    this.center = {
      x: config.sourceCanvas.clientWidth / 2,
      y: config.sourceCanvas.clientHeight / 2,
    };
    this.videoEndTime = config.videoEndTime;
    this.watch = config.watch;
    this.isHideCanvas = config.isHideCanvas;
    this.isPlayMode = config.isPlayMode;
  }
}
