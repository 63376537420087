<template>
  <section :style="drawerOpenPopupStyle" class="user-invite-create">
    <v-card width="466" color="#FFFFFF" class="popup-box fullscreen-center">
      <button class="close-btn" @click="$emit('closePopup')" autotest="remove-user-popup-close-btn">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>
      <div class="title-div">
        <icon-base width="120" height="120" opacity="0.75">
          <d-icon-delete-user />
        </icon-base>
        <div v-if="showForm == 'remove-user-group'">
          <div class="title-text">{{ $t("admin.popupTitleRemoveUser") }}</div>
          <div
            class="subtitle-text"
            v-html="$t('admin.popupMsgRemoveUserFromWorkspace', { name: formData.displayName })"
          ></div>
          <!-- "Are you sure you want to remove <b>{name}</b> from the Workspace?", -->
        </div>
        <div v-if="showForm == 'delete-user-system'">
          <div class="title-text">{{ $t("admin.popupTitleDeleteUser") }}</div>
          <div
            class="subtitle-text"
            v-html="$t('admin.popupMsgDeleteUserFromAllWorkspaces', { name: formData.displayName })"
          ></div>
          <!-- "If you do this, you will lose all the records regarding this user <b>{name}</b> and he will be deleted from all the Workspaces and roles he is assigned.", -->
        </div>
      </div>
      <v-layout xs12>
        <v-flex xs6 mr-2>
          <v-btn
            outline
            depressed
            round
            block
            class="body-2 text-capitalize"
            color="#4689f4"
            @click="$emit('closePopup')"
            autotest="remove-user-popup-no-btn"
            >{{ $t("all.no") }}</v-btn
          >
        </v-flex>
        <v-flex xs6 ml-2>
          <v-btn
            v-if="showForm == 'remove-user-group'"
            depressed
            round
            block
            class="body-2 text-capitalize white--text no-text-transform"
            color="#E03535"
            @click="$emit('removeUser')"
            autotest="remove-user-popup-yes-btn"
            >{{ $t("admin.popupBtnRemove") }}</v-btn
          >
          <v-btn
            v-if="showForm == 'delete-user-system'"
            depressed
            round
            block
            class="body-2 text-capitalize white--text no-text-transform"
            color="#E03535"
            @click="$emit('deleteUser')"
            >{{ $t("admin.popupBtnRemove") }}</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card>
    <div class="popup-close" @click="$emit('closePopup')"></div>
  </section>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconDeleteUser from "../icons/colored/DIconDeleteUser.vue";
import DIconClose2 from "../icons/DIconClose2.vue";

export default {
  name: "DRemoveUserConfirmation",
  props: {
    showForm: String,
    formData: Object,
    drawerOpenPopupStyle: Object,
  },
  components: {
    IconBase,
    DIconClose2,
    DIconDeleteUser,
  },
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.popup-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 24px 24px 0 24px;
  margin-bottom: 24px;
}

.title-text {
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 16px;
}
.subtitle-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 64px;
}
.subtitle-text ::v-deep b {
  font-weight: 500 !important;
}
.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.no-text-transform {
  text-transform: none !important;
}
</style>
