import { postWorkspace, putWorkspace } from "@/server/workspace-server";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  createWorkspace: async ({ commit }, { data }) => await postWorkspace({ data }),
  patchWorkspace: async ({ commit }, { data }) => await putWorkspace({ data }),
};
