<template>
  <section class="player-unpublished-video">
    <div class="pa-4">
      <d-icon-unpublished-workflow :width="isUsingIframe || isMobileDevice ? '90' : '120'" />
      <p class="msg-title" :class="{ 'title-mobile': isUsingIframe || isMobileDevice }">
        <slot>
          <!-- fallback content -->
          {{ $t("player.thisWorkflowWasUnpublished") }}
        </slot>
      </p>
      <p class="msg-subtitle" :class="{ 'subtitle-mobile': isUsingIframe || isMobileDevice }">
        {{ $t("player.talkToSupervisor") }}
      </p>
      <v-btn
        v-if="!isUsingIframe && !isEmbedDeephow"
        round
        color="#4689f4"
        class="elevation-0 body-2 btn-width no-transform white--text"
        @click="closeScreen()"
      >
        <span>{{ isOpenedAsPopup ? $t("all.close") : $t("player.goToHome") }}</span>
      </v-btn>
    </div>
  </section>
</template>

<script>
import DIconUnpublishedWorkflow from "@/components/icons/colored/DIconUnpublishedWorkflow.vue";

export default {
  name: "PlayerUnpublishedVideo",
  props: {
    isPublic: Boolean,
    isMobileDevice: Boolean,
    isUsingIframe: Boolean,
    isEmbedDeephow: Boolean,
    isOpenedAsPopup: Boolean,
  },
  created() {},
  mounted() {},
  updated() {},
  data() {
    return {};
  },
  watch: {},
  components: {
    DIconUnpublishedWorkflow,
  },
  methods: {
    closeScreen() {
      if (this.isPublic) {
        this.$router.push("/");
        return location.reload();
      }
      if (this.isOpenedAsPopup) {
        this.$emit("close-player");
        return;
      }
      this.$router.push("/navigator/home");
    },
  },
  computed: {},
};
</script>

<style scoped>
.player-unpublished-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0c0c0e;
  align-items: center;
  justify-content: center;
  display: flex;
}
.msg-title {
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-bottom: 4px;
  margin-top: 16px;
}
.msg-subtitle {
  color: #8d909f;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.title-mobile {
  font-size: 16px;
  line-height: 20px;
  margin-top: -6px;
  margin-bottom: 2px;
}
.subtitle-mobile {
  font-size: 12px;
  line-height: 16px;
}
.no-transform {
  text-transform: none !important;
}
</style>
