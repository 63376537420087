<template>
  <section class="user-invite-create">
    <div class="user-invite-content fullscreen-center">
      <deephow-logo width="190" height="40" />
      <v-card v-if="!isSuccess" dark color="#17181A" class="invite-box">
        <p class="create-account-title">{{$t('password.createNewPassword')}}</p>
        <p class="create-account-subtitle">{{$t('password.selectNewPassword')}}</p>
        <v-form v-model="formValid" autocomplete="off" :style="{'margin':'56px 0 56px 0'}">
          <v-text-field
            v-model="newPassword"
            :label="$t('password.newPassword')"
            class="input-field mb-3"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            autocomplete="chrome-off"
            :rules="[rules.validPassword, rules.noWhiteSpace, rules.max]"
            required
          ></v-text-field>
          <v-text-field
            v-model="newPassword2"
            :label="$t('password.confirmNewPassword')"
            class="input-field"
            :append-icon="showPassword2 ? 'visibility' : 'visibility_off'"
            :type="showPassword2 ? 'text' : 'password'"
            @click:append="showPassword2 = !showPassword2"
            autocomplete="chrome-off"
            :rules="[rules.required]"
            required
          ></v-text-field>
          <p
            v-if="newPassword!=newPassword2 && newPassword2"
            class="error-text"
          >{{$t('password.passwordMustMatch')}}</p>
        </v-form>
        <v-btn
          :disabled="!formValid || newPassword!=newPassword2"
          @click="setNewPassword()"
          depressed
          round
          block
          class="body-2 text-capitalize white--text"
          color="#4689f4"
        >{{$t('password.confirm')}}</v-btn>
      </v-card>
      <v-card v-else dark color="#17181A" class="invite-box" height="420">
        <icon-base height="108" width="108" color="#30548a" class="mt-2 mb-3">
          <d-icon-success />
        </icon-base>
        <p class="create-account-title">{{$t('all.success')}}!</p>
        <p class="create-account-subtitle" v-html="$t('password.passwordCreatedMsg')">
          <!-- Your new password was created, <br>please wait while we redirect you… -->
        </p>
        <v-btn
          depressed
          round
          block
          class="body-2 text-capitalize white--text success-btn no-transform"
          color="#4689f4"
          @click="goToPlayer()"
        >{{$t('password.goToDeepHowPlayer')}}</v-btn>
      </v-card>
    </div>
    <div class="language-btn">
      <user-language-menu
        userNewPassword
        :isMobileDevice="false"
        :isTabletDevice="false"
        @change-lang-url="changeLangUrl()"
      ></user-language-menu>
    </div>

    <d-alert v-model="alert.isFailed" type="error" manualClose :message="alert.alertText"></d-alert>
  </section>
</template>

<script>
import axios from "axios";
import MixinUser from "./MixinUser.vue";
import IconBase from "./IconBase.vue";
import DeephowLogo from "./icons/logo/DeephowLogo.vue";
import DIconSuccess from "./icons/colored/DIconSuccess.vue";
import UserLanguageMenu from "@/components/DUser/UserLanguageMenu";
import DAlert from "@/components/ui_components/DAlert.vue";
export default {
  name: "UserNewPassword",
  props: {
    isMobileDevice: Boolean,
    isTabletDevice: Boolean,
  },
  data() {
    return {
      newPassword: "",
      newPassword2: "",
      showPassword: false,
      showPassword2: false,
      email: "",
      token: "",
      rules: {
        noWhiteSpace: value => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        required: value => !!value || this.$t("rules.required"),
        max: v => (v && v.length <= 25) || this.$t("rules.max25"),
        min: v => (v && v.length >= 6) || this.$t("rules.min6"),
        validPassword: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_+=[\]{}|\\,.?:"';<>/-])[^]{10,}$/;
          return (
            pattern.test(value) || this.$t("rules.validPasswordMin10")
            // "Password needs to be at least 10 characters, include lower and upper case letter, number, and special character."
          );
        },
      },
      formValid: false,
      isSuccess: false,
      alert: {
        isSuccess: false,
        isFailed: false,
        alertText: "",
      },
    };
  },
  components: {
    IconBase,
    DeephowLogo,
    DIconSuccess,
    UserLanguageMenu,
    DAlert,
  },
  mixins: [MixinUser],
  created() {
    this.token = this.$route.params.token;
    if (this.$route.params.lang) {
      this.$i18n.locale = this.$route.params.lang;
    }
    this.setAuthState();
  },
  mounted() {},
  methods: {
    changeLangUrl() {
      const newUrl = "/usernewpassword/" + this.$route.params.token + "/" + this.$i18n.locale;
      this.$router.push(newUrl);
    },
    goToPlayer() {
      this.signInWithEmail(this.email, this.newPassword);
    },
    setAuthState() {
      this.auth().onAuthStateChanged(user => {
        if (user) {
          this.$router.push("/navigator/home");
        }
      });
    },
    setNewPassword() {
      const self = this;
      const data = {
        token: this.token,
        password: this.newPassword,
      };
      axios
        .post(self.$service.admin + "reset/setpwd", data)
        .then(function(response) {
          self.email = response.data.email;
          self.isSuccess = true;
          // const msg = "password for user account " + email + " has been reset. You'll be signed in now."
          // alert(msg);
          setTimeout(() => {
            self.goToPlayer();
          }, 1000);
        })
        .catch(err => {
          self.alert.isFailed = true;
          self.alert.alertText = this.$t("password.failedResetPassword");
          // debugger;
          // alert(
          //   "failed to reset password. Your account was already created or there's an network issue"
          // );
        });
    },
  },
  computed: {},
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #0c0c0e;
}
.user-invite-content {
  max-width: 472px;
  margin: 0 auto;
}
.invite-box {
  position: relative;
  margin-top: 36px;
  padding: 48px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.create-account-title {
  color: #ffffff;
  font-family: Roboto;
  font-size: 32px;
  line-height: 44px;
  margin: 0;
}
.create-account-subtitle {
  color: #8d909f;
  font-family: Roboto;
  font-size: 20px;
  line-height: 30px;
}
.success-btn {
  position: relative;
  margin-top: 48px;
}
/* for fixing Chrome auto-filling bug of Vuetify */
.input-field ::v-deep input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #17181a inset !important;
}
.input-field ::v-deep input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.error-text {
  color: #888888 !important;
  caret-color: #888888 !important;
  font-size: 12px;
  text-align: left;
  margin-top: -14px;
}
.no-transform {
  text-transform: none !important;
}
.language-btn {
  position: absolute;
  top: 32px;
  right: 48px;
}
@media (max-width: 436px) {
  .user-invite-create {
    overflow: auto;
    padding: 0 12px 36px 12px;
  }
  .user-invite-content {
    margin-top: 100px;
  }
  .language-btn {
    right: 12px;
  }
}
@media screen and (min-width: 437px) {
  .fullscreen-center {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
</style>
