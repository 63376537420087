<template>
  <section :style="drawerOpenPopupStyle" class="confirmation-popup">
    <v-card width="466" color="#FFFFFF" class="popup-box fullscreen-center">
      <button class="close-btn" @click="$emit('closePopup')" autotest="upgrade-org-admin-popup-close-btn">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>
      <div class="title-div">
        <div class="avatar-div">
          <d-avatar :size="130" :userId="formData.id" class="avatar-pic" />
          <div class="org-admin-icon">
            <icon-base width="24" height="24" color="white">
              <d-icon-org-admin />
            </icon-base>
          </div>
        </div>
        <div>
          <div class="title-text">{{ $t("admin.upgradeToOrgAdmin") }}</div>
          <div
            class="subtitle-text"
            v-html="$t('admin.popupMsgUpgradeToOrgAdmin', { name: formData.displayName })"
          ></div>
        </div>
      </div>
      <v-layout xs12 px-4>
        <v-flex xs6 mr-2>
          <v-btn
            outline
            depressed
            round
            block
            class="body-2 text-capitalize"
            color="#4689f4"
            @click="$emit('closePopup')"
            autotest="upgrade-org-admin-popup-no-btn"
            >{{ $t("all.no") }}</v-btn
          >
        </v-flex>
        <v-flex xs6 ml-2>
          <v-btn
            depressed
            round
            block
            class="body-2 white--text no-text-transform"
            color="#4689F3"
            @click="upgradeToOrgAdmin()"
            autotest="upgrade-org-admin-popup-yes-btn"
            >{{ $t("admin.yesUpgrade") }}</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card>
    <div class="popup-close" @click="$emit('closePopup')"></div>
    <d-alert v-model="alert.isShow" :type="alert.type" :message="alert.message"></d-alert>
  </section>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconOrgAdmin from "@/components/icons/DIconOrgAdmin.vue";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import { upgradeToOrgAdmin, RoleType } from "@/js/user-role/user-role.js";
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import { flow, toPairs, fromPairs } from "lodash-es";

export default {
  name: "UpgradeToOrgAdminConfirmation",
  props: {
    formData: Object,
    drawerOpenPopupStyle: Object,
  },
  mixins: [MixinDB, MixinUser],
  components: {
    IconBase,
    DIconClose2,
    DIconOrgAdmin,
    DAvatar,
    DAlert,
  },
  data() {
    return {
      alert: {
        type: null,
        isShow: false,
        message: "",
      },
      isUpgrading: false,
    };
  },
  methods: {
    showAlertMessage({ message, type }) {
      this.alert.isShow = true;
      this.alert.type = type;
      this.alert.message = message;
    },
    upgradeToOrgAdmin() {
      if (this.isUpgrading) {
        return;
      }

      this.isUpgrading = true;

      upgradeToOrgAdmin({
        mixinUserRef: this,
        mixinDbRef: this,
        vueInstanceRef: this,
        userData: {
          fullName: this.formData.displayName,
          email: this.formData.email,
        },
      })
        .then((result) => {
          if (result && result.isSuccessfullyChangeRole) {
            this.showAlertMessage({
              message: this.$t("admin.alertSuccessfullyUpgradeToOrg"),
              type: "success",
            });

            this.$emit("update-success", {
              targetUserData: this.formData,
            });

            /** update vuex data */
            const newRole = RoleType.OrgAdmin;
            const replaceWithNewRole = (list) => {
              return list.map(([groupId, oldRole]) => [groupId, newRole]);
            };
            const newRoleMapInAllWorkspace = flow([toPairs, replaceWithNewRole, fromPairs])(this.formData.roles);
            const isToRemoveUser = false; // true is update user data

            this.$store.dispatch("updateUser", {
              user: this.formData,
              update: {
                role: newRole,
                roles: newRoleMapInAllWorkspace,
                disabled: isToRemoveUser,
              },
              action: "update",
            });
          } else {
            this.showAlertMessage({
              message: this.$t("admin.alertFailedUpgradeToOrg"),
              type: "error",
            });
          }

          return new Promise((resolve) => {
            setTimeout(() => {
              this.$emit("closePopup");
              resolve();
            }, 1000);
          });
        })
        .finally(() => {
          this.isUpgrading = false;
        });
    },
  },
};
</script>
<style scoped>
.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.popup-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.avatar-div {
  position: relative;
  display: inline-block;
  height: 130px;
  width: 130px;
}
.avatar-pic {
  border-radius: 50%;
  border: 1px solid #dadada;
}
.org-admin-icon {
  position: absolute;
  right: -8px;
  top: 0;
  height: 40px;
  width: 40px;
  background-color: #ffbf18;
  border-radius: 20px;
  padding: 6px;
  border: white 1px solid;
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 24px 24px 0 24px;
  margin-bottom: 24px;
}

.title-text {
  color: #52545d;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: 24px;
}
.subtitle-text {
  margin: 12px 0 40px 0;
  word-wrap: normal;
  word-break: break-word;
  color: #8d909f;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
.subtitle-text ::v-deep b {
  font-weight: 500 !important;
}
.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.no-text-transform {
  text-transform: none !important;
}
</style>
