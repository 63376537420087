export function saveInitialStepWithVideoTrackerOnce({
  mixinTrackerRef,
  currentStepIndex,
  steps
}) {
  const stepsArray = mixinTrackerRef.workflowTracker.stepsArray;
  const isNotYetSave = stepsArray.length === 0;
  if (isNotYetSave) {
    const currentStep = steps[currentStepIndex];
    mixinTrackerRef.handleOnStepChanged({
      stepId: currentStep ? currentStep.id : null
    });
  }
}

export function watchOnStepChangedWithVideoTracker({
  mixinTrackerRef,
  prevStepIndex,
  currentStepIndex,
  steps
}) {
  const isStepChanged = currentStepIndex !== prevStepIndex;
  if (isStepChanged) {
    const currentStep = steps[currentStepIndex];
    mixinTrackerRef.handleOnStepChanged({
      stepId: currentStep ? currentStep.id : null
    });
  }
}
