<template>
  <div class="PlayerLoading" :style="indexStyle">
    <div>
      <d-icon-loading width="80" height="80" />
      <p class="white--text mt-3">
        <slot>
          {{ $t("player.loadingVideo") }}
        </slot>
      </p>
    </div>
  </div>
</template>
  
<script>
import DIconLoading from "@/components/icons/animated/DIconLoading.vue";
export default {
  name: "PlayerLoading",
  props: {
    zIndex: {
      type: String,
    },
  },
  components: {
    DIconLoading,
  },
  computed: {
    indexStyle() {
      if (!this.zIndex) return {};
      return {
        "z-index": this.zIndex,
      };
    },
  },
};
</script>
  
<style lang="scss" scoped>
.PlayerLoading {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.7);
}
</style>
  