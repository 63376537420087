<template>
  <section v-if="hasAccess" class="skills-main" :class="{ 'drawer--open': isSideMenuOpen }">
    <component :is="isSupportWorkspaceGroup ? 'skills-organization-v-2' : 'skills-organization'" />
  </section>
</template>

<script>
import MixinDB from "./MixinDB.vue";
import MixinUser from "./MixinUser.vue";
import { IS_SUPPORT_WORKSPACE_GROUP } from "@/constants/studio-version";
import { mapGetters } from "vuex";

export default {
  name: "SkillsMain",
  props: {},
  created() {
    this.auth().onAuthStateChanged((user) => {
      if (user) {
        if (this.$access && this.$access.skills) {
          this.hasAccess = true;
        } else {
          this.getUserRole(user).then(() => {
            this.setUserAccess().then(() => {
              if (this.$access && this.$access.skills) {
                this.hasAccess = true;
              } else {
                //no skills access
                this.$router.replace("/navigator/home");
              }
            });
          });
        }
      }
    });
  },
  mounted() {},
  data() {
    return {
      isSupportWorkspaceGroup: IS_SUPPORT_WORKSPACE_GROUP,
      hasAccess: false,
    };
  },
  components: {
    SkillsOrganization: () => import("@/components/SkillsOrganization"),
    SkillsOrganizationV2: () => import("@/components/DSkills/SkillsOrganizationV2"),
  },
  mixins: [MixinDB, MixinUser],
  methods: {},
  computed: {
    ...mapGetters("global", ["getter_is_side_menu_open_desktop_ui"]),
    isSideMenuOpen() {
      return this.getter_is_side_menu_open_desktop_ui && this.$route.path.indexOf("skillsbuilder") === -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.skills-main {
  position: fixed;
  top: 70px;
  height: calc(100% - 70px);
  width: 100%;
  overflow: hidden;
  &.drawer--open {
    left: var(--side-bar-width);
    width: calc(100% - var(--side-bar-width));
    transform: translate(0);
  }
}
</style>
