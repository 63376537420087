
export default class WebSocketClient {
  constructor (baseUrl) {
    this.baseUrl = baseUrl;
    this.wsClient = null;
    this.idToken = '';
    this.collection = '';
    this.conditions = {};
    this.callback = null;
    this.retryCount = 0;
    this.retryInterval = (5 * 1000);
    this.maxRetryCount = 10;
  };

  openListener(idToken, collection, conditions, callback) {
    if (this.wsClient) {
      //console.log('already open connection !!');
      return;
    }

    let ws = new WebSocket(this.baseUrl);
    ws.onopen = () => {
      //console.log('open connection @' + String(new Date()));

      ws.send(JSON.stringify({
        idToken: idToken, 
        collection: collection, 
        conditions: conditions
      }));
    }

    ws.onmessage = event => {
        if (this.retryCount > 0) {
          this.retryCount = 0;
        }

        this.callback(JSON.parse(event.data));
    }

    ws.onclose = () => {
      //console.log('close connection @' + String(new Date()));
      
      if (this.retryCount < this.maxRetryCount) {
        this.reconnectListener();
        this.retryCount ++;
      }

    }

    this.wsClient = ws;
    this.idToken = idToken;
    this.collection = collection;
    this.conditions = conditions;
    this.callback = callback;
  };

  closeListener() {
    if (!this.wsClient) {
      //console.log('already close connection !!');
      return;
    }

    this.retryCount = this.maxRetryCount;
    
    let ws = this.wsClient;
    ws.close();

    this.wsClient = null;
  };

  reconnectListener() {
    const timeout = this.retryInterval * (this.retryCount + 1);
    setTimeout(async() => {
      //console.log('retry connection @' + String(new Date()), this.retryCount);
      this.wsClient = null;
      this.openListener(this.idToken, this.collection, this.conditions, this.callback);
    }, timeout);
  };

  refreshListener(idToken, collection, conditions, callback) {
    if (!this.wsClient) {
      //console.log('already close connection !!');
      return;
    }

    let ws = this.wsClient;
    ws.send(JSON.stringify({
      idToken: idToken, 
      collection: collection, 
      conditions: conditions
    }));

    this.idToken = idToken;
    this.collection = collection;
    this.conditions = conditions;
    this.callback = callback;
  };
}
