import * as pluralize from "pluralize";

const isWorkflowLanguageWithoutSpace = (languageCode = "") => {
  const list = ["zh", "zh-tw", "ja-jp", "th-th"];
  return list.indexOf(languageCode) !== -1;
}

/**
 * keyterms would be lower case or upper case but no combination
 * @param keyterms = String[]
 * @param text = String
 * @returns Boolean
 */
export const isTextAsKeyterm = (keyterms = [], text = "") => {
  const termAfterTrim = text.replace(/\s+/g, ' ').trim()
  const lowerText = termAfterTrim.toLowerCase();
  const upperText = termAfterTrim.toUpperCase();
  const matchText = keyterms.find(term => term === lowerText || term === upperText);
  return !!matchText;
};

/**
 * Check is workflow language is valid to use keyterms
 * @param languageCode: String of country code
 * @returns Boolean
 */
export const isAllowedKeytermsLanguage = (languageCode = "") => {
  const allowedList = [
    "en-us", "pt-br", "es-mx", "de-de", "zh", "zh-tw",
    "fr-fr", "nl-nl", "ru-ru", "vi-vn", "ko-kr", "pl-pl",
    "cs-cz", "ja-jp", "it-it", "hu-hu", "ro-ro", "th-th",
    "he-il", "ar-ar"
  ]
  return allowedList.indexOf(languageCode) !== -1;
}

/**
 * Sorted keyterms by alphabet
 * @param {*} keyterms 
 * @returns String[]
 */
export const sortKeytermsByAlphabet = (keyterms = []) => {
  return keyterms.sort((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });
}

/**
 * In English workflow, singular and pluralize words are all matched keyterm
 * @param {*} keyterms 
 * @returns String[]
 */
export const generateAllMatchedKeyterms = (keyterms = []) => {
  const pluralTerms = keyterms.map((term) => pluralize(term));
  const singularTerms = keyterms.map((term) => pluralize.singular(term));
  const allPossibleTerms = [...pluralTerms, ...singularTerms, ...keyterms];
  return [...new Set(allPossibleTerms)];
}

/** Check selected text is mapping keyterms rules */
const enInvalidPunctuationRegex = new RegExp(/[.,\/#!?$%\^&\*;:{}=\_`~()+]/)
const zhInvalidPunctuationRegex = new RegExp('[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\uff5e|\ufe4f|\uffe5]');
const isTextWithInvalidPunctuation = (text = "") => {
  if (!text) return false;

  const isInvalidInEn = enInvalidPunctuationRegex.test(text);
  const isInvalidInZh = zhInvalidPunctuationRegex.test(text);

  return isInvalidInEn || isInvalidInZh;
}
const isSpaceChar = (text = "", position = -1) => {
  if (!text || position < 0 || position > text.length - 1 || !text[position]) {
    return false;
  }
  const spaceRegex = /\s/;
  return spaceRegex.test(text[position]);
}
const isTextWithSpaceInStartOrEnd = ({ text = "", firstPosition = -1, lastPosition = -1 }) => {
  return isSpaceChar(text, firstPosition) || isSpaceChar(text, lastPosition);
}
const isTextTooLong = (text = "", languageCode = "") => {
  const MAX_WORD_NUM = 10;
  const isLanguageWithoutSpace = isWorkflowLanguageWithoutSpace(languageCode);
  let isTooLong = false;
  if (isLanguageWithoutSpace) {
    const enRegex = new RegExp(/([^A-Za-z])/);
    const splitTextList = text.split(enRegex).filter(char => char && char !== " ");
    isTooLong = splitTextList.length > MAX_WORD_NUM;
  } else {
    isTooLong = text.split(" ").filter((char) => char).length > MAX_WORD_NUM;
  }
  return isTooLong;
}
const isCompletedWord = (firstChar = "", lastChar = "", languageCode = "") => {
  const isLanguageWithoutSpace = isWorkflowLanguageWithoutSpace(languageCode);
  if (isLanguageWithoutSpace) return true;

  const validCharList = [" ", "", ".", ",", "!", "?"];
  return validCharList.indexOf(firstChar) !== -1 && validCharList.indexOf(lastChar) !== -1;
}
export const isSelectedTextValidKeyterm = (selectedText = "", languageCode = "", docActiveElement) => {
  if (selectedText === " " || selectedText === "") return false;

  /** below words are not support for being keyterms, <> is mean selected scoped
   *  - not completed word: break<fast>
   *  - sentence is too long with more than 10 words: <One two three four five six seven eight nine ten eleven>
   *  - first character is space: < breakfast>
   *  - last character is space: <breakfast >
   *  - except -, others punctuation is not allowed
   */
  const originText = docActiveElement.value
  const firstPosition = docActiveElement.selectionStart - 1;
  const lastPosition = docActiveElement.selectionEnd
  const isInvalidText = isTextWithInvalidPunctuation(selectedText)
    || isTextWithSpaceInStartOrEnd({ text: originText, firstPosition: firstPosition + 1, lastPosition: lastPosition - 1 })
    || !isCompletedWord(originText[firstPosition], originText[lastPosition], languageCode)
    || isTextTooLong(selectedText, languageCode);
  return !isInvalidText;
}

/**
 * Add <mark>keyterms</mark> on sentence
 * @param {*} param0 
 * @returns 
 */
export const applyKeytermsHighlightsOnSentence = ({
  text = "",
  termList = [],
  languageCode = ""
}) => {
  // highlight multiple word keyterms
  let cleanText = text.replace(/\n$/g, "\n\n");
  termList.forEach((highlightStr) => {
    if (!highlightStr) return;

    const regString = isWorkflowLanguageWithoutSpace(languageCode)
      ? highlightStr.replaceAll("", "\\s*")
      : highlightStr.replaceAll(" ", "\\s*");
    const checkReg = new RegExp("(" + regString + ")", "gi");
    const matchStringList = cleanText.match(checkReg) || [];
    matchStringList.forEach((str) => {
      const markString = str.replaceAll(" ", "&nbsp");
      cleanText = cleanText.replaceAll(str, `<mark>${markString}</mark>`);
    })
  });
  return cleanText;
};

export const getFormatedNewTerm = (newTerm = "") => {
  const isUpperCase = newTerm === newTerm.toUpperCase();
  const termWithCase = isUpperCase ? newTerm : newTerm.toLowerCase();
  const term = termWithCase.replace(/\s+/g, " ").trim();
  return term;
}
/**
 * Add new keyterm in list and then generate new sorted keyterms list
 * @param {*} currentKeytermList 
 * @param {*} newTerm 
 * @returns String[]
 */
export const addAndGenerateNewKeytermList = (currentKeytermList = [], newTerm = "") => {
  const newKeytermsList = sortKeytermsByAlphabet([...currentKeytermList, newTerm]);
  return newKeytermsList;
}

/**
 * Remove keyterm from list and then generate new sorted keyterms list
 * @param {*} currentKeytermList 
 * @param {*} removeTerm
 * @returns String[]
 */
export const removeAndGenerateNewKeytermList = (currentKeytermList = [], removeTerm = "", languageCode) => {
  const isLanguageWithoutSpace = isWorkflowLanguageWithoutSpace(languageCode);
  const isWithMuliWords = isLanguageWithoutSpace ? true : removeTerm.split(" ").length > 0;
  const singularTerm = pluralize.isSingular(removeTerm) ? removeTerm : pluralize.singular(removeTerm);
  const lowerCaseRemoveTerm = isWithMuliWords ? removeTerm.toLowerCase() : singularTerm.toLowerCase();
  const newKeytermsList = currentKeytermList.filter((item) => item.toLowerCase() !== lowerCaseRemoveTerm);
  return newKeytermsList;
}