<template>
  <div>
    <v-dialog dark v-model="showPopup" max-width="960px">
      <v-card color="dDarkBg" class="pa-0 text-xs-left" min-height="420px">
        <v-card-title class="title font-weight-regular grey--text">
          <span v-if="popupType=='new'">{{$t('builder.addALink')}}</span>
          <span v-if="popupType=='edit'">{{$t('builder.editLink')}}</span>
          <v-spacer></v-spacer>
          <v-icon small color="grey" @click="$emit('close-popup')">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form v-model="formValid" autocomplete="off" style="padding:24px;">
            <p class="lable-text mt-2">
              <!-- Enter the address down below to add a link to this step. -->
              {{$t('builder.addLinkAddress')}}
            </p>
            <v-text-field
              dark
              solo
              flat
              ref="firstField"
              class="text-field-box"
              background-color="transparent"
              autocomplete="chrome-off"
              v-model="newLink.link"
              :rules="[rules.required, rules.validUrl]"
              placeholder="http://…"
              @change="checkUrl()"
              @input="madeChange=true"
              @blur="trackLinkInputBlurByHeap('link')"
            ></v-text-field>
            <p class="lable-text mt-2">
              <!-- Do you want to add a label? -->
              {{$t('builder.addLinkLable')}}
            </p>
            <v-text-field
              dark
              solo
              flat
              background-color="transparent"
              class="text-field-box"
              :value="newLink.label"
              :rules="[rules.required, rules.max]"
              :counter="90"
              maxlength="90"
              @input="handleInputLabel"
              @blur="trackLinkInputBlurByHeap('label')"
              :placeholder="$t('builder.clickOpenOfficialDocument')"
            ></v-text-field>
            <!-- placeholder="i.e.: Click to open the official document" -->
          </v-form>
        </v-card-text>
        <v-card-actions class="pr-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            round
            outline
            class="body-2 mr-2 text-capitalize btn-width"
            color="white"
            @click="$emit('close-popup')"
          >{{$t('all.cancel')}}</v-btn>
          <v-btn
            v-if="popupType=='new'"
            round
            depressed
            class="body-2 white--text text-capitalize btn-width"
            color="#4689f4"
            @click="addLink()"
            :disabled="!formValid"
          >{{$t('all.add')}}</v-btn>
          <v-btn
            v-if="popupType=='edit'"
            round
            depressed
            class="body-2 white--text text-capitalize btn-width"
            color="#4689f4"
            @click="editLink()"
            :disabled="!formValid || !madeChange"
          >{{$t('builder.editLink')}}</v-btn>
        </v-card-actions>
      </v-card>
      <div class="click-to-close" @click="$emit('close-popup')"></div>
    </v-dialog>
  </div>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconDone from "../icons/DIconDone.vue";
import DIconAlert from "../icons/DIconAlert.vue";
import DIconInfo from "../icons/DIconInfo.vue";
import DOMPurify from "dompurify";
import { INPUT_TYPE } from "@/constants/heapEvents";

export default {
  name: "DBuilderLinkPopup",
  props: {
    showPopup: Boolean, //for v-model value
    popupType: String,
    selectedLink: Object,
    editIdx: Number,
  },
  components: {
    IconBase,
    DIconDone,
    DIconAlert,
    DIconInfo,
  },
  watch: {},
  updated() {},
  data: function () {
    return {
      formValid: false,
      newLink: {
        link: "",
        label: "",
      },
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        max: (v) => !v || v.length <= 90 || this.$t("rules.max90"),
        noWhiteSpace: (value) => {
          const pattern = /\s*\S+/;
          return pattern.test(value) || this.$t("rules.noWhiteSpace");
        },
        validUrl: (v) => {
          const pattern = /^(http(s)?:\/\/)\w+[^\s]+(\.[^\s]+){1,}$/;
          return pattern.test(v) || this.$t("rules.invalidUrl");
        },
      },
      madeChange: false,
      offAutoFocus: false,
    };
  },
  created() {},
  mounted() {
    if (this.popupType === "edit") {
      this.newLink = Object.assign({}, this.selectedLink);
    }
    this.$nextTick(() => this.$refs.firstField.focus());
  },
  methods: {
    checkUrl() {
      const pattern = /(http(s)?:\/\/)/;
      if (!pattern.test(this.newLink.link) && this.newLink.link) {
        this.newLink.link = "http://" + this.newLink.link;
      }
    },
    addLink() {
      this.$emit("click-add-btn", this.newLink);
    },
    editLink() {
      this.$emit("click-edit-btn", this.newLink);
    },
    handleInputLabel(text) {
      const sanitizedText = DOMPurify.sanitize(text);
      this.newLink.label = sanitizedText;
      this.madeChange = this.selectedLink.label !== sanitizedText;
    },
    trackLinkInputBlurByHeap(inputType) {
      // Due to auto focus, don't track blur event first time when input nothing.
      if (!this.madeChange && !this.offAutoFocus) {
        return (this.offAutoFocus = true);
      }

      const type = INPUT_TYPE[inputType] || INPUT_TYPE.other;
      return this.$emit("trackLinkInputBlurByHeap", type);
    },
  },
  computed: {},
};
</script>

<style scoped>
.btn-width {
  width: 120px;
  text-transform: none !important;
}
.click-to-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.lable-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 14px;
  padding: 0 12px;
}

.text-field-box ::v-deep .v-text-field__slot input {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
}
.text-field-box ::v-deep .v-text-field__slot {
  border: 1px solid #323338;
  background-color: #17181a;
  padding: 8px 24px;
}
.text-field-box ::v-deep .v-text-field__slot input::placeholder {
  opacity: 0.2;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
}
</style>
