<template>
  <v-img
    :height="height"
    :width="width"
    class="vuetify-image"
    :contain="contain"
    :aspect-ratio="aspectRatio"
    :src="source"
    @click="$emit('click')"
  ></v-img>
</template>

<script>
import { getImageBlob, getSignedImageUrl } from "@/js/common/imageLib.js";

export default {
  name: "d-image",
  props: {
    src: String,
    aspectRatio: Number,
    contain: Boolean,
    height: String,
    width: String,
    emitOnUrlLoaded: Boolean,
  },
  watch: {
    src: function (src) {
      this.handleSignImageSrc(src);
    },
  },
  mounted() {
    this.handleSignImageSrc(this.src);
  },
  data() {
    return {
      source: "",
    };
  },
  methods: {
    async handleSignImageSrc(src) {
      try {
        if (!src) return;
        if (
          this.$route.name === "PlayerPublic" ||
          this.$route.name === "PlayerFullscreenPublic" ||
          src.startsWith("blob:")
        ) {
          this.source = src;
          return;
        }
        const signedUrl = await getSignedImageUrl(src);
        const { ok, url: blobUrl } = await getImageBlob(signedUrl);
        this.source = ok ? blobUrl : signedUrl;
        if (this.emitOnUrlLoaded) {
          this.$emit("imageUrlLoaded", src, this.source);
        }
      } catch (e) {
        this.source = "";
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.vuetify-image {
  position: relative;
}
</style>
