import Vue from "vue";
import { postSearchWorkflows } from "@/server/api-server";

export const namespaced = true;

const DEFAULT_PAGE_SIZE = 30;
const createSearchParams = function (inputs, toLoadPage, pageSize) {
  return {
    ...inputs,
    paginate: {
      page: toLoadPage,
      size: pageSize,
    },
    locale: "en-us",
  };
};

export const state = {
  searchResults: [],
  currentPage: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  searchText: "",
  loadedAll: false,
  isLoading: false,
  selectedSubCategories: [],
  selectedCategoriesMap: {},
};

export const mutations = {
  STORE_SEARCH_RESULTS(state, { results, loadMore }) {
    if (loadMore) {
      state.searchResults = state.searchResults.concat(results);
    } else {
      state.searchResults = results;
    }
  },
  STORE_SEARCH_TEXT(state, payload) {
    state.searchText = payload;
  },
  CLEAR_SEARCH_RESULTS(state) {
    state.searchResults = [];
    state.currentPage = 1;
    state.loadedAll = false;
    state.searchText = "";
  },
  SET_CURRENT_PAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_LOADED_ALL(state, results) {
    state.loadedAll = results.length === 0 || results.length < state.pageSize;
  },
  SET_IS_LOADING(state, payload = false) {
    state.isLoading = payload;
  },
  ADD_SELECTED_SUB_CATEGORY(state, { categoryId, subCategoryId }) {
    if (!state.selectedCategoriesMap[categoryId]) {
      Vue.set(state.selectedCategoriesMap, categoryId, []);
    }
    state.selectedCategoriesMap[categoryId].push(subCategoryId);
    state.selectedSubCategories.push(subCategoryId);
  },
  REMOVE_SELECTED_SUB_CATEGORY(state, { categoryId, subCategoryId }) {
    const categoryIdx = state.selectedCategoriesMap[categoryId].findIndex(({ id }) => id === subCategoryId);
    state.selectedCategoriesMap[categoryId].splice(categoryIdx, 1);
    const idx = state.selectedSubCategories.indexOf(subCategoryId);
    state.selectedSubCategories.splice(idx, 1);
  },
  CLEAR_SELECTED_SUB_CATEGORIES_BY_CATEGORY_ID(state, categoryId) {
    const ids = state.selectedCategoriesMap[categoryId];
    state.selectedSubCategories = state.selectedSubCategories.filter((itemId) => !ids.includes(itemId));
    state.selectedCategoriesMap[categoryId] = [];
  },
  CLEAR_ALL_SELECTED_SUB_CATEGORIES(state) {
    state.selectedCategoriesMap = {};
    state.selectedSubCategories = [];
  },
};

export const getters = {
  getter_search_results(state) {
    return state.searchResults;
  },
  getter_search_results_by_selected_type: (state) => (resultType) => {
    return state.searchResults.filter((result) => result.highlight.includes(resultType));
  },
  getter_search_results_number(_, getters) {
    return {
      title: getters.getter_search_results_by_selected_type("title").length,
      content: getters.getter_search_results_by_selected_type("content").length,
      publisher: getters.getter_search_results_by_selected_type("publisher").length,
    };
  },
  getter_search_results_total_number(_, getters) {
    return (
      getters.getter_search_results_number.title +
      getters.getter_search_results_number.content +
      getters.getter_search_results_number.publisher
    );
  },
  getter_is_loading_search_workflows(state) {
    return state.isLoading && state.searchResults.length === 0;
  },
  getter_is_loading_more_search_workflows(state) {
    return state.isLoading && state.searchResults.length > 0;
  },
  getter_is_loaded_all_workflows(state) {
    return state.loadedAll;
  },
  getter_formated_selected_categories(state) {
    return Object.keys(state.selectedCategoriesMap)
      .filter((key) => state.selectedCategoriesMap[key].length > 0)
      .map((key) => {
        return {
          name: key,
          values: state.selectedCategoriesMap[key],
        };
      });
  },
  getter_is_search_categories_only(state, getters) {
    return getters.getter_formated_selected_categories.length > 0 && !state.searchText;
  },
};

export const actions = {
  async fetchSearchWorkflows({ commit, state }, { inputs, loadMore = false }) {
    if (state.isLoading || (loadMore && state.loadedAll)) return;
    commit("SET_IS_LOADING", true);
    if (!loadMore) {
      commit("CLEAR_SEARCH_RESULTS");
      commit("SET_CURRENT_PAGE", 1);
    }
    const toLoadPage = loadMore ? state.currentPage + 1 : 1;
    const params = createSearchParams(inputs, toLoadPage, state.pageSize);
    const { ok, data, errorMessage } = await postSearchWorkflows(params);
    if (ok) {
      const results = data.items;
      commit("STORE_SEARCH_RESULTS", { results, loadMore });
      commit("SET_CURRENT_PAGE", toLoadPage);
      commit("SET_LOADED_ALL", results);
      const text = params.filters.text || "";
      commit("STORE_SEARCH_TEXT", text);
    } else {
      console.error("Faild to get search workflows results", errorMessage);
    }
    commit("SET_IS_LOADING", false);
  },
  clearSearchWorkflows({ commit }) {
    commit("CLEAR_SEARCH_RESULTS");
    commit("CLEAR_ALL_SELECTED_SUB_CATEGORIES");
  },
  onClickSubCategory({ commit, state }, { categoryId, subCategoryId }) {
    const idx = state.selectedSubCategories.indexOf(subCategoryId);
    if (idx === -1) {
      commit("ADD_SELECTED_SUB_CATEGORY", { categoryId, subCategoryId });
    } else {
      commit("REMOVE_SELECTED_SUB_CATEGORY", { categoryId, subCategoryId });
    }
  },
  clearSelectedSubCategoriesByCategoryId({ commit }, { categoryId }) {
    commit("CLEAR_SELECTED_SUB_CATEGORIES_BY_CATEGORY_ID", categoryId);
  },
  clearAllSelectedCategories({ commit }) {
    commit("CLEAR_ALL_SELECTED_SUB_CATEGORIES");
  },
};
