<template >
  <div class="d-admin-setting">
    <div class="tab-title-row">
      <div class="tab-title text-no-wrap" autotest="team-count">
        <div v-html="$t('admin.teamsIn')"></div>
        <div
          class="dBlue--text text-truncate text-no-wrap workspace-btn"
          @click="showGroupMenu=true"
        >{{getGroupNameById(currentGroupId)}}</div>
        <v-icon color="#52545D" class="drop-list-arrow">keyboard_arrow_down</v-icon>
      </div>

      <d-group-menu
        v-if="showGroupMenu"
        class="d-group-menu"
        :labelText="$t('admin.teamsIn')"
        :currentGroupId="currentGroupId"
        :groupDictionary="groupDictionary"
        :userGroupList="userGroupList"
        :isMobileDevice="false"
        light
        @close-menu="showGroupMenu=false"
        @update-current-group="showGroupMenu=false, updateCurrentGroup($event)"
      ></d-group-menu>
      <v-spacer></v-spacer>
      <v-btn
        round
        color="#4689f4"
        class="white--text elevation-0 body-2 text-capitalize btn-width mt-1"
        @click="showPopupType='create'"
        autotest="create-team-btn"
      >{{$t('admin.createTeam')}}</v-btn>
    </div>
    <div class="table-wrap">
      <d-data-table
        add-search
        local-search
        initial-sort
        :headers="tableHeader"
        :items="teamsData"
        :loading="isLoading"
        :placeholder="$t('admin.searchForTeams')"
        searchAutotest="team-search-bar"
      >
        <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
        <template slot="headerCell" slot-scope="props">
          <span class="head-text">{{ props.header.text }}</span>
        </template>

        <template slot="items" slot-scope="props">
          <td class="text-xs-left" :style="{'width':'30%'}">
            <span
              class="team-name-hover app-all-white-spaces"
              @click="$emit('go-to-single-team', props.item)"
              autotest="team-name-display"
            >{{props.item.name}}</span>
          </td>
          <td class="text-xs-left" :style="{'width':'30%'}" autotest="team-users-display">{{props.item.totalUsers}}</td>
          <td class="text-xs-left app-all-white-spaces" :style="{'width':'30%'}" autotest="team-workspace-display">{{props.item.groupName}}</td>

          <td class="text-xs-right text-no-wrap">
            <button 
              class="mr-3 table-btn" 
              @click="editTeamData=props.item, showPopupType='edit'"
              autotest="edit-team-btn"
            >
              <icon-base height="22" width="22">
                <d-icon-pen />
              </icon-base>
            </button>
            <button 
              class="table-btn" 
              @click="openDeleteGroupConfirmation(props.item)"
              autotest="delete-team-btn"
            >
              <icon-base height="22" width="22">
                <d-icon-trash />
              </icon-base>
            </button>
          </td>
        </template>
        <template slot="no-data">
          <span>{{$t('admin.noTeamsCreatedYet')}}</span>
        </template>
        <template slot="no-results">
          <span>{{$t('analytics.msgNoSearchResults')}}</span>
        </template>
      </d-data-table>
    </div>

    <admin-team-popup
      v-if="showPopupType=='create' || showPopupType=='edit'"
      :showPopupType="showPopupType"
      :teamsData="teamsData"
      :editTeamData="editTeamData"
      :currentGroupId="currentGroupId"
      :groupDictionary="groupDictionary"
      :userGroupList="userGroupList"
      @add-team="addTeam($event)"
      @close-popup="showPopupType='', editTeamData={}"
      @update-team="updateTeam($event)"
    ></admin-team-popup>

    <d-confirmation-popup
      v-model="showDeleteTeam"
      light
      redActionBtn
      :title="$t('admin.deleteTeam')"
      :cancelBtnName="$t('all.cancel')"
      :actionBtnName="canNotDeleteGroup? '':$t('skills.delete')"
      :content="deleteGroupMsg"
      :autotest="{
        close: 'delete-team-close-btn',
        action: 'delete-team-popup-yes-btn',
        cancel: 'delete-team-popup-cancel-btn',
      }"
      @clickActionBtn="deleteSelectedTeam()"
    ></d-confirmation-popup>

    <d-alert v-model="alert.isSuccess" type="success" :message="alert.alertText"></d-alert>
    <d-alert v-model="alert.isFailed" type="error" :message="alert.alertText"></d-alert>
  </div>
</template>

<script>
import Vue from "vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import IconBase from "@/components/IconBase.vue";
import DIconPen from "../icons/DIconPen.vue";
import DIconTrash from "../icons/DIconTrash.vue";
import DGroupMenu from "@/components/ui_components/DGroupMenu.vue";
import AdminTeamPopup from "@/components/DAdmin/AdminTeamPopup.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import DIconTeams from "@/components/icons/colored/DIconTeams.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import { createTeam, deleteTeam } from "@/server/teams-server.js";

export default {
  name: "AdminTeams",
  props: {},
  watch: {
    currentGroupId: function() {
      Vue.prototype.$editorGroup = this.currentGroupId;
      if (this.currentGroupId) {
        this.getTeams();
      }
    },
  },
  created() {
    this.getGroupList();
  },
  mounted() {},
  data() {
    return {
      currentGroupId: "",
      userGroupList: [],
      groupDictionary: {},
      showGroupMenu: false,
      tableHeader: [
        {
          text: this.$t("all.name"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "team-name-sort",
        },
        {
          text: this.$t("all.users"),
          align: "left",
          sortable: true,
          value: "totalUsers",
          autotest: "team-users-sort",
        },
        {
          text: this.$t("all.workspace"),
          align: "left",
          sortable: true,
          value: "groupName",
          autotest: "team-workspace-sort",
        },
        {
          text: "",
          sortable: false,
        },
      ],
      teamsData: [],
      isLoading: false,
      searchInput: "",
      showPopupType: "",
      editTeamData: {},
      showDeleteTeam: false,
      alert: {
        isSuccess: false,
        isFailed: false,
        alertText: "",
      },
      canNotDeleteGroup: false,
      deleteGroupMsg: "",
    };
  },
  components: {
    IconBase,
    DGroupMenu,
    DIconPen,
    DIconTrash,
    AdminTeamPopup,
    DConfirmationPopup,
    DIconTeams,
    DAlert,
    DDataTable,
  },
  mixins: [MixinDB, MixinUser],
  methods: {
    getTeams() {
      this.isLoading = true;
      this.fetchTeams().then(data => {
        let adjustedData = [];
        data.forEach(team => {
          if (team) {
            let teamData = team;
            teamData["groupName"] = this.groupDictionary[team.group];
            adjustedData.push(teamData);
          }
        });
        this.teamsData = adjustedData;
        this.isLoading = false;
      });
    },
    updateCurrentGroup(group) {
      this.currentGroupId = group.id;
    },
    getGroupNameById(id) {
      const groupName = this.groupDictionary[id];
      if (id == "All") return this.$t("all.all");
      if (groupName) {
        return groupName;
      } else {
        return "-";
      }
    },
    getGroupList() {
      const self = this;
      this.fetchMyGroups().then(data => {
        data.forEach(group => {
          if (group) {
            this.groupDictionary[group.id] = group.name;
            this.userGroupList.push({
              id: group.id,
              name: group.name,
              private: group.private,
            });
          }
        });
        this.userGroupList.sort(function(a, b) {
          const groupA = a.name.toUpperCase();
          const groupB = b.name.toUpperCase();
          if (groupA < groupB) {
            return -1;
          }
          if (groupA > groupB) {
            return 1;
          }
          return 0;
        });
        if (self.$role == "admin-organization") {
          this.userGroupList.unshift({ name: this.$t("all.all"), id: "All" });
        }
        this.currentGroupId = this.$editorGroup || this.userGroupList[0].id;
        this.getTeams();
      });
    },
    fetchMyGroups() {
      const self = this;
      if (self.$role == "admin-organization") {
        return self.fetchAllGroups();
      }
      return new Promise(function(resolve, reject) {
        let myGroups = [];
        let promises = [];
        for (const groupId in self.$userProfile.roles) {
          const role = self.$userProfile.roles[groupId];
          if (role !== "viewer" && role !== "publisher") {
            myGroups.push(groupId);
          }
        }
        myGroups.forEach(groupId => {
          promises.push(self.getDocument("groups", groupId));
        });
        Promise.all(promises)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            self.alert.alertText = self.$t("admin.alertErrorGettingWorkspaceList");
            self.alert.isFailed = true;
            // alert("error getting workspace list.");
          });
      });
    },
    fetchAllGroups() {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.getDocumentByFieldValue("groups", "organization", self.$organization).then(data => {
          resolve(data);
        });
      });
    },
    fetchTeams() {
      const self = this;
      return new Promise(function(resolve, reject) {
        const comparisons = [
          {
            field: "organization",
            comparison: "==",
            value: self.$organization,
          },
        ];
        if (self.currentGroupId && self.currentGroupId != "All") {
          comparisons.push({
            field: "group",
            comparison: "==",
            value: self.currentGroupId,
          });
        }
        self
          .getDocumentByQuery("teams", comparisons, [
            {
              type: "limit",
              value: 1000,
            },
          ])
          .then(data => {
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    updateTeam(update) {
      for (let i = 0; i < this.teamsData.length; i++) {
        if (this.teamsData[i].id == update.id) {
          this.teamsData.splice(i, 1, { ...this.teamsData[i], ...update });
          this.alert.alertText = this.$t("admin.alertSuccessfullyUpdatedTeam");
          this.alert.isSuccess = true;
        }
      }
    },
    async addTeam(event) {
      const { ok, data } = await createTeam({
        workspace: event.groupId,
        name: event.teamName,
      });
      if (ok) {
        const team = {
          organization: this.$organization,
          id: data.item,
          name: event.teamName,
          group: event.groupId,
          groupName: this.groupDictionary[event.groupId],
          totalUsers: 0,
        };
        this.teamsData.splice(0, 0, team);
        this.alert.alertText = this.$t("admin.alertSuccessfullyCreatedTeam");
        this.alert.isSuccess = true;
      } else {
        this.alert.alertText = this.$t("admin.alertFailedCreatedTeam");
        this.alert.isFailed = true;
      }
    },
    async deleteSelectedTeam() {
      const { ok, errorMessage } = await deleteTeam({
        teamId: this.editTeamData.id,
      });
      if (ok) {
        this.alert.alertText = this.$t("admin.alertDeleteTeamSuccess");
        this.alert.isSuccess = true;
        this.teamsData = this.teamsData.filter(team => team.id !== this.editTeamData.id);
      } else {
        this.canNotDeleteGroup = true;
        if (errorMessage.includes("existing skill(s) assigned to")) {
          this.deleteGroupMsg = this.$t("admin.alertDeleteTeamFailedSkills");
        } else {
          this.deleteGroupMsg = this.$t("admin.alertDeleteTeamFailed");
        }
        this.showDeleteTeam = true;
      }
    },
    openDeleteGroupConfirmation(item) {
      this.editTeamData = item;
      this.canNotDeleteGroup = false;
      this.deleteGroupMsg = this.$t("admin.popupMsgDeleteTeam", { name: this.editTeamData.name });
      this.showDeleteTeam = true;
    },
  },
  computed: {
    userProfile() {
      //get user profile from vuex
      return this.$store.state.userProfile;
    },
  },
};
</script>

<style scoped>
.d-admin-setting {
  position: relative;
  height: 100%;
  width: 100%;
}
.tab-title-row {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 30px;
  padding: 0 28px;
}
.tab-title {
  color: #3f3f3f;
  font-size: 32px;
  line-height: 44px;
  text-align: left;
  width: 50%;
  min-width: 600px;
  position: relative;
  display: flex;
}
.drop-list-arrow {
  padding: 12px 0 0 8px;
}
.d-group-menu {
  position: fixed;
  top: 210px;
  left: 0;
  height: calc(100% - 210px);
  width: 100%;
  z-index: 999;
}
.workspace-btn {
  position: relative;
  max-width: calc(100% - 200px);
  cursor: pointer;
  padding: 5px 0 0 8px;
  white-space: pre-wrap;
}
.btn-width {
  min-width: 140px;
  text-transform: none !important;
}
/* table */
.table-wrap {
  position: fixed;
  top: 224px;
  left: 0;
  width: 100%;
  height: calc(100% - 224px);
  padding: 0 28px;
  overflow: scroll;
}
.table-wrap:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}

.head-text {
  text-transform: uppercase;
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
.team-name-hover:hover {
  color: #2d2e32;
  cursor: pointer;
  font-weight: 500;
}
.table-btn:hover {
  color: #2d2e32;
}
@media screen and (max-width: 959px) {
  .tab-title-row {
    padding: 0 20px;
  }
  .table-wrap {
    padding: 0 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1903px) {
}
</style>
