<template>
  <div class="DShareDownloadAlert" id="DShareDownloadAlert" v-extend-idle:DShareDownloadAlert.mixinIdleRef="this">
    <template v-if="!downloading && ['ready','processing'].includes(downloadStatus)">
      <div class="DShareDownloadAlert__title">
        {{ downloadStatus==='processing' ? $t("explorer.tagProcessing") + '...' : downloadStatus === 'ready' ? $t('builder.yourVideoReadyToDownload') : ''}}
      </div>

      <div class="DShareDownloadAlert__videoInfo">
        {{downloadingVideoTitle}}
      </div>
      <div
        v-if="downloadStatus==='processing'"
        class="DShareDownloadAlert__processingBar"
      />

      <div
        v-else-if="downloadStatus==='ready'"
        class="DShareDownloadAlert__actions"
      >
        <v-btn
          round
          depressed
          dark
          color="#4689f3"
          class="text-capitalize"
          @click="$emit('download-video')"
        >{{$t('builder.download')}}</v-btn
        >
      </div>
    </template>

    <div  v-if="downloading" class="DShareDownloadAlert__download">
      <v-progress-circular
        :rotate="-90"
        :size="32"
        :width="1"
        :value="downloadProgress"
        color="primary"
      >
        <span class="progress-num">{{Math.floor(downloadProgress)}}%</span>
      </v-progress-circular>
      <div class="DShareDownloadAlert__download-detail">
        <p>{{$t('builder.downloading')}}</p>
        <p>{{downloadingVideoTitle}}</p>
      </div>
    </div>
  </div>
</template>


<script>
import DExtendIdle from "@/directives/DExtendIdle";
import MixinIdle from "@/components/MixinIdle.vue";
export default {
  name: "DShareDownloadAlert",
  directives: {
    "extend-idle": DExtendIdle,
  },
  mixins: [MixinIdle],
  props: {
    downloadStatus: String,
    downloadingVideoTitle: String,
    downloading: Boolean,
    downloadProgress: Number,
  },
};
</script>

<style scoped lang="scss">
.DShareDownloadAlert {
  --processing-w: calc(360px / 3);
  position: fixed;
  right: 40px;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 360px;
  background-color: #33353a;
  overflow: hidden;
  padding: 1rem;
  gap: 4px;

  &__title {
    color: #fff;
  }

  &__videoInfo {
    color: #9397a6;
  }

  &__processingBar {
    height: 4px;
    position: relative;
    background-color: #28292e;
    width: 100%;
    &:before {
      content: "";
      background-color: #4689f3;
      width: var(--processing-w);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      animation: processing 1s infinite alternate;
    }
  }

  &__actions {
    text-align: end;
    width: 100%;
    button {
      display: inline-block;
      background-color: #4689f3;
      color: #fff;
      border-radius: 180px;
      padding: 6px 12px;
      margin: 0;
    }
  }

  &__download {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    &-detail {
      p {
        margin: 0;
        text-align: start;
      }
      p:first-child {
        font-weight: 600;
        font-size: 16px;
        color: #fff;
      }
      p:last-child {
        font-size: 14px;
        color: #9397a6;
      }
    }
  }
}

@keyframes processing {
  from {
    left: 0;
  }
  to {
    left: calc(100% - var(--processing-w));
  }
}
</style>
