<template>
  <section class="admin-workspace-popup">
    <v-card width="480" color="#FFFFFF" class="card-popup">
      <button class="card-button--close" @click="$emit('closeCreateChannel')" :autotest="getAutotest('close')">
        <icon-base color="#4A4A4A" width="22" height="22">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="card-title">
        <d-icon-workspace width="170" />
        <div v-if="showForm === 'create-group'">
          <div class="card-title--text">{{ $t("admin.createWorkspace") }}</div>
        </div>
        <div v-if="showForm === 'edit-group'">
          <div class="card-title--text app-all-white-spaces" autotest="edit-workspace-popup-name-display">
            {{ formData.name }}
          </div>
        </div>
      </div>
      <v-form v-model="formValid" autocomplete="off" class="card-body">
        <v-layout xs12>
          <v-flex xs8 mr-2>
            <d-text-field
              autofocus
              v-model="groupName"
              :label="$t('admin.workspaceName') + '*'"
              :rules="[rules.required, rules.noAllSpace, rules.groupNameAllowed]"
              autocomplete="chrome-off"
              @input="madeChanges = true"
              :autotest="getAutotest('name-input')"
            ></d-text-field>
          </v-flex>
          <v-flex xs4 ml2>
            <div class="switch-item">
              <div class="text-capitalize mr-1">{{ $t("admin.privateOrnot") }}</div>
              <d-tooltip textWrap>
                <template slot="content">
                  <span>{{ $t("admin.privateWorkspaceInfo") }}</span>
                </template>
                <div class="info-button">
                  <v-icon color="#000000" class="info-icon ma-0 pa-0" small>info_outline</v-icon>
                </div>
              </d-tooltip>
              <v-switch
                v-model="isPrivate"
                color="#4689f4"
                class="switch-btn ml-2"
                :autotest="getAutotest('private-toggle')"
                readonly
                @change="madeChanges = true"
                @click.stop="clickPrivateSwitch()"
              ></v-switch>
            </div>
          </v-flex>
        </v-layout>

        <d-select
          v-model="businessAdded"
          :items="businessData"
          displayProperty="name"
          valueProperty="id"
          :label="$t('all.business')"
          @change="madeChanges = true"
          :autotest="getAutotest('business-dropdown')"
          listWidth="430"
        ></d-select>

        <v-layout xs12>
          <v-flex xs6 mr-2 v-if="showForm === 'create-group' && $access.languages">
            <d-select
              v-model="languageAdded"
              :items="languageList"
              :label="$t('admin.defaultLanguage') + '*'"
              @change="madeChanges = true"
              autotest="create-workspace-popup-language-dropdown"
            >
              <template slot="selection">
                {{ languageFullName[languageAdded] }}
              </template>
              <template slot="item" slot-scope="props">{{ languageFullName[props.item] }}</template>
            </d-select>
          </v-flex>
          <v-flex xs6 :class="{ 'ml-2': showForm === 'create-group' }">
            <d-select
              v-model="selectedColor"
              :items="colorList"
              :label="$t('admin.coverColor')"
              @change="madeChanges = true"
              autotest="create-workspace-popup-color-dropdown"
            >
              <template slot="selection">
                <div class="d-select__selection">
                  <div class="d-select__colorBlock" :style="{ background: getWorkspaceColor(selectedColor) }"></div>
                  <span>{{ $t("admin." + selectedColor) }}</span>
                </div>
              </template>
              <template slot="item" slot-scope="props">
                <div class="d-select__item">
                  <div class="d-select__colorBlock mt-0" :style="{ background: getWorkspaceColor(props.item) }"></div>
                  <span>{{ $t("admin." + props.item) }}</span>
                </div>
              </template>
            </d-select>
          </v-flex>
        </v-layout>
      </v-form>
      <v-card-actions class="card-actions">
        <v-layout xs12>
          <v-flex xs6 mr-2>
            <v-btn
              outline
              depressed
              round
              block
              class="body-2 text-capitalize"
              color="#4689f4"
              autotest="upgrade-org-admin-popup-no-btn"
              @click="$emit('closeCreateChannel')"
              >{{ $t("all.cancel") }}</v-btn
            >
          </v-flex>
          <v-flex xs6 ml-2>
            <v-btn
              v-if="showForm === 'create-group'"
              depressed
              round
              block
              class="body-2 text-capitalize white--text"
              color="#4689f4"
              :disabled="disabled.create"
              @click="createGroup()"
              autotest="create-workspace-popup-create-btn"
              >{{ $t("all.create") }}</v-btn
            >

            <v-btn
              v-if="showForm === 'edit-group'"
              depressed
              round
              block
              class="body-2 text-capitalize white--text"
              color="#4689f4"
              :disabled="disabled.edit"
              @click="saveChanges()"
              autotest="edit-workspace-popup-close-btn"
              >{{ $t("all.save") }}</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>

    <!-- This workspace name is already in the system. -->
    <d-alert v-model="isExistingGroup" type="alert" :message="$t('admin.alertWorkspaceAlreadyInSystem')"></d-alert>

    <!-- Failed to create the workspace. -->
    <d-alert v-model="isAddGroupFailed" type="alert" :message="$t('admin.aletFailedCreateWorkspace')"></d-alert>

    <!-- Failed to edit the workspace. -->
    <d-alert v-model="isEditGroupFailed" type="alert" :message="$t('admin.alertFailedEditWorkspace')"></d-alert>

    <!-- Failed to edit the workspace. -->
    <d-alert v-model="showPrivateAlert" type="alert" :message="$t('admin.privateCannotChanged')"></d-alert>
  </section>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import IconBase from "@/components/IconBase.vue";
import DIconWorkspace from "@/components/icons/colored/DIconWorkspace.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import DTextField from "@/components/ui_components/DTextField.vue";
import DSelect from "@/components/ui_components/DSelect.vue";
import DTooltip from "@/components/ui_components/DTooltip.vue";
import { getWorkspaceColorCss } from "@/js/workspace-color/workspace-color.js";
import { checkAdminCommonCharacters } from "@/js/character-validator/character-validator.js";
import { mapState } from "vuex";

export default {
  name: "AdminWorkspacePopup",
  props: {
    formData: Object,
    showForm: String,
    groupsData: Array,
    businessData: Array,
  },
  data() {
    return {
      groupName: "",
      groupId: "",
      isPrivate: false,
      businessAdded: {
        id: "",
        name: "",
      },
      formValid: false,
      madeChanges: false,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || "Can not use spaces as name.";
        },
        groupNameAllowed: () => !this.groupNameNotAllowed || this.$t("rules.invalidName"),
      },
      showPart: "group-form",
      fullName: "",
      email: "",
      isExistingAdmin: false,
      isExistingGroup: false,
      isAddGroupFailed: false,
      isEditGroupFailed: false,
      languageList: [],
      languageFullName: {},
      languageAdded: "en",
      isCreating: false,
      isRequestApi: false,
      defaultColor: "blue",
      selectedColor: "",
      colorList: ["blue", "red", "green", "yellow", "purple"],
      colorSettings: {},
      showPrivateAlert: false,
    };
  },
  mixins: [MixinDB, MixinUser, MixinAdmin],
  components: {
    IconBase,
    DIconWorkspace,
    DIconClose2,
    DAlert,
    DTextField,
    DSelect,
    DTooltip,
  },
  created() {
    this.languageList = this.$i18nLocales;
    this.languageFullName = this.$i18nUiLocales;
    if (this.showForm === "create-group") {
      this.setFormForCreate();
    }
    if (this.showForm === "edit-group" && this.formData) {
      this.setFormForEditor();
    }
  },
  mounted() {},
  computed: {
    ...mapState("workspaceColor", ["workspaceColorsConfig"]),
    disabled() {
      const isDisabled = !this.formValid || this.isRequestApi;
      return {
        create: isDisabled || this.isCreating,
        edit: isDisabled || !this.madeChanges,
      };
    },
    groupNameNotAllowed() {
      return checkAdminCommonCharacters(this.groupName);
    },
  },
  methods: {
    clickPrivateSwitch() {
      if (this.showForm === "create-group") {
        this.isPrivate = !this.isPrivate;
        this.madeChanges = true;
      } else {
        this.showPrivateAlert = true;
      }
    },
    setFormForCreate() {
      this.selectedColor = this.defaultColor;
      if (this.$backendType === "ali") {
        this.languageAdded = "zh";
      }
    },
    setFormForEditor() {
      this.groupId = this.formData.id;
      this.groupName = this.formData.name;
      this.isPrivate = this.formData.private;
      this.selectedColor = this.formData.color || this.defaultColor;
      if (this.formData.business) {
        this.businessAdded = this.businessData.find((item) => item.id === this.formData.business);
      }
    },
    getWorkspaceColor(selectedColor) {
      return getWorkspaceColorCss(selectedColor, this.workspaceColorsConfig);
    },
    getAutotest(tag) {
      if (this.showForm === "create-group") {
        switch (tag) {
          case "close":
            return "create-workspace-popup-close-btn";
          case "name-input":
            return "create-workspace-popup-name-input";
          case "business-dropdown":
            return "create-workspace-popup-business-dropdown";
          case "private-toggle":
            return "create-workspace-popup-private-toggle";
          default:
            return "";
        }
      }
      if (this.showForm === "edit-group") {
        switch (tag) {
          case "close":
            return "edit-workspace-popup-close-btn";
          case "name-input":
            return "edit-workspace-popup-name-input";
          case "business-dropdown":
            return "edit-workspace-popup-business-dropdown";
          case "private-toggle":
            return "edit-workspace-popup-private-toggle";
          default:
            return "";
        }
      }
    },
    createOnlyGroup(data) {
      //when admin is already in the system
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .sendAdminRequest("add", "new-group", data)
          .then((response) => {
            resolve();
          })
          .catch((err) => {
            reject();
          });
      });
    },
    checkExistingGroup() {
      const existingData = this.groupsData.find((item) => item.name.toLowerCase() === this.groupName.toLowerCase());
      return existingData != null && existingData.id != this.formData.id;
    },
    async createGroup() {
      const self = this;
      if (self.isCreating) {
        return;
      }
      self.isCreating = true;
      if (self.checkExistingGroup()) {
        self.isExistingGroup = true;
        self.isCreating = false;
        return;
      }
      //check if admins already in the system or need to be invited
      const data = {
        name: this.groupName,
        organization: this.$organization,
        private: this.isPrivate,
        locale: this.languageAdded,
        color: this.selectedColor,
        idToken: await self.getIdToken(),
      };
      if (this.businessAdded.id) {
        data["business"] = this.businessAdded.id;
      }

      this.isRequestApi = true;
      this.createOnlyGroup(data)
        .then(() => {
          self.$emit("createGroupSuccess");
          self.$emit("updateGroupsData");
          self.$emit("closeCreateChannel");
          self.isCreating = false;
        })
        .catch((err) => {
          self.isAddGroupFailed = true;
          self.isCreating = false;
        })
        .finally(() => {
          this.isRequestApi = false;
        });
    },
    updateGroupData() {
      const self = this;
      if (self.checkExistingGroup()) {
        self.isExistingGroup = true;
        return;
      }
      // For Version 1: Public/Private flag cannot be changed when editing existing workspaces.
      let isPrivateGroup = self.formData.private;
      if (self.groupId === self.$defaultGroup) {
        // make sure General Workspace can not be turned to Private
        isPrivateGroup = false;
      }
      return new Promise(async function (resolve, reject) {
        const data = {
          name: self.groupName,
          business: self.businessAdded.id,
          groupId: self.groupId,
          color: self.selectedColor,
          private: isPrivateGroup,
        };
        self
          .sendAdminRequest("change", "group-change", data)
          .then((response) => {
            resolve();
          })
          .catch((err) => {
            self.isEditGroupFailed = true;
            reject();
          });
      });
    },
    saveChanges() {
      this.isRequestApi = true;
      this.updateGroupData()
        .then(() => {
          this.$emit("editGroupSuccess");
          this.$emit("updateGroupsData");
          this.$emit("closeCreateChannel");
        })
        .finally(() => {
          this.isRequestApi = false;
        });
    },
  },
};
</script>
<style scoped>
.admin-workspace-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.card-popup {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 auto;
}
.card-button--close {
  position: absolute;
  right: 26px;
  top: 28px;
  z-index: 1;
}
.card-title {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 84px;
}
.card-title--text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #52545d;
  padding: 12px 28px 0 28px;
}
.card-body {
  margin: 40px 24px 8px 24px;
}
.card-actions {
  padding: 24px;
}
.d-select__colorBlock {
  height: 24px;
  width: 42px;
  border-radius: 4px;
  margin-top: -3px;
}
.d-select__selection {
  display: flex;
}
.d-select__selection span {
  font-size: 16px;
  line-height: 18px;
  color: #9b9b9b;
  margin-left: 8px;
}
.d-select__item {
  display: flex;
}
.d-select__item span {
  font-size: 14px;
  line-height: 24px;
  color: #9b9b9b;
  margin-left: 8px;
}
.switch-item {
  position: relative;
  display: flex;
  padding: 34px 0 0 8px;
  color: #404040;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.switch-item ::v-deep .tooltip-div {
  width: 230px !important;
}
.info-button {
  position: relative;
  height: 24px;
  width: 16px;
  cursor: pointer;
}
.switch-btn {
  margin: 0;
  padding: 0;
}
.info-icon {
  position: relative;
  top: -1px;
}
</style>
