<template>
  <section class="d-create-skill-popup">
    <div class="close-bg" @click="$emit('close-create-playlist-popup')"></div>
    <v-card color="dWhite" width="466px" class="create-playlist-div fullscreen-center">
      <button class="close-btn" @click="$emit('close-create-playlist-popup')">
        <icon-base color="#8D909F" height="14" width="14">
          <d-icon-close-2 />
        </icon-base>
      </button>
      <div class="create-playlist-icon">
        <icon-base height="130" width="130">
          <d-icon-create-playlist />
        </icon-base>
        <p class="title-text">{{ $t("skills.createSkill") }}</p>
        <p class="subtitle-text">{{ $t("skills.createSkillSubtitle") }}</p>
      </div>
      <div class="form-div">
        <v-form v-model="formValid" autocomplete="off">
          <v-text-field
            light
            autofocus
            v-model="playlistTitle"
            :rules="[rules.required, rules.noWhiteSpace, rules.max]"
            @keydown.native="noSpace($event)"
            :counter="90"
            autotest="create-skill-skill-name"
            class="skill-name-text-field"
          >
            <template slot="label">
              <span class="input-lable-text">{{ $t("skills.skillName") }}</span>
            </template>
          </v-text-field>
          <d-composite-workspace-dropdown
            dropdownClass="mt-4"
            :workspaceId="currentGroupId"
            :workspaceLabel="$t('new.addToWorkspace')"
            :workspaceGroupId="currentWorkspaceGroupId"
            :workspacesForOldVersion="userGroupListUploader"
            @change:workspaceId="(value) => (currentGroupId = value)"
            @change:workspaceGroupId="(value) => (currentWorkspaceGroupId = value)"
          />
        </v-form>
      </div>
      <v-card-actions class="uploader-content-btns">
        <v-spacer></v-spacer>
        <v-btn
          round
          depressed
          outline
          class="body-2 text-capitalize grey--text btn-width mr-3"
          color="#4689f4"
          @click="$emit('close-create-playlist-popup')"
          autotest="create-skill-cancel"
          >{{ $t("new.cancel") }}</v-btn
        >
        <v-btn
          round
          depressed
          class="body-2 text-capitalize white--text btn-width"
          color="#4689f4"
          :disabled="!formValid || !noWhiteSpaceRule.test(playlistTitle)"
          @click="createPlaylist()"
          autotest="create-skill-create"
          >{{ $t("skills.create") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import Vue from "vue";
import MixinVideo from "../MixinVideo.vue";
import MixinDB from "../MixinDB.vue";
import IconBase from "../IconBase.vue";
import DIconDeleteUser from "../icons/colored/DIconDeleteUser.vue";
import DIconClose2 from "../icons/DIconClose2.vue";
import DIconCreatePlaylist from "../icons/colored/DIconCreatePlaylist.vue";
import DCompositeWorkspaceDropdown from "@/components/DStudio/DCompositeWorkspaceDropdown.vue";
import { IS_SUPPORT_WORKSPACE_GROUP } from "@/constants/studio-version";
import { mapGetters } from "vuex";

export default {
  name: "DCreateSkillPopup",
  props: {
    userGroupList: Array,
    groupDictionary: Object,
  },
  components: {
    IconBase,
    DIconClose2,
    DIconDeleteUser,
    DIconCreatePlaylist,
    DCompositeWorkspaceDropdown,
  },
  data() {
    return {
      isSupportWorkspaceGroup: IS_SUPPORT_WORKSPACE_GROUP,
      playlistTitle: "",
      currentGroupId: "",
      currentWorkspaceGroupId: "",
      formValid: false,
      userGroupListUploader: [],
      noWhiteSpaceRule: /\s*\S+/,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        max: (v) => (v && v.length <= 90) || this.$t("rules.max90"),
        noWhiteSpace: (value) => {
          const pattern = /\s*\S+/;
          return pattern.test(value) || this.$t("rules.noWhiteSpace");
        },
      },
    };
  },
  mixins: [MixinVideo, MixinDB],
  created() {
    this.setCurrentWorkspace();
  },
  methods: {
    createPlaylist() {
      Vue.prototype.$editorGroup = this.currentGroupId;
      this.$router.push({
        name: "SkillsBuilder",
        params: {
          playlistId: "new",
          groupId: this.currentGroupId,
          playlistTitle: this.playlistTitle,
        },
      });
    },
    setCurrentWorkspace() {
      this.userGroupListUploader = this.userGroupList.filter((group) => {
        return group.id != "All";
      });

      // old version
      if (!this.isSupportWorkspaceGroup) {
        this.setCurrentGroup();
        return;
      }

      // Workspace Group version
      this.currentGroupId = this.$route.params.workspaceId ? this.$route.params.workspaceId : this.$defaultGroup;
      this.currentWorkspaceGroupId = this.getWorkspaceGroupId(this.currentGroupId);
    },
    getWorkspaceGroupId(workspaceId) {
      const workspaceGroup = this.getter_get_edit_workspace_group_by_workspace_id(workspaceId);
      return (workspaceGroup && workspaceGroup.id) || "";
    },
    setCurrentGroup() {
      if (!this.userGroupListUploader) return;
      if (this.$role === "admin-organization") {
        // for org admin
        if (this.$editorGroup && this.$editorGroup !== "All") {
          this.currentGroupId = this.$editorGroup;
        } else {
          this.currentGroupId = this.$defaultGroup;
        }
      } else {
        // for other roles
        // use $editorGroup if $editorGroup in the group list
        let selectedGroup = "";
        this.userGroupListUploader.forEach((group) => {
          if (group.id === this.$editorGroup && group.id !== "All") {
            selectedGroup = group.id;
          }
        });
        if (selectedGroup) {
          this.currentGroupId = selectedGroup;
        } else {
          // choose the one other than the default group if more than 1 group in the group list
          const otherGroups = this.userGroupListUploader.filter((group) => {
            return group.id != this.$defaultGroup;
          });
          this.currentGroupId = otherGroups.length > 0 ? otherGroups[0].id : this.$defaultGroup;
        }
      }
    },
    noSpace(event) {
      if (event.key === " " && this.playlistTitle == "") {
        event.preventDefault();
      }
    },
  },
  computed: {
    ...mapGetters("workspaceGroups", ["getter_get_edit_workspace_group_by_workspace_id"]),
  },
};
</script>
<style scoped>
.d-create-skill-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.close-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.create-playlist-div {
  margin: 0 auto;
  width: 466px;
  height: 600px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1;
}
.create-playlist-icon {
  position: relative;
  padding-top: 40px;
  left: 0;
  width: 100%;
  height: 280px;
  margin: 0;
}
.title-text {
  color: grey;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 0;
}
.subtitle-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 0;
}
.form-div {
  position: relative;
  width: 100%;
  height: 160px;
  padding: 0 24px;
  margin: 0;
}
.input-lable-text {
  color: #8d909f;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
}
.uploader-content-btns {
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
  padding: 0 24px;
}
.btn-width {
  width: 120px;
}
.no-text-transform {
  text-transform: none !important;
}
.skill-name-text-field {
  margin-bottom: -16px;
}
</style>