<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#28292E" />
    <path
      d="M16.875 11.4219L12.3984 15.7109C12.2578 15.8281 12.1172 15.875 12 15.875C11.8594 15.875 11.7188 15.8281 11.6016 15.7344L7.10156 11.4219C6.86719 11.2109 6.86719 10.8359 7.07812 10.625C7.28906 10.3906 7.66406 10.3906 7.875 10.6016L12 14.5391L16.1016 10.6016C16.3125 10.3906 16.6875 10.3906 16.8984 10.625C17.1094 10.8359 17.1094 11.2109 16.875 11.4219Z"
      fill="#9397A6" />
  </svg>
</template>

<script>
export default {
  name: "DIconArrowDown",
};
</script>

<style scoped>
</style>
