<template >
  <div class="d-user-card">
    <div class="user-info">
      <div class="arrow-right"></div>
      <d-avatar 
        class="user-avatar"
        :size="96" 
        :userId="this.userData['id']" 
      />
      <div v-for="(attribute, idx) in customUserAttributes" :key="idx">
        <div
          class="info-lable"
        >{{labelDictionary[attribute.display.toLowerCase()] || attribute.display}}</div>
        <div class="info-text-break">{{ getAttributeValue(attribute.attribute) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import DAvatar from "@/components/ui_components/DAvatar.vue";

export default {
  name: "DAdminUserCard",
  props: {
    userData: Object,
    customUserAttributes: Array,
  },
  watch: {},
  mounted() {
  },
  data() {
    return {
      labelDictionary: {
        location: this.$t("admin.location"),
        site: this.$t("admin.site"),
        "user id": this.$t("admin.userId"),
        department: this.$t("admin.department"),
        email: this.$t("all.email"),
      },
    };
  },
  components: {
    DAvatar,
  },
  methods: {
    getAttributeValue(attribute) {
      if (this.userData[attribute]) {
        return this.userData[attribute];
      } else {
        return "";
      }
    },
    getDate(hireDate) {
      return new Date(hireDate).toLocaleDateString();
    },
  },
  computed: {},
};
</script>

<style scoped>
.d-user-card {
  position: absolute;
  width: 378px;
  left: -378px;
  top: -20px;
  z-index: 9999999;
  padding-right: 12px;
  cursor: auto;
  pointer-events: all;
}
.arrow-right {
  position: absolute;
  right: -8px;
  top: 22px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 10px;
  border-color: transparent transparent transparent #ffffff;
}
.user-avatar {
  position: absolute;
  left: 16px;
  top: 16px;
}
.user-info {
  position: relative;
  width: 100%;
  min-height: 140px;
  padding: 24px 24px 24px 138px;
  text-align: left;
  color: #52545d;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
}
.status-lable {
  font-size: 14px;
}
.user-name {
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 2px;
  word-break: break-word;
}
.green-dot {
  height: 6px;
  width: 6px;
  background-color: #3abf6f;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 2px;
}
.red-dot {
  height: 6px;
  width: 6px;
  background-color: #e03535;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 2px;
}
.info-lable {
  position: relative;
  color: #52545d;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: uppercase;
}
.info-text-break {
  position: relative;
  width: 100%;
  word-break: break-all;
  overflow: hidden;
  margin-bottom: 16px;
}
</style>
