<template>
  <d-player-setting-options
    :options="options"
    valueAttr="value"
    labelAttr="label"
    :defaultValue="currentPlaySpeed"
    @change-value="changePlaySpeed($event)"
  />
</template>

<script>
import DPlayerSettingOptions from "@/components/DWorkflowPlayer/setting/DPlayerSettingOptions.vue";
export default {
  name: "DPlayerSettingSpeed",
  components: { DPlayerSettingOptions },
  props: {
    speedOption: {
      type: Array,
    },
    currentPlaySpeed: {
      type: Number,
    },
  },
  computed: {
    options() {
      return this.speedOption.map((speed) => ({
        value: speed,
        label: speed === 1 ? this.$t("player.normal") : `${speed}x`,
      }));
    },
  },
  methods: {
    changePlaySpeed({ value }) {
      this.$emit("change-speed", { playSpeed: value });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>