<template>
  <div class="skills-explorer" @click="finishEditingPlaylistName()">
    <div class="tab-title-row">
      <p class="tab-title text-no-wrap">
        <span v-html="$t('skills.skillsIn')"></span>
        <span
          class="dBlue--text group-title text-truncate workspace-btn"
          @click="showGroupMenu = true"
          autotest="skills-workspaces"
          >{{ getGroupNameById(currentGroupId) }}</span
        >
        <v-icon color="#52545D" class="drop-list-arrow">keyboard_arrow_down</v-icon>
      </p>
      <d-group-menu
        v-if="showGroupMenu"
        class="d-group-menu"
        :labelText="$t('skills.skillsIn')"
        :currentGroupId="currentGroupId"
        :groupDictionary="groupDictionary"
        :userGroupList="userGroupList"
        :isMobileDevice="false"
        light
        @close-menu="showGroupMenu = false"
        @update-current-group="(showGroupMenu = false), updateCurrentGroup($event)"
      ></d-group-menu>
      <v-spacer></v-spacer>
      <v-btn
        round
        color="#4689f4"
        class="white--text elevation-0 body-2 text-capitalize btn-width mx-0"
        @click="showCreatePlaylistPopup = true"
        autotest="skills-create-skill"
        >{{ $t("skills.createSkill") }}</v-btn
      >
    </div>
    <div class="skill-explorer-scroll">
      <v-layout row wrap class="num-card-row">
        <d-analytics-num-card
          max4
          :isLoading="isLoading"
          :num="allPlaylistData.length"
          :text="$t('skills.skillsCreated')"
          autotest="skills-skills-created"
        >
          <d-icon-publishers />
        </d-analytics-num-card>
        <d-analytics-num-card
          max4
          :isLoading="isLoading"
          :num="publishedPlaylists.length"
          :text="$t('skills.skillsPublished')"
          autotest="skills-skills-published"
        >
          <d-icon-workflows-published />
        </d-analytics-num-card>
      </v-layout>

      <div class="table-shadow padding-page-bottom">
        <div class="serach-box center-horizontally">
          <div class="search-icon">
            <icon-base color="#52545D" width="24" height="24">
              <d-icon-search />
            </icon-base>
          </div>
          <form autocomplete="off" style="width: 100%">
            <v-text-field
              ref="searchField"
              flat
              solo
              v-model="searchInput"
              :placeholder="$t('skills.hintSearchSkills')"
              single-line
              hide-details
              background-color="transparent"
              color="#4689f4"
              class="serach-input"
              autotest="skills-search-for-skills"
            ></v-text-field>
          </form>
          <v-btn
            v-if="currentGroupId == 'All'"
            small
            round
            depressed
            outline
            class="grey--text ml-3 px-3 mt-2 mr-0 no-transform"
            @click="exportTableData()"
            autotest="skills-export-table"
            >{{ $t("analytics.exportTable") }}
          </v-btn>
        </div>
        <v-data-table
          v-model="selectedSkills"
          disable-initial-sort
          class="analytics-table"
          :headers="tableHeader"
          :items="allPlaylistData"
          :loading="isLoading"
          :search="searchInput"
          item-key="id"
          :rows-per-page-items="[10, 25, 50, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]"
          :rows-per-page-text="$t('skills.skillsPerPage')"
        >
          <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
          <template slot="headerCell" slot-scope="props">
            <span class="head-text" :autotest="props.header.autotest">{{ props.header.text }}</span>
          </template>

          <template slot="items" slot-scope="props">
            <td class="text-xs-center" :style="{ width: '60px' }">
              <button @click="props.selected = !props.selected">
                <div v-if="!props.selected" class="unchecked-circle"></div>
                <icon-base v-else width="18" height="18">
                  <d-icon-circle-checked />
                </icon-base>
              </button>
            </td>
            <td class="text-xs-left text-capitalize mr-2 pt-1 title-td">
              <d-tooltip-show-all
                @click="toSkillsBuilder(props.item)"
                :text="props.item.playlistTitle"
                maxWidth="520"
                autotest="skills-skill-name"
              ></d-tooltip-show-all>
              <br />
              <span class="text-no-wrap creater-name">
                {{ $t("skills.createdBy") }}
                <d-tooltip-show-all
                  v-if="props.item.createdBy"
                  :text="props.item.createdBy.displayName"
                  class="name-tooltip"
                  color="#919191"
                ></d-tooltip-show-all>
                <span v-if="props.item.createdAt">{{ getDate(props.item.createdAt) }}</span>
              </span>
            </td>
            <td class="text-xs-left pt-1">
              <span autotest="skills-skill-update">
                {{ getDate(props.item.publishedDate) }}
              </span>
              <br />
              <span class="text-no-wrap creater-name">
                {{ $t("skills.updatedBy") }}
                <d-tooltip-show-all
                  v-if="props.item.updatedBy"
                  :text="props.item.updatedBy.displayName"
                  class="name-tooltip"
                  color="#919191"
                ></d-tooltip-show-all>
                <d-tooltip-show-all
                  v-else-if="props.item.createdBy"
                  :text="props.item.createdBy.displayName"
                  class="name-tooltip"
                  color="#919191"
                ></d-tooltip-show-all>
              </span>
            </td>
            <td class="text-xs-left">
              <v-menu
                transition="slide-y-transition"
                offset-y
                :disabled="props.item.workflows.length === 0"
                :class="{ 'disable-menu': props.item.workflows.length === 0, 'first-disable-menu': props.index == 0 }"
                :data-diasbale-text="$t('skills.noContentMsg')"
              >
                <button
                  slot="activator"
                  class="role-btn text-capitalize btn-hover"
                  :class="{ 'disable-role-btn': props.item.workflows.length === 0 }"
                  autotest="skills-skill-publish"
                >
                  {{ props.item.published ? $t("all.published") : $t("all.unpublished") }}
                  <v-icon small class="dropdown-arrow">keyboard_arrow_down</v-icon>
                </button>
                <v-list dense>
                  <v-list-tile
                    v-if="!props.item.published"
                    class="dropdown-list-item"
                    @click="changeSkillStatus(props.item, 'publish')"
                  >
                    <v-list-tile-title class="body-1 text-capitalize">{{ $t("all.published") }}</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile v-else class="dropdown-list-item" @click="changeSkillStatus(props.item, 'unpublish')">
                    <v-list-tile-title class="body-1 text-capitalize">{{ $t("all.unpublished") }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </td>
            <td class="text-xs-center text-no-wrap">
              <button
                @click="(currentPlaylist = props.item), (deleteConfirmation = true)"
                autotest="skills-skill-delete"
              >
                <icon-base color="#1E1F22" height="22" width="22">
                  <d-icon-trash />
                </icon-base>
              </button>
            </td>
          </template>
          <template slot="no-data">
            <span>{{ $t("analytics.msgNoDataYet") }}</span>
          </template>
          <template slot="no-results">
            <span>{{ $t("analytics.msgNoSearchResults") }}</span>
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- selected Workflows expand -->
    <v-expand-transition>
      <div v-if="selectedSkills.length > 0" class="remove-div">
        <div class="fullscreen-center">
          <p class="remove-text">
            <span class="dBlue--text">{{ selectedSkills.length }}</span>
            {{ $tc("skills.skillsSelected", selectedSkills.length) }}
            <v-btn
              outline
              round
              color="#4689f4"
              class="body-2 text-capitalize btn-width ml-4"
              @click="clearAllSkills()"
              >{{ $t("skills.clearAll") }}</v-btn
            >
            <v-btn
              round
              color="#4689f4"
              class="elevation-0 body-2 white--text text-capitalize btn-width"
              @click="deleteSkillsConfirmation = true"
              >{{ $t("skills.delete") }}</v-btn
            >
          </p>
        </div>
      </div>
    </v-expand-transition>

    <!-- create-playlist-popup -->
    <d-create-skill-popup
      v-if="showCreatePlaylistPopup"
      :userGroupList="userGroupList"
      :groupDictionary="groupDictionary"
      @close-create-playlist-popup="showCreatePlaylistPopup = false"
    ></d-create-skill-popup>

    <!-- delete playlist confirmation dialog -->
    <d-confirmation-popup
      light
      v-model="deleteConfirmation"
      redActionBtn
      :title="$t('skills.deleteSkill')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('admin.popupBtnDelete')"
      :content="$t('skills.popupMsgDeleteSkill')"
      @clickActionBtn="confirmDeletePlaylist()"
      :autotest="{
        close: 'skills-delete-skill-x',
        action: 'skills-delete-skill-yes',
        cancel: 'skills-delete-skill-no',
      }"
    ></d-confirmation-popup>
    <d-confirmation-popup
      light
      v-model="deleteSkillsConfirmation"
      redActionBtn
      :title="$t('skills.deleteSkills')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('admin.popupBtnDelete')"
      :content="$t('skills.popupMsgDeleteSkills')"
      @clickActionBtn="deleteSelectedSkills()"
      :autotest="{
        close: 'skills-delete-skill-x',
        action: 'skills-delete-skill-yes',
        cancel: 'skills-delete-skill-no',
      }"
    ></d-confirmation-popup>

    <d-alert v-model="alert.isSaving" manualClose type="info" :message="$t('editor.alertIsSaving')"></d-alert>
    <d-alert v-model="alert.isNotSuccess" type="error" :message="alert.alertText"></d-alert>
    <d-alert v-model="alert.isSuccess" type="success" :message="alert.alertText"></d-alert>
  </div>
</template>

<script>
import Vue from "vue";
import IconBase from "./IconBase.vue";
import DIconCircleTrash from "./icons/DIconCircleTrash.vue";
import DIconCirclePen from "./icons/DIconCirclePen.vue";
import DIconNewList from "./icons/colored/DIconNewList.vue";
import MixinDB from "./MixinDB.vue";
import MixinDelete from "./MixinDelete.vue";
import MixinAnalytics from "./MixinAnalytics.vue";
import DIconProcessingBarFlex from "./icons/animated/DIconProcessingBarFlex.vue";
import DPlaylistPoster from "./ui_components/DPlaylistPoster.vue";
import DConfirmationPopup from "./DPopup/DConfirmationPopup.vue";
import DCreateSkillPopup from "./ui_components/DCreateSkillPopup.vue";
import DAnalyticsNumCard from "./ui_components/DAnalyticsNumCard.vue";
import DIconAllUsers from "./icons/analytics/DIconAllUsers.vue";
import DIconPublishers from "./icons/analytics/DIconPublishers.vue";
import DIconWorkflowsPublished from "./icons/analytics/DIconWorkflowsPublished.vue";
import DIconHistogram from "./icons/analytics/DIconHistogram.vue";
import DIconSearch from "./icons/DIconSearch.vue";
import DIconTrash from "./icons/DIconTrash.vue";
import DIconCircleChecked from "./icons/DIconCircleChecked.vue";
import DAlert from "./ui_components/DAlert.vue";
import DGroupMenu from "./ui_components/DGroupMenu.vue";
import DTooltipShowAll from "./ui_components/DTooltipShowAll.vue";
import { notifySkillHasChangedPublishedState } from "@/server/notifications-server.js";

export default {
  name: "SkillsExplorer",
  props: {
    userGroupList: Array,
    groupDictionary: Object,
  },
  components: {
    IconBase,
    DIconCircleTrash,
    DIconCirclePen,
    DIconNewList,
    DIconProcessingBarFlex,
    DPlaylistPoster,
    DConfirmationPopup,
    DCreateSkillPopup,
    DAnalyticsNumCard,
    DIconAllUsers,
    DIconPublishers,
    DIconWorkflowsPublished,
    DIconHistogram,
    DIconSearch,
    DIconTrash,
    DIconCircleChecked,
    DAlert,
    DGroupMenu,
    DTooltipShowAll,
  },
  watch: {
    currentGroupId: function () {
      Vue.prototype.$editorGroup = this.currentGroupId;
    },
    userGroupList: function () {
      this.currentGroupId = this.userGroupList[0].id;
    },
  },
  created() {},
  mounted() {
    if (this.userGroupList[0] && this.currentGroupId === "") {
      let selectedGroup = "";
      this.userGroupList.forEach((group) => {
        if (group.id === this.$editorGroup) {
          selectedGroup = group.id;
        }
      });
      this.currentGroupId = selectedGroup || this.userGroupList[0].id;
    }
    this.getPlaylists();
  },
  updated() {},
  data: function () {
    return {
      currentGroupId: "",
      editingPlaylistNameIdx: -1,
      newPlaylistTitle: "",
      editingPlaylistId: "",
      isEditingPlaylistName: false,
      deleteConfirmation: false,
      deleteSkillsConfirmation: false,
      currentPlaylist: {},
      isLoading: false,
      showCreatePlaylistPopup: false,
      searchInput: "",
      selectedSkills: [],
      tableHeader: [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: this.$t("all.name"),
          align: "left",
          sortable: true,
          value: "playlistTitle",
          autotest: "skills-row-name",
        },
        {
          text: this.$t("skills.lastUpdated"),
          align: "left",
          sortable: true,
          value: "publishedDate",
          autotest: "skills-row-last-update",
        },
        {
          text: this.$t("skills.status"),
          align: "left",
          sortable: true,
          value: "published",
          autotest: "skills-row-status",
        },
        {
          text: "",
          sortable: false,
        },
      ],
      alert: {
        isSuccess: false,
        isNotSuccess: false,
        isSaving: false,
        alertText: "",
      },
      showGroupMenu: false,
    };
  },
  mixins: [MixinDB, MixinDelete, MixinAnalytics],
  computed: {
    publishedPlaylists() {
      return this.$store.state.playlists.filter((playlist) => {
        if (this.currentGroupId == "All") {
          return playlist.published;
        } else {
          return playlist.published && this.currentGroupId == playlist.group;
        }
      });
    },
    allPlaylistData() {
      return this.$store.state.playlists.filter((playlist) => {
        if (this.currentGroupId == "All") {
          return true;
        } else {
          return this.currentGroupId == playlist.group;
        }
      });
    },
    filtedPlaylist() {
      return this.allPlaylistData;
    },
  },
  methods: {
    updateCurrentGroup(group) {
      this.currentGroupId = group.id;
    },
    clearAllSkills() {
      this.selectedSkills = [];
    },
    getListTime(list) {
      if (list.length === 0) {
        return "00:00";
      } else {
        let time = 0;
        for (const workflow of list) {
          time += workflow.duration;
        }
        return this.getTimeFormat(time);
      }
    },
    getTimeFormat(time) {
      // 00:00
      let sec = time;
      if (!sec || sec === Infinity) return "0:00";
      let minutes = Math.floor(sec / 60);
      let seconds = sec - minutes * 60;
      let padding = seconds < 10 ? "0" : "";
      return minutes + ":" + padding + Math.floor(seconds);
    },
    getPlaylists() {
      const options = {
        organization: this.$organization,
      };
      this.isLoading = true;
      this.$store.dispatch("getPlaylists", options);
      this.$store.subscribe(
        function (mutation, state) {
          if (mutation.type == "getPlaylistsEnd") {
            this.isLoading = false;
          }
        }.bind(this)
      );
    },
    confirmDeletePlaylist() {
      this.deletePlaylist(this.currentPlaylist)
        .then(() => {
          this.alert.isSuccess = true;
          this.alert.alertText = this.$t("skills.alertSkillSuccessfullyDeleted");
        })
        .catch((err) => {
          this.alert.isNotSuccess = true;
          this.alert.alertText = this.$t("skills.alertSkillFailedDeleted");
        });
    },
    deleteSelectedSkills() {
      const promises = [];
      this.selectedSkills.forEach((skill) => {
        promises.push(this.deletePlaylist(skill));
      });
      const self = this;
      Promise.all(promises)
        .then(() => {
          self.alert.isSuccess = true;
          self.alert.alertText = self.$t("skills.alertSkillSuccessfullyDeleted");
        })
        .catch((err) => {
          self.alert.isNotSuccess = true;
          self.alert.alertText = self.$t("skills.alertSkillFailedDeleted");
        });
    },
    toSkillsBuilder(playlist) {
      Vue.prototype.$editorGroup = this.currentGroupId;
      if (this.editingPlaylistNameIdx === -1) {
        this.$router.push({
          name: "SkillsBuilder",
          params: {
            playlistId: playlist.id,
            groupId: playlist.group,
          },
        });
      }
    },
    handleScroll(event) {
      const scrollHeight = event.target.scrollTop;

      const areaToScroll = event.target.scrollHeight - event.target.offsetHeight;
      if (areaToScroll <= Math.ceil(event.target.scrollTop)) {
        this.totalToShow += 8;
        if (this.totalToShow >= this.workflows.length) {
          const options = {
            organization: this.$organization,
            quantity: 100,
          };
          if (this.currentGroupId && this.currentGroupId != "All") {
            options["group"] = this.currentGroupId;
          }
          //no more workflows to show; add more
          this.$store.dispatch("getMorePlaylists", options);
        }
      }
    },

    getGroupNameById(id) {
      const groupName = this.groupDictionary[id];
      if (id == "All") return this.$t("all.all");
      if (groupName) {
        return groupName.charAt(0).toUpperCase() + groupName.slice(1);
      } else {
        return "-";
      }
    },
    clickEditPlaylistName(idx, playlist) {
      this.newPlaylistTitle = playlist.playlistTitle;
      this.editingPlaylistNameIdx = idx;
      this.$nextTick(() => this.$refs.inputVideoName[0].focus());
      this.editingPlaylistId = playlist.id;
    },
    handleInput(event) {
      this.noSpace(event);
      if (event.keyCode == 13) {
        //event.keyCode == 13 is to add line breaks
        event.preventDefault();
        this.isEditingPlaylistName = false;
        this.editingPlaylistNameIdx = -1;
      }
    },
    handleUpdatePlaylistTitle() {
      if (this.newPlaylistTitle != "") {
        this.updateDocument("playlists", this.editingPlaylistId, {
          playlistTitle: this.newPlaylistTitle,
        })
          .then(() => {})
          .catch((err) => {
            alert(err);
          });
      }
    },
    noSpace(event) {
      if (event.key === " " && this.newPlaylistTitle == "") {
        event.preventDefault();
      }
    },
    finishEditingPlaylistName() {
      if (!this.isEditingPlaylistName) {
        this.isEditingPlaylistName = false;
        this.editingPlaylistNameIdx = -1;
      }
    },
    /////////////////
    getDate(timestampObj) {
      const time = timestampObj.toDate();
      return new Date(time).toLocaleDateString();
    },
    changeSkillStatus(skill, todo) {
      this.alert.isSaving = true;
      let publishedBoolean = false;
      if (todo == "publish") {
        publishedBoolean = true;
      }
      const self = this;
      const id = skill.id;
      this.updateDocument("playlists", id, {
        id: id,
        published: publishedBoolean,
      })
        .then(() => {
          skill.published = publishedBoolean;
          if (todo == "publish") {
            self.alert.alertText = self.$t("skills.alertSkillSuccessfullyPublished");
            notifySkillHasChangedPublishedState({
              playlistId: id,
              isPublished: true,
              vueInstanceRef: self,
            });
          } else if (todo == "unpublish") {
            self.alert.alertText = self.$t("skills.alertSkillSuccessfullyUnpublished");
            notifySkillHasChangedPublishedState({
              playlistId: id,
              isPublished: false,
              vueInstanceRef: self,
            });
          }

          self.alert.isSaving = false;
          self.alert.isSuccess = true;
        })
        .catch((err) => {
          // alert(err);
          self.alert.isSaving = false;
          self.alert.isNotSuccess = true;
          self.alert.alertText = self.$t("skills.alertFailedSavedSkill");
        });
    },
    async exportTableData() {
      const self = this;
      let dataExport = await this.getSkillsUsersExport(this.$organization);
      let data = [];
      let data2 = dataExport.split("\r\n");
      data2.forEach((element) => {
        data.push([element]);
      });
      const fileExport = "Skill_" + self.currentGroupId + "_users_data.csv";
      this.downloadTableAsCsvFile(data, fileExport);
    },
  },
};
</script>

<style scoped>
.skills-explorer {
  position: fixed;
  top: 134px;
  height: calc(100% - 134px);
  width: 100%;
  overflow: hidden;
}
.tab-title-row {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 32px;
  padding: 0 28px;
}
.tab-title {
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 44px;
  text-align: left;
}
.drop-list-arrow {
  position: relative;
  top: -4px;
}
.group-title {
  display: inline-block;
  max-width: 300px;
  margin-bottom: -8px;
}
.btn-width {
  min-width: 120px;
  text-transform: none !important;
}
.skill-explorer-scroll {
  position: absolute;
  width: 100%;
  height: calc(100% - 90px);
  overflow: auto;
  padding: 0 28px 64px 28px;
}
.skill-explorer-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.num-card-row {
  position: relative;
  width: calc(100% + 16px);
  left: -8px;
  margin-bottom: 32px;
}
/*** analytics-table ***/
.table-shadow {
  position: relative;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
}
.serach-box {
  height: 70px;
  padding: 0 22px;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.search-icon {
  height: 32px;
  width: 32px;
  padding: 4px;
}
.serach-input {
  margin: 0;
  padding: 0;
}
.serach-input ::v-deep .v-text-field__slot input {
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.serach-input ::v-deep .v-text-field__slot input::placeholder {
  opacity: 0.5;
  color: #919191;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.analytics-table ::v-deep thead tr:first-child {
  height: 40px !important;
  background-color: #eaeaea;
}
.analytics-table ::v-deep table {
  color: #78767a;
  font-family: Roboto;
  font-size: 16px;
  line-height: 18px;
}
.analytics-table ::v-deep td {
  position: relative;
  font-size: 16px;
  height: 70px;
}
.head-text {
  text-transform: uppercase;
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
.title-td {
  position: relative;
  width: 50% !important;
  max-width: 50% !important;
  min-width: 50% !important;
}
/* ******* */
.creater-name {
  color: #919191;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 28px;
}
.name-tooltip {
  display: inline-block;
  margin-bottom: 2px;
}
.unchecked-circle {
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  border: 1px solid #d4d4d4;
  border-radius: 9px;
}

.role-btn {
  position: relative;
  background-color: white;
  height: 34px;
  width: 174px;
  text-align: left;
  padding: 0 8px;
  border: 1px solid #dadada;
}
.dropdown-arrow {
  position: absolute;
  right: 8px;
  color: #78767a;
  top: 8px;
}
.dropdown-list-item {
  color: #78767a;
}
.dropdown-list-item:hover {
  background-color: #f5f5f5 !important;
}
.btn-hover:hover {
  color: #2d2e32;
}
.role-btn:hover .dropdown-arrow {
  color: #2d2e32;
}
.disable-role-btn {
  opacity: 0.5;
}
.padding-page-bottom {
  margin-bottom: 72px;
}
.remove-div {
  position: fixed;
  height: 70px;
  width: 100%;
  background-color: #323338;
  bottom: 0;
  left: 0;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.remove-text {
  color: #ffffff;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}
.tooltip-btn {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
}
.d-group-menu {
  position: fixed;
  top: 210px;
  left: 0;
  height: calc(100% - 210px);
  width: 100%;
  z-index: 999;
}
.workspace-btn {
  cursor: pointer;
}
.no-transform {
  text-transform: none;
}
.disable-menu ::v-deep .v-menu__activator--disabled {
  cursor: not-allowed;
  user-select: none;
  pointer-events: none;
}

.disable-menu:before {
  position: absolute;
  top: 0;
  padding: 10px;
  width: 174px;
  margin-left: 0;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  content: attr(data-diasbale-text);
  opacity: 0;
  background-color: #fff;
  color: #4e4e4e;
  font-size: 1rem;
  border: 1px solid #eaeaea;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.first-disable-menu:before {
  top: 34px;
}
.disable-menu:hover:before {
  opacity: 1;
  transform: translateY(calc(-50% - 12px));
}
</style>
