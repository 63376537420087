<template>
  <section class="user-invite-create">
    <v-card width="466" color="#FFFFFF" class="popup-box fullscreen-center">
      <button class="close-btn" @click="$emit('close-popup')" :autotest="getAutotest('close')">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="title-div">
        <icon-base width="100" height="100">
          <d-icon-categories />
        </icon-base>
        <div>
          <div class="popup-title">
            <span v-if="showPopupType == 'create'">{{ $t("admin.createCategory") }}</span>
            <span v-if="showPopupType == 'edit'">{{ $t("admin.editCategory") }}</span>
          </div>
          <div>
            <v-form v-model="formValid" @submit.prevent autocomplete="off" :style="{ margin: '42px 0 72px 0' }">
              <v-text-field
                autofocus
                v-model="categoryName"
                class="create-input"
                :label="$t('admin.categoryName') + '*'"
                :rules="[rules.required, rules.noAllSpace, rules.categoryNameAllowed]"
                autocomplete="chrome-off"
                @input="madeChanges = true"
                :autotest="getAutotest('name-input')"
              ></v-text-field>
            </v-form>
          </div>
          <v-card-actions class="btn-div">
            <v-spacer></v-spacer>
            <v-btn
              v-if="showPopupType == 'create'"
              depressed
              round
              class="body-2 white--text text-capitalize btn-width"
              color="#4689F3"
              :disabled="!formValid"
              @click="createCategory()"
              autotest="create-category-popup-create-btn"
              >{{ $t("all.create") }}</v-btn
            >
            <v-btn
              v-if="showPopupType == 'edit'"
              depressed
              round
              class="body-2 white--text text-capitalize btn-width"
              color="#4689F3"
              :disabled="!formValid || categoryName == editCategoryData.name"
              @click="saveCategory()"
              autotest="edit-category-popup-save-btn"
              >{{ $t("all.save") }}</v-btn
            >
          </v-card-actions>
        </div>
      </div>
    </v-card>
    <!-- Category already in the system. -->
    <d-alert v-model="isExistingCategory" type="alert" :message="$t('admin.alertCategoryAlreadyInSystem')"></d-alert>

    <!-- Failed to add Category. -->
    <d-alert v-model="isAddedFailed" type="alert" :message="$t('admin.alertFailedAddCategory')"></d-alert>

    <!-- Failed to edit Category. -->
    <d-alert v-model="isEditFailed" type="alert" :message="$t('admin.alertFailedEditCategory')"></d-alert>

    <div class="popup-close" @click="$emit('close-popup')"></div>
  </section>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconClose2 from "../icons/DIconClose2.vue";
import DIconCategories from "../icons/colored/DIconCategories.vue";
import DAlert from "../ui_components/DAlert.vue";
import { checkAdminCommonCharacters } from "@/js/character-validator/character-validator.js";

export default {
  name: "AdminSettingCategoriesPopup",
  props: {
    showPopupType: String,
    categoriesData: Array,
    editCategoryData: Object,
  },
  components: {
    IconBase,
    DIconClose2,
    DIconCategories,
    DAlert,
  },
  data() {
    return {
      categoryName: "",
      formValid: false,
      madeChanges: false,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        categoryNameAllowed: () => !this.categoryNameNotAllowed || this.$t("rules.invalidName"),
      },
      isExistingCategory: false,
      isAddedFailed: false,
      isEditFailed: false,
    };
  },
  computed: {
    categoryNameNotAllowed() {
      return checkAdminCommonCharacters(this.categoryName);
    },
  },
  created() {
    if (this.showPopupType == "edit") {
      this.categoryName = this.editCategoryData.name;
    }
  },
  mounted() {},
  methods: {
    getAutotest(tag) {
      if (this.showPopupType === "create") {
        switch (tag) {
          case "close":
            return "create-category-popup-close-btn";
          case "name-input":
            return "create-category-popup-name-input";
          default:
            return "";
        }
      }
      if (this.showPopupType === "edit") {
        switch (tag) {
          case "close":
            return "edit-category-popup-close-btn";
          case "name-input":
            return "edit-category-popup-name-input";
          default:
            return "";
        }
      }
    },
    createCategory() {
      if (this.checkExistingCategories()) {
        this.isExistingCategory = true;
        return;
      }
      // TODO: create Category
      this.$emit("add-category", { name: this.categoryName });
      this.$emit("close-popup");
    },
    saveCategory() {
      if (this.checkExistingCategories()) {
        this.isExistingCategory = true;
        return;
      }
      this.$emit("update-category", {
        id: this.editCategoryData.id,
        name: this.categoryName,
        saveToDb: { name: this.categoryName },
      });
      this.$emit("close-popup");
    },
    checkExistingCategories() {
      const existingData = this.categoriesData.find(
        (item) => item.name.toLowerCase() === this.categoryName.toLowerCase()
      );
      return existingData != null && existingData.id != this.editCategoryData.id;
    },
  },
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999 !important;
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.popup-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 28px 20px 0 20px;
}
.popup-title {
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-top: 12px;
}

.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.no-text-transform {
  text-transform: none !important;
}
.btn-div {
  margin-top: 32px;
}
.btn-width {
  width: 120px;
}
</style>
