<template>
  <div class="AttachmentsBar">
    <player-attachments-button
      v-if="showAttachmentsBtn"
      :upper="hideControls"
      :currentStep="currentStep"
      :showList="showList"
      @show-list="showList = true"
      @hide-list="showList = false"
    />
    <attachments-bar-list
      :showAttachmentsBar="showAttachmentsBtn && showList"
      :currentStep="currentStep"
      :upper="hideControls"
      @hide-list="showList = false"
    />
  </div>
</template>
    
<script>
import PlayerAttachmentsButton from "@/components/DWorkflowPlayer/attachments/PlayerAttachmentsButton.vue";
import AttachmentsBarList from "@/components/DWorkflowPlayer/attachments/AttachmentsBarList.vue";
import { mapGetters } from "vuex";
export default {
  name: "AttachmentsBar",
  props: {
    hideControls: Boolean,
    currentStep: Number,
    iframeFullscreen: Boolean,
    isEmbedDeephow: Boolean,
  },
  components: {
    PlayerAttachmentsButton,
    AttachmentsBarList,
  },
  watch: {
    getter_is_window_mode: {
      handler(getter_is_window_mode) {
        this.showList = !getter_is_window_mode;
      },
    },
    iframeFullscreen: {
      handler(iframeFullscreen) {
        this.showList = iframeFullscreen;
      },
    },
  },
  created() {
    this.showList = this.iframeFullscreen || this.isEmbedDeephow;
  },
  data() {
    return {
      showList: false,
    };
  },
  computed: {
    ...mapGetters("workflowPlayer", ["getter_attachments", "getter_show_attachments_list", "getter_is_window_mode"]),
    showAttachmentsBtn() {
      return this.getter_attachments[this.currentStep] && this.getter_attachments[this.currentStep].length > 0;
    },
  },
};
</script>
    
<style lang="scss" scoped>
.AttachmentsBar {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  min-width: 240px;
  height: 100%;
  pointer-events: none;
  &--upper {
    top: 24px;
    transition: 0.2s;
  }
}
</style>
    