import { padStart, round } from "lodash-es";
export const TIME_REGEX = /^\d{2,3}:\d{2}$/;
export const CENTI_SECOND_TIME_REGEX = /^(\d{2,3}):(\d{2})\.(\d{2})$/;
import { MULTI_EDITOR_STATUS } from "@/constants/editorStatus";

export async function getVideoDuration(videoUrl) {
  return new Promise(function (resolve) {
    try {
      let duration;
      const videoPlayer = document.createElement("video");
      videoPlayer.addEventListener("loadedmetadata", function () {
        if (videoPlayer.duration === Infinity) {
          videoPlayer.currentTime = Number.MAX_SAFE_INTEGER;
          videoPlayer.ontimeupdate = () => {
            videoPlayer.ontimeupdate = null;
            duration = videoPlayer.duration;
            resolve(duration);
          };
        } else {
          duration = videoPlayer.duration;
          resolve(duration);
        }
      });
      videoPlayer.src = videoUrl;
    } catch (err) {
      resolve(0);
    }
  });
}

export function getCurrentEndTime(dbDuration, playerDuration, expectedEndTime) {
  if (expectedEndTime === playerDuration) {
    return dbDuration;
  }
  const currentEndTime = dbDuration < expectedEndTime ? dbDuration : expectedEndTime;
  return currentEndTime;
}

export function getDefaultProgressTimeRange(type, videoDuration) {
  return type === "erase" ? [videoDuration * 0.45, videoDuration * 0.55] : [0, videoDuration];
}
export function isValidTimeText(timeText = "") {
  const isValidFormat = TIME_REGEX.test(timeText);
  if (!isValidFormat) return false;
  const [min, sec] = timeText.split(":");
  if (sec >= 60) return false;
  return true;
}

export function isValidCentiTimeText(timeText = "") {
  const matchedResult = timeText.match(CENTI_SECOND_TIME_REGEX);
  if (!matchedResult) return false;
  const [, min, sec, centiSec] = matchedResult;
  if (sec >= 60) return false;
  return true;
}

export function isValidTimeRange(timeSeconds, minTime, maxTime) {
  if (timeSeconds < minTime) return false;
  if (timeSeconds > maxTime) return false;
  return true;
}

export function isValidSelectedTimeRange(targetName, targetTimeSeconds, minTime, maxTime) {
  const [startTime, endTime] = targetName === "startTime" ? [targetTimeSeconds, maxTime] : [minTime, targetTimeSeconds];
  const rangeMargin = endTime - startTime;
  if (rangeMargin <= 0) return false;
  return true;
}

export function toTimeFormat(timeSeconds = 0) {
  const timeInt = Math.floor(timeSeconds);
  const minutes = Math.floor(timeInt / 60);
  const seconds = timeInt - minutes * 60;
  return `${padStart(minutes, 2, "0")}:${padStart(seconds, 2, "0")}`;
}

export function toCentiSecondTimeFormat(timeSeconds) {
  const timeNumberFormat = parseFloat(timeSeconds);
  const [, centiSec] = timeNumberFormat.toFixed(3).match(/\.(\d{2})/);
  const timeInt = Math.floor(timeNumberFormat);
  const minutes = Math.floor(timeInt / 60);
  const seconds = timeInt - minutes * 60;
  return `${padStart(minutes, 2, "0")}:${padStart(seconds, 2, "0")}.${centiSec}`;
}

export function toCentiSecondTimeFormatWithinFrame(timeSeconds) {
  const [timeWithoutCenti, centi] = toCentiSecondTimeFormat(round(timeSeconds, 2)).split(".");
  let frame = round(+centi / (100 / MULTI_EDITOR_STATUS.DEFAULT_FPS));
  return `${timeWithoutCenti}:${padStart(String(frame), 2, "0")}`;
}

export function toSeconds(timeText = "") {
  const [min, sec] = timeText.split(":");
  return Number(min) * 60 + Number(sec);
}

export function toCentiSeconds(timeText = "") {
  const matchedResult = timeText.match(CENTI_SECOND_TIME_REGEX);
  if (!matchedResult) return 0;
  const [, min, sec, centiSec] = matchedResult;
  return Number(min) * 60 + Number(sec) + Number(centiSec * 0.01);
}
