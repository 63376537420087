module.exports = {
  ad: {
    highlights: "Destaques",
    highlightsIntro: "Aqui você sempre terá uma visão rápida de novos lançamentos de recursos, como este. Além disso, Stephanie vai lembrá-lo e ajudar a acompanhar o seu treinamento de habilidades em andamento.",
    recommendations: "Recomendações",
    recommendationsIntro: "Stephanie recomendará fluxos de trabalho com base em suas atividades por aqui. Ela vai aprender sobre todas as interações que você tem com ela!",
    favoritesIntro: "Já se perguntou o quão incrível seria ter todos os fluxos de trabalho que você mais precisa, ali esperando por você?! Agora você tem um lugar especial para mantê-los prontos para ir!",
    categoriesIntro: "Tantos fluxos de trabalho, tão pouco tempo ... não seria bom apenas clicar e mostrar categorias que são relevantes para você? Agora você pode! :)",
    loginMsgTitle: "Nova atualização de funcionalidades!",
    loginMsg: "Agora você pode pesquisar facilmente por seu espaço de trabalho. Além disso, confira as recomendações de conteúdo para ajudá-lo a utilizar melhor a sua conta na DeepHow."
  },
  all: {
    success: "Sucesso",
    player: "Player",
    editor: "Editor",
    admin: "Administrador",
    analytics: "Análise",
    skills: "Habilidades",
    skill: "Habilidade",
    unpublished: "Não publicado",
    published: "Publicado",
    signOut: "Sair",
    step: "etapa",
    steps: "etapas",
    numStep: "0 etapa | 1 etapa | {count} etapas",
    transcription: "Transcrição",
    no: "Não",
    yes: "Sim",
    delete: "excluir",
    diagram: "diagrama",
    all: "Tudo",
    discard: "descartar",
    save: "salve",
    next: "próximo",
    process: "Processar",
    cancel: "Cancelar",
    close: "Fechar",
    allUsers: "Todos os usuários",
    users: "Usuários",
    user: "usuário",
    numUsers: "usuários",
    numUser: "usuário",
    countUsers: "0 Usuário | 1 Usuário | {count} Usuários",
    workspaces: "Áreas de trabalho",
    workspace: "Área de trabalho",
    workspaceGroup: "Grupo de espaço de trabalho",
    workflows: "Fluxos de trabalho",
    workflow: "Fluxo de trabalho",
    organization: "Organização",
    business: "Negócio",
    create: "criar",
    name: "Nome",
    namePerson: "Nome",
    email: "E-mail",
    employeeId: "ID do empregado",
    role: "função",
    organizationAdmin: "Administrador da Organização",
    workspaceAdmin: "Administrador do Espaço de Trabalho",
    publisher: "Editor",
    viewer: "Visualizador",
    organizationAdmins: "Administradores da Organização",
    workspaceAdmins: "Administradores da área de trabalho",
    publishers: "Editores",
    viewers: "Visualizadores",
    add: "Adicionar",
    attachments: "anexos",
    private: "privado",
    public: "público",
    content: "Conteúdo",
    noRecordFound: "Nenhum registro encontrado",
    loadingRecords: "Carregando registros...",
    teams: "Equipes",
    loadedAllRecords: "Todos registros carregados",
    language: "Linguagem",
    interfaceLanguage: "Linguagem de interface",
    personalInfo: "Informações pessoais",
    privacyPolicy: "Política de Privacidade",
    team: "Equipes",
    unselect: "Desmarcar",
    select: "Selecionar",
    applied: "Aplicado",
    noAccessToAdminTools: "Esta conta de usuário atualmente não tem acesso às ferramentas de administração",
    changePhoto: "Mudar foto",
    removePhoto: "Remova a foto",
    profilePhoto: "Foto de perfil",
    takeOrUpload: "Tirar ou enviar foto",
    useCamera: "Use a câmera",
    uploadPhoto: "Enviar foto",
    editPhoto: "Editar foto",
    takePhoto: "Tirar foto",
    retake: "Tirar novamente",
    countWorkspaces: "Área de trabalho | Área de trabalho | Áreas de trabalho",
    whatsNew: "O que há de novo?",
    howToUse: "Como usar",
    defaultInterfaceLanguage: "Linguagem de interface padrão",
    newVersionSnackbarText: "Uma nova versão da DeepHow está disponível.",
    refresh: "refrescar",
    unpublishedList: "Não publicado",
    publishedList: "Publicado",
    userAgreement: "Termo de Acordo do Usuário",
    numAttachments: "0 anexo | 1 anexo | {count} anexos",
    attachment: "anexo",
    knowledgeBase: "Base DeepHow Knowledge",
    changeName: "Mude o nome",
    changePassword: "Mudar senha",
    openInApp: "Aberto em Deephow Navigator",
    userNewToDeepHow: "Você é novo na DeepHow?",
    downloadTheApp: "Baixe o aplicativo",
    continueToUseBrowser: "Continue a usar o navegador",
    contentManager: "Gestor de conteúdos",
    gen2Forbidden: "Como usuário da Gen2 Light, você não pode fazer login agora.",
    confirm: "Confirme",
    myWorkspaceGroups: "Meus grupos de espaço de trabalho",
    otherWorkspaceGroups: "Outros grupos de espaço de trabalho",
    searchResult: "Resultados da pesquisa",
    noResultsFound: "Nenhum resultado encontrado.",
    browseWorkspace: "Navegar pelos espaços de trabalho...",
  },
  password: {
    createYourAccount: "Crie sua conta",
    checkNameChoosePassword: "Verifique seu nome e escolha uma senha",
    createAccount: "Criar conta",
    createNewPassword: "Crie uma nova senha",
    selectNewPassword: "Seleciona uma nova senha para sua conta",
    newPassword: "Nova senha",
    confirmNewPassword: "Confirme a nova senha",
    passwordMustMatch: "A senha deve corresponder.",
    confirm: "Confirme",
    passwordCreatedMsg: "Sua nova senha foi criada, <br> por favor aguarde enquanto redirecionamos você...",
    goToDeepHowPlayer: "Vá para o DeepHow Player",
    failedResetPassword: "Falha ao redefinir a senha. Sua conta já foi criada ou há um problema de rede",
    alertSetPasswordFailed: "Falha ao definir a senha. Sua conta já foi criada ou há um problema de rede.",
    linkExpired: "Este link expirou",
    passwordExpired: "Seu link de redefinição de senha expirou.",
    inviteExpired: "Seu convite expirou.",
    resetPasswordMsg: "Clique no botão abaixo para solicitar um email de redefinição de senha.",
    // added 09/08/2022
    alertFailedResetPassword: "Falha ao redefinir a senha.",
    alertSuccessfullyResetPassword: "Redefinido com sucesso a senha, aguarde enquanto o redirecionamos para fazer login novamente… <br><br> ou clique no botão abaixo.",
    newPasswordNotTheSame: "A nova senha não deve ser a mesma que a antiga.",
    alertCurrentPasswordIncorrect: "Senha atual incorreta",
    alertTooManyAttempts: "Muitas tentativas para validar a senha atual. Tente mais tarde.",
  },
  analytics: {
    overview: "Visão geral",
    engagement: "Engajamento",
    activeUsers: "Usuários ativos",
    workflowsCreated: "Fluxos de trabalho criados",
    workflowsPublished: "Fluxos de trabalho publicados",
    workflowViews: "Visualizações de fluxo de trabalho",
    timeCreated: "Hora criada",
    timePublished: "Tempo de publicação",
    timeViewed: "Tempo visto",
    searchWorkspace: "Pesquisar por áreas de trabalho...",
    mostUsers: "Maioria dos usuários",
    mostWorkflowsCreated: "A maioria dos fluxos de trabalho criados",
    numberOfUsers: "Número de usuários",
    organizationOverview: "Visão geral da organização",
    numWorkflowsIn: "Fluxos de trabalho em",
    searchWorkflow: "Pesquisar fluxos de trabalho...",
    createdBy: "Criado por",
    usersIn: "Usuários em",
    searchUser: "Pesquisar por Usuários...",
    watchTime: "Tempo de exibição",
    workflowsViewed: "Fluxos de trabalho visualizados",
    percentViewed: "% Visto",
    dateViewed: "Data vista",
    watchWorkflow: "Assistir ao fluxo de trabalho",
    backToUsers: "Voltar para os usuários",
    backToWorkflows: "Voltar para fluxos de trabalho",
    likes: "Curtir",
    avgTimeViewed: "Tempo visto média ",
    numberofViews: "Número de visualizações",
    workspacesPerPage: "Áreas de trabalho por página",
    usersPerPage: "Usuários por página",
    workflowsPerPage: "Fluxos de trabalho por página",
    exportTable: "Tabela de exportação",
    TopViewers: "Principais Visualizadores",
    TopPublishers: "Principais Editores",
    totalViews: "Total de visualizações",
    msgNoDataYet: "Sem dados disponíveis",
    msgNoSearchResults: "Nenhum registro similar encontrado",
    hms: "(H:M:S)",
    viewHistory: "Ver histórico",
    views: "Visualizações",
    totalViewingTime: "Tempo total de visualização",
    usersViewedThisWorkflow: "<b>0</b> Usuário que viram este fluxo de trabalho| <b>1</b> Usuário que viram este fluxo de trabalho | <b>{count}</b> Usuários que viram este fluxo de trabalho",
    date: "Data",
    // added 2022/07/11
    dashboards: "Painéis",
    reactions: "Reações",
    userReaction: "Reação do usuário",
    viewAll: "Ver tudo",
    favorites: "Favoritos",
  },
  admin: {
    status: "Status",
    active: "Ativo",
    inactive: "Inativo",
    invited: "Convidamos",
    invitationExpired: "Convite expirado",
    couldNotInvited: "Não foi possível ser convidado",
    hintSearchUsers: "Pesquisar usuários...",
    hintSearchWorkspaces: "Pesquisar área de trabalho...",
    hintSearchBusiness: "Pesquisar negócio...",
    alertSuccessfullyInviteUser: "Convidou o usuário com sucesso.",
    alertFailedInviteUser: "Falha ao convidar usuário.",
    alertSuccessfullyDeletedUser: "Usuário excluido com sucesso.",
    alertFailedDeleteUser: "Falha ao excluir usuário.",
    alertSuccessfullyCreatedWorkspace: "Área de trabalho criado com sucesso.",
    alertSuccessfullyEditedWorkspace: "Área de trabalho editado com sucesso.",
    alertSuccessfullyCreatedBusiness: "Negócio criado com sucesso.",
    alertSuccessfullyEditedBusiness: "Negócio editado com sucesso.",
    alertUserInvitationSuccessfullyResent: "Convite do usuário reenviado com sucesso.",
    alertFailedResendUserInvitation: "Falha ao reenviar o convite do usuário.",
    alertUserInvitationSuccessfullyDelete: "Convite de usuário excluido com sucesso.",
    alertFailedDeleteUserInvitation: "Falha ao excluir o convite do usuário.",
    resendInvitation: "Reenviar convite",
    popupMsgResendInvitation: "Tem certeza que deseja reenviar o convite para",
    popupBtnSend: "Sim, envie",
    deleteInvitation: "Apagar convite",
    popupMsgDeleteInvitation: "Tem certeza que deseja excluir o convite para",
    addUser: "Adicionar usuário",
    msgNoMembersYet: "Esta organização ainda não tem membros.",
    msgNoRecordsAllUsers: "Nenhum registro similar encontrado em todos os usuários.",
    createWorkspace: "Criar área de trabalho",
    msgNoWorkspacesYet: "Esta organização ainda não possui áreas de trabalho.",
    msgNoRecordsWorkspaces: "Nenhum registro correspondente encontrado nos áreas de trabalho.",
    msgNoRecordsWorkspacesGroupAdmin: "Esta conta ainda não possui áreas de trabalho.",
    createBusiness: "Criar negócios",
    msgNoBusinessYet: "Esta organização ainda não possui negócios.",
    msgNoRecordsBusiness: "Nenhum registro similar encontrado em negócios.",
    usersIn: "Usuários em",
    tableMsgNoUsersYet: "Este área de trabalho ainda não tem membros.",
    tableMsgNoRecordsThisWorkspace: "Nenhum registro similar encontrado nesse área de trabalho.",
    memberAt: "Membro em",
    alerBusinessAlreadyInSystem: "Negócio já no sistema",
    alerFailedAddBusiness: "Falha ao adicionar negócios.",
    alertFailedEditBusiness: "Falha ao editar negócios.",
    alertWorkspaceAlreadyInSystem: "Este nome de área de trabalho já está no sistema.",
    aletFailedCreateWorkspace: "Falha ao criar área de trabalho.",
    alertFailedEditWorkspace: "Falha ao editar área de trabalho.",
    resendAllInvites: "Reenviar todos os convites",
    resendAllInvitesMsg: "Você enviará um convite para todos que estão pendentes e expirados. Tem certeza?",
    yesSendInvites: "Sim, enviar convites",
    businessesPerPage: "Negócios por página",
    //add org user
    popupTitleAddOrgUser: "Adicionar usuário administrador da organização",
    invite: "Convidar",
    popupTitleNewUserInvited: "Novo usuário convidado!",
    popupMsgNewUserInvitedOrg: "foi convidado.<br> Após validar a conta você terá acesso a esta organização",
    popupTitleExistingUserInvited: "Usuário existente adicionado!",
    popupMsgExistingUserInvitedOrg: "Foi adicionado a esta organização.",
    popupBtnAddAnotherUser: "Adicionar outro usuário",
    alertFailedCheckPendingInvitations: "Falha ao verificar se o usuário tem convites pendentes.",
    alertFailedAddExistingUserAsOrganizationAdmin: "Falha ao adicionar usuário existente como administrador da organização",
    alertAddExistingUserAsOrganizationAdmin: "Adicione o usuário existente como administrador da organização.",
    alertUserAlreadyOrganizationAdmin: "Usuário já no sistema como administrador da organização.",
    alertFailedCheckUserAlreadyInSystem: "Falha ao verificar se o usuário já esta no sistema.",
    //Add user to workspace
    addUserToWorkspace: "Adicionar usuário ao área de trabalho",
    companyEmail: "E-mail da empresa",
    employeeIdOnly: "ID de funcionário apenas",
    fullName: "Nome completo",
    workspaceName: "Nome do área de trabalho",
    businessName: "Nome da empresa",
    popupMsgNewUserInvited: "foi convidado.<br>Após validar a conta, ele terá acesso a este área de trabalho.",
    popupMsgExistingUserInvited: "foi adicionado a este área de trabalho.",
    popupTitleNewUserCreated: "Novo usuário criado!",
    popupTitleUserAlreadyinSystem: "Usuário já esta no sistema",
    alertUserIsAlreadyInWorkspace: "Este usuário ({info}) já está neste área de trabalho.",
    alertPendingInvitation: "o usuário tem um convite pendente. Aguarde até que o usuário aceite o convite atual.",
    searchingforUsers: "Procurando usuários...",
    noResultsFound: "Nenhum resultado encontrado.",
    // Change User Role
    popupTitleChangeUserRole: "Alterar função do usuário",
    popupMsgChangeUser: "Tem certeza de que deseja alterar a função de <b>{name}</b> de <b>{oldRole}</b> para <b>{newRole}</b>?",
    popupBtnChange: "Sim, mude",
    alertUpdatedUserSettings: "Configurações do usuário atualizadas com sucesso.",
    // Remove User
    popupTitleRemoveUser: "Você tem certeza que deseja remover",
    popupMsgRemoveUserFromWorkspace: "Tem certeza de que deseja remover <b>{name}</b> do espaço de trabalho?",
    popupBtnRemove: "Sim remova",
    alertRemoveUser: "Usuário removido com sucesso do espaço de trabalho.",
    // Delete User
    popupTitleDeleteUser: "Excluir usuário do sistema",
    popupMsgDeleteUserFromAllWorkspaces: "Se você fizer isso, perderá todos os registros relativos a este usuário <b>{name}</b> e ele será excluído de todos os espaços de trabalho e funções que lhe foram atribuídas.",
    popupBtnDelete: "Sim, deletar",
    tooltipEditUser: "Editar usuário",
    tooltipResendInvite: "Reenviar convite",
    tooltipCancelInvite: "Cancelar convite",
    tooltipRemoveUser: "Remover usuário",
    // settings
    settings: "Definições",
    location: "Localização",
    hireDate: "Data de contratação",
    department: "Departamento",
    position: "Posição",
    site: "site",
    userId: "ID de usuário",
    ssoMapping: "Mapeamento SSO",
    ssoMappingNoData: "Não há dados para mapear ainda.<br/> Você precisa fazer o upload do CSV via FTP, entre em contato com o suporte.",
    attributes: "Atributos",
    oneAttributeToMap: "Você só pode selecionar um atributo para mapear usuários em áreas de trabalho",
    selectAttribute: "Selecione o atributo",
    changeAttribute: "Alterar atributo",
    discardChanges: "Descartar mudanças",
    applyChanges: "Aplicar mudanças",
    applyChangesMsg: "Você fará alterações nos seguintes áreas de trabalho, tem certeza?",
    mappingValuesUpdated: "Valores de mapeamento atualizados",
    mappingValuesUpdatedSubtitle: "Para atualizar o mapeamento com sucesso, você precisa aplicar as alterações. Você pode fazer isso quando terminar de editar todos os áreas de trabalho",
    values: "Valores",
    clickPlusToAddValues: "Clique no ícone de mais para adicionar valores",
    selectValues: "Selecione os valores",
    searchForValues: "Pesquise por valores...",
    assign: "Atribuir",
    assignValues: "Atribuir valores",
    addUsersBtn: "Sim, adicionar usuários",
    YesChangeBtn: "Sim, mude",
    numMore: "mais {count}",
    removeValues: "Remover valores",
    remove: "Remover",
    changeAttributeMessage: "Tem certeza que deseja alterar o atributo? <br/> Você perderá todo o mapeamento real que possui para este atributo.",
    discardChangesMessage: "Você vai perder todas as alterações feitas desde o último salvamento, tem certeza?",
    discardChangesBtn: "Sim, descarte as alterações",
    applyChangesBtn: "Sim, aplique as alterações",
    alertDiscardedSuccess: "Mapeamento descartado com sucesso.",
    alertAppliedSuccess: "Mapeamento aplicado com sucesso.",
    alertAppliedFailed: "Falha ao aplicar mapeamento.",
    //permissions
    permissions: "Permissões",
    products: "Produtos DeepHow",
    roleAccessToProduct: "Defina abaixo quais funções do usuário poderão acessar cada produto",
    analytics: "Análise",
    skillsManager: "Gestão de competência",
    skillsPlayer: "Player de competência",
    workflowCapture: "Captura de fluxo de trabalho",
    workflowEditor: "Editor de fluxo de trabalho",
    organizationAdmin: "Administrador da organização",
    groupAdmin: "Administrador do área de trabalho",
    publisher: "Editores",
    viewer: "Visualizadores",
    rolesPermission: "Permissão de funções",
    rolesPermissionMessage: "Tem certeza de que deseja alterar a permissão de função para este produto? <br/> As alterações serão feitas imediatamente.",
    inviteUser: "Convidar usuário",
    workflowSharing: "Compartilhamento de fluxo de trabalho",
    allowUserstoSharePublicLinks: "Permitir que os usuários compartilhem links públicos.",
    changeWorkflowSharingExplanation: "Ao desligar o compartilhamento de links públicos, os fluxos de trabalho públicos só serão acessados ​​por usuários que estão assinados na plataforma.",
    allowWorkflowSharingMessage: "Tem certeza de que deseja ativar o compartilhamento de links públicos?",
    preventWorkflowSharingMessage: "Tem certeza de que deseja desativar os links públicos? As alterações serão feitas imediatamente.",
    workflowDownload: "Baixar fluxo de trabalho",
    allowUsersToDownloadWorkflows: "Permitir que os usuários baixem fluxos de trabalho.",
    allowDownloadWorkflowsMessage: "Tem certeza de que deseja permitir que os usuários baixem os fluxos de trabalho?",
    preventDownloadWorkflowsMessage: "Tem certeza de que deseja impedir que os usuários baixem fluxos de trabalho?",
    autoLogout: "Logout automático",
    autoLogoutAfterSelectedTime: "Users will be automatically logged out after the selected time.",
    numMinutes: "0 minuto | 1 minuto | {count} minutos",
    changeLogoutTimeMessage: "Tem certeza de que deseja alterar o tempo automaticamente logado para {Num} minutos?",
    enableAutoLogoutMessage: "Tem certeza de que deseja ativar o logout automático?",
    disableAutoLogoutMessage: "Tem certeza de que deseja desativar o logout automático?",
    changesMadeImmediately: "As alterações serão feitas imediatamente.",
    sessionIsAboutToExpire: "Sua sessão está prestes a expirar.",
    youWillBeLoggedOutNumSeconds: "Você será logado em 0 segundo | Você será logado em {count} segundos",
    continueSession: "Continuar a sessão",
    //system languages
    languages: "Linguagem",
    systemLanguage: "Linguagem do sistema",
    searchForWorkspaces: "Buscar por Áreas de Trabalho...",
    workspacesPerPage: "Áreas de trabalho por página",
    workspace: "Área de trabalho",
    workspaceLanguage: "Linguagem",
    changeLanguage: "Mudar de língua",
    popupMsgChangeLanguage: "Mudar de língua irá afetar todos os usuários dentro deste Área de Trabalho, tem certeza de que deseja mudar?",
    alertLanguageSuccessfullyChanged: "Sucesso ao trocar língua",
    alertFailedChangeLanguage: "Falhar ao trocar língua",
    // Categories
    categories: "Categorias",
    alertCategoriesNotSaved: "Categorias não salvas, tente novamente mais tarde.",
    noCategoryResult: "Nenhum resultado de pesquisa de categoria",
    noCategoriesAddedYet: "Nenhuma categoria adicionada ainda",
    categoriesAssignment: "Atribuição de categorias",
    categoryPlaceholder: "Categorias de pesquisa e subcategorias ...",
    previouslyUsed: "Usado anteriormente",
    allCategories: "Todas as categorias",
    workflowCategories: "Categorias de fluxo de trabalho",
    searchForCategories: "Buscar por categorias",
    categoryItems: "itens",
    categoryItemsNum: "itens",
    createCategory: "Criar categoria",
    editCategory: "Editar categoria",
    categoryName: "nome",
    noCategoriesCreatedYet: "Nenhuma categoria criada ainda",
    noItemsCreatedYet: "Nenhum item criado ainda",
    categoriesPerPage: "Categorias por página",
    itemsPerPage: "Itens por página",
    alertCategoryAlreadyInSystem: "Categoria já existente no sistema",
    alertFailedAddCategory: "Falha ao adicionar categoria",
    alertFailedEditCategory: "Falha ao editar ctegoria",
    deleteCategory: "Excluir categoria",
    popupMsgDeleteCategory: "Tem certeza de que deseja exlcuir a categoria?",
    itemsIn: "Itens em",
    searchForItems: "Buscar por itens...",
    createItem: "Criar item",
    editItem: "Editar item",
    deleteItem: "Excluir item",
    itemName: "nome",
    alertItemAlreadyInSystem: "Item já existente no sistema",
    alertFailedAddItem: "Falha ao adicionar item",
    alertSuccessAddItem: "Sucesso ao adicionar item",
    alertFailedEditItem: "Falha ao editar item",
    alertSuccessEditItem: "Sucesso ao editar item",
    // Teams
    teamsPerPage: "Equipes por página",
    max1000UsersPerTeam: "* Max 1000 usuários por time.",
    usersSelected: "0 usuário selecionado | 1 usuário selecionado |  {count} usuários selecionados",
    selectAll: "Selecionar tudo",
    removeAll: "Remover tudo",
    createTeam: "Criar equipe",
    editTeam: "Editar equipe",
    teamName: "nome",
    teamsIn: "Equipes em",
    searchForTeams: "Buscar por equipe",
    searchForUsers: "Buscar por usuários",
    noUsersAddedYet: "Nenhum usuário adicionado na sua equipe ainda",
    dateAdded: "Data adicionada",
    alertTeamAlreadyInSystem: "Equipe já existente no sistema",
    alertFailedEditTeam: "Falha ao editar equipe",
    deleteTeam: "Excluir equipe",
    popupMsgDeleteTeam: "Você excluirá a equipe <b> {name} </b>. Você tem certeza que deseja fazer isso?",
    noTeamsCreatedYet: "Nenhuma equipe criada ainda",
    popupMsgDeleteItem: "Tem certeza de que deseja excluir o item",
    removeUser: "Remover usuário",
    popupMsgRemoveUser: "Você removerá <b> {name} </b> desta equipe. Você tem certeza que deseja fazer isso?",
    alertErrorGettingWorkspaceList: "Erro ao obter a lista do área de trabalho.",
    alertSuccessfullyCreatedTeam: "Equipe criada com sucesso",
    alertFailedCreatedTeam: "Falha ao criar equipe",
    alertSuccessfullyUpdatedTeam: "Equipe atualizada com sucesso",
    alertSuccessfullyRemovedUserFromTeam: "Sucesso ao remover usuário da equipe",
    alertFailedRemovedUserFromTeam: "Falha ao remover usuário da equipe",
    alertSuccessfullyAddedUserToTeam: "Sucesso ao adicionar usuário à equipe",
    alertFailedAddedUserToTeam: "Falha ao adicionar usuário à equipe",
    alertWarningAddedUserToTeamAlready: "O usuário já foi adicionado à equipe.",
    //added 2022/02/03
    alertAllInvitationResentSuccess: "Todos os convites foram reenviados com sucesso.",
    alertAllInvitationResentFailed: "Falha ao reenviar todos os convites.",
    alertCheckUserInSystemFailed: "Falha ao verificar se o usuário já está no sistema.",
    alertOrganizationAdminAssignedFailed: "O administrador da organização não pode ser atribuído a um espaço de trabalho",
    //added 2022/02/07
    alertFailedDeleteItem: "Falha ao excluir o item.",
    alertSuccessfullyDeletedItem: "Item excluído com sucesso.",
    alertCategoryDeleteFailed: "Falha ao excluir a categoria.",
    alertSuccessfullyDeletedCategory: "Deletou com sucesso a categoria.",
    //added 2022/02/08: 
    upgradeToOrgAdmin: "Atualize para org. Admin.",
    yesUpgrade: "Sim, atualizar.",
    popupMsgUpgradeToOrgAdmin: "Tem certeza de que deseja atualizar <b>{name}</b>? Os administradores da organização têm acesso total para configurar as configurações de sua organização e muito mais ...",
    alertFailedUpgradeToOrg: "Falha ao atualizar para Org. Admin.",
    alertSuccessfullyUpgradeToOrg: "Atualizado com sucesso para Org. Admin.",
    // TODO added 2022/02/17
    userActive: "Usuário ativo?",
    profile: "Perfil",
    // TODO: added 2022/02/09 settings > Master Password
    masterPassword: "Senha mestra",
    enableMasterPassword: "Ativar senha mestra",
    disableMasterPassword: "Desativar senha mestra",
    resetMasterPassword: "Redefinir senha mestre",
    employeeIDAccess: "Acesso à ID do funcionário",
    employeeIDAccessMsg:"Se você ativar esse recurso, os usuários que acessam somente com o ID do funcionário terão que inserir esta senha para acessar DeepHow.",
    disableMasterPasswordMsg:"Se você desligar esse recurso, os usuários que acessam somente com o ID de funcionário não precisarão inserir uma senha para acessar a DeepHow.",
    resetMasterPasswordMsg:"Os usuários que acessam apenas com o ID do funcionário precisarão usar essa nova senha para acessar a DeepHow.",
    insertPassword: "Insira a senha",
    alertEnablePasswordFailed: "Falha ao ativar a senha mestre.",
    alertEnablePasswordSuccess: "Senha mestre habilitada com sucesso.",
    alertDisablePasswordFailed: "Falha ao desativar a senha mestre.",
    alertDisablePasswordSuccess: "Senha mestre desabilitada com sucesso.",
    savePasswordMsg: "Esse recurso não será ativado até que sua senha seja salva com sucesso.",
    resetPasswordMsg: "Digite uma nova senha mestra e salve.",
    currentPassword: "Senha atual",
    yesEnable: "Sim, ative",
    yesDisable: "Sim, desativar",
    downgradeOrgAdmin: "Rebaixar Admin da organização",
    popupMsgDowngradeOrgAdmin: "Tem certeza de que deseja rebaixar <b>{name}</b>? Ele será rebaixado para o papel que você selecionou em todos os espaços de trabalho que ele pertence.",
    selectRole: "Selecione a função para este usuário",
    yesDowngrade: "Sim, rebaixar",
    alertFailedDowngradeToOrg: "Falha ao alterar a função para {userRole}",
    alertSuccessfullyDowngradeToOrg: "alterou com sucesso a função para {userRole}",
    lastActivity: "Último Login",
    coverColor: "Cor de capa",
    defaultLanguage: "Idioma padrão",
    blue: "Azul",
    red: "Vermelho",
    green: "Verde",
    yellow: "Amarelo",
    purple: "Roxo",
    belongsToWorkspaces: "Pertence a espaços de trabalho",
    alertFailedSaveChanges: "Falha ao salvar alterações.",
    // TODO: added 04/02
    alertFailedRemoveUser: "Falha ao remover o usuário.",
    // TODO: added 0608
    privateOrnot: "privado?",
    privateWorkspaceInfo: "Este espaço de trabalho só será visto pelos membros atribuídos a ele…",
    noPermission: "Você não tem permissão para ver isso.",
    noPermissionContact: "A página que você está tentando acessar tem acesso restrito. <br> Se você acha que isso é um erro, entre em contato com seu administrador.",
    privateCannotChanged: "A configuração privada não pode ser alterada após a criação do espaço de trabalho.",
    // Shared Account
    sharedAccount: "Conta compartilhada",
    addManually: "Adicionar manualmente",
    addASharedAccount:"Adicione uma conta compartilhada",
    addASharedAccountMsg: "Contas compartilhadas não podem ser usadas para assistir a fluxos de trabalho individualmente, elas são usadas apenas para usuários de ID dos funcionários para criar contas na DeepHow e também acompanharem cada usuário em dispositivos compartilhados ...",
    accountName: "Nome da conta",
    alertFailedAddShared: "Échec de l'ajout de compte partagé",
    alertSuccessfullyCreatedShared: "Compte partagé créé avec succès",
    alertSuccessfullyAddExistingShared: "Ajout de compte partagé existant avec succès",
    accountId: "identifiant de compte",
    addSharedAccount:"Adicionar conta compartilhada",
    sharedAccountId:"ID da conta compartilhada",
    unselectedTeam: "Equipe Unslect",
    // TODO: added 2022/06/22
    workflowReview: "Revisão do fluxo de trabalho",
    workflowReviewDescription: "Se esta opção estiver ligada, os administradores do espaço de trabalho poderão escolher se seus espaços de trabalho exigem revisão do fluxo de trabalho ou não",
    preventReviewWorkflowsMessage: "Tem certeza de que deseja impedir que os usuários revise os fluxos de trabalho?",
    allowReviewWorkflowsMessage: "Tem certeza de que deseja permitir que os usuários revisem os fluxos de trabalho?",
    // TODO: added 2022/06/29
    addAsReviewer: "Adicione como revisor",
    removeAsReviewer: "Remova como revisor",
    addAsReviewerSuccessMessage: "Adicione {user} como revisor com sucesso.",
    addAsReviewerFailedMessage: "Falha ao adicionar {user} como revisor.",
    removeAsReviewerSuccessMessage: "Remova {user} como revisor com sucesso.",
    removeAsReviewerFailedMessage: "Falha ao remover {user} como revisor.",
    // Shared Account Admin settings 07/27
    allowsSharedAccountMsg: "Permita que a organização e os administradores do espaço de trabalho criem contas compartilhadas. Os usuários poderão se auto-gravar usando suas contas de identificação de funcionários em um dispositivo compartilhado.",
    disableSharedAccountMsg: "Tem certeza de que deseja desativar a conta compartilhada? <br> As alterações serão feitas imediatamente.",
    enableSharedAccountMsg: "Tem certeza de que deseja ativar a conta compartilhada? <br> As alterações serão feitas imediatamente.",
    alertEnableSharedAccountFailed: "Falhou em ativar a conta compartilhada.",
    alertEnableSharedAccountSuccess: "Conta compartilhada ativada com sucesso.",
    alertDisableSharedAccountFailed: "Falhou em desativar a conta compartilhada.",
    alertDisableSharedAccountSuccess: "Conta compartilhada com sucesso desativada.",
    // QR Code & Watermark
    qrCodeAndWatermark: "Código QR e marca d'água",
    insertYourBrand: "Insira sua marca",
    uploadYourLogo: "Faça upload do seu logotipo",
    uploadingYourLogo: "Carregando seu logotipo",
    logoSuccessfullyUploaded: "Logotipo carregado com sucesso",
    errorInUpload: "Erro no seu upload",
    errorInUploadMsg: "Tipo de arquivo não suportado. Selecione o seguinte tipo de arquivo: .png",
    uploadLogoMsg: "Arraste e solte um <b>.png</b> com um arquivo máximo <b>3 MB</b> ou <span>navegue</span> do computador.",
    uploadLogoReplaceMsg: "Arraste ou <span>Navegue</span> para substituir por uma imagem do computador ou <span id='DUploadImage_removeLogo'>remover</span> a imagem atual.",
    simulation: "Simulação",
    simulationMsg: "Veja abaixo como seu logotipo será apresentado na marca d'água e nos códigos QR.",
    workflowTitle: "Título do fluxo de trabalho",
    logoAlreadyUploaded: "Logo já carregado",
    alertLogoSuccessfullyRemoved: "Logotipo removido com sucesso.",
    alertLogoFailedRemoved: "Falha ao remover o logotipo.",
    alertUserInOtherOrg: "Este usuário já existe em outra organização.",
    // added 10/13 delete workspace
    deleteWorkspace: "Excluir espaço de trabalho",
    popupMsgDeleteWorkspace: "Tem certeza de que deseja excluir este espaço de trabalho ({name})?",
    alertDeleteGroupSuccess: "Exclua com sucesso este espaço de trabalho.",
    alertDeleteGroupFailedWorkflows: "Existem fluxos de trabalho atribuídos a este espaço de trabalho, então você não pode excluí -lo.",
    alertDeleteGroupFailedSkills: "Existem habilidades atribuídas a este espaço de trabalho, então você não pode excluí -lo.",
    alertDeleteGroupFailedTeams: "Existem equipes designadas para este espaço de trabalho, então você não pode excluí -lo.",
    // added 11/3 delete team
    alertDeleteTeamSuccess: "Excluiu com sucesso esta equipe.",
    alertDeleteTeamFailedSkills: "Esta equipe não tem permissão para remover, devido às habilidades existentes atribuídas.",
    alertDeleteTeamFailed: "Falhou em excluir esta equipe.",
    // Change Profile Name
    changeProfileName: "Alterar o nome do perfil",
    allowsChangeProfileNameMsg: "Os usuários podem mudar seus nomes em seus perfis.",
    disableChangeProfileNameMsg: "Tem certeza de que deseja desativar a possibilidade de mudar o nome? <br> As alterações serão feitas imediatamente.",
    enableChangeProfileNameMsg: "Tem certeza de que deseja ativar a possibilidade de mudar o nome? <br> As alterações serão feitas imediatamente.",
    alertEnableChangeProfileNameSuccess: "Sucesso ao abilitar o possibilidade de trocar o nome de usuário.",
    alertEnableChangeProfileNameFailed: "Falha ao abilitar o possibilidade de trocar o nome de usuário.",
    alertDisableChangeProfileNameSuccess: "Sucesso ao desabilitar o possibilidade de trocar o nome de usuário.",
    alertDisableChangeProfileNameFailed: "Falha ao desabilitar o possibilidade de trocar o nome de usuário.",
    // Workflow Comments
    workflowComments: "Comentários do fluxo de trabalho",
    allowsWorkflowCommentsMsg: "Os usuários podem deixar comentários para os fluxos de trabalho publicados.",
    disableWorkflowCommentsMsg: "Tem certeza de que deseja desativar os comentários do fluxo de trabalho? <br> As alterações serão feitas imediatamente.",
    enableWorkflowCommentsMsg: "Tem certeza de que deseja ativar comentários do fluxo de trabalho? <br> As alterações serão feitas imediatamente.",
    alertEnableWorkflowCommentsSuccess: "Comentários de fluxo de trabalho ativados com sucesso.",
    alertEnableWorkflowCommentsFailed: "Falha ao ativar comentários do fluxo de trabalho.",
    alertDisableWorkflowCommentsSuccess: "Sucesso ao desativar comentários do fluxo de trabalho.",
    alertDisableWorkflowCommentsFailed: "Falha ao desativar comentários do fluxo de trabalho.",
    // Workspace Groups
    workspaceGroupsNum: "Grupo de espaço de trabalho | grupo de espaço de trabalho | grupos de espaço de trabalho",
    searchWorkspaceGroups: "Pesquisar por grupos de trabalho...",
    noWorkspaceGroupsYet: "Nenhum grupo de espaço de trabalho ainda criado.",
    createWorkspaceGroup: "Crie grupo de espaço de trabalho",
    editWorkspaceGroup: "Editar grupo de espaço de trabalho",
    workspaceGroupName: "Nome do grupo de espaço de trabalho",
    editWorkspace: "Editar espaço de trabalho",
    userNum: "Usuário | Usuário | Usuários",
    teamNum: "Equipes | Equipes | Equipes",
    defaultLanguage: "Idioma padrão",
    deleteWorkspaceGroup: "Excluir grupo de espaço de trabalho",
    alertCreateWorkspaceGroupSuccess: "Criou com sucesso o grupo de espaço de trabalho.",
    alertWorkspaceGroupNameAlreadyExists: "Este nome de Grupo de Trabalho já existe no sistema.",
    alertEditWorkspaceGroupSuccess: "Grupo de espaço de trabalho editado com sucesso.",
    alertEditWorkspaceGroupFailed: "Falhou em editar o grupo de espaço de trabalho.",
    alertDeleteWorkspaceGroupSuccess: "Excluiu com sucesso este grupo de espaço de trabalho.",
    alertDeleteWorkspaceGroupFailed: "Falha ao excluir este grupo de espaço de trabalho.",
    alertWorkspaceAssignedDeleteGroupFailed: "Existem espaços de trabalho atribuídos a este grupo de espaço de trabalho, logo você não podea excluí -lo.",
    deleteWorkspaceGroupMessage: "Tem certeza de que deseja excluir este grupo de espaço de trabalho (<b>{name}</b>)?",
    alertWorkspaceNameAlreadyExists: "O nome do Espaço de Trabalho já existe.",
  },
  builder: {
    publish: "Publicar",
    reviewTranscription: "Transcrição de revisão",
    viewer: "Visualizadores",
    links: "Links",
    trim: "Cortar",
    translation: "Tradução",
    share: "Compartilhar",
    addDiagramMsg: "Arraste ou clique para adicionar",
    onlyImageAccepted: "Somente arquivos de imagens são aceitos",
    unsupportedImageFormats: "Formatos de imagem não suportados: svg, tiff, xbm",
    addDiagram: "Adicionar diagrama",
    start: "Começar ",
    end: "Fim",
    resetToOriginal: "Redefinir para o original",
    trimStartMsg: "Escolha onde que seu vídeo começa e termina",
    trimProcessingMsg: "O corte não está disponível enquanto o processamento do fluxo de trabalho está em andamento.  <br> Volte mais tarde",
    cannotTrimLessThan3s: "Você não pode cortar vídeos com menos de 3 segundos",
    trimVideo: "Cortar vídeo",
    trimStep: " Passo de corte",
    editWorkflow: "Editar fluxo de trabalho",
    popupBtnEdit: "Sim, edite",
    popupBtnPublish: "Sim, publique",
    popupBtnUnpublish: "Sim, cancelar a publicação",
    popupMsgTrimConfirmation: "Tem certeza que deseja cortar?",
    popupMsgTrimWithSaveConfirmation: "Você tem alterações não salvas em seu fluxo de trabalho. Salvaremos as alterações para você.",
    popupBtnTrim: "Sim, cortar",
    popupMsgTranscoding: "A transcodificação está em andamento.",
    popupMsgTrimBackLater: "Você pode retomar a edição do fluxo de trabalho após a conclusão da transcodificação.",
    popupMsgTrimSuccess: "Seu video foi cortado com sucesso!",
    done: "Feito",
    publishWorkflow: "Publicar fluxo de trabalho",
    popupMsgPublishWorkflow: "Você publicará seu fluxo de trabalho e todos os usuários poderão vê-lo. Tem certeza que deseja fazer isso?",
    popupMsgEditWorkflow: "Se você entrar no modo de edição, seu fluxo de trabalho será automaticamente cancelado. Tem certeza que deseja continuar?",
    popupMsgUnpublishWorkflow: "Você vai cancelar a publicação de seu fluxo de trabalho, tem certeza de que deseja fazer isso?",
    alertSaveFailed: "Falha ao salvar o fluxo de trabalho",
    alertTrimResetSuccess: "A etapa foi redefinida com sucesso",
    alertTrimResetFailed: "Falha ao redefinar a etapa.",
    alertTrimCanNotReset: "Esta estapa não pode ser redefinida.",
    resetStep: "Etapa de reinicialização",
    popupMsgResetStep: "Tem certeza que deseja redefinir? Os diagramas adicionados a esta etapa poderão ser perdidos.",
    popupBtnReset: "Sim, reinicie",
    hintSearchBuilder: "Pesquise fluxos de trabalhos e palavras-chave...",
    stepsSelected: "Selecionado",
    clear: "Limpar",
    insert: "Inserir",
    reset: "Redefinir",
    resetWorkflow: "Redefinir fluxo de trabalho",
    resetWorkflowAlert: "Você perderá todo o trabalho realizado neste fluxo de Wor e começará do início novamente, tem certeza de que deseja continuar?",
    resetWorkflowKeepCopy: "Mantenha uma cópia deste fluxo de trabalho",
    // Transcript / Translation
    closeTranscript: "Fechar transcrição",
    popupMsgCloseTranscript: "Você tem alterações não salvas em sua transcrição. Se você continuar, você <b> perderá todas as suas alterações </b>. Tem certeza?",
    stepNoTranscript: "Etapa sem transcrição",
    originalTranscript: "Transcrição original",
    addLanguageTooltip: "Clique aqui para adicionar novos idiomas ao seu vídeo",
    closeTranslation: "Fechar tradução",
    popupMsgCloseTranslation: "Você tem alterações não salvas em sua tradução. Se você continuar, você <b> perderá todas as suas alterações </b>. Tem certeza?",
    switchTranslation: "Mudar tradução",
    popupBtnNoSwitch: "Não, basta trocar",
    popupBtnYesSwitch: "Sim, salve e mude",
    createAudio: "Criar áudio",
    addNewLanguageTooltip: "Clique aqui para adicionar um novo idioma ao seu vídeo",
    popupMsgCreateAudio: "Tem certeza de que deseja criar as traduções de áudio para o seu fluxo de trabalho? Recomendamos vivamente que o faça apenas uma vez, visto que a Stephanie irá processar toda sua transcrição.",
    popupBtnYesCreate: "Sim, criar",
    alertAudioBeingCreated: "Seu áudio de tradução está sendo criado.",
    alertTranscriptionSaved: "Transcrição salva com sucesso.",
    alertTranscriptionSaveFailed: "Falha ao salvar a transcrição.",
    alertTranslationsSaved: "Traduções salvas com sucesso.",
    alertTranslationsSaveFailed: "Falha ao salvar traduções.",
    alertAudioCreated: "áudio criado com sucesso.",
    alertAudioFailed: "Falha ao criar seu áudio de tradução.",
    alertNoAudioGenerated: "Nenhum áudio foi gerado porque não há atualização na tradução.",
    alertErrorCreateTranscription: "Erro ao criar a transcrição.",
    //Share workflow
    shareWorkflow: "Compartilhar fluxo de trabalho",
    fullWorkflow: "Fluxo de trabalho completo",
    specificSteps: "Etapas específicas",
    chooseShareType: "Escolha o tipo de compartilhamento",
    qrCode: "Código QR",
    link: "Link",
    embedLink: "Link de incorporação",
    privateWorkflow: "Fluxo de trabalho privado",
    publicWorkflow: "Fluxo de trabalho público",
    close: "Fechar",
    saveQRCode: "Salvar o código QR",
    download: "Download",
    copyLink: "Cópia de Link",
    copied: "Copiado",
    downloading: "Baixando",
    videoPrivacy: "Privacidade de vídeo",
    popupMsgVideoPrivacy: "Quando você torna seu fluxo de trabalho público, qualquer pessoa pode ter acesso, mesmo sem uma conta DeepHow. Tem certeza?",
    includeSubtitles: "Incluir legendas",
    noSubtitles: "Sem legendas",
    selectLanguage: "Selecione o idioma",
    workflowReady: "Fluxo de trabalho pronto!",
    prepareToDownload: "Prepare-se para fazer o download",
    processingDownloadVideoMsg: "Não feche esta página! <br/> Em alguns minutos seu arquivo estará pronto para download.",
    processingWorkflow: "Processando fluxo de trabalho...",
    openAnotherTabMsg: "Enquanto você espera, poderá navegar no DeepHow em outra guia",
    openNewTab: "Abra uma nova aba",
    alertErrorPreparingDownload: "Ocorreu um erro ao preparar o seu download. Tente novamente ou entre em contato com a DeepHow para obter assistência.",
    hide: "Esconder",
    addNewLink: "Adicionar novo link",
    clickToAddLinks: "Clique para adicionar links a essa etapa",
    addALink: "Adicionar um link",
    editLink: "Editar link",
    addLinkAddress: "Digite o endereço abaixo para adicionar um link a esta etapa.",
    addLinkLable: "Você quer adicionar um rótulo?",
    clickOpenOfficialDocument: "i.e.: Clique para abrir o documento oficial",
    //settings /categories
    settings: "Configurações",
    categories: "Categorias",
    noCategories: "Você ainda não criou nenhuma categoria",
    selectCategoriesMessage: "Selecione ao menos uma categoria para que usuários possam filtrar o conteúdo facilmente",
    closeSettings: "Fechar configurações",
    popupMsgCloseSettings: "Você tem alterações não salvas. Se continuar, você <b> perderá todas as alterações </b>. Tem certeza?",
    workflowCategories: "Categorias de fluxo de trabalho",
    popupBtnAssignCategoriesAndPublish: "Atribuir categorias e publicar",
    popupBtnSkipAndPublish: "Pule esta etapa e publique mesmo assim",
    popupMsgworkflowCategories: "Antes de publicar, por que você não seleciona algumas categorias para seu fluxo de trabalho, para que os usuários possam achar mais fácil?",
    alertCategoriesSaved: "Categorias salvas com sucesso.",
    alertCategoriesSaveFailed: "Falha ao salvar categorias.",
    searchForCategoryName: "Buscar por {name}...",
    loadingCategories: "Carregando categorias...",
    alertFailedDownloadVideo: "Falha ao baixar o vídeo",
    // Workflow Cover
    workflowCover: "Capa de fluxo de trabalho",
    selectFromTheVideo: "Selecione no vídeo",
    browseForAFile: "Navegue por um arquivo",
    dropFileMessage: "Solte seu arquivo ou clique aqui para navegar.",
    selectedImage: "Imagem selecionada",
    noImageSelected: "Nenhuma imagem selecionada",
    alertSuccessfullyUpdatedCover: "Cobertura de fluxo de trabalho atualizada com sucesso.",
    alertFailedUpdatedCover: "Falha ao atualizar a tampa do fluxo de trabalho",
    selectAnotherImage: "Selecione outra imagem",
    alertErrorStepLostTranscription: "Você atualmente tem passos que não foram traduzidos. Por favor, traduzi-los antes de de baixar seu fluxo de trabalho.",
    annotation: "Anotação",
    allNotes: "Todas as notas",
    noNotesAddedYet: "Nenhuma nota ainda adicionada.",
    popupMsgEnterTrim: "Sua etapa tem anotações. Se você deseja aparar o vídeo, essas anotações desaparecerão.",
    popupMsgEnterErase: "Sua etapa tem anotações. Se você deseja apagar o vídeo, essas anotações desaparecerão.",
    popupMsgEnterAnnotation: "Certifique-se de que o aparar ou apagar o vídeo antes de fazer anotações.",
    workingOn: "Trabalhando em",
    eraseStep: "Apagar etapa",
    popupMsgEraseSuccess: "Sua etapa foi apagada com sucesso!",
    popupBtnYesErase: "Sim, apagar",
    popupMsgEraseConfirmation: "Tem certeza de que deseja apagar?",
    eraseProcessingMsg: "Não é permitido apagar enquanto o processamento do fluxo de trabalho está em andamento. <br> Por favor, volte mais tarde",
    translateOnlyTitlesBtn: "Traduzir títulos e nomes das etapas",
    untitled: "Sem título",
    hintNoEmptyTitle: "O título não pode estar vazio",
    popupMsgSwitchTranslation: "Você tem alterações não salvas na sua <b>{lang}</b> tradução. Você gostaria de salvá -los antes de mudar?",
    alertNoEmptyContent: "Títulos ou frases não podem estar vazios. Por favor, insira o conteúdo.",
    PDF: "Pdf",
    PDFSizeLimit: "O tamanho do arquivo PDF é limitado a 50 MB",
    addPDF: "Adicione PDF",
    onlyPDFAccepted: "Apenas arquivos PDF aceitos",
    preview: "Visualização",
    // Send to reviewer
    sendToReviewer: "Enviar para o revisor",
    alertTitleSelectReviewers: "Selecione o (s) usuário (s) para revisar seu fluxo de trabalho",
    alertSendToReviewSuccess: "Seu fluxo de trabalho foi enviado com sucesso para revisar.",
    alertSendToReviewFailed: "Falha ao enviar para revisão",
    subtitlesSize: "Tamanho das legendas",
    small: "Pequeno",
    medium:"Médio",
    big: "Grande",
    yourVideoReadyToDownload: "Seu vídeo pronto para baixar.",
    yourVideoWasSuccessfullyDownloaded: "Seu fluxo de trabalho foi baixado com sucesso.",
    notAvailable: "não disponível",
    downloadFileFormat: "formato",
    selectDownloadWorkflowQuality: "Selecione a qualidade do vídeo",
    downloadSizeLimitedMsg: "Baixar Scorm em {resolution} suporta até {duration} minutos de trabalho.",
    workflowTotalDurationMsg: "Duração total do fluxo de trabalho: {minute} min {second} seg.",
    // Export Compliance Wire
    export: "Exportar",
    code: "Código",
    organization: "Organização",
    approvalDate: "Data de aprovação",
    processing: "Em processamento",
    workflowShouldBePublic: "O fluxo de trabalho deve ser público.",
    alertExportComplianceWireSuccess: "Documento de controle de exportação com sucesso. Código: {code}",
    alertExportComplianceWireExists: "O documento de controle já existe. Código: {code}",
    alertExportComplianceWireFailed: "Algo deu errado ao exportar o documento de controle.",
  },
  editor: {
    closeWorkflow: "Fechar fluxo de trabalho",
    popupMsgCloseWorkflow: "Você tem alterações não salvas em seu fluxo de trabalho. Se continuar, <b> perderá todas as alterações </b>. Tem certeza?",
    popupBtnClose: "Sim, fechar",
    processWorkflow: "Processando fluxo de trabalho",
    popupMsgProcessWorkflow: "Excelente! Parece que você terminou seu comentário e deseja que Stephanie o processe. <br> <br> Você <b> não poderá </b> voltar para esta tela de revisão. Você está pronto?",
    popupBtnProcess: "Sim, processar",
    alertIsSaving: "O salvamento está em andamento.",
    alertNoEmptySentence: "As frases não podem estar vazias. Insira ou exclua as frases e salve novamente.",
    alertIsSaved: "Suas alterações foram salvas.",
    alertWasUnpublished: "Seu fluxo de trabalho foi cancelado com sucesso.",
    alertWasPublished: "Seu fluxo de trabalho foi publicado com sucesso.",
    alertNoMoreSteps: "Você não pode adicionar mais etapas aqui.",
    alertNoEmptyTitle: "O título não pode estar vazio. Insira o título e salve novamente.",
    scrollToVoice: "Role para voz",
    noAudioMsg: "Não foi possível encontrar nenhum áudio para transcrever em seu fluxo de trabalho...",
    youWillDeleteStep: "Você excluirá a etapa",
    areYouSure: "Tem certeza?",
    areYouSureDelete: "Tem certeza que <br> deseja excluir?",
    replace: "Substituir",
    keyword: "Palavra-chave",
    find: "achar",
    replaceWith: "Substituir com",
    replaceAll: "Substituir tudo",
    replaceAllMsg: "Você vai substituir todas essas palavras.",
    of: "de",
    msgToSteps: "Analisando seu fluxo de trabalho... <br> aguarde...",
    msgToEditor1: "Agora vamos revisar o fluxo de trabalho antes de publicar",
    msgToEditor2: "Vou guiá-lo em cada etapa, sem problemas ",
    msgToBuilder1: "Quase lá, me dê um segundo ",
    msgToBuilder2: "Estou criando etapas para o seu fluxo de trabalho",
    newStep: "Novo passo",
    hintNoEmptySentence: "As frases não podem estar vazias",
    hintNoEmptyTitle: "(o título não pode estar vazio)",
    alertNoTranscription: "Nenhuma transcrição para o fluxo de trabalho.",
    alertEmptyingTranscription: "AVISO! Você está esvaziando a transcrição. Você não pode fazer isso",
    alertStepCorrupted: "A etapa {count} está corrompida. Remova-o antes de processar",
    // Editor 2.0
    trim: "Cortar",
    erase: "Apagar",
    voiceOver: "Gravar Voz",
    videoEditing: "Edição de vídeo",
    segmentation: "Segmentação",
    popupMsgRestToOriginal: "Tem certeza de que deseja redefinir para o vídeo original? Todas as alterações adicionadas serão perdidas.",
    trimHintMessage: "Use as setas para selecionar a área que você deseja cortar",
    eraseHintMessage: "Use as setas para selecionar a área que você deseja apagar",
    voiceOverHintMessage: "Selecione a área que deseja gravar a voz...",
    voiceOverStartHint: "Pressione AQUI para iniciar sua gravação...",
    voiceOverStart: "Gravar",
    voiceOverSave: "Salvar registro",
    voiceOverCancel: "Descartar",
    voiceOverCancelPopupMsg: "Tem certeza de que deseja excluir sua gravação?",
    voiceOverPause: "Pausa",
    voiceOverResume: "Retomar",
    unableToGetAudioInput: "Nenhum dispositivo de entrada de áudio detectado",
    restart: "reiniciar",
    editVideo: "Editar vídeo",
    popupRequestTranscription: "Não foi possível encontrar a transcrição para o fluxo de trabalho. Vamos criar uma nova.",
    popupMsgEditVideoWarning: "Todas as alterações feitas na transcrição serão perdidas se você editar seu vídeo, você tem certeza de que você quer editar?",
    popupMsgReTranscriptionWarning: "NOTA: Se você fez alguma ação de edição de vídeo (como CORTAR/APAGAR/GRAVAR VOZ), não poderá usar a função de re-transcrição.",
    popupMsgReTranscriptionWarningMultiEditing: "Nota: se você realizou alguma ação de edição de vídeo (como cortar/dublar), não será possível usar a função de Re-transcrição.",
    rotate: "Girar",
    rotateLeft: "Vire à esquerda",
    rotateRight: "Vire à direita",
    goBack: "voltar",
    goBackToEditorHome: "Volte para o Editor",
    zoom: "zoom",
    // transcription new features
    transcriptionCopySuccess: "Transcrição copiada para sua área de transferência",
    keyTerms: "Termos chave",
    AILabs: "AI Labs",
    copyTranscription: "Cópia de transcrição",
    changeLanguage: "Mudar idioma",
    copy: "cópia de",
    changeLanguageTitle: "Selecione o novo idioma para sua transcrição",
    changeLanguageSubTitle: "Stephanie precisará re-processar toda a sua transcrição.",
    reProcessingYourVideo: "Stephanie está relançando seu vídeo ...",
    goBackToEditor: "Você pode esperar aqui ou voltar ao editor para continuar trabalhando!",
    // keyterms: added on 08/09/2022
    addTerm: "Adicionar termo",
    removeTerm: "Remova o termo",
    termsFound: "<span>0</span> termo encontrado | <span>1</span> termo encontrado | <span>{count}</span> termos encontrados",
    loadingTerms: "Termos de carregamento",
    termsInfo: "Os termos podem ser muito úteis ao pesquisar conteúdos específicos nos fluxos de trabalho.",
    // Editor 3.0
    cut: "Cortar",
    tabsWarningMessages: "Aviso! Se você estiver saindo desta página sem salvar, todas as alterações que você fez serão perdidas."
  },
  player: {
    loadingVideo: "Carregando vídeo...",
    loadingLanguage: "Carregando idioma...",
    previousStep: "Passo anterior",
    nextStep: "Próxima etapa",
    audio: "Áudio",
    subtitles: "Legendas",
    autoPlay: "Reprodução",
    off: "Off",
    on: "On",
    quality: "Qualidade",
    speed: "Velocidade",
    normal: "Normal",
    rotatePhone: "<span class='primary--text'>Gire </span>seu telefone para uma<br>melhor experiência",
    alertTranscodingFailed: "Ops, parece que a transcodificação do vídeo falhou. Entre em contato com a DeepHow para obter mais assistência.",
    alertTranscodingStillProgress: "Ops, parece que a transcodificação do vídeo ainda está em andamento. Por favor, volte mais tarde.",
    settings: "Definições",
    openInBrowser: "Abra o navegador",
    goToHome: "Ir à Página Principal",
    thisWorkflowWasUnpublished: "Este fluxo de trabalho foi despublicado",
    thisWorkflowWasDeleted: " Este fluxo de trabalho foi excluído",
    talkToSupervisor: "Se você acha que este fluxo de trabalho deve ser publicado, converse com seu supervisor",
    selectWorkspace: "Selecione o Área de Trabalho",
    // player2.0
    home: "Página inicial",
    myWorkflows: "Meus fluxos de trabalho",
    recommendedForYou: "Recomendado para você",
    favorites: "Favoritos",
    welcomeToDeepHowNavigator: "Bem-vindo ao DeepHow Navegador",
    numNewSkillsTrainings: "<span> 0 treinamentos de habilidades </span> <br> atribuídos a você. | <span> 1 treinamento de habilidade </span> <br> atribuído a você. | <span> {count} treinamentos de habilidades </span> atribuídos a você.",
    clickForTips: "Clique aqui para algumas dicas inteligentes.",
    learnHow: "Aprenda como",
    goToSkills: "Vá para habilidades",
    at: "no ",
    youAreIn: "Você está em",
    loadingRecommendations: "Carregando recomendações ...",
    loadingFavoriteWorkflows: "Carregando fluxos de trabalho favoritos ...",
    noFavoriteWorkflows: "Você não tem itens em seus favoritos ainda.",
    canFindSavedItemsHere: "Você pode encontrar todos os seus itens salvos aqui.",
    removeWorkflowFromFavorites: "Tem certeza de que deseja remover este fluxo de trabalho de seus Favoritos?",
    yesGoAhead: "Sim, prosseguir",
    thisWorkflowIsNotAvailable: "Este fluxo de trabalho não está disponível",
    dismiss: "Liberar",
    navigator: "Navegador",
    howToUseNavigator: "Aprenda a usar o novo <b>Navegador DeepHow</b>",
    checkTheNewFeatures: "Verifique os novos recursos.",
    unknownUser: "Usuário Desconhecido",
    okCloseBtn: "Ok, fechar.",
    emptyRecommendWorkflowTitle: "Ei! Onde está o conteúdo?",
    emptyRecommendWorkflowDescription: "Não se preocupe! Assim que os fluxos de trabalho forem criados, eu recomendo alguns para você. Volte logo!",
    thisWorkflowDoesNotExist: "Este fluxo de trabalho não existe",
    // navigator
    resume: "Resumo",
    myWorkspaces: "Meus espaços de trabalho",
    otherWorkspaces: "Outros espaços de trabalho.",
    loading: "Carregando...",
    loadingfavoriteWorkspaces: "Carregando espaços de trabalho favoritos ...",
    removeWorkspaceFromFavorites: "Tem certeza de que deseja remover este espaço de trabalho dos seus favoritos? ",
    YouAreViewing: "Você está vendo",
    allWorkspaces: "Todos os espaços de trabalho.",
    removeFromFavorites: "Remover dos favoritos",
    favoriteThisVideo: "Favoritar este vídeo",
    shareThisVideo: "Compartilhar este vídeo",
    viewAll: "Ver tudo",
    openFile: "Abrir arquivo", 
    suggestedKeyterms: "Termos chave sugeridos",
    // workflow player added 10/10/2022
    posted: "Publicado",
    views: "0 visualização | 1 visualização | {count} visualizações",
    saveFavorite: "Salvar",
    react: "Reagir",
    numColleaguesReact: "0 colegas reagiram | 1 colega reagiu | {count} colegas reagiram",
    youAndNumColleaguesReact: "<span>Você</span> e 0 colegas reagiram | <span>Você</span> e 1 colega reagiram | <span>Você</span> e {count} colegas reagiram",
    youReacted: "<span>Você</span> reagiram",
    userReaction: "Reação do usuário",
    fullscreen: "Tela cheia",
    exitFullscreen: "Saia de tela cheia",
    // 1/23/2023 Comments
    comments: "Comentários",
    deleteComment: "Excluir comentário",
    deleteCommentMsg: "Tem certeza de que deseja excluir este comentário? <br> Isso não pode ser desfeito.",
    edit: "Editar",
    updating: "Atualizando ...",
    changesSaved: "Alterações salvas.",
    noComments: "Sem comentários ainda ...",
    makeFirstComment: "Faça o primeiro comentário abaixo",
    comment: "Comente",
    writeAComment: "Escreva um comentário...",
    edited: "editado",
    emojiRecent: "Usado frequentemente",
    emojiSmileys: "Smileys e emoticon",
    emojiPeople: "Pessoas e corpo",
    emojiNature: "Animais e natureza",
    emojiFoods: "Comida e bebida",
    emojiActivity: "Atividade",
    emojiPlaces: "Viagem e lugares",
    emojiObjects: "Objetos",
    emojiSymbols: "Símbolos",
    emojiFlags: "Bandeiras",
    emojiCustom: "Personalizado",
    back: "voltar",
    feedback: "Opinião",
    leaveAFeedback: "Deixe um feedback",
    leaveAFeedbackMsg: "Conte -nos sobre quaisquer problemas, deixe uma sugestão ou mesmo uma crítica construtiva.",
    typeYourMessageHere: "Digite sua mensagem aqui",
    submit: "Enviar",
    thankYou: "Obrigado.",
    feedbackSuccessMsg: "O seu comentário foi enviado com sucesso.",
    alertFeedbackFailedMsg: "Falha ao enviar seu feedback. Por favor, tente novamente.",
    diagrams: "Diagramas",
    pdfFiles: "Arquivos PDF",
    theaterMode: "Modo de teatro",
    exitTheaterMode: "Saia do modo de teatro",
  },
  profile: {
    // added 12/09/2021
    addPositionLocation: "Adicionar posição e localização",
    noWorkflowsCreated: "Ainda não há fluxos de trabalho criados.",
    comeBackLater: "Volte mais tarde.",
    createdWorkflows: "Criou fluxos de trabalho",
    profileOwnerNoContentMsg: "<span>ei, {name}! Aqui está a sua nova página de perfil.</span> <br> No futuro, você poderá compartilhar conhecimento com todos. Espere!",
    profileColleguesNoContentMsg: "<span>Esta é a página de perfil de {name}.</span><br>Ele ainda não pode publicar conteúdo, mas em um futuro próximo poderá!",
    editProfile: "Editar Perfil",
    personalInformation: "Informação pessoal",
    personalInformationMsg: "As pessoas que visitam seu perfil verão as seguintes informações.",
    photo: "foto",
    position: "Posição",
    locationRegion: "Localização/ região",
    whatYouDo: "Deixe as pessoas saberem o que você faz nesta organização.",
    whereYouBased: "Informe as pessoas onde você está baseado.",
    accountManagement: "Gerenciamento de contas",
    accountManagementMsg: "Esta informação é privada e não aparece em seu perfil público.",
    identifyMyself: "Eu me identifico como",
    yourPassword: "Sua senha",
    nonDeclared: "Não declarado (padrão)",
    male: "Homem",
    female: "Mulher",
    nonBinary: "Não binário",
    viewProfile: "Ver perfil",
    alertFailedToUpdateProfile: "Falha ao atualizar seu perfil."
  },
  explorer: {
    search: "Procurar",
    in: "em&nbsp;",
    howTo: "Como...",
    didYouMean: "Você quis dizer",
    searchInTitle: "No título",
    searchInContent: "No conteúdo",
    searchInVideo: "Sugestões da Stephanie",
    title: "Título",
    searchStartMsg1: "O que você está procurando?",
    searchStartMsg2: "Use a caixa de pesquisa acima",
    sortBy: "Ordernar por",
    lastUpdated: "Última atualização",
    alphabetically: "Alfabeticamente",
    workflowsIn: "Fluxos de trabalho em",
    tagNew: "Novo",
    tagProcessing: "Em processamento",
    msgProcessing: "Stephanie está processando seu fluxo de trabalho… <br/> aguarde :)",
    tagFailed: "Falhou",
    msgFailed: "Ops, algo deu errado ao processar a fala ou o vídeo. Tentaremos novamente em breve...",
    msgVideoLimitExceeded: "O vídeo excedeu a duração suportada pela DeepHow. Ele será recarregado automaticamente em dois ou mais fluxos de trabalho.",
    msgVideoExceededMaximum: "O vídeo excede o comprimento máximo do fluxo de trabalho suportado pela Deephow. Será dividido automaticamente em dois ou mais fluxos de trabalho",
    tagTranscoding: "Transcodificação",
    msgTranscoding: "Stephanie está transcodificando seu fluxo de trabalho ... <br/> aguarde :)",
    deleteWorkflow: "Ecluir fluxo de trabalho",
    popupBtnDelete: "Sim, deletar",
    popupMsgDeleteWorkflow: "Tem certeza que deseja excluir esse fluxo de trabalho? Você perderá todas as etapas e dados relacionados a este fluxo de trabalho.",
    noUnpublishedWorkflows: "Você ainda não tem fluxos de trabalho não publicados...",
    noPublishedWorkflows: "Você ainda não publicou fluxos de trabalho...",
    startCreatingWorkflow: "Vamos começar a criar um fluxo de trabalho!",
    startPublishingWorkflow: "Vamos começar a publicar o fluxo de trabalho!",
    noSearchResult: "Nenhum resultado encontrado...",
    trySearchAgain: "Por favor, tente pesquisar novamente",
    searchingForWorkflows: "Procurando fluxos de trabalho...",
    secondsAgo: "0 segundos atrás | 1 segundo atrás | {count} segundos atrás",
    minutesAgo: "0 minutos atrás | 1 minutos atrás | {count} minutos atrás",
    hoursAgo: "0 horas atrás | 1 horas atrás | {count} horas atrás",
    daysAgo: "0 dia atrás | 1 dia atrás | {count} dias atrás",
    monthsAgo: "0 mês atrás | 1 mês atrás | {count} meses atrás",
    yearsAgo: "0 ano atrás | 1 ano atrás | {count} anos atrás",
    sAgo: "{count} s",
    minAgo: "{count} min",
    hAgo: "{count} h",
    dAgo: "{count} d",
    mAgo: "{count} min",
    yAgo: "{count} y",
    renameWorkflow: "Renomear fluxo de trabalho",
    moveWorkflowTo: "Mover o fluxo de trabalho para",
    duplicateWorkflow: "Fluxo de trabalho duplicado",
    unpublishWorkflow: "Cancelar publicação do fluxo de trabalho",
    shareKnowhow: "Compartilhe know-how",
    or: "Ou",
    clickHere: "Clique aqui",
    andCopyMsg: "e copie o link da URL para enviar por e-mail",
    successfulSharing: "Compartilhamento bem-sucedido.",
    send: "Mandar",
    typeHereAMessage: "Digite aqui uma mensagem",
    wellDone: "Bem feito",
    numMore: "{count} mais",
    moveWorkflow: "Mover fluxo de trabalho",
    currentWorkspace: "Área de trabalho atual",
    whereToMsg: "Para onde você deseja mover <br> este fluxo de trabalho?",
    move: "Mover",
    alertSuccessfullyMoved: "O fluxo de trabalho foi movido com sucesso.",
    alertFailedMoved: "Falha ao mover o fluxo de trabalho.",
    noNotificationsMsg: "Você não tem nenhuma notificação ",
    betaSearch: "Esteja ciente de que a sugestão de Stephanie ainda está na versão beta ...",
    relevantSteps: "Etapas relevantes:",
    relevance: "relevância",
    resultsFor: "{num} resultados para <b>&ldquo;{input}&rdquo;</b>",
    resultFor: "{num} resultado para <b>&ldquo;{input}&rdquo;</b>",
    byPublisher: "Por Editor",
    alertFailedShareWorkflow: "Falhou em compartilhar o fluxo de trabalho.",
  },
  new: {
    newWorkflow: "Novo fluxo de trabalho",
    uploadVideo: "Envie vídeo",
    recordScreen: "Tela de registro",
    replaceVideoSubtitle: "Arraste ou clique para subsituir o vídeo.",
    recordScreenSubtitle: "Stephanie está pronta para começar a gravar sua tela.",
    workflowName: "Nome do fluxo de trabalho",
    workflowLanguage: "Linguagem do fluxo de trabalho",
    addToWorkspace: "Adicionar ao área de trabalho",
    upload: "Envie",
    startRecording: "Comecar a gravar",
    cancel: "Cancelar",
    wrongUploadFile: "Tipo de arquivo não compatível. Selecione o seguinte tipo de arquivo:",
    limitFileDuration: "A duração do arquivo suporta até {duration} min. Use o aplicativo DeepHow Uploader para Windows",
    limitFileDurationGen2: "A duração do arquivo suporta até {duration} min.",
    uploadingVideo: "Enviando vídeo",
    uploadingVideoSubtitle: "Aguarde enquanto Stephanie carrega o seu vídeo",
    renderingVideo: "Renderizando/salvando vídeo",
    renderingVideoSubtitle: "Aguarde enquanto Stephanie renderiza o seu vídeo",
    almostDone: "Estamos quase terminando!",
    uploadingStep1: "Etapa 1 de 2 - Analisando o fluxo de trabalho...",
    uploadingStep2: "Enviando o vídeo...",
    successSubtitle: "Seu fluxo de trabalho foi carregado com sucesso",
    goToEditor: "Vá para o editor",
    goToContentManger: "Vá para o gerenciador de conteúdo",
    uploadingAlert: "Alterar ou fechar a guia do navegador causará erros de upload.",
    recordingScreen: "Tela de gravação",
    support15Min: "Lembre-se de que a captura de tela suporta até <b> 15 minutos </b>.",
    hereWeGo: "Aqui vamos nós!",
    remember15Min: "Lembre-se de que você tem 15 minutos.",
    windowsAppAvailable: "O aplicativo Windows <br> já está disponível",
    windowsAppAvailableNews: "DeepHow Uploader já está disponivel para Windows. Agora você pode fazer upload de arquivos maiores e também gravar tela.",
    downloadNow: "Baixe agora!",
    deepHowWindowsApp: "DeepHow Windows App",
    downloadAndInstalltheWindowsApp: "Baixar e instalar o Windows App.",
    notAllowedToInstall: "Se você não tem permissão para instalar a versão acima,",
    downloadThisVersion: "Baixe esta versão",
    and: " e ",
    watchThisVideo: "<span>Assista a este vídeo</span> sobre como usá-lo.",
    // Added on 2022/01/19
    abiAuthorizationLabel: "Eu reconheço que todos os indivíduos neste vídeo assinaram a <span> autorização de gravação de fotografia / vídeo </span> e liberação específica para o meu país.",
    abiPrivacyLabel: "Eu concordo que li e entendi a <span>declaração de privacidade de gravação de fotografia Abinbev.</span>",
    // Added on 2022/06/15
    importFromZoom: "Importar do Zoom",
    importFromZoomSteps: "Etapa {step} de {totalStep}",
    selectVideosFrom: "Selecione vídeos de",
    videoSelected: "<span> 0 </span> vídeo selecionado | <span> 1 </span> vídeo selecionado | <span> {count} </span> vídeos selecionados",
    workflowInfo: "Informações do fluxo de trabalho",
    newImport: "Nova importação",
    orBackToEditor: "ou de volta ao <span>Editor</span>",
    importFromZoomSuccessMsg: "Seus vídeos começarão a fazer o upload em breve, pode levar algum tempo até que estejam disponíveis para edição.",
    zoomFailedUploadMsg: "Falha ao fazer upload de vídeo Zoom",
    zoomPeriodLastMonth: "mês passado",
    zoomPeriodPastMonth: "passado {n} meses",
    loadingZoomRecordings: "Carregando vídeos",
    zoomNoRecordingsInfo: "Nenhuma gravação encontrada para o período selecionado.",
    yourVideoExceedTwoHours: "Seu vídeo excede 2 horas e não pode ser carregado.",
    yourVideoExceed3GSize: "Seu vídeo excede 3 GB e não pode ser carregado.",
    yourVideoExceedTwoHoursAnd3GSize: "Seu vídeo excede 2 horas, 3 GB e não pode ser carregado.",
    videoUploadWarningMessage: "Arraste ou clique para importar seu vídeo (apenas MP4 ou webm) Máx. O tamanho é de 3 GB e até 2 horas.",
    videoUploadWarningMessageGen2: "Arraste ou clique para importar seu vídeo (apenas MP4 ou WebM) máx. Até 40 minutos.",
  },
  auth: {
    aboutDeephow: "Sobre DeepHow",
    authTitle: "Primeira solução de IA para treinamento de negócios.",
    authIntro: "DeepHow preenche a lacuna de habilidades em manufatura, serviço e reparo por meio de uma plataforma de aprendizagem baseada em IA e em vídeos interativos de como fazer.",
    allRightReserved: "Todos os direitos reservados",
    logIn: "Conecte-se",
    logOut: "Sair",
    logInSubtitle: "Por favor insira seus dados para entrar.",
    logInSubtitleSso: "Por favor clique abaixo para entrar",
    emailOrId: "E-mail ou ID de funcionário",
    hello: "Olá,",
    passwordSubtitle: "Por favor insira sua senha.",
    password: "Senha",
    forgotPassword: "Esqueceu a senha",
    forgotPasswordSubtitle: "Por favor, insira seu e-mail, para que possamos enviar um link para criar uma nova senha.",
    email: "E-mail",
    resetPassword: "Redefinir senha",
    alertResetSuccess: "Por favor, verifique sua caixa de entrada de e-mail.",
    alertResetFailed: "Falha ao recuperar a senha.",
    alertInvalidId: "ID de funcionário inválido.",
    successMsg: "Verifique seu e-mail e clique no link para definir uma nova senha para sua conta.",
    logInWithSSO: "Entrar com SSO",
    loggingInWithSSO: "Fazendo login com SSO...",
    logInWithGoogle: "Faça login com o Google",
    logInWithMicrosoft: "Faça login com a Microsoft",
    loadingSso: "Carregando SSO...",
    about: "Sobre",
    // 12/17/2021 iframe
    loginToYourAccount: "<b>Contectar-se</b> à sua conta",
    pleaseInputYourPassword: "Por favor insira sua senha.",
    backToLogin: "Voltar ao log in",
    // added 03/02/2022
    wrongPassword: "Senha incorreta.",
    // Shared Account
    sharedDevice: "Dispositivo compartilhado",
    loginExistingAccount: "Faça login com a conta existente",
    createNewAccount: "Criar uma nova conta",
    noUsersMsg: "Nenhum usuário ainda se conectou a este dispositivo compartilhado ...",
    insertIDMsg: "Por favor, insira seu ID de funcionário",
    createIdUserMsg: "Estamos <b>quase lá</b>! <br>Por favor, preencha seus detalhes abaixo.",
    confirmEmployeeID: "Confirme o ID do funcionário",
    welcomeToDeepHow: "Bem -vindo à DeepHow, <b>{name}</b>",
    confirmWorkspaceTeamMsg: "Confirme seu espaço de trabalho e selecione sua equipe!",
    LetsGetStarted: "Vamos começar!",
    // added 05/26/2022
    useSsoLogin: "O login de email/senha não está ativado para sua organização. Faça login com o SSO.",
    // added 02/17/2023
    cookieAlert: "Aceito o uso de cookies, conforme detalhado em nossa <a href='{pdfUrl}' target='_blank'> Política de Privacidade </a>.",
    useCookie: "Usamos cookies!",
    useCookieContext: "Usamos cookies para garantir que você tenha uma experiência incrível usando o DeepHow!",
    privacyPolicy: "Política de Privacidade",
    acceptCookies: "Aceitar cookies"
  },
  rules: {
    required: "Obrigatório.",
    invalidEmail: "E-mail inválido.",
    noSpace: "Não é possível adicionar espaços.",
    validPassword: "A senha deve ter pelo menos 6 caracteres, incluir número, letras minúsculas e maiúsculas.",
    maxPassword: "Máximo de 16 caracteres.",
    mustMatch: "A senha deve corresponder.",
    max25: "Máximo de 25 caracteres.",
    max90: "Máximo de 90 caracteres.",
    max100: "Máximo de 100 caracteres.",
    max140: "Máximo de 140 caracteres.",
    numMaxCharacters: "Máximo de 0 caractere. | Máximo de 1 caractere. | Máximo de {count} caracteres.",
    min6: "Mínimo de 6 caracteres.",
    noWhiteSpace: "Insira o texto como título.",
    noAllSpace: "Não pode usar espaços como nome.",
    invalidEmployeeID: "ID de funcionário inválido.",
    invalidUrl: "URL inválida",
    mustMatchId: "O ID do funcionário deve corresponder.",
    canNotEnterSharedAccount: "Não pode inserir ID da conta compartilhada.",
    validPasswordMin10: "A senha precisa ter pelo menos 10 caracteres, incluir número, caractere especial, letra inferior e superior.",
    invalidName: "Nome inválido.",
  },
  alert: {
    success: "Sucesso",
    info: "Informação",
    alert: "Alerta",
    error: "Erro",
    dontShowThisMsgAgain: "Não mostre esta mensagem novamente",
    unexpectedError: "Ocorreu um erro inesperado. Tente novamente ou entre em contato com a DeepHow para obter assistência.",
  },
  skills: {
    skillsMatrix: "Matriz de habilidades",
    createSkill: "Criar habilidades",
    skillsCreated: "Habilidades criadas",
    skillsPublished: "Habilidades publicadas",
    skillsIn: "Habilidades em",
    hintSearchSkills: "Pesquisar habilidades...",
    lastUpdated: "Última atualização",
    skillsPerPage: "Habilidades por página",
    usersAssigned: "Úsuarios atribuídos",
    status: "Status",
    createdBy: "de",
    skillsSelected: "habilidade selecionada |habilidade selecionada | habilidade selecionada",
    updatedBy: "de",
    deleteSkill: "Apagar habilidade",
    popupMsgDeleteSkill: "Tem certeza que deseja excluir essa habilidade?",
    deleteSkills: "Apagar habilidade",
    popupMsgDeleteSkills: "Tem certeza que deseja excluir essas habilidades?",
    clearAll: "Limpar tudo",
    clearAllFilters: "Limpar todos os filtros",
    clearFilter: "Limpar filtro",
    delete: "Excluir",
    youAreViewing: "Você está vendo",
    numWorkflows: "0 fluxo de trabalho | 1 fluxo de trabalho |{count} fluxos de trabalho",
    searchIn: "Buscar em",
    loadingSkillMatrix: "Carregando matriz de habilidades...",
    createSkillSubtitle: "Selecione um nome e onde deseja enviá-lo",
    skillName: "Nome da habilidade",
    create: "Criar",
    editSkill: "Editar habilidade",
    popupMsgEditSkill: "Se você entrar no modo de edição, sua habilidade será automaticamente não publicada. Tem certeza que deseja continuar?",
    publishSkill: "Publicar habilidade",
    popupMsgPublishSkill: "Você publicará sua habilidade e os usuários poderão vê-la, tem certeza que deseja fazer isso?",
    alertFailedSaveSkill: "Falha ao salvar habilidade",
    alertSkillSuccessfullyPublished: "Sua habilidade foi publicada com sucesso.",
    alertSkillSuccessfullyUnpublished: "Sua habilidade não foi publicada com sucesso.",
    alertSkillSuccessfullySaved: "Sua habilidade foi salva com sucesso",
    alertFailedSavedSkill: "Falha ao salvar habilidade",
    alertFailedPublishSkill: "Falha ao publicar habilidade",
    alertSkillSuccessfullyDeleted: "Sua habilidade foi excluida com sucesso",
    alertSkillFailedDeleted: "Falha ao excluir habilidade",
    content: "Conteúdo",
    addContent: "Adicionar Conteúdo",
    workflowDeleted: "Fluxo de trabalho excluído",
    workflowsSelected: "Fluxo de trabalho selecionado | fluxo de trabalho selecionado| fluxos de trabalhos selecionados",
    selectAll: "Selecionar tudo",
    remove: "Remover",
    noContentMsg: "Você ainda não tem conteúdo em sua habilidade",
    assignUsers: "Atribuir usuários",
    noUserMsg: "Você ainda não tem usuários atribuidos a seu treinamento",
    numUsers: "<b>0</b> Usuário | <b>1</b> Usuário | <b>{count}</b> Usuários",
    assignedOn: "Atribuido em",
    certifiedBy: "Certificado por",
    progress: "Progresso",
    aletUserSuccessfullyRemoved: "O usuário foi removido com sucesso.",
    removeUser: "Remover usuário",
    popupMsgRemoveUserFromSkill: "Tem certeza de que deseja remover <b>{name}</b> esta habilidade?",
    assign: "Atribuir ",
    successfulAssigned: "Atribuido com sucesso",
    AddedToSkill: "Adicionado à habilidade.",
    loadingSkills: "Carregando habilidades...",
    searchingForSkills: "Procurando por habilidades...",
    toDo: "Pendente",
    completed: "Concluído",
    assignedBy: "Assinado por",
    min: "min",
    nextPart: "Próxima parte",
    noSkillsAssignedMsg: "Você ainda não tem habilidades atribuidas.",
    noSkillsAssignedMsg2: "Avisaremos quando você tiver algo novo!",
    noCompletedSkillsMsg: "Você ainda não completou habilidades.",
    noCompletedSkillsMsg2: "Vamos começar a aprender habilidades!",
    noDataSkillsMatrix: "Você ainda não tem dados na Matriz de Habilidades...",
    certificate: "Certificado",
    certificateMsg: "Os usuários receberão um certificado de conclusão se concluírem esta habilidade com sucesso.",
    selectCertificateMsg: "Selecione abaixo os usuários que poderão validar o certificado:",
    validateSkill: "Validar habilidade",
    validateSkillMsg: "Tem certeza de que deseja validar <b> {name} </b> a habilidade {skill}? <br /> Você não poderá desfazer isso.",
    yesValidate: "Sim, validar",
    noValidate: "Não",
    certifier: "Certificador",
    signaturesOn: "em",
    teamProgress: "Progresso da equipe",
    openTeam: "Abrir equipe",
    removeCertificate: "Remova o certificado",
    removeCertificateMsg: "Você removerá todos os usuários capazes de validar o certificado, você tem certeza?",
    yesRemove: "Sim, remover",
    mustAddUserMsg: "* O certificado não será ativado se nenhum usuário for adicionado.",
    skillCover: "Capa de habilidade",
    alertSuccessfullyUpdatedSkillCover: "A cobertura de habilidades foi atualizada com sucesso.",
    alertFailedUpdatedSkillCover: "Falha ao atualizar a cobertura de habilidades.",
    closeSkill: "Fechar habilidade",
    allWorkflowsDeleted: "Todos os fluxos de trabalho são excluídos nesta habilidade.",
    waitingForValidation: "Esperando pela validação",
    validatedBy: "Validado por:",
    skillWasUnpublished: "Habilidade foi despublicada.",
    skillWasUnpublishedMsg: "Eu vou deixar você saber quando esta habilidade estiver disponível novamente. Por enquanto, não há nada a fazer.",
    notAssignedToSkill: "Você não foi atribuído a essa habilidade.",
    notAssignedToSkillMsg: "Desculpe, esta página é disponível apenas aos usuários que foram atribuídos a esta habilidade.",
    remindPendingUsers: "Lembrar usuários pendentes",
    alertSuccessRemindPendingUsers: "Lembrou com sucesso os usuários pendentes.",
    alertFailedRemindPendingUsers: "Falha ao lembrar os usuários pendentes.",
    alertNoPendingUsers: "Não foram encontrados usuários pendentes.",
    numVideo: "0 vídeo | 1 vídeo | {count} vídeos",
    // Skill Deadline
    deadline: "Data limite",
    deadlineMsg: "Defina um período de tempo geral para os usuários terminarem de assistir a essa habilidade. Após o prazo, os usuários pendentes serão notificados ...",
    deadlineForThisSkill: "Ative o prazo para essa habilidade.",
    certificateForThisSkill: "Ative o certificado para essa habilidade.",
    deadlineStartsMsg: "O período começa a contar a partir da data que o usuário foi atribuido à habilidade.",
    selectAPeriod: "Selecione um período",
    finished: "Finalizado",
    editDeadline: "Editar prazo",
    editDeadlineMsg: "Ao editar o prazo, o anterior não será mais válido e o novo período será aplicado a todos os usuários nessa habilidade. <br><br> tem certeza de que deseja continuar?",
    alertSuccessNewDeadline: "Novo prazo agora é aplicado a todos os usuários.",
    alertSuccessRemoveDeadline: "O prazo de habilidade foi removido com sucesso.",
    alertFailedDeadline: "Falha ao atualizar o prazo de habilidade.",
    dueToday: "Devido hoje",
    turnOffDeadlineMsg: "Ao desligar o prazo, os dados anteriores serão perdidos. Na próxima vez que for ativado, ele redefinirá todas as datas de início do usuário. <br><br> tem certeza de que deseja continuar?"
  },
  time: {
    numDays: "0 dia | 1 dia | {count} dias",
    numWeeks: "0 semana | 1 semana | {count} semanas",
    numMonths: "0 mês | 1 mês | {count} meses",
    numDaysLeft: "0 dias restantes | 1 dia restante | {count} dias restantes",
    numDaysDelayed: "0 dias atrasado | 1 dia atrasado | {count} dias atrasados",
  },
  notification: {
    sharedWorkflow: "Compartilhou um fluxo de trabalho",
    assignedNewSkill: "Compartilhou uma habilidade",
    sentAnnouncement: "Enviou um anúncio",
    needValidation: "Precisa de sua validação",
    noNotificationsMsg: "Você não tem nenhuma notificação",
    justNow: "Agora mesmo",
    updatedSkill: "atualizou a habilidade",
    unpublishedSkill: "habilidade despublicada",
    sentAReminder: "enviou-lhe um lembrete sobre esta habilidade. Comece a assistir para que ele possa acompanhar seu progresso.",
    assignedNewSkillDue: "atribuiu a você uma nova habilidade. Você tem <span>{time}</span> para terminar de assistir.",
    delayedSkillMsg: "Você tem uma habilidade <span>em atraso</span>. Comece a assistir agora para voltar aos trilhos!",
    daysLeftMsg: "Você só tem <span>{time}</span> para terminar de assistir a essa habilidade. Apresse -se para que você não fique atrasado!",
    askToReviewWorkflow: "pediu que você revisasse um fluxo de trabalho.",
    reactedWorkflowMsg: "<span>{user}</span> reagiu ao seu fluxo de trabalho.",
    reactedWorkflowMsgTwoUsers: "<span>{user1} e {user2}</span> reagiram ao seu fluxo de trabalho.",
    reactedWorkflowMsgMoreUsers: "<span>{user1}, {user2} e {count} Outros</span> reagiram ao seu fluxo de trabalho.",
  },
  mediaPicker: {
    insertFullWorkflow: "Insira o fluxo de trabalho completo",
    selectWorkflowStep: "Insira o fluxo de trabalho completo",
    stepSelected: "etapa selecionada"
  },
  zoomIntegration: {
    settingsTitle: "Configuraçãos do Zoom",
    settingsSubtitle: "Integração",
    deleteCopy: "Exclua a cópia do vídeo gravado do Zoom Cloud",
    goToDeephow: "Vá para Deephow", 
    landingSuccess: "<b> App de vídeo de Know-How <b> bem-sucedido instalado.",
    landingError: "Ocorreu um erro ao instalar o aplicativo de vídeo <b> deephow know-how </b>. Entre em contato com <a href = 'mailto: service@deephow.com'>service@deephow.com</a> Para obter suporte.",
  },
  reactions: {
    likeIt: "Gosto disso",
    greatContent: "Ótimo conteúdo",
    wellPresented: "Bem apresentado",
    niceProduction: "Boa produção",
  },
  maven: {
    // TODO: maven chat added on 03/15/2023
    mavenChat: "Chat",
    searchInWorkspace: "Search in {workspace}",
    showReferences: "Show references",
    hideReferences: "Hide references",
    helloMessage: "Hello {userName},",
    assistMessage: "How can I assist you today?",
    askMeAnything: "Ask me anything...",
    tryOtherQuestionMessage: "You can try asking other question.",
    audioIsReady: "Please recording your audio clip in English...",
    audioLimitationTime: "The audio limitation time is 3 mins.",
    clearChatHistory: "Clear chat history",
    newLineHint: "new line: shift + Enter",
    // TODO: maven map added on 04/14/2023
    map: "Map",
    resetMap: "reset map",
    filterWorkspaces: "filter workspaces",
    showQuestions: "show questions",
    hideQuestions: "hide questions",
    showClusters: "show clusters",
    hideClusters: "hide clusters",
    question: "Question",
    loadingMap: "Loading map...",
    clearChatHistoryConfirmation: "Clearing the chat history will start a new chat. Are you sure?",
    clearConfirm: "Yes, clear",
  },
  $vuetify: {
    dataIterator: {
      rowsPerPageText: "Itens por página",
      rowsPerPageAll: "Tudo",
      pageText: "{0}-{1} de {2}",
      noResultsText: "Nenhum registro correspondente encontrado",
      nextPage: "Próxima página",
      prevPage: "Página anterior"
    },
    dataTable: {
      rowsPerPageText: "Linhas por página"
    },
    noDataText: "Sem dados disponíveis",
    carousel: {
      prev: "Visual anterior",
      next: "Próxima visual"
    }
  }
};
