<template>
  <section class="d-avatar" :class="{'avatar-border':border}">
    <v-avatar :size="size">
      <img 
        v-if="blobAvatar" 
        :src="blobAvatar" 
      />
      <img v-else src="@/img/user-light.png" />
    </v-avatar>
  </section>
</template>

<script>
import MixinAvatar from "@/components/MixinAvatar.vue";

export default {
  name: "d-avatar",
  props: {
    userId: String,
    size: {
      Number,
      default: 36,
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  components: {
  },
  watch: {
    userId: function () {
      //console.log("watch", this.userId);
      this.blobAvatar = "";
      this.getUserAvatar(this.userId, function (avatar) {
        //console.log("url", avatar.url, avatar.userId, this.userId);
        if (this.userId == avatar.userId) {
          this.blobAvatar = avatar.url;
        }
      }.bind(this));
    }
  },
  mounted() {
    //console.log("mounted", this.userId);
    this.getUserAvatar(this.userId, function (avatar) {
      if (this.userId == avatar.userId) {
        this.blobAvatar = avatar.url;
      }
    }.bind(this));
  },
  mixins: [MixinAvatar],
  data() {
    return {
      blobAvatar: ""
    };
  },
  methods: {
  },
  computed: {}
};
</script>

<style scoped>
.d-avatar {
  display: inline-block;
}
.avatar-border{
  border: white 2px solid;
  border-radius: 50%;
}
</style>
