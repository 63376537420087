import { fetchWorkspaceById, fetchWorkflowByWorkflowId } from "@/server/api-server";
import { postSearch } from "@/server/search-server";
import authChoice from "../../../auth";
import Analytics from "@/js/analytics/analytics";

export const namespaced = true;
export const state = {
  workspaceId: "", // group id
  isShowPublishedWorkflow: false,
  isSearchResultEmpty: false,
  workspace: null,

  // search data
  searchHighlightResults: [],
  searchWorkflows: [],
  didYouMean: "",
  searchInput: "",
  isSearching: false,
  showSearchResult: "title", // "content", "title", "publisher"
  selectedSortOption: { name: "", sort: "" },
};

export const mutations = {
  STORE_SEARCH_HIGHLIGHT_WORKFLOW(state, data) {
    state.searchHighlightResults = data;
  },
  STORE_SEARCH_WORKFLOWS(state, workflows) {
    state.searchWorkflows = workflows;
  },
  STORE_DID_YOU_MEAN(state, data) {
    state.didYouMean = data;
  },
  STORE_WORKSPACE_ID(state, id) {
    state.workspaceId = id;
  },
  STORE_WORKSPACE_DATA(state, data) {
    state.workspace = data;
  },
  STORE_SEARCH_INPUT(state, input) {
    state.searchInput = input;
  },
  CLEAR_SEARCH_DATA(state) {
    state.searchWorkflows = [];
    state.searchHighlightResults = [];
    state.didYouMean = "";
    state.searchInput = "";
    state.showSearchResult = "title";
    state.isSearchResultEmpty = false;
  },
};

export const getters = {
  getters_studio_workflows(state, _2, global) {
    return global.workflows
      .filter((workflow) => {
        if (!state.workspaceId) {
          return true;
        }
        // SD1-550 prevent to view private workspace in all.
        return state.workspaceId !== "All" ? workflow.group === state.workspaceId : workflow.privateWorkspace === false;
      })
      .filter((workflow) => {
        const isWorkflowInvalid = workflow.archived || workflow.deleted;
        if (isWorkflowInvalid) {
          return false;
        }
        return state.isShowPublishedWorkflow
          ? workflow.published === state.isShowPublishedWorkflow
          : !workflow.published;
      })
      .sort((comparisonA, comparisonB) => comparisonB.publishedDate.seconds - comparisonA.publishedDate.seconds);
  },
  getters_filtered_studio_search_workflows(state) {
    const filteredWorkflowIds = state.searchHighlightResults
      .filter(({ highlight }) => highlight.includes(state.showSearchResult))
      .map(({ id }) => id);
    const filteredWorkflows = state.searchWorkflows
      .filter(({ id }) => filteredWorkflowIds.includes(id))
      .filter((workflow) => workflow.publishedDate);

    if (filteredWorkflows.length !== 0) {
      filteredWorkflows.sort((comparisonA, comparisonB) => {
        switch (state.selectedSortOption.sort) {
          case "last-updated":
            if (typeof comparisonB.publishedDate === "string") {
              return new Date(comparisonB.publishedDate).getTime() - new Date(comparisonA.publishedDate).getTime();
            }
            return comparisonB.publishedDate.seconds - comparisonA.publishedDate.seconds;
          case "alphabetically":
            return comparisonA.title.localeCompare(comparisonB.title);
          default:
            return 1;
        }
      });
    }
    return filteredWorkflows;
  },
  getters_is_filtered_studio_search_workflows_empty(state, getters) {
    return state.searchWorkflows.length !== 0 && getters.getters_filtered_studio_search_workflows.length === 0;
  },
  getters_workspace_name(state) {
    if (!state.workspace) {
      return "";
    }
    return state.workspace.name;
  },
  getters_workspace_groups_name(state) {
    if (!state.workspace) {
      return "";
    }
    return state.workspace.worksapceGroupName;
  },
  getters_is_show_enter_search(state) {
    return !state.isSearchResultEmpty && state.searchInput === "";
  },
  getter_is_results_empty(state) {
    return state.isSearchResultEmpty;
  },
  getters_is_searching(state) {
    return state.isSearching;
  },
  getters_get_highlight_counters_by_type: (state) => (type) => {
    // "content", "title", "publisher"
    if (type === "all") {
      return state.searchHighlightResults.length;
    }
    return state.searchHighlightResults.filter(({ highlight }) => highlight.includes(type)).length;
  },
};

export const actions = {
  async fetchWorkflowsByWorkspace({ rootGetters, dispatch, commit }, { getWorkflowsAgain = false, workspaceId }) {
    const options = {
      organization: rootGetters.getter_organization,
      getWorkflowsAgain,
    };
    const { ok, data } = await fetchWorkspaceById(workspaceId);
    if (workspaceId !== "All") {
      options.group = workspaceId;
      options.privateWorkspace = data.item.private;
    }
    if (!ok) {
      console.error(`Fetch workspace api has occurred error`);
    }
    commit("STORE_WORKSPACE_ID", workspaceId);
    commit("STORE_WORKSPACE_DATA", data.item);
    dispatch("getWorkflows", options, { root: true });
  },
  async searchWorkflowsByInput({ state, rootGetters, commit, dispatch }, input) {
    state.isSearchResultEmpty = false;
    state.isSearching = true;
    try {
      const token = await authChoice().currentUser.getIdToken();
      const data = {
        published: state.isShowPublishedWorkflow,
        text: input,
        locale: "en-us",
        token,
        organization: rootGetters.getter_organization,
        index: "workflows",
        ...(state.workspaceId && state.workspaceId !== "All" && { group: state.workspaceId }),
      };
      commit("STORE_SEARCH_INPUT", input);
      const { ok, data: responseData, errorMessage } = await postSearch({ path: "search", payload: data });
      if (!ok) {
        console.error(errorMessage);
        return;
      }

      if (responseData.results.length === 0) {
        state.isSearchResultEmpty = true;
      }

      commit("STORE_SEARCH_HIGHLIGHT_WORKFLOW", responseData.results);
      commit("STORE_DID_YOU_MEAN", responseData.didYouMean);
      dispatch("changeSortOption", {
        name: "",
        sort: "",
      });
      dispatch("fetchWorkflowsByIds");

      Analytics.setTrack(
        "Web - Search - Enter - Search",
        {
          "Search Content": input,
          "Workspace ID": state.workspaceId ? state.workspaceId : "null",
        },
        {
          category: "EditorExplorer",
          action: "Search Workflow",
          name: "Search and Enter",
          params: {
            dimension3: input,
            dimension4: state.workspaceId ? state.workspaceId : "null",
          },
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      state.isSearching = false;
    }
  },
  async fetchWorkflowsByIds({ state, commit }) {
    try {
      const promises = state.searchHighlightResults.map(({ id }) => fetchWorkflowByWorkflowId(id));
      const responses = await Promise.all(promises);
      const workflows = responses.reduce((accumulator, { ok, data, errorMessage }) => {
        if (!ok) {
          console.error(errorMessage);
          return accumulator;
        }
        return accumulator.concat(data.item);
      }, []);
      commit("STORE_SEARCH_WORKFLOWS", workflows);
    } catch (error) {
      console.error(error);
    }
  },
  changeWorkflowPublishedStatus({ state, dispatch }, status) {
    state.isShowPublishedWorkflow = typeof status === "boolean" ? status : Boolean(status);
    if (state.searchInput !== "") {
      dispatch("searchWorkflowsByInput", state.searchInput);
    }
  },
  changeWorkflowSpaceId({ commit }, id) {
    commit("STORE_WORKSPACE_ID", id);
  },
  changeTabType({ state }, type) {
    state.showSearchResult = type;
  },
  clearSearch({ commit }) {
    commit("CLEAR_SEARCH_DATA");
  },
  changeSortOption({ state }, sort) {
    state.selectedSortOption = sort;
  },
};
