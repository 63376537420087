<template>
  <div id="app">
    <v-app>
      <d-header
        :isLoggedIn="isLoggedIn"
        :drawer="drawer"
        :access="access"
        :currentRouteName="currentRouteName"
        @toggleDrawer="drawer = !drawer"
        @open-user-profile="showUserProfile = !showUserProfile"
      />

      <v-navigation-drawer
        floating
        hide-overlay
        v-if="isLoggedIn && !isMobileDevice && !isTabletDevice && !isSupportWorkspaceGroup"
        dark
        v-model="drawer"
        width="248"
        temporary
        class="secondary"
      >
        <v-list class="pt-4 grey--text" dense>
          <v-list-tile v-if="access.editor" :to="'/editorexplorer'" active-class="active-icon-1 primary--text">
            <v-list-tile-action class="pl-3">
              <icon-base>
                <d-icon-editor />
              </icon-base>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ getter_is_gen2 ? $t("all.contentManager") : $t("all.editor") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            v-if="access.player && !getter_is_gen2"
            :to="'/navigator/home'"
            :class="{ 'active-icon-3 primary--text': playerRoutes.includes($route.name) }"
          >
            <v-list-tile-action class="player-icon">
              <icon-base viewBox="0 0 26 22" width="26" height="22">
                <d-icon-player />
              </icon-base>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("player.navigator") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            v-if="access.admin"
            :to="'/admin/main'"
            :class="{ 'active-icon-3 primary--text': $route.name == 'AdminMain' || $route.name == 'AdminWorkspace' }"
          >
            <v-list-tile-action class="pl-3">
              <icon-base>
                <d-icon-admin />
              </icon-base>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("all.admin") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            v-if="access.analytics && !getter_is_gen2"
            :to="'/analytics/main/'"
            :class="{
              'active-icon-3 primary--text':
                $route.name == 'AnalyticsMain' ||
                $route.name == 'AnalyticsSingleWorkflow' ||
                $route.name == 'AnalyticsSingleUser',
            }"
          >
            <v-list-tile-action class="pl-3">
              <icon-base width="20" height="20" style="margin: -2px 0 0 1px">
                <d-icon-analytics />
              </icon-base>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("all.analytics") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            v-if="access.skills && !getter_is_gen2"
            :to="'/skills/explorer/'"
            :class="{ 'active-icon-3 primary--text': $route.name == 'SkillsExplorer' || $route.name == 'SkillsMatrix' }"
          >
            <v-list-tile-action class="pl-3">
              <icon-base width="20" height="20" style="margin: -2px 0 0 1px">
                <d-icon-skills />
              </icon-base>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>
                {{ $t("all.skills") }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <maven-menu-items />
        </v-list>
      </v-navigation-drawer>

      <d-left-side-menu v-else-if="isLoggedIn && isSupportWorkspaceGroup" />

      <router-view
        :isMobileDevice="isMobileDevice"
        :isTabletDevice="isTabletDevice"
        :isIframe="isIframe"
        :isIosDevice="isIosDevice"
      ></router-view>
      <!-- uploader -->
      <uploader
        v-if="showUploader && uploadType !== 'ImportFromZoom'"
        :showUploader="showUploader"
        :uploadType="uploadType"
        :isRecordingScreen="isRecordingScreen"
        :userGroupList="groups"
        :class="{ 'hide-uploader': isRecordingScreen }"
        @close-uploader="(uploadType = ''), (showUploader = false)"
        @startRecordScreen="updateIsRecordingScreen(true)"
        @endRecordScreen="updateIsRecordingScreen(false)"
        @update-current-group="updateCurrentGroupAfterUpload($event)"
        @go-to-editor="goToEditor()"
      ></uploader>
      <!-- zoom uploader -->
      <zoom-uploader
        v-if="showUploader && uploadType === 'ImportFromZoom'"
        :userGroupList="groups"
        @close-uploader="(uploadType = ''), (showUploader = false)"
      />
      <user-profile
        v-if="showUserProfile"
        :isMobileDevice="false"
        :userGroupList="groups"
        :groupDictionary="group_dictionary"
        :isTabletDevice="isTabletDevice"
        @close-user-profile="showUserProfile = false"
      />
      <user-profile-main
        v-if="userProfileConfig.isOpen"
        :isMobileDevice="isMobileDevice"
        :isTabletDevice="isTabletDevice"
        :isIosDevice="isIosDevice"
      />
      <new-version-snackbar :isMobileDevice="isMobileDevice" />
      <d-idle
        v-if="customizedPermissions != null"
        :allowAutoLogout="customizedPermissions.allowAutoLogout"
        :autoLogoutTimeout="customizedPermissions.autoLogoutTimeout"
        @update-allowAutoLogout="customizedPermissions.allowAutoLogout = $event"
        @logout="
          resetLoginData();
          $router.push('/');
        "
      />
      <d-global-alert />
      <d-use-app-popup v-if="showUseAppPopup" @close="showUseAppPopup = false" />
      <d-cookie-accept-popup v-if="showAcceptCookiePopup" @close="showAcceptCookiePopup = false" />
      <d-gen2-forbidden-popup v-model="showGen2ForbiddenAlert" @close="forbiddenPopupHandler" />
    </v-app>
  </div>
</template>

<script>
import Vue from "vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import eventbus from "./main";
import MixinAnalytics from "@/components/MixinAnalytics.vue";
import Analytics from "./js/analytics/analytics";
import DHeader from "@/components/DHeader/DHeader.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { isEnableSharedAccountFn } from "@/js/user/share-account.js";
import {
  updateBrowerTabLocalStorage,
  getLogoutImmediatelyLocalStorage,
  removeLogoutImmediatelyLocalStorage,
} from "@/js/idle/idle.js";
import { IS_TABLET, IS_MOBILE } from "@/constants/device-version";
import { isIOSDevice } from "@/js/mobile.js";
import { mavenAllowedListInMobile, MAVEN_ROUTE_NAME } from "@/router/routes/MavenRoute.js";
import { IS_MAVEN_WHITELIST_DOMAIN } from "@/constants/envVersion";
import { IS_SUPPORT_WORKSPACE_GROUP } from "@/constants/studio-version";
import { playerRoutes } from "@/constants/routers";

export default {
  name: "App",
  components: {
    DHeader,
    UserProfile: () => import("@/components/DUser/UserProfile"),
    Uploader: () => import("@/components/Uploader.vue"),
    ZoomUploader: () => import("@/components/DUploader/ZoomUploader.vue"),
    IconBase: () => import("@/components/IconBase"),
    DGlobalAlert: () => import("@/components/ui_components/DGlobalAlert"),
    UserProfileMain: () => import("@/components/DUserProfile/UserProfileMain"),
    DIconEditor: () => import("@/components/icons/DIconEditor"),
    DIconPlayer: () => import("@/components/icons/DIconPlayer"),
    DIconAdmin: () => import("@/components/icons/DIconAdmin"),
    DIconAnalytics: () => import("@/components/icons/analytics/DIconAnalytics"),
    DIconSkills: () => import("@/components/icons/DIconSkills"),
    NewVersionSnackbar: () => import("@/components/DPlayer/NewVersionSnackbar"),
    DIdle: () => import("@/components/ui_components/DIdle.vue"),
    DUseAppPopup: () => import("@/components/DPopup/DUseAppPopup.vue"),
    DCookieAcceptPopup: () => import("@/components/DPopup/DCookieAcceptPopup.vue"),
    DGen2ForbiddenPopup: () => import("@/components/DPopup/DGen2ForbiddenPopup.vue"),
    MavenMenuItems: () => import("@/components/DMaven/MavenMenuItems.vue"),
    DLeftSideMenu: () => import("@/components/DLeftSideMenu/DLeftSideMenu.vue"),
  },
  mixins: [MixinDB, MixinUser, MixinAnalytics],
  mounted() {},
  data() {
    return {
      isLoggedIn: false,
      drawer: false,
      access: {
        editor: false,
        player: true,
        admin: false,
        skills: false,
      },
      // TODO: replace "isTabletDevice" and "isMobileDevice"
      // when building new header and side menu by Content Structure project
      isMobileDevice: IS_MOBILE,
      isTabletDevice: IS_TABLET,
      isIosDevice: false,
      hostname: "",
      isIframe: false,
      currentGroupId: "",
      showUploader: false,
      uploadType: "",
      showUserProfile: false,
      customizedPermissions: null,
      playerRoutes,
      afterUpdateCurrentGroupFn: null,
      showUseAppPopup: false,
      showAcceptCookiePopup: false,
      showGen2ForbiddenAlert: false,
      isSupportWorkspaceGroup: IS_SUPPORT_WORKSPACE_GROUP,
    };
  },
  watch: {
    $route(to, from) {
      // SD1-914 below is temporary usage, it would be changed as API is ready.
      if (["StudioWorkspace", "EditorExplorer"].includes(to.name)) {
        this.fetchEditableGroups();
      } else if (to.name === "SharedMain") {
        /** auto logout: to initialize browserTabList */
        updateBrowerTabLocalStorage([]);
      }
      this.redirectRouter();
    },
    groups: function (groups) {
      if (groups.length === 0) return;

      // Change current group to uploaded group after reload page because finished screenRecorded
      const screenRecordedToGroup = localStorage["screenRecordedToGroup"] || "";
      localStorage.setItem("screenRecordedToGroup", "");
      if (screenRecordedToGroup.length > 0) {
        this.currentGroupId = screenRecordedToGroup;
      } else {
        this.currentGroupId = this.$editorGroup || this.groups[0].id;
      }
    },
    userProfile() {
      this.getUserLocale();
    },
  },
  created() {
    // Don't track client device size once client already did.
    if (!localStorage.getItem("trackSize")) {
      Analytics.setTrack("Web - Client Size", {
        Width: window.window.innerWidth,
        Height: window.innerHeight,
      });
      localStorage.setItem("trackSize", "true");
    }
    /** direct link to iOS APP from iPhone or iPad */
    const isUsingIOSDevice = isIOSDevice();
    const regexPublicLink = new RegExp(/\/p\//);
    const isPublicLink = regexPublicLink.test(location.pathname);
    if (isUsingIOSDevice && !isPublicLink) this.showUseAppPopup = true;

    this.initAccess();
    const self = this;
    self.auth().onAuthStateChanged((user) => {
      if (user) {
        /** To confirm user need to logout and force to trigger */
        if (getLogoutImmediatelyLocalStorage() === "true") {
          this.resetLoginData();
          this.logout();
          removeLogoutImmediatelyLocalStorage();
          return;
        }

        Analytics.setUserUid(user.uid);

        self.initializeUser(user).then(() => {
          const onAccessReady = function () {
            if (!self.$organization) {
              //if group is not yet available (when the user refresh the page)
              self.getUserData();
            } else {
              self.fetchEditableGroups();
            }
          };
          self.setupWorkspaceColorsConfig({
            mixinDbRef: self,
          });
          if (self.$route.query.redirect) {
            onAccessReady();
            self.$router.push(self.$route.query.redirect);
          } else {
            onAccessReady();
            self.redirectRouter();
          }
        });
      } else {
      }
    });

    this.isIosDevice = this.detectIosDevice();
    this.hostname = window.location.hostname;
    // check if it's using iframe
    if (window.self != window.top) {
      this.isIframe = true;
    }
    eventbus.$on("show-uploader", function ({ uploadType, afterUpdateCurrentGroupFn } = {}) {
      self.showUploader = true;
      self.uploadType = uploadType;
      self.afterUpdateCurrentGroupFn = afterUpdateCurrentGroupFn;
    });
    eventbus.$on("show-cookie-banner", async () => {
      const acceptedUsers = JSON.parse(localStorage.getItem("acceptedUsers"));
      // check current account accepted cookie policy or not,
      // if userProfile not ready call api immediately
      try {
        if (self.userProfile.length === 0) {
          await self.$store.dispatch("getUserProfile");
        }
        const email = self.userProfile[0].email;
        if (!acceptedUsers || !acceptedUsers.includes(email)) {
          localStorage.setItem(
            "acceptedUsers",
            JSON.stringify([...(acceptedUsers ? acceptedUsers : []), this.userProfile[0].email])
          );
          self.showAcceptCookiePopup = true;
        }
      } catch (e) {
        self.showAcceptCookiePopup = true;
      }
    });
  },
  computed: {
    ...mapState("editor", ["groups"]),
    ...mapState("global", ["userProfileConfig"]),
    ...mapGetters("editor", ["group_dictionary"]),
    ...mapGetters(["getter_is_gen2", "getter_is_allowed_using_maven_features"]),
    userProfile() {
      //get user profile from vuex
      return this.$store.state.userProfile;
    },
    companyLogo() {
      if (this.userProfile[0]) {
        return this.userProfile[0].companyLogo;
      }
    },
    currentRouteName() {
      const routerName = this.$route.name;
      if (this.playerRoutes.includes(routerName)) {
        return this.$t("player.navigator");
      }
      return this.routerDictionary[routerName];
    },
    routerDictionary() {
      return {
        EditorExplorer: this.getter_is_gen2 ? this.$t("all.contentManager") : this.$t("all.editor"),
        SkillsPlayerExplorer: this.$t("all.player"),
        SkillPlayer: this.$t("all.player"),
        AdminMain: this.$t("all.admin"),
        AdminWorkspace: this.$t("all.admin"),
        AdminTeam: this.$t("all.admin"),
        AnalyticsMain: this.$t("all.analytics"),
        AnalyticsSingleWorkflow: this.$t("all.analytics"),
        AnalyticsSingleUser: this.$t("all.analytics"),
        SkillsMain: this.$t("all.skills"),
        SkillsExplorer: this.$t("all.skills"),
        SkillsMatrix: this.$t("all.skills"),
        SkillsBuilder: this.$t("all.skills"),
        SkillsBuilderSettings: this.$t("all.skills"),
        SkillsBuilderUsers: this.$t("all.skills"),
      };
    },
    isRecordingScreen() {
      return this.$store.state.isRecordingScreen;
    },
    showDrawerBtn() {
      return this.isLoggedIn && !this.isMobileDevice && !this.isTabletDevice && !this.$shared;
    },
  },
  methods: {
    ...mapActions("editor", ["fetchEditableGroups"]),
    ...mapActions("workspaceColor", ["setupWorkspaceColorsConfig"]),
    async redirectRouter() {
      if (!this.isLoggedIn) {
        return;
      }
      try {
        if (this.userProfile.length === 0) {
          await this.$store.dispatch("getUserProfile");
        }
        if (this.getter_is_gen2 && !IS_MAVEN_WHITELIST_DOMAIN) {
          if (this.showGen2ForbiddenAlert) return;
          this.showGen2ForbiddenAlert = true;
          return;
        }
        // for Shared Account
        const response = await isEnableSharedAccountFn({
          clientKey: this.$clientKey,
        });
        if (response.isEnabled && this.$isSharedAccount) {
          this.$router.push("/shared");
          return;
        }
        if (this.$route.query.redirect) {
          this.$router.push(self.$route.query.redirect);
          return;
        }
        const defaultHomePage = this.getter_is_gen2 ? "/MavenChat" : "/navigator/home";
        if (this.$route.path === "/") {
          this.$router.push(defaultHomePage);
          return;
        }
        const gen2ForbiddenPathRegex = /\/(skills|navigator|analytics)/;
        if (this.getter_is_gen2 && gen2ForbiddenPathRegex.test(this.$route.path)) {
          this.$router.push(defaultHomePage);
          return;
        }
        const currentRoute = this.$route.name;
        const canAccessByMobileAndTablet = [
          ...this.playerRoutes,
          "PlayerMain",
          "PlayerPublic",
          "PlayerFullscreenPublic",
          ...mavenAllowedListInMobile,
        ].includes(currentRoute);
        const isPlayer = this.playerRoutes.includes(currentRoute);

        // Maven route rules
        // if gen1: can not access MavenChat and MavenMap, otherwise direct to Navigator
        // if gen2: can access MavenChat and MavenMap, otherwise redirect to MavenChat
        // if gen2plus: can access MavenChat and MavenMap
        const isMavenChat = MAVEN_ROUTE_NAME.MAVEN_CHAT === currentRoute;
        const isMavenMap = MAVEN_ROUTE_NAME.MAVEN_MAP === currentRoute;

        if ((isMavenChat || isMavenMap) && !this.getter_is_gen2 && !this.getter_is_allowed_using_maven_features) {
          this.$router.push(defaultHomePage);
        }
        // hide the non-player part of the website if it's using mobile
        if (this.isMobileDevice && !isPlayer && !canAccessByMobileAndTablet) {
          this.$router.push(defaultHomePage);
          return;
        }
        // hide the non-player part of the website if it's using Tablet
        if (this.isTabletDevice && !isPlayer && !canAccessByMobileAndTablet) {
          this.$router.push(defaultHomePage);
        }
      } catch (e) {
        console.log("redirectRouter error", e.message);
      }
    },
    initializeUser(user) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$store.dispatch("getUserProfile");
        self
          .getUserRole(user)
          .then(() => {
            self.isLoggedIn = true;
            self.setUserAccess();
            self.access = self.$access;
            if (self.$backendType == "ali") {
              self.setUserStorageAccess();
              if (!self.setUserStorageAccessInterval) {
                //TODO make this better , ex: all event trigger based
                self.setUserStorageAccessInterval = setInterval(self.setUserStorageAccess, 1 * 60 * 60 * 1000); // per hour to prevent sts token expired
              }
            }
            self.customizedPermissions = self.getCustomizedPermissions();
            resolve();
          })
          .catch((err) => {
            reject();
          });
      });
    },
    getUserLocale() {
      const userLocale = this.userProfile[0].locale;
      if (userLocale) {
        this.$i18n.locale = userLocale;
        localStorage.setItem("locale", userLocale);
      } else {
        this.updateUserLocale(this.$i18n.locale);
      }
    },
    updateUserLocale(lang) {
      //save to database
      const self = this;
      const id = this.userProfile[0].id;
      if (lang) {
        const userData = {
          locale: lang,
        };
        self
          .updateDocument("users", id, {
            locale: lang,
          })
          .then(() => {
            self.userProfile[0].locale = lang;
            localStorage.setItem("locale", lang);
          })
          .catch((err) => console.log(err));
      }
    },
    detectIosDevice() {
      return (
        ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
          navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    updateCurrentGroupAfterUpload(group) {
      this.currentGroupId = group.id;
      Vue.prototype.$editorGroup = group.id;
      // TODO_MAYBE_CAN_USE_VUEX_ACTION_TO_REPLACE (CREATE)
      if (typeof this.afterUpdateCurrentGroupFn === "function") {
        this.afterUpdateCurrentGroupFn({
          groupId: group.id,
        });
      }
    },
    checkFrameCaptureError(workflow) {
      if (workflow.frameCaptureStatus) {
        return workflow.frameCaptureStatus.includes("error");
      } else {
        return false;
      }
    },
    updateIsRecordingScreen(booType) {
      this.$store.commit("updateIsRecording", booType);
    },
    resetLoginData() {
      this.isLoggedIn = false;
      this.setPrototypeUser(null);
      this.setPrototypeOrganization(null);
      this.customizedPermissions = null;
    },
    forbiddenPopupHandler() {
      this.showGen2ForbiddenAlert = false;
      this.logout();
    },
  },
};
</script>

<style>
html {
  overflow-y: hidden !important;
}
body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #0c0c0e;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8d909f !important;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  font-family: "Roboto", Helvetica, Arial, Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #242527;
  /* Removing the White Bars in Safari on iPhone iOS 11 */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

button:focus {
  outline: none;
}

.player-icon {
  padding-left: 14px;
}

/* make v-dialog background color darker */
.v-overlay--active:before {
  opacity: 0.8 !important;
}
.v-overlay:before {
  background-color: #000000 !important;
}

.dropdown-list-item:hover {
  background-color: #52545d !important;
}

.uploader-menu .v-list {
  background: #2c2d32 !important;
  color: white !important;
}
.app-no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.v-list-app {
  background: #2c2d32 !important;
  color: white;
}
.hide-uploader {
  opacity: 0;
  pointer-events: none !important;
  z-index: -1;
}
.app-all-white-spaces {
  /* show all the white spaces for names */
  white-space: pre-wrap !important;
}
</style>
