<template>
  <section class="builder-explorer">
    <v-toolbar flat color="transparent" height="140px" class="pl-2 pt-0">
      <button class="tool-bar-icon close-btn" @click="pauseVideo(), closeExplorer(), clearSteps()">
        <icon-base>
          <d-icon-close2 />
        </icon-base>
      </button>
    </v-toolbar>
    <div class="px-3">
      <div class="mx-auto search-div">
        <v-text-field
          dark
          flat
          v-model="searchInput"
          label="Find"
          single-line
          hide-details
          class="primary--text px-2 search-text"
          color="primary"
          background-color="transparent"
          prepend-inner-icon="search"
          :placeholder="$t('builder.hintSearchBuilder')"
          solo
          @change="setMatomoOnChangeTrack"
        ></v-text-field>
      </div>
    </div>

    <div class="video-explorer" @scroll="handleScroll">
      <d-no-result :showNoResult="workflows.length == 0 && searchInput != ''" />
      <v-layout row wrap>
        <v-flex v-for="workflow in workflows" :key="workflow.id" xs6 sm4 md3 lg2 d-flex>
          <div color="transparent" class="hover-card">
            <div ref="cardDiv" class="video-div" :class="{ 'video-highlight': workflow.id == selectedVideo }">
              <div v-if="workflow.id != selectedVideo" class="darken-video-hover"></div>
              <div v-if="workflow.blocked == 'transcoding'" class="transcoding-block">
                <div class="video-tag-trapezoid video-tag-processing">
                  <span class="video-tag-text">{{ workflow.blocked }}</span>
                </div>
                <div class="aspect-ratio-box-inside">
                  <div class="fullscreen-center">
                    <icon-base color="white" width="24" height="24" class="mt-1">
                      <d-icon-circle-lock />
                    </icon-base>
                    <p class="processing-layer-text" v-html="$t('explorer.msgTranscoding')"></p>
                  </div>
                </div>
              </div>
              <d-image
                :aspect-ratio="16 / 9"
                :src="workflow.poster"
                @click="pauseVideo(), showStepList(workflow)"
                class="video-poster"
              ></d-image>
              <div class="video-name-div">
                <div class="video-name">{{ workflow.title }} {{ workflow.blocked }}</div>
                <div class="video-step-num text-capitalize">
                  <span class="font-bold">{{ getStepsLength(workflow.steps) }}</span>
                  {{ getStepsLength(workflow.steps) > 1 ? $t("all.steps") : $t("all.step") }}
                </div>
                <div v-if="workflow.id == selectedVideo" class="video-highlight-arrow">
                  <v-icon color="#4689f4" large>arrow_drop_down</v-icon>
                </div>
              </div>
            </div>
            <!-- steps-list -->
            <v-expand-transition>
              <v-card
                v-if="workflow.id == selectedVideo"
                class="steps-list"
                :style="{ height: expansionHeight + 'px' }"
                color="#191919"
              >
                <v-layout align-center row>
                  <div class="steps-arrow">
                    <button
                      :class="{ 'arrow-btn': showingStepGroup != 0 }"
                      :disabled="showingStepGroup == 0"
                      @click="pauseVideo(), showingStepGroup--"
                    >
                      <icon-base width="36" height="36">
                        <d-icon-arrow-back />
                      </icon-base>
                    </button>
                  </div>

                  <div class="steps-row" :style="{ height: expansionHeight + 'px' }">
                    <div class="steps-text text-capitalize">{{ $t("all.steps") }}</div>
                    <div class="steps-close">
                      <v-btn icon small @click="pauseVideo(), closeSteps()">
                        <icon-base color="white" height="18" width="18">
                          <d-icon-close2 />
                        </icon-base>
                      </v-btn>
                    </div>
                    <div class="steps-row-video">
                      <div v-show="isLoading" class="steps-row-video--loading">
                        <v-progress-circular size="90" color="white" indeterminate />
                      </div>
                      <div v-for="idx in getNumInShowingGroup()" :key="idx" :style="{ width: getVideoWidth() }">
                        <v-scale-transition>
                          <v-card
                            flat
                            v-if="transitionControl[idx - 1] && showSteps"
                            class="step-div"
                            transition="scale-transition"
                            color="transparent"
                          >
                            <div class="video-container">
                              <d-video-secure
                                ref="videoPlayer"
                                class="video-player"
                                :poster="steps[getStepIndex(idx)].poster"
                                :signedSrc="getVideoMetadata(getStepIndex(idx))"
                                @ended="pauseVideo()"
                              ></d-video-secure>
                              <div
                                class="video-player video-btn-cover"
                                :class="{
                                  'video-btn-show': isInSelectedList(idx),
                                  'video-added-darken': isAddedToSteps(steps[getStepIndex(idx)].id),
                                }"
                              >
                                <button
                                  v-if="isAddedToSteps(steps[getStepIndex(idx)].id)"
                                  class="video-btn-check-added"
                                >
                                  <icon-base width="20" height="20">
                                    <d-icon-circle-checked />
                                  </icon-base>
                                </button>
                                <button
                                  v-else
                                  class="video-btn-check"
                                  @click="clickStep(steps[getStepIndex(idx)], workflow.id)"
                                >
                                  <icon-base v-if="isInSelectedList(idx)" width="20" height="20">
                                    <d-icon-circle-checked />
                                  </icon-base>
                                  <icon-base v-else width="20" height="20">
                                    <d-icon-circle-unchecked />
                                  </icon-base>
                                </button>

                                <div class="fullscreen-center">
                                  <button v-if="currentPlayerIdx != idx - 1" @click="playOrPauseVideo(idx - 1)">
                                    <icon-base color="white" width="70" height="70" viewBox="0 0 24 24">
                                      <d-icon-play />
                                    </icon-base>
                                  </button>
                                  <button v-else @click="playOrPauseVideo(idx - 1)">
                                    <icon-base
                                      v-if="!isPlaying"
                                      color="white"
                                      width="70"
                                      height="70"
                                      viewBox="0 0 24 24"
                                    >
                                      <d-icon-play />
                                    </icon-base>
                                    <icon-base v-else color="white" width="70" height="70" viewBox="0 0 24 24">
                                      <d-icon-pause />
                                    </icon-base>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="body-2 steo-name-div">
                              <div class="white--text text-xs-left text-no-wrap text-truncate step-name">
                                {{ getStepIndex(idx) + 1 }}.
                                {{ steps[getStepIndex(idx)].title }}
                              </div>
                              <div class="grey--text step-time">
                                {{ getTimeFormat(steps[getStepIndex(idx)].duration) }}
                              </div>
                            </div>
                          </v-card>
                        </v-scale-transition>
                      </div>
                    </div>
                  </div>
                  <div class="steps-arrow">
                    <button
                      :class="{ 'arrow-btn': (showingStepGroup + 1) * numInGroup < steps.length }"
                      :disabled="(showingStepGroup + 1) * numInGroup >= steps.length"
                      @click="pauseVideo(), showingStepGroup++"
                    >
                      <icon-base width="36" height="36">
                        <d-icon-arrow-forward />
                      </icon-base>
                    </button>
                  </div>
                </v-layout>
              </v-card>
            </v-expand-transition>

            <v-expand-transition>
              <div v-if="workflow.id == selectedVideo" :style="{ height: expansionHeight + 22 + 'px' }"></div>
            </v-expand-transition>
          </div>
          <v-expand-transition>
            <v-card flat v-show="selectedStepsId.length != 0" class="step-selected" color="#323338">
              <div class="step-selected-content">
                <span class="body-2 white--text mr-5">
                  <span class="primary--text">{{ selectedStepsId.length }}</span>
                  {{ selectedStepsId.length > 1 ? $t("all.steps") : $t("all.step") }} {{ $t("builder.stepsSelected") }}
                </span>
                <v-btn outline round color="#4689f4" class="body-2 text-capitalize btn-width" @click="clearSteps()">{{
                  $t("builder.clear")
                }}</v-btn>
                <v-btn
                  round
                  color="#4689f4"
                  class="elevation-0 body-2 white--text text-capitalize btn-width"
                  @click="insertSteps()"
                  >{{ $t("builder.insert") }}</v-btn
                >
              </div>
            </v-card>
          </v-expand-transition>
        </v-flex>
      </v-layout>
    </div>
  </section>
</template>

<script>
import DNoResult from "./ui_components/DNoResult.vue";
import IconBase from "./IconBase.vue";
import DIconClose2 from "./icons/DIconClose2.vue";
import DIconCircleUnchecked from "./icons/DIconCircleUnchecked.vue";
import DIconCircleChecked from "./icons/DIconCircleChecked.vue";
import DIconPlay from "./icons/DIconPlay.vue";
import DIconPause from "./icons/DIconPause.vue";
import DIconArrowForward from "./icons/DIconArrowForward.vue";
import DIconArrowBack from "./icons/DIconArrowBack.vue";
import DIconCircleLock from "./icons/DIconCircleLock.vue";
import DImage from "./ui_components/DImage.vue";
import MixinDB from "./MixinDB.vue";
import DVideoSecure from "./ui_components/DVideoSecure.vue";
import { fetchWorkflowSteps } from "@/server/api-server";
import Analytics from "@/js/analytics/analytics";

export default {
  name: "BuilderExplorer",
  watch: {
    showSteps: function () {
      if (this.showSteps) {
        this.setTransitionControl();
      }
    },
    searchInput: function () {
      if (this.showSteps) {
        this.pauseVideo();
        this.closeSteps();
      }
    },
    workflows: function () {
      this.getMoreWorkflows();
    },
  },
  props: {
    languageCode: String,
    workflowId: String,
    group: String,
    addedSteps: Array,
  },
  mixins: [MixinDB],
  mounted() {},
  data() {
    return {
      searchInput: "",
      steps: [],
      showSteps: false,
      videoTitle: "",
      selectedVideo: "",
      selectedSteps: [],
      selectedStepsId: [],
      showingStepGroup: 0,
      numInGroup: 5,
      isLoading: false,
      isPlaying: false,
      gotSteps: false,
      expansionHeight: 0,
      currentPlayerIdx: -1,
      transitionControl: [],
      previousNumInGroup: 0,
    };
  },
  components: {
    DNoResult,
    IconBase,
    DIconClose2,
    DIconCircleUnchecked,
    DIconCircleChecked,
    DIconPlay,
    DIconPause,
    DIconArrowForward,
    DIconArrowBack,
    DIconCircleLock,
    DImage,
    DVideoSecure,
  },
  methods: {
    handleScroll() {
      const areaToScroll = event.target.scrollHeight - event.target.offsetHeight;
      if (areaToScroll <= Math.ceil(event.target.scrollTop)) {
        const options = {
          organization: this.$organization,
          group: this.group,
        };
        this.$store.dispatch("getMoreWorkflows", options);
      }
    },
    isAddedToSteps(thisId) {
      let isinSteps = false;
      this.addedSteps.forEach((el) => {
        if (el.id == thisId) {
          isinSteps = true;
          return isinSteps;
        }
      });
      return isinSteps;
    },
    getLowestRezVideo(idx) {
      if (this.steps[idx].videos) {
        const lowest = Object.keys(this.steps[idx].videos).sort(function (a, b) {
          return a - b;
        })[0];
        return this.steps[idx].videos[lowest];
      } else {
        return this.steps[idx].video;
      }
    },
    isInSelectedList(idx) {
      const stepIdx = this.getStepIndex(idx);
      return this.selectedStepsId.indexOf(this.steps[stepIdx].id) != -1;
    },
    getNumInShowingGroup() {
      if (this.numInGroup * (this.showingStepGroup + 1) <= this.steps.length) {
        return this.numInGroup;
      } else {
        return this.steps.length % this.numInGroup;
      }
    },
    doSetTimeout(i) {
      const waitTime = 100 * i + 200;
      setTimeout(() => {
        this.transitionControl.push(this.showSteps);
      }, waitTime);
    },
    setTransitionControl() {
      this.transitionControl = [];
      for (let i = 0; i < 5; i++) {
        this.doSetTimeout(i);
      }
    },
    getVideoWidth() {
      return parseInt(100 / this.numInGroup) + "%";
    },
    getStepIndex(idx) {
      if (idx <= this.steps.length) {
        return idx - 1 + this.numInGroup * this.showingStepGroup;
      } else {
        return 0;
      }
    },
    pauseHidingVideo() {
      if (this.previousNumInGroup > this.numInGroup) {
        this.pauseVideo();
      }
    },
    getVideoNumInGroup() {
      this.previousNumInGroup = this.numInGroup;
      const windowWidth = window.innerWidth;
      if (windowWidth > 1264) {
        this.numInGroup = 5;
      } else if (windowWidth > 960) {
        this.numInGroup = 3;
      } else if (windowWidth > 600) {
        this.numInGroup = 2;
      } else {
        this.numInGroup = 1;
      }
      this.pauseHidingVideo();
    },
    getHeight() {
      this.expansionHeight = parseInt(((window.innerWidth - 188) / this.numInGroup / 16) * 9 + 110);
      // should find a better way later
    },
    insertSteps() {
      const steps = this.selectedSteps.map((step) => ({
        ...step,
        workflows: [...step.workflows, this.workflowId],
      }));
      this.$emit("addStepsEvent", steps);
      this.clearSteps();
      this.closeExplorer();
    },
    clearSteps() {
      this.selectedSteps = [];
      this.selectedStepsId = [];
    },
    clickStep(clickStep, workflowId) {
      const inArrayIndex = this.selectedStepsId.indexOf(clickStep.id);
      if (inArrayIndex === -1) {
        this.selectedSteps.push(clickStep);
        this.selectedStepsId.push(clickStep.id);
      } else {
        this.selectedSteps.splice(inArrayIndex, 1);
        this.selectedStepsId.splice(inArrayIndex, 1);
      }
    },
    playOrPauseVideo(idx) {
      const previousPlayerIdx = this.currentPlayerIdx;
      this.currentPlayerIdx = idx;
      if (this.isPlaying) {
        if (this.currentPlayerIdx == previousPlayerIdx) {
          this.isPlaying = false;
          this.$refs.videoPlayer[idx].pause();
        } else {
          this.$refs.videoPlayer[previousPlayerIdx].pause();
          this.$refs.videoPlayer[idx].play();
        }
      } else if (this.$refs.videoPlayer[idx]) {
        this.$refs.videoPlayer[idx].play();
        this.isPlaying = true;
      }
    },
    pauseVideo() {
      if (this.isPlaying) {
        this.isPlaying = false;
        this.$refs.videoPlayer[this.currentPlayerIdx].pause();
      }
    },
    getTimeFormat(time) {
      time = parseFloat(time).toFixed(0);
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;
      const hours = Math.floor(time / 3600);
      time = time - hours * 3600;
      function str_pad_left(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
      }
      const finalTime = str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
      return finalTime;
    },
    closeSteps() {
      this.selectedVideo = "";
      this.steps = [];
      this.showSteps = false;
    },
    getStepsLength(steps) {
      if (!steps) return 0;
      else return steps.length;
    },
    closeExplorer() {
      this.closeSteps();
      this.$emit("closeExplorer");
    },
    async showStepList(workflow) {
      this.isLoading = true;
      this.showSteps = false;
      this.showingStepGroup = 0;
      this.selectedVideo = workflow.id;
      const { ok, data } = await fetchWorkflowSteps(workflow.id);
      this.isLoading = false;
      if (!ok) {
        return;
      }
      this.steps = data.items;
      this.getVideoNumInGroup();
      this.getHeight();
      this.showSteps = true;
    },
    resizeWindow() {
      this.getVideoNumInGroup();
      this.getHeight();
    },
    getVideoMetadata(idx) {
      const metadata = {
        type: "step",
        rez: "hls",
        id: this.steps[idx].id,
        notTranscodedVideo: this.steps[idx].video,
      };

      if (this.steps[idx].videos) {
        const rez = Object.keys(this.steps[idx].videos)[0];
        metadata.rez = rez;
      }

      return metadata;
    },
    getMoreWorkflows() {
      //make sure there's at least some published or unpublished workflows
      const min = 20;
      if (this.workflows.length < min) {
        const options = {
          organization: this.$organization,
          quantity: 100,
          group: this.group,
        };
        this.$store.dispatch("getMoreWorkflows", options);
      }
    },
    setMatomoOnChangeTrack() {
      Analytics.setInputChangeTrack({
        category: "BuilderMain",
        action: "Edit Segmentation",
        name: "Search workflow in Add Step Popup",
      });
    },
  },
  mounted() {
    this.resizeWindow();
    this.$nextTick(() => {
      window.addEventListener("resize", this.resizeWindow);
    });
    this.getMoreWorkflows();
  },
  computed: {
    workflows() {
      // return only processed workflow
      return this.$store.state.workflows
        .filter((workflow) => {
          return (
            workflow.processed &&
            workflow.languageCode == this.languageCode &&
            workflow.id != this.workflowId &&
            this.group === workflow.group
          );
        })
        .filter((workflow) => {
          if (workflow.title.toLowerCase().includes(this.searchInput.toLowerCase())) {
            return true;
          } else if (workflow.keywords) {
            return workflow.keywords.toString().includes(this.searchInput);
          } else {
            return false;
          }
        });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeWindow);
  },
};
</script>

<style scoped>
.builder-explorer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.9);
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 99;
}
.video-explorer {
  position: fixed;
  top: 140px;
  width: 100%;
  height: calc(100% - 140px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 80px 72px 80px 80px;
}
.video-explorer:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.close-btn {
  position: fixed;
  top: 21px;
  left: 32px;
}
.tool-bar-icon {
  color: #52545d;
  height: 28px;
  width: 28px;
  padding: 3px;
  margin-right: 18px;
}
.tool-bar-icon:hover {
  color: #ffffff;
}
.search-div {
  margin-top: -70px;
  max-width: 610px;
  border-radius: 30px;
  background-color: #0d0d0d;
  padding-top: 2px;
}
.search-text ::v-deep .v-text-field__slot input::placeholder {
  font-weight: 300;
}
.video-div {
  position: relative;
  background-color: #191919;
  border: 1px solid #191919;
  margin: 0 11px 22px 11px;
}
.video-highlight {
  border: 2px solid #4689f4;
}
.video-poster {
  width: 100%;
  cursor: pointer;
  position: relative;
}
.darken-video-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1;
  pointer-events: none;
  opacity: 0.5;
}
.hover-card:hover .darken-video-hover {
  opacity: 0;
  transition: 0.3s;
}
.transcoding-block {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 0;
  padding-bottom: 56.25%;
}
.video-tag-trapezoid {
  position: absolute;
  border-bottom: 22px solid #4689f3;
  border-left: 22px solid transparent;
  border-right: 22px solid transparent;
  width: 114px;
  top: 21px;
  left: -25px;
  z-index: 3;
  transform: rotate(-45deg);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.video-tag-processing {
  border-bottom: 22px solid #ffbf18;
}
.video-tag-text {
  position: absolute;
  top: 6px;
  left: -8px;
  height: 10px;
  width: 80px;
  color: #ffffff;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  text-transform: capitalize;
}
.hover-card:hover .video-tag-trapezoid {
  opacity: 0.8;
}
.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
}
.processing-layer-text {
  color: #ffffff;
  width: 100%;
  padding: 0 8px;
  /* font-size: 14px; */
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
.video-name-div {
  height: 64px;
  padding: 6px;
}
.video-name {
  color: #ffffff;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 12px;
  overflow: hidden;
  height: 36px;
  line-height: 18px;
  word-wrap: break-word;
  word-break: break-all;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.video-step-num {
  color: #ffffff;
  text-align: left;
  font-size: 12px;
  opacity: 0.6;
}
.font-bold {
  font-weight: 500;
}

.video-highlight-arrow {
  position: relative;
  top: -10px;
}

.steps-list {
  position: absolute;
  width: 100%;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #191919;
  z-index: 1;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
}
.steps-arrow {
  width: 79px;
  padding: 90px 0;
}
.arrow-btn {
  color: white;
  opacity: 0.3;
}
.arrow-btn:hover {
  opacity: 1;
}
.steps-row {
  position: relative;
  width: calc(100% - 158px);
  padding-top: 60px;
}
.steps-row-video {
  position: relative;
  width: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  min-height: 170px;
}
.steps-row-video--loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-div {
  margin: 0 13px;
}
.steps-text {
  position: absolute;
  top: 20px;
  left: 11px;
  color: white;
  font-size: 20px;
  font-weight: 400;
}
.steps-close {
  position: absolute;
  top: 15px;
  right: 1px;
}
.video-container {
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 */
  height: 0px;
  position: relative;
  background-color: #0d0d0d;
}
.video-player {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.video-player:focus {
  outline: 0;
}
.video-btn-cover {
  border: 2px solid #4689f4;
  background-color: rgb(0, 0, 0, 0.5);
  visibility: hidden;
}
.video-container:hover .video-btn-cover {
  visibility: visible;
}
.video-btn-show {
  visibility: visible;
}
.video-added-darken {
  visibility: visible;
  border: 2px solid transparent;
}
.video-btn-check-added {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  opacity: 0.5;
  pointer-events: none;
}
.video-btn-check {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.steo-name-div {
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 7px;
  height: 50px;
}
.step-name {
  padding-top: 3px;
  width: calc(100% - 38px);
}
.step-time {
  position: absolute;
  right: 0;
}
.step-selected {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 3;
  height: 70px;
}
.step-selected-content {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn-width {
  width: 120px;
}
</style>
