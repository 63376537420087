import { render, staticRenderFns } from "./DShareDownload.vue?vue&type=template&id=ff41e44c&scoped=true&"
import script from "./DShareDownload.vue?vue&type=script&lang=js&"
export * from "./DShareDownload.vue?vue&type=script&lang=js&"
import style0 from "./DShareDownload.vue?vue&type=style&index=0&id=ff41e44c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff41e44c",
  null
  
)

export default component.exports