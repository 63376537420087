<template>
  <div class="AttachmentsBarList" :class="{ 'AttachmentsBarList--show': showAttachmentsBar }">
    <div
      class="AttachmentsBarList__list"
      :class="{ 'AttachmentsBarList__list--upper': upper }"
      @mouseleave="$emit('hide-list')"
    >
      <div class="AttachmentsBarList__listScroll">
        <div
          v-for="(data, index) in getter_attachments[currentStep]"
          :key="data.type"
          class="AttachmentsBarList__listScroll--item"
        >
          <!-- DIAGRAMS -->
          <div v-if="data.type === ADDON_TYPE.DIAGRAM">
            <div class="AttachmentsBarList__label">
              <icon-base width="12" height="12" class="AttachmentsBarList__label--icon">
                <d-icon-diagram />
              </icon-base>
              {{ $t("player.diagrams") }}
            </div>
            <div
              v-for="(imgURL, idx) in data.diagrams"
              :key="'diagram' + idx + imgURL"
              class="mb-4"
              @click="onclickOpenAttachmentsPopup(idx)"
            >
              <attachment-diagram-card :imgURL="imgURL" />
            </div>
          </div>
          <!-- LINKS -->
          <div v-if="data.type === ADDON_TYPE.LINKS">
            <div class="AttachmentsBarList__label mt-4">
              <icon-base width="14" height="14" class="AttachmentsBarList__label--icon">
                <d-icon-link />
              </icon-base>
              {{ $t("builder.links") }}
            </div>
            <div v-for="(item, idx) in data.links" :key="data.type + idx">
              <attachment-link-card :title="item.label" :link="item.link" />
            </div>
          </div>
          <!-- PDF -->
          <div v-if="data.type === ADDON_TYPE.PDF">
            <div class="AttachmentsBarList__label mt-4">
              <icon-base width="14" height="14" class="AttachmentsBarList__label--icon">
                <d-icon-pdf />
              </icon-base>
              {{ $t("player.pdfFiles") }}
            </div>
            <div v-for="(item, idx) in data.pdfs" :key="data.type + idx">
              <attachment-pdf-card :item="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import IconBase from "@/components/IconBase";
import DIconDiagram from "@/components/icons/player/DIconDiagram.vue";
import DIconLink from "@/components/icons/player/DIconLink";
import DIconPdf from "@/components/icons/DIconPdf.vue";
import AttachmentDiagramCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentDiagramCard.vue";
import AttachmentLinkCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentLinkCard.vue";
import AttachmentPdfCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentPdfCard.vue";
import { ADDON_TYPE } from "@/constants/attachmentStatus";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AttachmentsBarList",
  props: {
    currentStep: Number,
    showAttachmentsBar: Boolean,
    upper: Boolean,
  },
  components: {
    IconBase,
    DIconDiagram,
    DIconLink,
    DIconPdf,
    AttachmentDiagramCard,
    AttachmentLinkCard,
    AttachmentPdfCard,
  },
  created() {},
  data() {
    return {
      ADDON_TYPE,
    };
  },
  methods: {
    ...mapActions("workflowPlayer", ["openAttachmentsPopup", "setSelectedAttachmentStep"]),
    onclickOpenAttachmentsPopup(idx) {
      let attachmentStep = this.getters_steps[this.currentStep];
      attachmentStep["stepIndex"] = this.currentStep;
      this.setSelectedAttachmentStep({ step: attachmentStep });
      this.openAttachmentsPopup({ selectedDiagramIdx: idx, isOpen: true });
    },
  },
  computed: {
    ...mapGetters("workflowPlayer", ["getter_attachments", "getters_steps"]),
  },
};
</script>
    
<style lang="scss" scoped>
.AttachmentsBarList {
  position: absolute;
  left: 0;
  width: 0;
  top: 0;
  height: 100%;
  transition: 0.3s;
  pointer-events: none;
  background: #12131426;
  &--show {
    width: 100%;
    transition: 0.3s;
  }
  &--upper {
    top: 14px;
    height: calc(100% - 128px);
  }
  &__list {
    $bottomControlsHeight: 128px;
    $topLower: 90px;
    $topUpper: 14px;
    position: absolute;
    left: 0;
    width: 100%;
    top: $topLower;
    height: calc(100% - #{$bottomControlsHeight} - #{$topLower});
    pointer-events: none;
    transition: 0.3s;
    pointer-events: auto;
    overflow: hidden;
    &--upper {
      top: $topUpper;
      height: calc(100% - #{$bottomControlsHeight} - #{$topUpper});
    }
  }
  &__listScroll {
    position: absolute;
    left: 32px;
    width: 184px;
    padding-right: 4px;
    top: 60px;
    height: calc(100% - 60px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: #585858;
    }
    &--item {
      position: relative;
      width: 100%;
      min-height: 100px;
      margin-bottom: 10px;
    }
  }
  &__label {
    position: relative;
    text-align: left;
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
    white-space: nowrap;
    &--icon {
      margin-bottom: -1px;
      margin-right: 6px;
    }
  }
}
</style>
    