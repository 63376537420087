<template>
  <div class="d-admin-permissions">
    <div class="tab-title-row center-horizontally">
      <div class="text-no-wrap content-title text-style text-truncate">
        {{ $t("all.defaultInterfaceLanguage") }}
      </div>
      <v-spacer></v-spacer>
    </div>

    <div class="table-div">
      <d-data-table
        add-search
        local-search
        initial-sort
        :headers="tableHeader"
        :items="workspacesData"
        :loading="isLoading"
        :placeholder="$t('admin.searchForWorkspaces')"
        searchAutotest="workspace-search-bar"
      >
        <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
        <template slot="headerCell" slot-scope="props">
          <span class="head-text">{{ props.header.text }}</span>
        </template>
        <template slot="items" slot-scope="props">
          <td class="text-xs-left" :style="{ width: '40%' }">
            <span class="name-text" autotest="workspace-name-display">{{ props.item.name }}</span>
          </td>
          <!-- <td class="text-xs-left" :style="{'width':'30%'}">{{props.item.language}}</td> -->
          <td class="text-xs-left">
            <v-menu transition="slide-y-transition" offset-y autotest="workspace-language-dropdown">
              <button slot="activator" class="role-btn text-capitalize btn-hover">
                {{ languageFullName[props.item.locale] || "" }}
                <v-icon small class="dropdown-arrow">keyboard_arrow_down</v-icon>
              </button>
              <v-list dense>
                <div v-for="(lang, idx) in languageList" :key="idx">
                  <v-list-tile
                    v-if="props.item.locale != lang"
                    class="dropdown-list-item body-1 text-capitalize"
                    @click="(editWorkspaceData = props.item), (editLanguage = lang), (showChangeLanguage = true)"
                  >
                    {{ languageFullName[lang] }}
                  </v-list-tile>
                </div>
              </v-list>
            </v-menu>
          </td>
        </template>
        <template slot="no-data">
          <span>{{ $t("admin.msgNoWorkspacesYet") }}</span>
        </template>
        <template slot="no-results">
          <span>{{ $t("analytics.msgNoSearchResults") }}</span>
        </template>
      </d-data-table>
    </div>

    <d-confirm-popup
      v-model="showChangeLanguage"
      :title="$t('admin.changeLanguage')"
      :cancelBtn="$t('all.cancel')"
      :actionBtn="$t('admin.YesChangeBtn')"
      :actionBtnColor="'#4689F3'"
      @click-action-btn="changeDefaultLanguage()"
    >
      <template slot="icon">
        <icon-base width="120" height="120" viewBox="0 0 96 120">
          <d-icon-mapping />
        </icon-base>
      </template>
      <template slot="content">
        <span v-html="$t('admin.popupMsgChangeLanguage', { name: editWorkspaceData.name })"></span>
      </template>
    </d-confirm-popup>

    <d-alert v-model="alert.isSuccess" type="success" :message="alert.alertText"></d-alert>
    <d-alert v-model="alert.isFailed" type="error" :message="alert.alertText"></d-alert>
  </div>
</template>

<script>
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import IconBase from "@/components/IconBase.vue";
import DIconDone from "@/components/icons/DIconDone.vue";
import DIconTrash from "@/components/icons/DIconTrash.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import DIconMapping from "@/components/icons/colored/DIconMapping.vue";
import DConfirmPopup from "@/components/DPopup/DConfirmPopup.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import AdminSubCategory from "@/components/DAdmin/AdminSubCategory.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import DIconWorkspaces from "../icons/DIconWorkspaces.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";

export default {
  name: "AdminLanguages",
  props: {},
  watch: {},
  mixins: [MixinUser, MixinDB, MixinAdmin],
  mounted() {
    this.languageList = this.$i18nLocales;
    this.languageFullName = this.$i18nUiLocales;
    this.getGroupList();
  },
  data() {
    return {
      searchInput: "",
      tableHeader: [
        {
          text: this.$t("admin.workspace"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "workspace-sort",
        },
        {
          text: this.$t("all.interfaceLanguage"),
          align: "left",
          sortable: false,
          value: "language",
        },
      ],
      workspacesData: [],
      // languageFullName: {
      //   en: "English",
      //   'en-us': "English",
      //   zh: "中文",
      //   es: "Español",
      //   jp: "日本語",
      //   de: "Deutsche",
      //   "pt-br": "Português",
      //   "fr-fr": "Français",
      // },
      languageFullName: {},
      languageList: [],
      // languageList: [
      //   "en",
      //   "zh",
      // ],
      showChangeLanguage: false,
      editWorkspaceData: {},
      editLanguage: "",
      alert: {
        isSuccess: false,
        isFailed: false,
        alertText: "",
      },
      isLoading: false,
    };
  },
  components: {
    IconBase,
    DIconDone,
    DIconTrash,
    DIconPen,
    DIconMapping,
    DConfirmPopup,
    DConfirmationPopup,
    AdminSubCategory,
    DAlert,
    DIconWorkspaces,
    DDataTable,
  },
  methods: {
    getGroupList() {
      this.isLoading = true;
      this.fetchMyGroups()
        .then((data) => {
          data.forEach((group) => {
            if (group) {
              this.workspacesData.push({
                id: group.id,
                name: group.name,
                locale: group.locale,
                business: group.business,
              });
            }
          });
          this.workspacesData.sort(function (a, b) {
            const groupA = a.name.toUpperCase();
            const groupB = b.name.toUpperCase();
            if (groupA < groupB) {
              return -1;
            }
            if (groupA > groupB) {
              return 1;
            }
            return 0;
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchMyGroups() {
      const self = this;
      if (self.$role == "admin-organization") {
        // self.userGroupList.push({ name: "All", id: "All" });
        return self.fetchAllGroups();
      }
      return new Promise(function (resolve, reject) {
        let myGroups = [];
        let promises = [];

        //myGroups = self.$userProfile.groups;
        for (const groupId in self.$userProfile.roles) {
          const role = self.$userProfile.roles[groupId];
          if (role != "viewer") {
            myGroups.push(groupId);
          }
        }

        myGroups.forEach((groupId) => {
          promises.push(self.getDocument("groups", groupId));
        });
        Promise.all(promises)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            alert("error getting workspace list.");
          });
      });
    },
    fetchAllGroups() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.getDocumentByFieldValue("groups", "organization", self.$organization).then((data) => {
          resolve(data);
        });
      });
    },
    changeDefaultLanguage() {
      this.alert.isSaving = true;
      const self = this;
      const lang = self.editLanguage;
      const id = self.editWorkspaceData.id;

      this.updateGroupData()
        .then(() => {
          // change workspaceData
          let workspace = this.workspacesData.find((obj) => {
            return obj.id === id;
          });

          workspace.locale = lang;

          self.alert.alertText = self.$t("admin.alertLanguageSuccessfullyChanged");
          self.alert.isSaving = false;
          self.alert.isSuccess = true;
        })
        .catch((err) => {
          alert(err);
          self.alert.isSaving = false;
          self.alert.isNotSuccess = true;
          self.alert.alertText = self.$t("admin.alertFailedChangeLanguage");
        });
    },
    updateGroupData() {
      const self = this;
      return new Promise(async function (resolve, reject) {
        const data = {
          locale: self.editLanguage,
          groupId: self.editWorkspaceData.id,
          name: self.editWorkspaceData.name,
          business: self.editWorkspaceData.business,
        };
        //resolve();
        self
          .sendAdminRequest("change", "group-change", data)
          .then((response) => {
            resolve();
          })
          .catch((err) => {
            self.isEditGroupFailed = true;
            reject(err);
          });
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
.d-admin-permissions {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  text-align: left;
  z-index: 0;
}
.tab-title-row {
  position: relative;
  width: 100%;
  height: 40px;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table-div {
  position: absolute;
  top: 86px;
  right: 32px;
  height: calc(100% - 244px);
  width: calc(100% - 64px);
  overflow: auto;
}
.table-div:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.text-style {
  font-family: Roboto;
  letter-spacing: 0;
}
.content-title {
  color: #3f3f3f;
  font-size: 32px;
  line-height: 32px;
}
.head-text {
  text-transform: uppercase;
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
.table-btn {
  color: #78767a;
}
.table-btn:hover {
  color: #2d2e32;
}
.name-text {
  color: #3f3f3f;
}
.role-btn {
  position: relative;
  background-color: white;
  height: 34px;
  width: 174px;
  text-align: left;
  padding: 0 8px;
  border: 1px solid #dadada;
}
.dropdown-arrow {
  position: absolute;
  right: 8px;
  color: #78767a;
  top: 8px;
}
.dropdown-list-item {
  color: #78767a;
}
.dropdown-list-item:hover {
  background-color: #f5f5f5 !important;
}
</style>
