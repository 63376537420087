<template>
  <section :style="drawerOpenPopupStyle" class="user-invite-create">
    <v-card width="466" color="#FFFFFF" class="popup-box fullscreen-center">
      <button class="close-btn" @click="$emit('closePopup')">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="title-div">
        <icon-base width="120" height="120">
          <d-icon-user-role />
        </icon-base>
        <div>
          <div class="title-text font-weight-medium">{{ $t("admin.popupTitleChangeUserRole") }}</div>
          <!-- Are you sure you want to change <b>{name}'s</b> role from <b>{oldRole}</b> to <b>{newRole}</b>? -->
          <div
            class="subtitle-text"
            v-html="
              $t('admin.popupMsgChangeUser', {
                name: formData.displayName,
                oldRole: roleDictionary[oldRole],
                newRole: roleDictionary[newRole],
              })
            "
          ></div>
        </div>
      </div>
      <v-layout xs12>
        <v-flex xs6 mr-2>
          <v-btn
            outline
            depressed
            round
            block
            class="body-2 text-capitalize"
            color="#4689f4"
            @click="$emit('closePopup')"
            >{{ $t("all.no") }}</v-btn
          >
        </v-flex>
        <v-flex xs6 ml-2>
          <v-btn
            depressed
            round
            block
            class="body-2 text-capitalize white--text no-text-transform"
            color="#4689f4"
            @click="$emit('changeRole')"
            >{{ $t("admin.popupBtnChange") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>

    <div class="popup-close" @click="$emit('closePopup')"></div>
  </section>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconUserRole from "../icons/colored/DIconUserRole.vue";
import DIconClose2 from "../icons/DIconClose2.vue";

export default {
  name: "DChangeUserRoleConfirmation",
  props: {
    oldRole: String,
    newRole: String,
    formData: Object,
    roleDictionary: Object,
    drawerOpenPopupStyle: Object,
  },
  components: {
    IconBase,
    DIconUserRole,
    DIconClose2,
  },
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.popup-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 24px 24px 0 24px;
  margin-bottom: 24px;
}

.title-text {
  color: #52545d;
  font-family: Roboto;
  /* font-size: 20px; */
  font-size: 24px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 4px;
}
.subtitle-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 20px;
  margin-bottom: 72px;
}
.subtitle-text ::v-deep b {
  font-weight: 500 !important;
}
.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.no-text-transform {
  text-transform: none !important;
}
</style>
