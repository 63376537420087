export const ANNOTATION_TYPE = {
  PEN: "path",
  ARROW: "arrow",
  TEXT: "i-text",
  SELECT: "select",
  LINE: "line",
  CIRCLE: "circle",
  STICKER: "sticker",
};

export const ANNOTATION_DB_FABRIC_KEY_MAP = {
  // [DB]: "Fabric"
  backgroundColor: "backgroundColor",
  fill: "fill",
  strokeWidth: "strokeWidth",
  strokeColor: "stroke",
  fontWidth: "fontWidth",
  fontStyle: "fontStyle",
  endTime: "endTime",
  startTime: "startTime",
  fontWeight: "fontWeight",
};

export const ANNOTATION_COLOR = {
  PRIMARY: "#4689f4",
  BLACK: "#ffffff",
  WHITE: "#000000",
  RED: "#ff0600",
  GREEN: "#00ff00",
  YELLOW: "#ffff00",
};

export const ANNOTATION_LINE_WIDTH = {
  THIN: "THIN",
  BOLD: "BOLD",
  NORMAL: "NORMAL",
};

export const ANNOTATION_LINE_WIDTH_VALUE = {
  [ANNOTATION_LINE_WIDTH.THIN]: 4,
  [ANNOTATION_LINE_WIDTH.NORMAL]: 6,
  [ANNOTATION_LINE_WIDTH.BOLD]: 8,
};


export const ANNOTATION_TEXT_STYLE = {
  BOLD: "BOLD",
  ITALIC: "ITALIC",
};

export const LINEAR_STATUS = [ANNOTATION_TYPE.LINE, ANNOTATION_TYPE.ARROW];

export const BEHAVIOR_STATUS = {
  MOVING: "moving",
  MUTATE_START_VECTOR: "mutate start vector",
  MUTATE_END_VECTOR: "mutate end vector",
  MUTATE_TOP_RIGHT_VECTOR: "mutate top right vector",
  MUTATE_TOP_LEFT_VECTOR: "mutate left right vector",
  MUTATE_BOTTOM_RIGHT_VECTOR: "mutate bottom right vector",
  MUTATE_BOTTOM_LEFT_VECTOR: "mutate bottom left vector",
};

export const RECT_POSITION = {
  BOTTOM_LEFT_VECTOR: "bottomLeftVector",
  BOTTOM_RIGHT_VECTOR: "bottomRightVector",
  TOP_RIGHT_VECTOR: "topRightVector",
  TOP_LEFT_VECTOR: "topLeftVector",
};
