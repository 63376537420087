<template>
  <div class="erase">
    <div class="erase__video">
      <d-video-secure
        ref="eraseVideoPlayer"
        crossorigin="anonymous"
        :style="{ height: '100%', width: '100%' }"
        :poster="videoPoster"
        :signedSrc="videoSource"
        @ended="handleVideoPause"
        @timeupdate.native="handleVideoOnTimeUpdate"
        @waiting="isShowSpinner = true"
        @canplay="isShowSpinner = false"
        @loadedmetadata="fetchVideoData"
      />
      <div
        class="erase__video--icon"
        :class="{ 'show-btn': !isVideoPlaying }"
        :style="{ height: videoIconConfig.height }"
        @click="pauseOrPlayVideo"
      >
        <v-progress-circular v-if="isShowSpinner" :size="70" color="white" indeterminate />
        <icon-base v-else color="white" width="96" height="96">
          <d-icon-play v-if="!isVideoPlaying" />
          <d-icon-pause v-else />
        </icon-base>
      </div>
    </div>

    <div class="erase__progress-bar" @click="checkIsWorkflowPublished">
      <ZoomTimeline
        type="erase"
        :currentTime="currentTime"
        :imageMapId="currentStep.imageMap"
        :videoEndTime="videoDuration"
        :startTime="progressData.startTime"
        :endTime="progressData.endTime"
        :zoomValue="zoomValue"
        @handleZoomMaxValue="handleZoomMaxValue"
        @handleVideoSetCurrentTime="handleVideoSetCurrentTime"
        @handleProgressDataChange="handleProgressDataChange"
        @trackInputRangeChangeByHeap="trackInputRangeChangeByHeap"
      />

      <div v-if="!currentStep.videos" class="erase__progress-bar--warning-cover">
        <icon-base color="white" width="30" height="30">
          <d-icon-circle-lock />
        </icon-base>
        <p class="processing-layer-text mt-2" v-html="$t('builder.eraseProcessingMsg')" />
      </div>
    </div>

    <div class="erase__console">
      <div class="erase__console--left">
        <span class="title">{{ consoleTitle }}</span>
        <button
          class="arrow"
          :class="{ inactive: currentStepIndex === 0 }"
          :disabled="currentStepIndex === 0"
          @click="$emit('update:currentStepIndex', currentStepIndex - 1)"
        ></button>
        <button
          class="arrow"
          :class="{ inactive: currentStepIndex === steps.length - 1 }"
          :disabled="currentStepIndex === steps.length - 1"
          @click="$emit('update:currentStepIndex', currentStepIndex + 1)"
        ></button>
      </div>
      <div class="erase__console--middle">
        <v-btn outline round color="#4689f4" class="body-2 text-capitalize" @click="setDefaultData">{{
          $t("new.cancel")
        }}</v-btn>
        <v-btn
          :disabled="!isAllowedErase"
          round
          color="#4689f4"
          class="body-2 text-capitalize white--text"
          @click="handleConfirmPopup('erase')"
          >{{ $t("editor.erase") }}</v-btn
        >
      </div>
      <div class="erase__console--right">
        <builder-reset-button @click="handleConfirmPopup('reset')" :is-clickable="isDisabledResetButton" />
        <Zoom
          v-model="zoomValue"
          :max="zoomMaxValue"
          :disabled="isPublished"
          @trackZoomChangeByHeap="trackZoomChangeByHeap"
        />
      </div>
    </div>

    <d-confirmation-popup
      v-model="confirmPopupConfig.isShow"
      :title="confirmPopupConfig.title"
      :cancelBtnName="confirmPopupConfig.cancelBtnName"
      :actionBtnName="confirmPopupConfig.actionBtnName"
      :content="confirmPopupConfig.content"
      @clickActionBtn="handleClickConfirm"
    />

    <v-dialog dark persistent v-model="isShowTranscodingPopup" max-width="460px">
      <v-card color="#1E1F22" height="280px" class="pa-0 text-xs-center">
        <v-card-title class="title font-weight-regular grey--text">
          {{ $t("builder.eraseStep") }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="workflow.blocked === 'transcoding'" v-extend-idle:builderErase.mixinIdleRef="this">
          <h3 class="font-weight-regular mt-3 pb-2">
            <!-- Transcoding is in process. -->
            {{ $t("builder.popupMsgTranscoding") }}
          </h3>
          <d-icon-processing-bar-flex height="8px" width="80%" class="placeholder-bar" />
          <h3 class="font-weight-regular pt-2">
            {{ $t("builder.popupMsgTrimBackLater") }}
            <!-- You can resume editing the workflow
            <br>after the transcoding is complete.-->
          </h3>
        </v-card-text>
        <v-card-text v-else>
          <img alt="check_circle.png" class="mt-3" width="70px" height="70px" :src="check_circle_path" />
          <h3 class="font-weight-regular pb-2">
            {{ $t("builder.popupMsgEraseSuccess") }}
            <!-- Your Step was successfully trimmed! -->
          </h3>
        </v-card-text>
        <v-card-actions v-if="workflow.blocked !== 'transcoding'" class="pr-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            round
            depressed
            class="caption white--text text-capitalize"
            color="#4689f4"
            @click="isShowTranscodingPopup = false"
            >{{ $t("builder.done") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <d-alert v-model="dAlertData.isShow" :type="dAlertData.type" :message="dAlertData.message" />
  </div>
</template>

<script>
import DVideoSecure from "@/components/ui_components/DVideoSecure.vue";
import IconBase from "@/components/IconBase.vue";
import DIconPlay from "@/components/icons/DIconPlay.vue";
import DIconPause from "@/components/icons/DIconPause.vue";
import BuilderResetButton from "@/components/DBuilder/BuilderResetButton";
import Zoom from "@/components/DEditorBuilder/Zoom/Zoom";
import { postStepResetVideo } from "@/server/step-server";
import { postStepEraseVideo } from "@/server/video-server";
import DIconProcessingBarFlex from "@/components/icons/animated/DIconProcessingBarFlex.vue";
import check_circle_path from "@/img/check_circle.png";
import ZoomTimeline from "@/components/DEditorBuilder/Zoom/ZoomTimeline";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import { getCurrentEndTime } from "@/js/video-player/video-player-time.js";
import DAlert from "@/components/ui_components/DAlert.vue";
import DIconCircleLock from "@/components/icons/DIconCircleLock.vue";
import MixinIdle from "@/components/MixinIdle.vue";
import DExtendIdle from "@/directives/DExtendIdle.js";
import Analytics from "@/js/analytics/analytics";

export default {
  name: "BuilderErase",
  components: {
    DVideoSecure,
    IconBase,
    DIconPlay,
    DIconPause,
    BuilderResetButton,
    Zoom,
    DIconProcessingBarFlex,
    ZoomTimeline,
    DConfirmationPopup,
    DAlert,
    DIconCircleLock,
  },
  mixins: [MixinIdle],
  directives: {
    "extend-idle": DExtendIdle,
  },
  props: {
    videoPoster: {
      type: String,
      default: () => "",
    },
    videoSource: {
      type: Object,
      default: () => {},
    },
    madeChanges: {
      type: Boolean,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      default: () => 0,
    },
    steps: {
      type: Array,
      required: true,
    },
    handleSaveAllChange: {
      type: Function,
      required: true,
    },
    isPublished: {
      type: Boolean,
      default: () => false,
    },
    workflow: {
      type: Object,
      required: true,
    },
  },
  data() {
    const playerConfig = {
      currentTime: 0,
      isShowSpinner: true,
      isVideoPlaying: false,
      videoDuration: 0,
    };
    const videoIconConfig = {
      height: this.$vuetify.breakpoint.width > 1440 ? "570px" : "445px",
      width: "900",
    };
    const confirmPopupConfig = {
      type: "reset",
      isShow: false,
      title: this.$t("builder.resetStep"),
      cancelBtnName: this.$t("all.no"),
      actionBtnName: this.$t("builder.popupBtnReset"),
      content: this.$t("builder.popupMsgResetStep"),
    };
    const dAlertData = {
      isShow: false,
      type: "",
      message: "",
    };
    return {
      ...playerConfig,
      videoIconConfig,
      confirmPopupConfig,
      dAlertData,
      progressData: {
        startTime: 0,
        endTime: 0,
      },
      zoomValue: 100,
      zoomMaxValue: 100,
      isShowTranscodingPopup: false,
      check_circle_path,
    };
  },
  methods: {
    checkIsWorkflowPublished() {
      if (this.isPublished) {
        this.$emit("showEditWorkflowPopup");
      }
      return this.isPublished;
    },
    setDefaultData() {
      const startTime = this.videoDuration * 0.45;
      this.progressData.startTime = startTime;
      this.$refs.eraseVideoPlayer.$el.currentTime = startTime;
      this.currentTime = startTime;
      this.progressData.endTime = this.videoDuration * 0.55;
      this.zoomValue = 100;
    },
    fetchVideoData(event) {
      if (!event) return;
      const { duration } = event.target;
      this.videoDuration = this.progressData.endTime = +duration.toFixed(2);
      this.handleVideoPause();
      this.setDefaultData();
      if (this.$refs.eraseVideoPlayer) {
        this.videoIconConfig.height = this.$refs.eraseVideoPlayer.$el.clientHeight + "px";
        this.videoIconConfig.width = this.$refs.eraseVideoPlayer.$el.clientWidth + "px";
      }
    },
    handleConfirmPopup(type = "erase") {
      if (this.checkIsWorkflowPublished()) return;
      switch (type) {
        case "erase":
          this.confirmPopupConfig.title = this.$t("builder.eraseStep");
          this.confirmPopupConfig.actionBtnName = this.$t("builder.popupBtnYesErase");
          this.confirmPopupConfig.content = this.$t("builder.popupMsgEraseConfirmation");
          break;
        case "reset":
        default:
          this.confirmPopupConfig.title = this.$t("builder.resetStep");
          this.confirmPopupConfig.actionBtnName = this.$t("builder.popupBtnReset");
          this.confirmPopupConfig.content = this.$t("builder.popupMsgResetStep");
      }
      this.confirmPopupConfig.type = type;
      this.confirmPopupConfig.isShow = true;
    },
    handleClickConfirm() {
      switch (this.confirmPopupConfig.type) {
        case "reset":
          this.resetStep();
          break;
        case "erase":
          this.eraseStep();
      }
    },
    async eraseStep() {
      const stepId = this.currentStep.id;
      const workflowId = this.$route.params.id;
      const startTime = +this.progressData.startTime.toFixed(2);
      const endTime = getCurrentEndTime(
        +this.currentStep.videoDuration,
        +this.videoDuration,
        +this.progressData.endTime.toFixed(2)
      );

      if (this.madeChanges) {
        await this.handleSaveAllChange({
          published: false,
          unpublishedAlert: false,
        });
      }

      postStepEraseVideo({
        workflowId,
        stepId,
        startTime,
        endTime,
      });

      Analytics.setTrack(`Web - Builder - Click - Confirm Erase`, null, {
        category: "BuilderMain",
        action: "Edit Video",
        name: "Confirm Erase",
      });
    },
    pauseOrPlayVideo() {
      if (this.isVideoPlaying) {
        this.handleVideoPause();
      } else {
        this.isVideoPlaying = true;
        this.$refs.eraseVideoPlayer.play();
      }
    },
    async resetStep() {
      const step_id = this.currentStep.id;
      const workflow_id = this.$route.params.id;
      const { ok, message } = await postStepResetVideo({
        step_id,
        workflow_id,
      });

      if (ok) {
        Analytics.setTrack(`Web - Builder - Click - Reset to Original`, null, {
          category: "BuilderMain",
          action: "Edit Video",
          name: "Confirm Reset to Original",
        });
      } else {
        console.error(message);
      }
    },
    handleVideoPause() {
      if (this.isVideoPlaying) {
        this.isVideoPlaying = false;
        this.$refs.eraseVideoPlayer.pause();
      }
    },
    handleVideoOnTimeUpdate(e) {
      this.currentTime = e.target.currentTime;
    },
    handleZoomMaxValue(zoomMaxValue) {
      this.zoomMaxValue = zoomMaxValue;
    },
    handleVideoSetCurrentTime(timeSecond) {
      if (this.checkIsWorkflowPublished()) return;
      this.$refs.eraseVideoPlayer.$el.currentTime = timeSecond;
      this.currentTime = timeSecond;
    },
    handleProgressDataChange({ name, value }) {
      if (this.checkIsWorkflowPublished()) return;
      this.progressData[name] = value;
    },
    trackInputRangeChangeByHeap() {
      Analytics.setTrack(`Web - Builder - Click - Edit Erase`, null, {
        category: "BuilderMain",
        action: "Edit Video",
        name: "Adjust Erase Time in BuilderMain",
      });
    },
    trackZoomChangeByHeap() {
      Analytics.setTrack(`Web - Builder - Click - Zoom In/Out`, null, {
        category: "BuilderMain",
        action: "Edit Video",
        name: "Zoom In/Out",
      });
    },
  },
  computed: {
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    isDisabledResetButton() {
      const { firstVersion, origin, id } = this.steps[this.currentStepIndex];
      return (firstVersion || origin) !== id;
    },
    consoleTitle() {
      return `${this.$t("builder.workingOn")}: ${this.$t("all.step")} ${this.currentStepIndex + 1} ${
        this.currentStep.title
      }`;
    },
    isAllowedErase() {
      return (
        !!this.currentStep.videos &&
        (this.progressData.startTime > 0 || this.progressData.endTime !== +this.videoDuration.toFixed(2))
      );
    },
  },
  watch: {
    "workflow.convertStatus"(convertStatus) {
      switch (convertStatus) {
        case "finished":
          this.dAlertData.type = "success";
          this.dAlertData.message = this.$t("editor.alertIsSaved");
          break;
        case "error":
          this.dAlertData.type = "error";
          this.dAlertData.message = this.$t("builder.alertSaveFailed");
          this.isShowTranscodingPopup = false;
          break;
      }
      if (["finished", "error"].includes(convertStatus)) {
        this.dAlertData.isShow = true;
      }
    },
    "workflow.blocked": {
      immediate: true,
      handler(blockMsg) {
        if (blockMsg === "transcoding") {
          this.isShowTranscodingPopup = true;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.erase {
  height: 100%;
  padding-top: 32px;
  --video-height: calc(100% - var(--control-height) - var(--progress-bar-height) - var(--tab-height));
  --video-width: 900px;
  --progress-bar-height: 130px;
  --tab-height: 140px;
  --control-height: 72px;
  --radius: 20px;

  @media screen and (max-height: 900px) {
    --video-width: 700px;
    --progress-bar-height: 100px;
  }

  &__video {
    position: relative;
    margin: 0 auto;
    height: var(--video-height);
    width: var(--video-width);

    &--icon {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;

      &:hover,
      &.show-btn {
        opacity: 1;
      }
    }
  }

  &__progress-bar {
    height: var(--progress-bar-height);
    background-color: black;
    padding: 1rem 2.5rem;
    position: relative;

    &--warning-cover {
      --cover-tool-tip: 25px;
      position: absolute;
      top: calc(var(--cover-tool-tip) * -1);
      left: 0;
      background-color: #1e1f22e8;
      width: 100%;
      z-index: 1010;
      color: #fff;
      height: calc(100% + var(--cover-tool-tip));

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &__console {
    height: var(--control-height);
    color: #fff;
    background-color: rgb(44, 45, 50);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    font-size: 16px;

    & > div {
      flex: 1;
    }

    &--left {
      display: flex;
      align-items: center;
      --rotate-degree: rotate(135deg);
      --color: #fff;
      .arrow {
        &:last-child {
          --rotate-degree: rotate(-45deg);
          margin: 0;
        }
        &.inactive {
          --color: #515151;
        }
        position: relative;
        display: inline-block;
        width: var(--radius);
        height: var(--radius);
        margin: 0 0.5rem 0 1rem;
        &:before,
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:before {
          color: var(--color);
          border: 1px solid var(--color);
          border-radius: 50%;
        }

        &:after {
          display: block;
          border-right: 4px solid var(--color);
          border-bottom: 4px solid var(--color);
          transform: var(--rotate-degree) scale(0.3) translate(-20%, -20%);
        }
      }

      .title {
        width: 300px;
        text-align: start;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &--middle {
      button {
        width: 160px;
      }
    }

    &--right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & ::v-deep .zoom {
        width: 185px;
      }
    }
  }
}

.placeholder-bar {
  position: relative;
  margin: 32px auto 32px auto;
}
</style>
