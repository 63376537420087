module.exports = {
  ad: {
    highlights: "ハイライト",
    highlightsIntro: "ここでは、今回のような新たにリリースされた機能をすばやく確認できます。また、現在継続中のスキルトレーニングについて、Stephanie が通知を送り、進捗を記録してくれます。",
    recommendations: "おすすめ",
    recommendationsIntro: "ここでの活動を基に、Stephanie がおすすめのワークフローを紹介します。皆さんとのやりとりを通じて、Stephanie は学習していきます。",
    favoritesIntro: "必要なワークフローがすべて揃っていて、すぐに使えるとしたら、素晴らしいと思いませんか？ここでは、ワークフローがいつでも使える状態になっています。",
    categoriesIntro: "ワークフローが多すぎて、時間がない...。クリックするだけで自分に関連するカテゴリが表示されたらいいなと思いませんか？是非ここでそれを実感してください。",
    loginMsgTitle: "新機能の更新！",
    loginMsg: "これで、ワークスペースを簡単に検索できます。さらに、DeepHowアカウントを最適に活用できるように設計されたコンテンツの推奨事項をご覧ください。"
  },
  all: {
    success: "成功",
    player: "プレーヤー",
    editor: "編集者",
    admin: "管理",
    analytics: "分析",
    skills: "スキル",
    skill: "スキル",
    unpublished: "非公開",
    published: "公開済み",
    signOut: "サインアウト",
    step: "ステップ",
    steps: "ステップ",
    numStep: "0 ステップ| 1 ステップ| {count} ステップ",
    transcription: "文字起こし",
    no: "いいえ",
    yes: "はい",
    delete: "削除する",
    diagram: "図",
    all: "すべて",
    discard: "破棄",
    save: "保存する",
    next: "次へ",
    process: "プロセス",
    cancel: "キャンセルする",
    close: "閉じる",
    allUsers: "すべてのユーザー",
    users: "ユーザー",
    user: "ユーザー",
    numUsers: "ユーザー",
    numUser: "ユーザー",
    countUsers: "0 人のユーザー| 1 人のユーザー| {count} 人のユーザー",
    workspaces: "ワークスペース",
    workspace: "ワークスペース",
    workspaceGroup: "ワークスペースグループ",
    workflows: "ワークフロー",
    workflow: "ワークフロー",
    organization: "組織",
    business: "ビジネス",
    create: "作成する",
    name: "名前",
    namePerson: "名前",
    email: "メール",
    employeeId: "従業員 ID",
    role: "役割",
    organizationAdmin: "組織管理者",
    workspaceAdmin: "ワークスペース管理者",
    publisher: "公開者",
    viewer: "閲覧者",
    organizationAdmins: "組織管理者",
    workspaceAdmins: "ワークスペース管理者",
    publishers: "公開者",
    viewers: "閲覧者",
    add: "追加する",
    attachments: "添付ファイル",
    private: "プライベート",
    public: "パブリック",
    content: "コンテンツ",
    noRecordFound: "レコードが見つかりませんでした",
    loadingRecords: "レコードを読み込んでいます...",
    teams: "チーム",
    loadedAllRecords: "すべてのレコードが読み込まれました",
    language: "言語",
    interfaceLanguage: "インターフェイス言語",
    personalInfo: "個人情報",
    privacyPolicy: "プライバシーポリシー",
    team: "チーム",
    unselect: "選択解除する",
    select: "選択する",
    applied: "適用済み",
    noAccessToAdminTools: "このユーザーアカウントには、現在管理ツールへのアクセス権がありません。",
    changePhoto: "写真を変更する",
    removePhoto: "写真を削除する",
    profilePhoto: "プロフィール写真",
    takeOrUpload: "写真の撮影またはアップロードを行う",
    useCamera: "カメラを使用する",
    uploadPhoto: "写真をアップロードする",
    editPhoto: "写真を編集する",
    takePhoto: "写真を撮影する",
    retake: "再撮影する",
    countWorkspaces: "ワークスペース | ワークスペース | ワークスペース",
    whatsNew: "最新情報",
    howToUse: "使い方",
    defaultInterfaceLanguage: "既定のインターフェイス言語",
    newVersionSnackbarText: "DeepHow の新しいバージョンが利用可能です。",
    refresh: "更新する",
    unpublishedList: "非公開",
    publishedList: "公開済み", 
    userAgreement: "ユーザー規約",
    numAttachments: "0 添付ファイル | 1 添付ファイル | {count} 添付ファイル",
    attachment: "添付ファイル",
    knowledgeBase: "DeepHowの知識ベース",
    changeName: "名前を変更します",
    changePassword: "パスワードを変更する",
    openInApp: "Deephowナビゲーターで開きます",
    userNewToDeepHow: "あなたは深い方法に初めてですか？",
    downloadTheApp: "アプリをダウンロードします",
    continueToUseBrowser: "ブラウザの使用を続けます",
    contentManager: "コンテンツマネージャ",
    gen2Forbidden: "Gen2 Lightユーザーとして、今すぐログインできません。",
    confirm: "確認",
    myWorkspaceGroups: "私のワークスペースグループ",
    otherWorkspaceGroups: "他のワークスペースグループ",
    searchResult: "検索結果",
    noResultsFound: "結果は見つかりませんでした。",
    browseWorkspace: "ワークスペースを閲覧する...",
  },
  password: {
    createYourAccount: "アカウントの作成",
    checkNameChoosePassword: "名前を確認してパスワードを選択してください",
    createAccount: "アカウントの作成",
    createNewPassword: "新しいパスワードの作成",
    selectNewPassword: "アカウントの新しいパスワードを選択してください",
    newPassword: "新しいパスワード",
    confirmNewPassword: "新しいパスワードを確認してください",
    passwordMustMatch: "パスワードは一致する必要があります。",
    confirm: "確認",
    passwordCreatedMsg: "新しいパスワードが作成されました。<br>リダイレクトするまでお待ちください…",
    goToDeepHowPlayer: "DeepHow プレーヤーに移動する",
    failedResetPassword: "パスワードのリセットに失敗しました。アカウントが既に作成されているか、ネットワークに問題があります",
    alertSetPasswordFailed: "パスワードの設定に失敗しました。アカウントはすでに作成されているか、ネットワークの問題があります。",
    linkExpired: "このリンクは期限切れになりました",
    passwordExpired: "パスワードリセットリンクの有効期限が切れています。",
    inviteExpired: "あなたの招待状が期限切れになりました。",
    resetPasswordMsg: "下のボタンをクリックして、パスワードリセットメールをリクエストします。",
    // added 09/08/2022
    alertFailedResetPassword: "パスワードのリセットに失敗しました。",
    alertSuccessfullyResetPassword: "パスワードのリセットに成功します。もう一度ログインするようにリダイレクトするときに待ってください... <br><br>または下のボタンをクリックしてください。",
    newPasswordNotTheSame: "新しいパスワードは古いパスワードと同じであってはなりません。",
    alertCurrentPasswordIncorrect: "現在のパスワードが間違っています",
    alertTooManyAttempts: "現在のパスワードを検証する試みが多すぎます。あとでもう一度試してみてください。",
  },
  analytics: {
    overview: "概要",
    engagement: "エンゲージメント",
    activeUsers: "アクティブユーザー",
    workflowsCreated: "作成したワークフロー",
    workflowsPublished: "公開したワークフロー",
    workflowViews: "ワークフローの閲覧数",
    timeCreated: "作成時刻",
    timePublished: "公開時刻",
    timeViewed: "閲覧時刻",
    searchWorkspace: "ワークスペースを検索します...",
    mostUsers: "最も多くのユーザー",
    mostWorkflowsCreated: "最も多く作成されたワークフロー",
    numberOfUsers: "ユーザー数",
    organizationOverview: "組織概要",
    numWorkflowsIn: "ワークフロー",
    searchWorkflow: "ワークフローを検索する...",
    createdBy: "著者",
    usersIn: "ユーザー",
    searchUser: "ユーザーを検索します...",
    watchTime: "視聴時間",
    workflowsViewed: "閲覧されたワークフロー",
    percentViewed: "閲覧率",
    dateViewed: "閲覧日",
    watchWorkflow: "ワークフローを見る",
    backToUsers: "ユーザーに戻る",
    backToWorkflows: "ワークフローに戻る",
    likes: "いいね!",
    avgTimeViewed: "平均閲覧時間",
    numberofViews: "閲覧数",
    workspacesPerPage: "1 ページあたりのワークスペースの数",
    usersPerPage: "1 ページあたりのユーザーの数",
    workflowsPerPage: "1 ページあたりのワークフローの数",
    exportTable: "テーブルをエクスポートする",
    TopViewers: "上位の閲覧者",
    TopPublishers: "上位の公開者",
    totalViews: "合計閲覧数",
    msgNoDataYet: "使用できるデータがありません",
    msgNoSearchResults: "一致するレコードが見つかりませんでした",
    hms: "(時間:分:秒)",
    viewHistory: "履歴を表示する",
    views: "閲覧数",
    totalViewingTime: "合計閲覧時間",
    usersViewedThisWorkflow: "<b>0</b> 人のユーザーがこのワークフローを閲覧しました | <b>1</b> 人のユーザーがこのワークフローを閲覧しました | <b>{count}</b> 人のユーザーがこのワークフローを閲覧しました",
    date: "日付",
    // added 2022/07/11
    dashboards: "ダッシュボード",
    reactions: "反応",
    userReaction: "お客様の声",
    viewAll: "すべてを表示します",
    favorites: "お気に入り",
  },
  admin: {
    status: "ステータス",
    active: "アクティブ",
    inactive: "非アクティブ",
    invited: "招待済み",
    invitationExpired: "招待の有効期限が切れています",
    couldNotInvited: "招待できませんでした",
    hintSearchUsers: "ユーザーを検索します...",
    hintSearchWorkspaces: "ワークスペースを検索します...",
    hintSearchBusiness: "ビジネスを検索します...",
    alertSuccessfullyInviteUser: "ユーザーが正常に招待されました。",
    alertFailedInviteUser: "ユーザーの招待に失敗しました。",
    alertSuccessfullyDeletedUser: "ユーザーが正常に削除されました。",
    alertFailedDeleteUser: "ユーザーの削除に失敗しました。",
    alertSuccessfullyCreatedWorkspace: "ワークスペースが正常に作成されました。",
    alertSuccessfullyEditedWorkspace: "ワークスペースが正常に編集されました。",
    alertSuccessfullyCreatedBusiness: "ビジネスが正常に作成されました。",
    alertSuccessfullyEditedBusiness: "ビジネスが正常に編集されました。",
    alertUserInvitationSuccessfullyResent: "ユーザー招待が正常に再送信されました。",
    alertFailedResendUserInvitation: "ユーザー招待の再送信に失敗しました。",
    alertUserInvitationSuccessfullyDelete: "ユーザー招待を正常に削除しました。",
    alertFailedDeleteUserInvitation: "ユーザー招待の削除に失敗しました。",
    resendInvitation: "招待を再送信する",
    popupMsgResendInvitation: "招待を再送信しますか?",
    popupBtnSend: "はい、送信します",
    deleteInvitation: "招待を削除する",
    popupMsgDeleteInvitation: "招待を削除しますか?",
    addUser: "ユーザーを追加する",
    msgNoMembersYet: "この組織にはまだメンバーがいません。",
    msgNoRecordsAllUsers: "すべてのユーザーで一致するレコードが見つかりませんでした。",
    createWorkspace: "ワークスペースを作成する",
    msgNoWorkspacesYet: "この組織にはまだワークスペースがありません。",
    msgNoRecordsWorkspaces: "ワークスペースで一致するレコードが見つかりませんでした。",
    msgNoRecordsWorkspacesGroupAdmin: "このアカウントにはまだワークスペースがありません。",
    createBusiness: "ビジネスを作成する",
    msgNoBusinessYet: "この組織にはまだビジネスがありません。",
    msgNoRecordsBusiness: "ビジネスで一致するレコードが見つかりませんでした。",
    usersIn: "ユーザー",
    tableMsgNoUsersYet: "このワークスペースにはまだメンバーがいません。",
    tableMsgNoRecordsThisWorkspace: "このワークスペースで一致するレコードが見つかりませんでした。",
    memberAt: "にいるメンバー",
    alerBusinessAlreadyInSystem: "既にシステム内に存在しているビジネス",
    alerFailedAddBusiness: "ビジネスの追加に失敗しました。",
    alertFailedEditBusiness: "ビジネスの編集に失敗しました。",
    alertWorkspaceAlreadyInSystem: "このワークスペース名は、既にシステムに存在します。",
    aletFailedCreateWorkspace: "ワークスペースの作成に失敗しました。",
    alertFailedEditWorkspace: "ワークスペースの編集に失敗しました。",
    resendAllInvites: "すべての招待を再送信する",
    resendAllInvitesMsg: "保留中および有効期限が切れているすべての招待に招待を送信してもよろしいですか?",
    yesSendInvites: "はい、招待を送信します",
    businessesPerPage: "1 ページあたりのビジネスの数",
    //add org user
    popupTitleAddOrgUser: "組織管理者ユーザーを追加する",
    invite: "招待",
    popupTitleNewUserInvited: "新規ユーザーを招待しました",
    popupMsgNewUserInvitedOrg: "が招待されました。<br>アカウントを検証した後、ユーザーはこの組織にアクセスできるようになります。",
    popupTitleExistingUserInvited: "既存ユーザーが追加されました",
    popupMsgExistingUserInvitedOrg: "この組織に追加されました。",
    popupBtnAddAnotherUser: "別のユーザーを追加する",
    alertFailedCheckPendingInvitations: "ユーザーに保留中の招待があるかどうかの確認に失敗しました。",
    alertFailedAddExistingUserAsOrganizationAdmin: "既存ユーザーを組織管理者として追加できませんでした",
    alertAddExistingUserAsOrganizationAdmin: "既存ユーザーを組織管理者として追加します。",
    alertUserAlreadyOrganizationAdmin: "ユーザーは既に組織管理者としてシステムに存在しています。",
    alertFailedCheckUserAlreadyInSystem: "ユーザーが既にシステムに存在しているかどうかの確認に失敗しました。",
    //Add user to workspace
    addUserToWorkspace: "ワークスペースにユーザーを追加する",
    companyEmail: "会社の E メール",
    employeeIdOnly: "従業員 ID のみ",
    fullName: "氏名",
    workspaceName: "ワークスペース名",
    businessName: "ビジネス名",
    popupMsgNewUserInvited: "招待されました。<br>アカウントを検証した後、ユーザーはこのワークスペースにアクセスできるようになります。",
    popupMsgExistingUserInvited: "このワークスペースに追加されました。",
    popupTitleNewUserCreated: "新規ユーザーが作成されました。",
    popupTitleUserAlreadyinSystem: "ユーザーは既にシステムに存在します。",
    alertUserIsAlreadyInWorkspace: "このユーザー ({info}) は既にこのワークスペースに存在します。",
    alertPendingInvitation: "ユーザーに保留中の招待があります。ユーザーが現在の招待を承諾するまでお待ちください。",
    searchingforUsers: "ユーザーを検索しています...",
    noResultsFound: "結果は見つかりませんでした。",
    // Change User Role
    popupTitleChangeUserRole: "ユーザーの役割を変更する",
    popupMsgChangeUser: "<b>{name}</b>の役割を<b>{oldRole}</b>から<b>{newRole}</b>に変更しますか?",
    popupBtnChange: "はい、変更します",
    alertUpdatedUserSettings: "ユーザー設定が正常に更新されました。",
    // Remove User
    popupTitleRemoveUser: "ワークスペースからユーザーを削除する",
    popupMsgRemoveUserFromWorkspace: "ワークスペースから<b>{name}</b>を削除しますか?",
    popupBtnRemove: "はい、削除します",
    alertRemoveUser: "ユーザーが正常にワークスペースから削除されました。",
    // Delete User
    popupTitleDeleteUser: "システムからユーザーを削除する",
    popupMsgDeleteUserFromAllWorkspaces: "これを行うと、このユーザー <b>{name}</b>に関するすべてのレコードが失われ、ユーザーは割り当てられているすべてのワークスペースや役割から削除されます。",
    popupBtnDelete: "はい、削除します",
    tooltipEditUser: "ユーザーを編集する",
    tooltipResendInvite: "招待を再送信する",
    tooltipCancelInvite: "招待をキャンセルする",
    tooltipRemoveUser: "ユーザーを削除する",
    // settings
    settings: "設定",
    location: "場所",
    hireDate: "入社日",
    department: "部署",
    position: "職務",
    site: "サイト",
    userId: "ユーザー ID",
    ssoMapping: "SSO マッピング",
    ssoMappingNoData: "マッピングを行うデータがまだありません。<br />CSV を FTP でアップロードする必要がありますので、サポートに連絡してください。",
    attributes: "属性",
    oneAttributeToMap: "ユーザーをワークスペースにマッピングする際に選択できる属性は、1 つだけです。",
    selectAttribute: "属性を選択する",
    changeAttribute: "属性を変更する",
    discardChanges: "変更を破棄する",
    applyChanges: "変更を適用する",
    applyChangesMsg: "次のワークスペースに変更を加えることになりますが、よろしいですか?",
    mappingValuesUpdated: "マッピング値が更新されました",
    mappingValuesUpdatedSubtitle: "マッピングを正常に更新するには、変更を適用する必要があります。これは、すべてのワークスペースの編集が終了すると、実行できます。",
    values: "値",
    clickPlusToAddValues: "プラスのアイコンをクリックして、値を追加してください",
    selectValues: "値を選択する",
    searchForValues: "値を検索します...",
    assign: "割り当てる",
    assignValues: "値を割り当てる",
    addUsersBtn: "はい、ユーザーを追加します",
    YesChangeBtn: "はい、変更します",
    numMore: "{count} 以上",
    removeValues: "値を削除する",
    remove: "削除",
    changeAttributeMessage: "属性を変更しますか?<br/>この属性の実際のマッピングはすべて失われます。",
    discardChangesMessage: "前回の保存後に行われた変更はすべて失われますが、よろしいですか?",
    discardChangesBtn: "はい、変更を破棄します",
    applyChangesBtn: "はい、変更を適用します",
    alertDiscardedSuccess: "マッピングは正常に破棄されました。",
    alertAppliedSuccess: "マッピングは正常に適用されました。",
    alertAppliedFailed: "マッピングの適用に失敗しました。",
    //permissions
    permissions: "アクセス権限",
    products: "DeepHow の製品",
    roleAccessToProduct: "各製品にアクセスできるユーザーの役割を以下に定義します",
    analytics: "分析",
    skillsManager: "スキル管理",
    skillsPlayer: "スキルプレーヤー",
    workflowCapture: "ワークフローのキャプチャ",
    workflowEditor: "ワークフローのエディター",
    organizationAdmin: "組織管理者",
    groupAdmin: "ワークスペース管理者",
    publisher: "公開者",
    viewer: "閲覧者",
    rolesPermission: "役割の権限",
    rolesPermissionMessage: "この製品に対する役割の権限を変更しますか? 変更はすぐに行われます。",
    inviteUser: "ユーザーを招待する",
    workflowSharing: "ワークフローの共有",
    allowUserstoSharePublicLinks: "ユーザーによるパブリックリンクの共有を許可する",
    changeWorkflowSharingExplanation: "パブリックリンクの共有をオフにすると、プラットフォームにサインインしているユーザーのみがパブリックワークフローにアクセスできます。",
    allowWorkflowSharingMessage: "パブリックリンクの共有をオンにしますか?",
    preventWorkflowSharingMessage: "パブリックリンクの共有をオフにしますか? 変更はすぐに行われます。",
    workflowDownload: "ワークフローのダウンロード",
    allowUsersToDownloadWorkflows: "ユーザーによるワークフローのダウンロードを許可する",
    allowDownloadWorkflowsMessage: "ユーザーによるワークフローのダウンロードを許可しますか?",
    preventDownloadWorkflowsMessage: "ユーザーによるワークフローのダウンロードを禁止しますか?",
    autoLogout: "自動ログアウト",
    autoLogoutAfterSelectedTime: "選択した時間が経過すると、ユーザーは自動的にログアウトします。",
    numMinutes: "0 分 | 1 分 | {count} 分",
    changeLogoutTimeMessage: "自動的にログアウトする時間を {num} 分に変更しますか?",
    enableAutoLogoutMessage: "自動ログアウトを有効にしますか?",
    disableAutoLogoutMessage: "自動ログアウトを無効にしますか?",
    changesMadeImmediately: "変更はすぐに行われます。",
    sessionIsAboutToExpire: "セッションがまもなく期限切れになります。",
    youWillBeLoggedOutNumSeconds: "0 秒後にログアウトします。 | 1 秒後にログアウトします。 |  {count} 秒後にログアウトします。",
    continueSession: "セッションを続行する",
    //system languages
    languages: "言語",
    systemLanguage: "システム言語",
    searchForWorkspaces: "ワークスペースを検索します...",
    workspacesPerPage: "1 ページあたりのワークスペースの数",
    workspace: "ワークスペース",
    workspaceLanguage: "言語",
    changeLanguage: "言語を変更する",
    popupMsgChangeLanguage: "言語を変更すると、このワークスペース内のすべてのユーザーに影響しますが、変更してもよろしいですか?",
    alertLanguageSuccessfullyChanged: "言語は正常に変更されました",
    alertFailedChangeLanguage: "言語の変更に失敗しました",
    // Categories
    categories: "カテゴリ",
    alertCategoriesNotSaved: "保存されていないカテゴリは、後でもう一度やり直してください。",
    noCategoryResult: "カテゴリの検索結果はありません",
    noCategoriesAddedYet: "まだカテゴリは追加されていません",
    categoriesAssignment: "カテゴリの割り当て",
    categoryPlaceholder: "検索カテゴリとサブカテゴリ...",
    previouslyUsed: "以前に使用されていました",
    allCategories: "すべてのカテゴリ",
    workflowCategories: "ワークフローのカテゴリ",
    searchForCategories: "カテゴリを検索します...",
    categoryItems: "アイテム",
    categoryItemsNum: "アイテム",
    createCategory: "カテゴリを作成する",
    editCategory: "カテゴリを編集する",
    categoryName: "名前",
    noCategoriesCreatedYet: "カテゴリはまだ作成されていません。",
    noItemsCreatedYet: "アイテムはまだ作成されていません。",
    categoriesPerPage: "1 ページあたりのカテゴリの数",
    itemsPerPage: "1 ページあたりのアイテムの数",
    alertCategoryAlreadyInSystem: "既にシステム内に存在しているカテゴリ",
    alertFailedAddCategory: "カテゴリの追加に失敗しました。",
    alertFailedEditCategory: "カテゴリの編集に失敗しました。",
    deleteCategory: "カテゴリを削除する",
    popupMsgDeleteCategory: "カテゴリを削除しますか?",
    itemsIn: "アイテムを", 
    searchForItems: "アイテムを検索します...",
    createItem: "アイテムを作成する",
    editItem: "アイテムを編集する",
    deleteItem: "アイテムを削除する",
    itemName: "名前",
    alertItemAlreadyInSystem: "既にシステム内に存在しているアイテム",
    alertFailedAddItem: "アイテムの追加に失敗しました。",
    alertSuccessAddItem: "アイテムが正常に追加されました。",
    alertFailedEditItem: "アイテムの編集に失敗しました。",
    alertSuccessEditItem: "アイテムが正常に編集されました。",
    // Teams
    teamsPerPage: "1 ページあたりのチームの数",
    max1000UsersPerTeam: "* 1 チームあたり最大 1,000 ユーザー",
    usersSelected: "0 人のユーザーが選択されました | 1 人のユーザーが選択されました | {count} 人のユーザーが選択されました",
    selectAll: "すべて選択",
    removeAll: "すべて削除",
    createTeam: "チームを作成する",
    editTeam: "チームを編集する",
    teamName: "名前",
    teamsIn: "チーム", 
    searchForTeams: "チームを検索します...",
    searchForUsers: "ユーザーを検索します...",
    noUsersAddedYet: "チームにはまだユーザーが追加されていません。",
    dateAdded: "追加日",
    alertTeamAlreadyInSystem: "既にシステム内に存在しているチーム。",
    alertFailedEditTeam: "チームの編集に失敗しました。",
    deleteTeam: "チームの削除",
    popupMsgDeleteTeam: "<b>{name}</b>チームを削除しますが、よろしいですか?",
    noTeamsCreatedYet: "チームはまだ作成されていません。",
    popupMsgDeleteItem: "アイテムを削除しますか?",
    removeUser: "ユーザーの削除",
    popupMsgRemoveUser: "このチームから<b>{name}</b>を削除しますが、よろしいですか?",
    alertErrorGettingWorkspaceList: "ワークスペースのリストの取得中にエラーが発生しました",
    alertSuccessfullyCreatedTeam: "チームが正常に作成されました",
    alertFailedCreatedTeam: "チームの作成に失敗しました。",
    alertSuccessfullyUpdatedTeam: "チームが正常に更新されました。",
    alertSuccessfullyRemovedUserFromTeam: "ユーザーが正常にチームから削除されました。",
    alertFailedRemovedUserFromTeam: "ユーザーをチームから削除するのに失敗しました。",
    alertSuccessfullyAddedUserToTeam: "ユーザーが正常にチームに追加されました。",
    alertFailedAddedUserToTeam: "ユーザーをチームに追加するのに失敗しました。",
    alertWarningAddedUserToTeamAlready: "ユーザーはすでにチームに追加されています。",
    // TODO added 2022/02/03
    alertAllInvitationResentSuccess: "すべての招待状が正常にresします。",
    alertAllInvitationResentFailed: "すべての招待状を再送信できませんでした。",
    alertCheckUserInSystemFailed: "ユーザーがすでにシステムに含まれているかどうかを確認できませんでした。",
    alertOrganizationAdminAssignedFailed: "組織管理者をワークスペースに割り当てることはできません",
    // TODO added 2022/02/07
    alertFailedDeleteItem: "アイテムの削除に失敗しました。",
    alertSuccessfullyDeletedItem: "正常に削除されたアイテム。",
    alertCategoryDeleteFailed: "カテゴリの削除に失敗しました。",
    alertSuccessfullyDeletedCategory: "正常に削除されたカテゴリ。",
    // TODO added 2022/02/08
    upgradeToOrgAdmin: "組織にアップグレードします。管理者",
    yesUpgrade: "はい、アップグレード",
    popupMsgUpgradeToOrgAdmin: "<b> {name} </b>をアップグレードしたいですか？組織。管理者は、組織の設定などを構成するために完全にアクセスできます…",
    alertFailedUpgradeToOrg: "組織へのアップグレードに失敗しました。管理者。",
    alertSuccessfullyUpgradeToOrg: "組織に正常にアップグレードされました。管理者。",
    // TODO added 2022/02/17
    userActive: "ユーザーアクティブ？",
    profile: "プロフィール",
    // TODO: added 2022/02/09 settings > Master Password
    masterPassword: "マスターパスワード",
    enableMasterPassword: "マスターパスワードを有効にします",
    disableMasterPassword: "マスターパスワードを無効にします",
    resetMasterPassword: "マスターパスワードをリセットします",
    employeeIDAccess: "従業員IDアクセス",
    employeeIDAccessMsg: "この機能をオンにすると、従業員IDのみにアクセスするユーザーは、このパスワードを入力してDeepHowにアクセスする必要があります。",
    disableMasterPasswordMsg: "この機能をオフにすると、従業員IDのみにアクセスするユーザーは、DeepHowにアクセスするためにパスワードを入力する必要はありません。",
    resetMasterPasswordMsg: "従業員IDのみにアクセスするユーザーは、この新しいパスワードを使用してDeepHowにアクセスする必要があります。",
    insertPassword: "パスワードを挿入します",
    alertEnablePasswordFailed: "マスターパスワードを有効にすることができませんでした。",
    alertEnablePasswordSuccess: "マスターパスワードを正常に有効にしました。",
    alertDisablePasswordFailed: "マスターパスワードの無効化に失敗しました。",
    alertDisablePasswordSuccess: "マスターパスワードを正常に無効にしました。",
    savePasswordMsg: "この機能は、パスワードが正常に保存されるまで有効になりません。",
    resetPasswordMsg: "新しいマスターパスワードを入力して保存します。",
    currentPassword: "現在のパスワード",
    yesEnable: "はい、有効になります",
    yesDisable: "はい、無効にします",
    downgradeOrgAdmin: "orgをダウングレードします。管理者",
    popupMsgDowngradeOrgAdmin: "<b> {name} </b>をダウングレードしたいですか？それらは、属するすべてのワークスペースで選択した役割に格下げされます。",
    selectRole: "このユーザーの役割を選択します",
    yesDowngrade: "はい、ダウングレード",
    alertFailedDowngradeToOrg: "{userrole}にダウングレードできませんでした。",
    alertSuccessfullyDowngradeToOrg: "{userrole}に格下げされました。",
    lastActivity: "前回のログイン",
    coverColor: "色をカバーします",
    defaultLanguage: "既定の言語",
    blue: "青い",
    red: "赤",
    green: "緑",
    yellow: "黄色",
    purple: "紫の",
    belongsToWorkspaces: "ワークスペースに属します",
    alertFailedSaveChanges: "変更を節約できませんでした。",
    // TODO: added 04/02
    alertFailedRemoveUser: "ユーザーの削除に失敗しました。",
    // TODO: added 0608
    privateOrnot: "プライベート?",
    privateWorkspaceInfo: "このワークスペースは、それに割り当てられたメンバーによってのみ表示されます…",
    noPermission: "あなたはこれを見ることを許可されていません。",
    noPermissionContact: "アクセスしようとしているページにはアクセスが制限されています。 <br>これが間違いだと感じたら、管理者に連絡してください。",
    privateCannotChanged: "ワークスペースが作成された後、プライベート設定を変更できません。",
    // Shared Account
    sharedAccount: "共有アカウント",
    addManually: "手動で追加します",
    addASharedAccount: "共有アカウントを追加します",
    addASharedAccountMsg: "共有アカウントを使用してワークフローを個別に視聴することはできません。従業員IDユーザーがディープハウでアカウントを自己作成するためにのみ使用され、共有デバイスで各ユーザーを追跡します...",
    accountName: "アカウント名",
    alertFailedAddShared: "共有アカウントの追加に失敗しました",
    alertSuccessfullyCreatedShared: "共有アカウントを正常に作成しました",
    alertSuccessfullyAddExistingShared: "既存の共有アカウントを正常に追加しました",
    accountId: "アカウントID",
    addSharedAccount: "共有アカウントを追加します",
    sharedAccountId: "共有アカウントID",
    unselectedTeam: "チームを選択しません",
    // TODO: added 2022/06/22
    workflowReview: "ワークフローレビュー",
    workflowReviewDescription: "このオプションがオンになっている場合、ワークスペース管理者は、ワークスペースがワークフローのレビューを必要とするかどうかを選択できます",
    preventReviewWorkflowsMessage: "ユーザーがワークフローを確認できないようにしますか？",
    allowReviewWorkflowsMessage: "ユーザーがワークフローを確認できるようにしたいですか？",
    // TODO: added 2022/06/29
    addAsReviewer: "レビュアーとして追加します",
    removeAsReviewer: "レビュアーとして削除します",
    addAsReviewerSuccessMessage: "レビュー担当者として{user}を正常に追加します。",
    addAsReviewerFailedMessage: "{user}をレビュアーとして追加できませんでした。",
    removeAsReviewerSuccessMessage: "レビュー担当者として{user}を正常に削除します。",
    removeAsReviewerFailedMessage: "レビューアとして{user}を削除できませんでした。",
    // Shared Account Admin settings 07/27
    allowsSharedAccountMsg: "組織およびワークスペース管理者が共有アカウントを作成できるようにします。ユーザーは、共有デバイスで従業員IDアカウントを使用して自己署名できます。",
    disableSharedAccountMsg: "共有アカウントを無効にしたいですか？ <br>変更はすぐに行われます。",
    enableSharedAccountMsg: "共有アカウントを有効にしたいですか？ <br>変更はすぐに行われます。",
    alertEnableSharedAccountFailed: "共有アカウントを有効にすることができませんでした。",
    alertEnableSharedAccountSuccess: "共有アカウントを正常に有効にしました。",
    alertDisableSharedAccountFailed: "共有アカウントの無効化に失敗しました。",
    alertDisableSharedAccountSuccess: "正常に無効になった共有アカウント。",
    // QR Code & Watermark
    qrCodeAndWatermark: "QRコード＆ウォーターマーク",
    insertYourBrand: "ブランドを挿入します",
    uploadYourLogo: "ロゴをアップロードします",
    uploadingYourLogo: "ロゴのアップロード",
    logoSuccessfullyUploaded: "ロゴは正常にアップロードされました",
    errorInUpload: "アップロードのエラー",
    errorInUploadMsg: "サポートされていないファイルタイプ。次のファイルタイプを選択してください：.png",
    uploadLogoMsg: "<b>.png</b>をドラッグアンドドロップして、最大<b>3 MB</b>ファイルまたはをコンピューターから<span>閲覧</span>します。",
    uploadLogoReplaceMsg: "ドラッグまたは <span>閲覧</span>して、コンピューターからの画像に交換するか、現在の画像を<span id='DUploadImage_removeLogo'>削除</span>します。",
    simulation: "シミュレーション",
    simulationMsg: "ビデオの透かしとQRコードで、ロゴがどのように表示されるかをご覧ください。",
    workflowTitle: "ワークフロータイトル",
    logoAlreadyUploaded: "ロゴはすでにアップロードされています",
    alertLogoSuccessfullyRemoved: "ロゴは正常に削除されました。",
    alertLogoFailedRemoved: "ロゴを削除できませんでした。",
    alertUserInOtherOrg: "このユーザーはすでに別の組織に存在しています。",
    // added 10/13 delete workspace
    deleteWorkspace: "ワークスペースを削除します",
    popupMsgDeleteWorkspace: "このワークスペース ({name}) を削除したいですか？",
    alertDeleteGroupSuccess: "このワークスペースを正常に削除します。",
    alertDeleteGroupFailedWorkflows: "このワークスペースにワークフローが割り当てられているため、削除することはできません。",
    alertDeleteGroupFailedSkills: "このワークスペースに割り当てられたスキルがあるため、削除することはできません。",
    alertDeleteGroupFailedTeams: "このワークスペースに割り当てられたチームがあるため、削除することはできません。",
    // added 11/3 delete team
    alertDeleteTeamSuccess: "このチームが正常に削除されました。",
    alertDeleteTeamFailedSkills: "このチームは、既存のスキルが割り当てられているため、削除することは許可されていません。",
    alertDeleteTeamFailed: "このチームの削除に失敗しました。",
    // Change Profile Name
    changeProfileName: "プロファイル名を変更します",
    allowsChangeProfileNameMsg: "ユーザーは、プロファイルで名前を変更することができます。",
    disableChangeProfileNameMsg: "変更プロファイル名を無効にしたいですか？ <br>変更はすぐに行われます。",
    enableChangeProfileNameMsg: "変更プロファイル名を有効にしたいですか？ <br>変更はすぐに行われます。",
    alertEnableChangeProfileNameSuccess: "プロファイル名の変更を正常に有効にしました。",
    alertEnableChangeProfileNameFailed: "変更プロファイル名を有効にすることができませんでした。",
    alertDisableChangeProfileNameSuccess: "プロファイル名の変更を正常に無効にしました。",
    alertDisableChangeProfileNameFailed: "変更プロファイル名の無効化に失敗しました。",
    // Workflow Comments
    workflowComments: "ワークフローのコメント",
    allowsWorkflowCommentsMsg: "ユーザーは、公開されたワークフローにコメントを残すことができます。",
    disableWorkflowCommentsMsg: "ワークフローのコメントを無効にしたいですか？ <br>変更はすぐに行われます。",
    enableWorkflowCommentsMsg: "ワークフローのコメントを有効にしたいですか？ <br>変更はすぐに行われます。",
    alertEnableWorkflowCommentsSuccess: "ワークフローのコメントを正常に有効にしました。",
    alertEnableWorkflowCommentsFailed: "ワークフローのコメントを有効にすることができませんでした。",
    alertDisableWorkflowCommentsSuccess: "ワークフローのコメントを無効にしました。",
    alertDisableWorkflowCommentsFailed: "ワークフローのコメントを無効にしなかった。",
    // Workspace Groups
    workspaceGroupsNum: "ワークスペースグループ | ワークスペースグループ | ワークスペースグループ",
    searchWorkspaceGroups: "ワークスペースグループを検索...",
    noWorkspaceGroupsYet: "まだ作成されていません。",
    createWorkspaceGroup: "ワークスペースグループを作成します",
    editWorkspaceGroup: "ワークスペースグループの編集",
    workspaceGroupName: "ワークスペースグループ名",
    editWorkspace: "ワークスペースを編集します",
    userNum: "人のユーザー| 人のユーザー| 人のユーザー",
    teamNum: "チーム | チーム | チーム",
    defaultLanguage: "既定の言語",
    deleteWorkspaceGroup: "ワークスペースグループを削除します",
    alertCreateWorkspaceGroupSuccess: "Workspaceグループを正常に作成しました。",
    alertWorkspaceGroupNameAlreadyExists: "このワークスペースグループ名はすでにシステムにあります。",
    alertEditWorkspaceGroupSuccess: "編集されたワークスペースグループに成功しました。",
    alertEditWorkspaceGroupFailed: "ワークスペースグループの編集に失敗しました。",
    alertDeleteWorkspaceGroupSuccess: "このワークスペースグループを正常に削除しました。",
    alertDeleteWorkspaceGroupFailed: "このワークスペースグループを削除できませんでした。",
    alertWorkspaceAssignedDeleteGroupFailed: "このグループに割り当てられたワークスペースがあるため、削除することはできません。",
    deleteWorkspaceGroupMessage: "このワークスペースグループ（<b>{name}</b>）を削除したいですか？",
    alertWorkspaceNameAlreadyExists: "ワークスペース名はすでに存在しています。",
  },
  builder: {
    publish: "公開する",
    reviewTranscription: "文字起こしを確認する",
    viewer: "閲覧者",
    links: "リンク",
    trim: "トリミング",
    translation: "翻訳",
    share: "共有する",
    addDiagramMsg: "ドラッグまたはクリックして図を追加する",
    onlyImageAccepted: "画像ファイルのみ受け付けます",
    unsupportedImageFormats: "サポートされていない画像形式: svg、tiff、xbm。",
    addDiagram: "図を追加する",
    start: "開始",
    end: "終了",
    resetToOriginal: "元の状態に戻す",
    trimStartMsg: "ビデオの開始位置と終了位置を選択します。",
    trimProcessingMsg: "ワークフロー処理の進行中は、トリミングは使用できません。<br>しばらくしてからここに戻ってください",
    cannotTrimLessThan3s: "3 秒未満のビデオはトリミングできません",
    trimVideo: "ビデオをトリミングする",
    trimStep: "ステップをトリミングする",
    editWorkflow: "ワークフローを編集する",
    popupBtnEdit: "はい、編集します",
    popupBtnPublish: "はい、公開します",
    popupBtnUnpublish: "はい、非公開にします",
    popupMsgTrimConfirmation: "トリミングを行いますか?",
    popupMsgTrimWithSaveConfirmation: "ワークフローに保存されていない変更があります。その変更を保存します。",
    popupBtnTrim: "はい、トリミングします",
    popupMsgTranscoding: "現在トランスコーディング中です。",
    popupMsgTrimBackLater: "トランスコードが完了した後、ワークフローの編集を再開できます。",
    popupMsgTrimSuccess: "ステップは正常にトリミングされました。",
    done: "完了",
    publishWorkflow: "ワークフローを公開する",
    popupMsgPublishWorkflow: "ワークフローを公開すると、すべてのユーザーにワークフローが表示されますが、公開しますか?",
    popupMsgEditWorkflow: "編集モードにすると、ワークフローは自動的に非公開になりますが、続行しますか?",
    popupMsgUnpublishWorkflow: "ワークフローを非公開にしますか?",
    alertSaveFailed: "ワークフローの保存に失敗しました。",
    alertTrimResetSuccess: "ステップは正常にリセットされました。",
    alertTrimResetFailed: "ステップのリセットに失敗しました。",
    alertTrimCanNotReset: "このステップはリセットできません。",
    resetStep: "ステップをリセットする",
    popupMsgResetStep: "リセットしますか? このステップに追加された図は失われます。",
    popupBtnReset: "はい、リセットします",
    hintSearchBuilder: "ワークフローとキーワードを検索します...",
    stepsSelected: "選択済み",
    clear: "クリアする",
    insert: "挿入する",
    reset: "リセット",
    resetWorkflow: "ワークフローをリセットします",
    resetWorkflowAlert: "あなたはこのワーフローで行われたすべての作業を失い、最初から始めます、あなたは続けたいですか？",
    resetWorkflowKeepCopy: "このワークフローのコピーを保管してください",
    // Transcript / Translation
    closeTranscript: "文字起こしを閉じる",
    popupMsgCloseTranscript: "文字起こしに保存されていない変更があります。続行すると、<b>すべての変更が失われます</b>が、よろしいですか?",
    stepNoTranscript: "文字起こしのないステップ",
    originalTranscript: "元の文字起こし",
    addLanguageTooltip: "ビデオに新しい言語を追加するには、ここをクリックしてください",
    closeTranslation: "翻訳を閉じる",
    popupMsgCloseTranslation: "翻訳に保存されていない変更があります。続行すると、<b>すべての変更が失われます</b>が、よろしいですか?",
    switchTranslation: "翻訳を切り替える",
    popupBtnNoSwitch: "保存されていない変更が",
    popupBtnYesSwitch: "翻訳にあります。<br>続行すると、<b>すべての変更が失われます</b>が、よろしいですか?",
    createAudio: "いいえ、切り替えるだけです",
    addNewLanguageTooltip: "はい、保存して切り替えます",
    popupMsgCreateAudio: "音声を作成する",
    popupBtnYesCreate: "ビデオに新しい言語を追加するには、ここをクリックしてください",
    alertAudioBeingCreated: "ワークフローの音声翻訳を作成しますか? Stephanie がすべての文字起こしを処理するため、ワークフローの音声翻訳を作成するのは一度のみにすることを強くお勧めします。",
    alertTranscriptionSaved: "はい、作成します",
    alertTranscriptionSaveFailed: "音声翻訳を作成しています。",
    alertTranslationsSaved: "文字起こしは正常に保存されました。",
    alertTranslationsSaveFailed: "文字起こしの保存に失敗しました。",
    alertAudioCreated: "翻訳が正常に保存されました。",
    alertAudioFailed: "翻訳の保存に失敗しました。",
    alertNoAudioGenerated: "音声が正常に作成されました。",
    alertErrorCreateTranscription: "音声翻訳の作成に失敗しました。",
    //Share workflow
    shareWorkflow: "ワークフローを共有する",
    fullWorkflow: "ワークフロー全体",
    specificSteps: "特定のステップ",
    chooseShareType: "共有の種類を選択する",
    qrCode: "QR コード",
    link: "リンク",
    embedLink: "リンクを埋め込む",
    privateWorkflow: "プライベートのワークフロー",
    publicWorkflow: "パブリックのワークフロー",
    close: "閉じる",
    saveQRCode: "QR コードを保存する",
    download: "ダウンロード",
    copyLink: "リンクをコピーする",
    copied: "コピー済み",
    downloading: "ダウンロードしています",
    videoPrivacy: "ビデオのプライバシー",
    popupMsgVideoPrivacy: "ワークフローを公開すると、DeepHow アカウントを持っていないユーザーでもアクセスできるようになりますが、実行しますか?",
    includeSubtitles: "字幕を含める",
    noSubtitles: "字幕なし",
    selectLanguage: "言語を選択する",
    workflowReady: "ワークフローの準備が完了しました",
    prepareToDownload: "ダウンロードの準備をする",
    processingDownloadVideoMsg: "このページを閉じないでください。<br />数分でファイルをダウンロードできるようになります。",
    processingWorkflow: "ワークフローを処理しています...",
    openAnotherTabMsg: "待っている間、別のタブで DeepHow に移動することができます。",
    openNewTab: "新しいタブを開く",
    alertErrorPreparingDownload: "ダウンロードの準備中にエラーが発生しました。再度実行するか、DeepHow にお問い合わせください。",
    hide: "非表示にする",
    addNewLink: "新しいリンクを追加する",
    clickToAddLinks: "クリックしてこのステップにリンクを追加する",
    addALink: "リンクを追加する",
    editLink: "リンクを編集する",
    addLinkAddress: "下のアドレスを入力して、このステップにリンクを追加します。",
    addLinkLable: "ラベルを追加しますか?",
    clickOpenOfficialDocument: "つまり、クリックして公式文書を開きます",
    //settings /categories
    settings: "設定",
    categories: "カテゴリ",
    noCategories: "まだカテゴリを作成していません。",
    selectCategoriesMessage: "ユーザーがコンテンツを簡単にフィルター処理できるように、カテゴリを少なくとも 1 つ選択します。",
    closeSettings: "設定を閉じる",
    popupMsgCloseSettings: "保存されていない変更があります。続行すると、<b>すべての変更が失われます</b>が、よろしいですか?",
    workflowCategories: "ワークフローのカテゴリ",
    popupBtnAssignCategoriesAndPublish: "カテゴリを割り当てて、公開する",
    popupBtnSkipAndPublish: "このステップをスキップして公開する",
    popupMsgworkflowCategories: "公開する前に、ワークフローのカテゴリをいくつか選択して、ユーザーが見つけやすくすることをお勧めします。",
    alertCategoriesSaved: "カテゴリは正常に保存されました。",
    alertCategoriesSaveFailed: "カテゴリの保存に失敗しました。",
    searchForCategoryName: "{name}を検索します...",
    loadingCategories: "カテゴリを読み込んでいます...",
    alertFailedDownloadVideo: "ビデオのダウンロードに失敗しました。",
    // Workflow Cover
    workflowCover: "ワークフローのカバー",
    selectFromTheVideo: "ビデオから選択する",
    browseForAFile: "ファイルを参照する",
    dropFileMessage: "ファイルをドロップするか、ここをクリックして参照します。",
    selectedImage: "選択した画像",
    noImageSelected: "選択した画像はありません",
    alertSuccessfullyUpdatedCover: "ワークフローのカバーが正常に更新されました。",
    alertFailedUpdatedCover: "ワークフローのカバーの更新に失敗しました。",
    selectAnotherImage: "別の画像を選択する",
    alertErrorStepLostTranscription: "現在、翻訳されていない手順があります。ワークフローをダウンロードする前に、それらを翻訳してください。",
    annotation: "注釈",
    allNotes: "すべてのメモ",
    noNotesAddedYet: "二度とこのメッセージを表示しないでください",
    popupMsgEnterTrim: "あなたのステップには注釈があります。ビデオをトリミングしたい場合は、これらの注釈がなくなります。",
    popupMsgEnterErase: "あなたのステップには注釈があります。ビデオを消去したい場合は、これらの注釈がなくなります。",
    popupMsgEnterAnnotation: "注釈を追加する前に、必ずビデオをトリミングまたは消去してください。",
    workingOn: "取りかかっている",
    eraseStep: "ステップを消去します",
    popupMsgEraseSuccess: "あなたのステップは正常に消去されました！",
    popupBtnYesErase: "はい、消去します",
    popupMsgEraseConfirmation: "あなたは確かに消去したいですか？",
    eraseProcessingMsg: "ワークフロー処理が進行中である間、消去は利用できません。 <br>後で戻ってきてください",
    translateOnlyTitlesBtn: "タイトルとステップ名を翻訳します",
    untitled: "無題",
    hintNoEmptyTitle: "タイトルを空にすることはできません",
    popupMsgSwitchTranslation: "<b> {lang} </b>翻訳に救われていない変更があります。切り替える前に保存しますか？",
    alertNoEmptyContent: "タイトルや文は空にすることはできません。コンテンツを入力してください。",
    PDF: "PDF",
    PDFSizeLimit: "PDFファイルサイズは50 MBに制限されています",
    addPDF: "PDFを追加します",
    onlyPDFAccepted: "PDFファイルのみが受け入れられました",
    preview: "プレビュー",
    // Send to reviewer
    sendToReviewer: "レビュアーに送信します",
    alertTitleSelectReviewers: "ユーザーを選択してワークフローを確認する",
    alertSendToReviewSuccess: "ワークフローはレビューに正常に送信されました。",
    alertSendToReviewFailed: "レビューに送信できませんでした",
    subtitlesSize: "字幕サイズ",
    small: "小さな",
    medium:"中くらい",
    big: "大きい",
    yourVideoReadyToDownload: "あなたのビデオはダウンロードする準備ができています。",
    yourVideoWasSuccessfullyDownloaded: "ワークフローは正常にダウンロードされました。",
    notAvailable: "利用不可",
    downloadFileFormat: "フォーマット",
    selectDownloadWorkflowQuality: "ビデオ品質を選択します",
    downloadSizeLimitedMsg: "{resolution}のSCORMをダウンロードすると、最大{duration}分のワークフローがサポートされています。",
    workflowTotalDurationMsg: "ワークフロー合計期間: {minute}分{minute}秒 Workflow total duration: {minute} min {minute} sec.",
    // Export Compliance Wire
    export: "輸出",
    code: "コード",
    organization: "組織",
    approvalDate: "承認日",
    processing: "処理中",
    workflowShouldBePublic: "ワークフローは公開する必要があります。",
    alertExportComplianceWireSuccess: "コントロールドキュメントを正常にエクスポートします。コード：{code}",
    alertExportComplianceWireExists: "コントロールドキュメントはすでに存在しています。コード：{code}",
    alertExportComplianceWireFailed: "コントロールドキュメントのエクスポート中に何かがうまくいかなかった。",
  },
  editor: {
    closeWorkflow: "ワークフローを閉じる",
    popupMsgCloseWorkflow: "ワークフローに保存されていない変更があります。続行すると、<b>すべての変更が失われます</b>が、よろしいですか?",
    popupBtnClose: "はい、閉じます",
    processWorkflow: "ワークフローを処理する",
    popupMsgProcessWorkflow: "レビューが完了したら、Stephanie による処理が必要です。<br><br>このレビュー画面に戻ることが<b>できません</b>が、よろしいですか?",
    popupBtnProcess: "はい、処理します",
    alertIsSaving: "保存中です。",
    alertNoEmptySentence: "文章は空にできません。文章を入力または削除してから、再度保存してください。",
    alertIsSaved: "変更が保存されました。",
    alertWasUnpublished: "ワークフローが正常に非公開になりました。",
    alertWasPublished: "ワークフローが正常に公開されました。",
    alertNoMoreSteps: "ここでは、ステップをこれ以上追加することはできません。",
    alertNoEmptyTitle: "タイトルは空にできません。タイトルを入力してから、再度保存してください。",
    scrollToVoice: "音声にスクロールする",
    noAudioMsg: "ワークフローでは、文字起こしする音声が見つかりませんでした...",
    youWillDeleteStep: "ステップを削除することになりますが、",
    areYouSure: "よろしいですか?",
    areYouSureDelete: "削除しても<br>よろしいですか?",
    replace: "置換",
    keyword: "キーワード",
    find: "検索",
    replaceWith: "置換する",
    replaceAll: "すべて置換",
    replaceAllMsg: "これらの単語をすべて置換します。",
    of: "/", 
    msgToSteps: "ワークフローを分析します...<br>お待ちください...",
    msgToEditor1: "ここで、公開する前にワークフローをレビューします。",
    msgToEditor2: "各ステップを案内しますので、ご安心ください。",
    msgToBuilder1: "もう少しですので、お待ちください",
    msgToBuilder2: "ワークフローのステップを作成しています。",
    newStep: "新しいステップ",
    hintNoEmptySentence: "文章は空にできません。",
    hintNoEmptyTitle: "(タイトルは空にできません)",
    alertNoTranscription: "ワークフローの文字起こしがありません。",
    alertEmptyingTranscription: "警告! 文字起こしを空にしていますが、それはできません。",
    alertStepCorrupted: "ステップ {count} が壊れています。処理を行う前に削除してください。",
    // Editor 2.0
    trim: "トリミングする",
    erase: "消去する",
    voiceOver: "ナレーション",
    videoEditing: "ビデオの編集",
    segmentation: "セグメンテーション",
    popupMsgRestToOriginal: "元のビデオにリセットしたいですか？追加された変更はすべて失われます。",
    trimHintMessage: "矢印を使用して、トリミングする領域を選択します",
    eraseHintMessage: "矢印を使用して、消去する領域を選択します",
    voiceOverHintMessage: "ここを押すと、録画が開始されます...",
    voiceOverStartHint: "ここを押して録音を開始します…",
    voiceOverStart: "記録",
    voiceOverSave: "録画を保存する",
    voiceOverCancel: "破棄する",
    voiceOverCancelPopupMsg: "録音を削除したいのですか？",
    voiceOverPause: "一時停止",
    voiceOverResume: "履歴書",
    unableToGetAudioInput: "オーディオ入力デバイスは検出されませんでした",
    restart: "再起動",
    editVideo: "ビデオを編集します",
    popupRequestTranscription: "ワークフローの転写を見つけることができません。新しいものを作成しましょう。",
    popupMsgEditVideoWarning: "ビデオを編集すると、転写に加えられたすべての変更が失われますが、編集したいですか？",
    popupMsgReTranscriptionWarning: "注：ビデオ編集アクション（トリム/消去/ボイスオーバーなど）を実行した場合、再転写機能を使用できません。",
    popupMsgReTranscriptionWarningMultiEditing: "注意：ビデオ編集操作（カット/吹き替えなど）を行った場合、再トランスクリプション機能を使用することはできません。",
    rotate: "回転します",
    rotateLeft: "左に回転",
    rotateRight: "右に回る",
    goBack: "戻る",
    goBackToEditorHome: "編集者の家に戻ります",
    zoom: "ズーム",
    // transcription new features
    transcriptionCopySuccess: "転写はクリップボードにコピーされました",
    keyTerms: "重要な用語",
    AILabs: "AIラボ",
    copyTranscription: "転写をコピーします",
    changeLanguage: "言語を変更",
    copy: "コピー",
    changeLanguageTitle: "転写用の新しい言語を選択してください",
    changeLanguageSubTitle: "ステファニーは、すべての転写を再処理する必要があります。",
    reProcessingYourVideo: "ステファニーはあなたのビデオを再処理しています...",
    goBackToEditor: "ここで待つか、編集者に戻って仕事を続けることができます！",
    // keyterms: added on 08/09/2022
    addTerm: "条件を追加します",
    removeTerm: "条件を削除します",
    termsFound: "<span>0</span>条件が見つかりました|見つかった<span>1</span>つの条件| <span>{count}</span>条件が見つかりました",
    loadingTerms: "条件の読み込み",
    termsInfo: "ワークフローで特定のコンテンツを検索する場合、条件は非常に役立ちます。",
    // Editor 3.0
    cut: "切る",
    tabsWarningMessages: "警告！保存せずにこのページを離れる場合、行ったすべての変更は失われます。"
  },
  player: {
    loadingVideo: "ビデオを読み込んでいます...",
    loadingLanguage: "言語を読み込んでいます...",
    previousStep: "前のステップ",
    nextStep: "次のステップ",
    audio: "音声",
    subtitles: "字幕",
    autoPlay: "自動再生",
    off: "オフ",
    on: "オン",
    quality: "品質",
    speed: "スピード",
    normal: "通常",
    rotatePhone: "お使いの電話を<span class='primary--text'>回転</span>させると、<br>より快適に使用できます",
    alertTranscodingFailed: "ビデオのトランスコーディングに失敗したようです。詳細については、DeepHow にお問い合わせください。",
    alertTranscodingStillProgress: "ビデオのトランスコーディングがまだ進行中のようです。しばらくしてからご確認ください。",
    settings: "設定",
    openInBrowser: "ブラウザーで開く",
    goToHome: "ホームに移動する",
    thisWorkflowWasUnpublished: "このワークフローは非公開でした",
    thisWorkflowWasDeleted: "このワークフローは削除されました",
    talkToSupervisor: "このワークフローを公開する必要がある場合、上司に相談してください",
    selectWorkspace: "ワークスペースを選択する",
    // player2.0
    home: "ホーム",
    myWorkflows: "自分のワークフロー",
    recommendedForYou: "あなたへのおすすめ",
    favorites: "お気に入り",
    welcomeToDeepHowNavigator: "DeepHow ナビゲーター へようこそ",
    numNewSkillsTrainings: "<span>0 つのスキルトレーニング</span><br>があなたに割り当てられています。 | <span>1 つのスキルトレーニング</span><br>があなたに割り当てられています。 | <span>{count} つのスキルトレーニング</span><br> があなたに割り当てられています。",
    clickForTips: "ここをクリックすると、便利なヒントが表示されます。",
    learnHow: "方法を学習する",
    goToSkills: "スキルに移動する",
    at: "@", 
    youAreIn: "現在いる場所",
    loadingRecommendations: "おすすめを読み込んでいます...",
    loadingFavoriteWorkflows: "お気に入りのワークフローを読み込んでいます...",
    noFavoriteWorkflows: "お気に入りにはまだアイテムがありません。",
    canFindSavedItemsHere: "保存したアイテムがここにすべて表示されます。",
    removeWorkflowFromFavorites: "このワークフローをお気に入りから削除してもよろしいですか?",
    yesGoAhead: "はい、そうしてください",
    thisWorkflowIsNotAvailable: "このワークフローは使用できません",
    dismiss: "今後表示しない",
    navigator: "ナビゲーター",
    howToUseNavigator: "新しい <br><b>DeepHow ナビゲーター</b> の使用方法を学習する",
    checkTheNewFeatures: "新機能を確認する。",
    unknownUser: "不明なユーザー",
    okCloseBtn: "わかりました、閉じます。",
    emptyRecommendWorkflowTitle: "おい！コンテンツはどこにありますか？",
    emptyRecommendWorkflowDescription: "心配しないで！ワークフローが作成されたらすぐに、お勧めします。すぐに帰る！",
    thisWorkflowDoesNotExist: "このワークフローは存在しません",
    // navigator
    resume: "見続ける",
    myWorkspaces: "私のワークスペース",
    otherWorkspaces: "他のワークスペース",
    loading: "読み込み...",
    loadingfavoriteWorkspaces: "お気に入りのワークスペースを読み込む...",
    removeWorkspaceFromFavorites: "お気に入りからこのワークスペースを削除したいですか？",
    YouAreViewing: "あなたは見ています",
    allWorkspaces: "すべてのワークスペース",
    removeFromFavorites: "お気に入りから削除",
    favoriteThisVideo: "このビデオが好きです",
    shareThisVideo: "この動画を共有します",
    viewAll: "すべてを表示します",
    openFile: "ファイルを開く",
    suggestedKeyterms: "提案された重要な用語",
    // workflow player added 10/10/2022
    posted: "ポストする",
    views: "{count}ビュー",
    saveFavorite: "収集",
    react: "フィードバック",
    numColleaguesReact: "{count}同僚のフィードバック",
    youAndNumColleaguesReact: "<span>あなた</span>と {count} 人の同僚からのフィードバック",
    youReacted: "<span>あなた</span>のフィードバック",
    userReaction: "お客様の声",
    fullscreen: "全画面表示",
    exitFullscreen: "フル画面を終了します",
    // 1/23/2023 Comments
    comments: "コメント",
    deleteComment: "コメントを削除します",
    deleteCommentMsg: "このコメントを削除したいですか？ <br>これは元に戻すことはできません。",
    edit: "編集",
    updating: "更新...",
    changesSaved: "変更が保存されました。",
    noComments: "コメントはまだありません…",
    makeFirstComment: "以下に最初のコメントをしてください",
    comment: "コメント",
    writeAComment: "コメントを書く...",
    edited: "編集",
    emojiRecent: "頻繁に使用されます",
    emojiSmileys: "スマイリー＆絵文字",
    emojiPeople: "人と体",
    emojiNature: "動物と自然",
    emojiFoods: "食べ物飲み物",
    emojiActivity: "アクティビティ",
    emojiPlaces: "旅行と場所",
    emojiObjects: "オブジェクト",
    emojiSymbols: "シンボル",
    emojiFlags: "フラグ",
    emojiCustom: "カスタム",
    back: "戻る",
    feedback: "フィードバック",
    leaveAFeedback: "フィードバックを残す",
    leaveAFeedbackMsg: "問題を教えていただくか、提案や建設的な批評をお願いします。",
    typeYourMessageHere: "ここにメッセージを入力してください",
    submit: "送信",
    thankYou: "ありがとうございます。",
    feedbackSuccessMsg: "フィードバックが正常に送信されました。",
    alertFeedbackFailedMsg: "フィードバックの送信に失敗しました。もう一度お試しください。",
    diagrams: "図",
    pdfFiles: "PDFファイル",
    theaterMode: "劇場モード",
    exitTheaterMode: "劇場モードを終了",
  },
  profile: {
    // added 12/09/2021
    addPositionLocation: "位置と場所を追加します",
    noWorkflowsCreated: "まだ作成されたワークフローはありません。",
    comeBackLater: "後で戻ってきます。",
    createdWorkflows: "作成されたワークフロー",
    profileOwnerNoContentMsg: "<span>ねえ、{name}！新しいプロフィールページは次のとおりです。</span> <br>将来、すべての人と知識を共有できるようになります。持続する！",
    profileColleguesNoContentMsg: "<span>これは{name}のプロフィールページです。</span> <br>彼はまだコンテンツを公開することができませんが、近い将来に彼はそうします！",
    editProfile: "プロファイル編集",
    personalInformation: "個人情報",
    personalInformationMsg: "あなたのプロフィールにアクセスする人には、次の情報が表示されます。",
    photo: "写真",
    position: "位置",
    locationRegion: "場所/地域",
    whatYouDo: "この組織で何をしているのかを人々に知らせてください。",
    whereYouBased: "あなたが拠点を置いている場所を人々に知らせてください。",
    accountManagement: "アカウント管理",
    accountManagementMsg: "この情報はプライベートであり、パブリックプロフィールには表示されません。",
    identifyMyself: "私は自分自身をそのことを認識しています",
    yourPassword: "あなたのパスワード",
    nonDeclared: "非宣言（デフォルト）",
    male: "男",
    female: "女性",
    nonBinary: "非バイナリ",
    viewProfile: "プロフィールを見る",
    alertFailedToUpdateProfile: "プロフィールの更新に失敗しました。"
  },
  explorer: {
    search: "検索",
    in: "検索場所:",
    howTo: "… する方法",
    didYouMean: "もしかして",
    searchInTitle: "タイトル内",
    searchInContent: "コンテンツ内",
    searchInVideo: "Stephanie による提案",
    title: "タイトル",
    searchStartMsg1: "お探しのものは何ですか?",
    searchStartMsg2: "上の検索ボックスを使用する",
    sortBy: "並べ替え",
    lastUpdated: "最終更新日",
    alphabetically: "アルファベット順",
    workflowsIn: "ワークフロー",
    tagNew: "新規",
    tagProcessing: "処理中",
    msgProcessing: "Stephanie がワークフローを処理しています...<br>お待ちください。",
    tagFailed: "失敗しました",
    msgFailed: "音声またはビデオの処理中に問題が発生しました。すぐに再試行します…",
    msgVideoLimitExceeded: "ビデオは、DeepHowがサポートする長さを超えました。2つ以上のワークフローで自動的に再アップロードされます。",
    msgVideoExceededMaximum: "ビデオは、Deephowがサポートする最大ワークフローの長さを超えています。 2つ以上のワークフローで自動的に分割されます",
    tagTranscoding: "処理",
    msgTranscoding: "Stephanie がワークフローをトランスコーディングしています...<br>お待ちください。",
    deleteWorkflow: "ワークフローを削除する",
    popupBtnDelete: "はい、削除します",
    popupMsgDeleteWorkflow: "ワークフローを削除しますか? このワークフローに関連するすべてのステップとデータが失われます。",
    noUnpublishedWorkflows: "非公開のワークフローはまだありません...",
    noPublishedWorkflows: "公開したワークフローはまだありません...",
    startCreatingWorkflow: "ワークフローの作成を開始しましょう。",
    startPublishingWorkflow: "ワークフローの公開を開始しましょう。",
    noSearchResult: "結果は見つかりませんでした...",
    trySearchAgain: "もう一度検索してみてください。",
    searchingForWorkflows: "ワークフローを検索しています...",
    secondsAgo: "0 秒前 | 1 秒前 | {count} 秒前",
    minutesAgo: "0 分前 | 1 分前 | {count} 分前",
    hoursAgo: "0 時間前 | 1 時間前 | {count} 時間前",
    daysAgo: "0 日前 | 1 日前 | {count} 日前",
    monthsAgo: "0 か月前 | 1 か月前 | {count} か月前",
    yearsAgo: "0 年前 | 1 年前 | {count} 年前",
    sAgo: "{count} 秒",
    minAgo: "{count} 分",
    hAgo: "{count} 時間",
    dAgo: "{count} 日",
    mAgo: "{count} か月",
    yAgo: "{count} 年",
    renameWorkflow: "ワークフローの名前を変更する",
    moveWorkflowTo: "ワークフローを移動する",
    duplicateWorkflow: "ワークフローを複製する",
    unpublishWorkflow: "ワークフローを非公開にする",
    shareKnowhow: "ノウハウを共有する",
    or: "または",
    clickHere: "ここをクリックする",
    andCopyMsg: "URL リンクをコピーしてメールで送信する",
    successfulSharing: "正常に共有しました。",
    send: "送信",
    typeHereAMessage: "ここにメッセージを入力してください",
    wellDone: "成功",
    numMore: "{count} 以上",
    moveWorkflow: "ワークフローを移動する",
    currentWorkspace: "現在のワークスペース",
    whereToMsg: "このワークフローを<br>どこに移動しますか?",
    move: "移動",
    alertSuccessfullyMoved: "ワークフローを正常に移動しました。",
    alertFailedMoved: "ワークフローの移動に失敗しました。",
    noNotificationsMsg: "現在、通知はありません。",
    betaSearch: "Stephanieの提案はまだベータ版にあることに注意してください...",
    relevantSteps: "関連するステップ:",
    relevance: "関連性",
    resultsFor: "<b>&ldquo;{input}&rdquo;</b> に対する{num}件の結果",
    resultFor: "<b>&ldquo;{input}&rdquo;</b> に対する{num}件の結果",
    byPublisher: "公開者",
    alertFailedShareWorkflow: "ワークフローを共有できませんでした。",
  },
  new: {
    newWorkflow: "新規ワークフロー",
    uploadVideo: "アップロード",
    recordScreen: "画面を録画する",
    replaceVideoSubtitle: "ドラッグまたはクリックしてビデオを置換します。",
    recordScreenSubtitle: "Stephanie が画面の記録を開始する準備ができました。",
    workflowName: "ワークフローの名前",
    workflowLanguage: "ワークフローの言語",
    addToWorkspace: "ワークスペースに追加する",
    upload: "アップロード",
    startRecording: "録画を開始する",
    cancel: "キャンセル",
    wrongUploadFile: "対応していないファイル形式です。次のファイル形式を選択してください。",
    limitFileDuration: "ファイルの時間は {duration} 分間まで対応しています。DeepHow Uploader App for Windows をご利用ください",
    limitFileDurationGen2: "ファイルの時間は {duration} 分間まで対応しています。",
    uploadingVideo: "ビデオのアップロード",
    uploadingVideoSubtitle: "Stephanie がビデオをアップロードするまでお待ちください。",
    renderingVideo: "ビデオのレンダリング/保存",
    renderingVideoSubtitle: "Stephanie がビデオをレンダリングするまでお待ちください。",
    almostDone: "あと少しで完成します。",
    uploadingStep1: "ステップ 1/2 - ワークフローを分析しています...",
    uploadingStep2: "ビデオをアップロードしています...",
    successSubtitle: "ワークフローが正常にアップロードされました。",
    goToEditor: "エディタへ移動する",
    goToContentManger: "コンテンツマネージャーに移動します",
    uploadingAlert: "ブラウザーのタブを変更または閉じると、アップロードのエラーが発生します。",
    recordingScreen: "録画画面",
    support15Min: "スクリーンキャプチャは最大 <b>15 分</b>まで対応しています。",
    hereWeGo: "録画を開始します!",
    remember15Min: "時間は 15 分であることに注意してください。",
    windowsAppAvailable: "Windows アプリ<br>が利用可能です。",
    windowsAppAvailableNews: "DeepHow Uploader アプリは、既に Windows で利用可能です。より大きなファイルのアップロードや、画面の録画が可能になりました。",
    downloadNow: "今すぐダウンロード!",
    deepHowWindowsApp: "DeepHow Windows アプリ",
    downloadAndInstalltheWindowsApp: "Windows アプリをダウンロードしてインストールする。",
    notAllowedToInstall: "上記のバージョンのインストールが許可されていない場合は、",
    downloadThisVersion: "こちらのバージョンをダウンロードし、",
    and: "そして",
    watchThisVideo: "使い方については、<span>こちらのビデオをご覧ください</span>。",
    // Added on 2022/01/19
    abiAuthorizationLabel: "この動画のすべての個人が、私の国に固有の<span>写真/動画録画の承認</span>とリリースに署名したことをここに認めます。",
    abiPrivacyLabel: "私はここで、Abinbev <Span>写真/ビデオ録音プライバシーステートメントを読んで理解したことに同意します。</span>",
    // Added on 2022/06/15
    importFromZoom: "ズームからインポートします",
    importFromZoomSteps: "{totalStep}のステップ{step}",
    selectVideosFrom: "からビデオを選択します",
    videoSelected: "<span> 0 </span>ビデオ選択 | <span> 1 </span>ビデオ選択 | <span> {count} </span>ビデオ選択",
    workflowInfo: "ワークフロー情報",
    newImport: "新しいインポート",
    orBackToEditor: "または<span>編集者</span>に戻ります",
    importFromZoomSuccessMsg: "あなたのビデオはすぐにアップロードされ始めます。編集に利用できるまでには時間がかかる場合があります。",
    zoomFailedUploadMsg: "ズームビデオのアップロードに失敗しました",
    zoomPeriodLastMonth: "先月",
    zoomPeriodPastMonth: "過去{n}ヶ月",
    loadingZoomRecordings: "ビデオの読み込み",
    zoomNoRecordingsInfo: "選択した期間には録音が見つかりませんでした。",
    yourVideoExceedTwoHours: "ビデオは2時間を超えてアップロードできません。",
    yourVideoExceed3GSize: "ビデオは3 GBを超えてアップロードできません。",
    yourVideoExceedTwoHoursAnd3GSize: "ビデオは2時間、3GBを超えてアップロードできません。",
    videoUploadWarningMessage: "ドラッグまたはクリックして、ビデオ（MP4またはWebMのみ）最大インポートします。サイズは3 GBで最大2時間です。",
    videoUploadWarningMessageGen2: "ドラッグまたはクリックして、ビデオ（MP4またはWebmのみ）最大インポートします。最大40分。",
  },
  auth: {
    aboutDeephow: "DeepHow について",
    authTitle: "熟練技能者養成のための初の AI ソリューション。",
    authIntro: "DeepHow は、インタラクティブなハウツー動画をベースにした AI 搭載の学習プラットフォームを通じて、製造、サービス、修理におけるスキルギャップを解消します",
    allRightReserved: "無断複写・複製・転載を禁じます。",
    logIn: "ログイン",
    logOut: "ログアウト",
    logInSubtitle: "詳細を入力してログインしてください。",
    logInSubtitleSso: "以下をクリックしてログインしてください。",
    emailOrId: "メールまたは従業員 ID",
    hello: "こんにちは、",
    passwordSubtitle: "パスワードを入力してください。",
    password: "パスワード",
    forgotPassword: "パスワードを忘れた",
    forgotPasswordSubtitle: "新しいパスワードを作成するためのリンクを送信しますので、メールアドレスを入力してください。",
    email: "メール",
    resetPassword: "パスワードをリセットする",
    alertResetSuccess: "メールの受信ボックスを確認してください。",
    alertResetFailed: "パスワードの復元に失敗しました。",
    alertInvalidId: "従業員 ID が無効です。",
    successMsg: "メールを確認し、リンクをクリックしてアカウントの新しいパスワードを定義してください。",
    logInWithSSO: "SSO でログインする",
    loggingInWithSSO: "SSO でログインしています...",
    logInWithGoogle: "Google でログインする",
    logInWithMicrosoft: "Microsoft でログインする",
    loadingSso: "SSO を読み込んでいます...",
    about: "製品情報",
    // 12/17/2021 iframe
    loginToYourAccount: "<b>ログイン</b>アカウントに",
    pleaseInputYourPassword: "パスワードを入力してください。",
    backToLogin: "ログインに戻る",
    // added 03/02/2022
    wrongPassword: "間違ったパスワード。",
    // Shared Account
    sharedDevice: "共有デバイス",
    loginExistingAccount: "既存のアカウントでログインします",
    createNewAccount: "新しいアカウントを作成します",
    noUsersMsg: "この共有デバイスにまだログインしているユーザーはいません...",
    insertIDMsg: "従業員IDを挿入してください",
    createIdUserMsg: "私たちは<b>ほとんどそこにいます</b>！<br>以下の詳細を入力してください。",
    confirmEmployeeID: "従業員IDを確認します",
    welcomeToDeepHow: "DeepHowへようこそ、<b> {name} </b>",
    confirmWorkspaceTeamMsg: "ワークスペースを確認し、チームを選択してください！",
    LetsGetStarted: "始めましょう！",
    // added 05/26/2022
    useSsoLogin: "電子メール/パスワードログインは、組織のログインを有効にしていません。 SSOでログインしてください。",
    // added 02/17/2023
    cookieAlert: "<a href='{pdfUrl}' target='_blank'>プライバシーポリシー</a>で詳述されているように、Cookieの使用を受け入れます。",
    useCookie: "クッキーを使用しています！",
    useCookieContext: "Cookieを使用して、DeepHowを使用して素晴らしい体験をすることを確認してください！",
    privacyPolicy: "プライバシーポリシー",
    acceptCookies: "クッキーを受け入れます"
  },
  rules: {
    required: "必須です。",
    invalidEmail: "無効なメールアドレスです。",
    noSpace: "スペースの追加はできません。",
    validPassword: "パスワードは 6 文字以上で、数字、小文字および大文字の英字を含む必要があります。",
    maxPassword: "最大 16 文字。",
    mustMatch: "パスワードは一致する必要があります。",
    max25: "最大 25 文字。",
    max90: "最大 90 文字。",
    max100: "最大 100 文字。",
    max140: "最大 140 文字。",
    numMaxCharacters: "最大 {count} 文字。",
    min6: "最小 6 文字。",
    noWhiteSpace: "タイトルにテキストを入力します。",
    noAllSpace: "名前にスペースを使用することはできません。",
    invalidEmployeeID: "無効な従業員 ID です。",
    invalidUrl: "無効な URL です。",
    mustMatchId: "従業員IDは一致する必要があります",
    canNotEnterSharedAccount: "共有アカウントIDを挿入できません。",
    validPasswordMin10: "パスワードは、少なくとも10文字である必要があり、番号、特別文字、下級文字、上品な文字が含まれます。",
    invalidName: "無効な名前。",
  },
  alert: {
    success: "成功",
    info: "情報",
    alert: "通知",
    error: "エラー",
    dontShowThisMsgAgain: "二度とこのメッセージを表示しないでください",
    unexpectedError: "予期しないエラーが発生しました。もう一度やり直すか、DeepHowにお問い合わせください。",
  },
  skills: {
    skillsMatrix: "スキルマトリックス",
    createSkill: "スキルを作成する",
    skillsCreated: "作成したスキル",
    skillsPublished: "公開したスキル",
    skillsIn: "スキル ",
    hintSearchSkills: "スキルを検索します...",
    lastUpdated: "最終更新日",
    skillsPerPage: "1 ページあたりのスキル数",
    usersAssigned: "割り当てられたユーザー",
    status: "ステータス",
    createdBy: "作成者:",
    skillsSelected: "つのスキルが選択されました",
    updatedBy: "更新者:",
    deleteSkill: "スキルを削除する",
    popupMsgDeleteSkill: "このスキルを削除しますか?",
    deleteSkills: "スキルを削除する",
    popupMsgDeleteSkills: "これらのスキルを削除しますか?",
    clearAll: "すべてクリアする",
    clearAllFilters: "すべてのフィルターをクリアする",
    clearFilter: "フィルターをクリアする",
    delete: "削除",
    youAreViewing: "現在閲覧中",
    numWorkflows: "0 つのワークフロー | 1 つのワークフロー | {count} つのワークフロー",
    searchIn: "検索場所:",
    loadingSkillMatrix: "スキルマトリックスを読み込んでいます...",
    createSkillSubtitle: "名前とアップロードする場所を選択してください",
    skillName: "スキルの名前",
    create: "作成",
    editSkill: "スキルを編集する",
    popupMsgEditSkill: "編集モードにすると、スキルは自動的に非公開になりますが、続行しますか?",
    publishSkill: "スキルを公開する",
    popupMsgPublishSkill: "スキルを公開すると、すべてのユーザーにスキルが表示されますが、公開しますか?",
    alertFailedSaveSkill: "スキルの保存に失敗しました。",
    alertSkillSuccessfullyPublished: "スキルが正常に公開されました。",
    alertSkillSuccessfullyUnpublished: "スキルが正常に非公開になりました。",
    alertSkillSuccessfullySaved: "スキルが正常に保存されました。",
    alertFailedSavedSkill: "スキルの保存に失敗しました",
    alertFailedPublishSkill: "スキルの公開に失敗しました",
    alertSkillSuccessfullyDeleted: "スキルが正常に削除されました",
    alertSkillFailedDeleted: "スキルの削除に失敗しました",
    content: "コンテンツ",
    addContent: "コンテンツを追加する",
    workflowDeleted: "削除されたワークフロー",
    workflowsSelected: "選択されたワークフロー | 選択されたワークフロー | 選択されたワークフロー",
    selectAll: "すべて選択",
    remove: "削除",
    noContentMsg: "スキルにはまだコンテンツがありません。",
    assignUsers: "ユーザーを割り当てる",
    noUserMsg: "トレーニングに割り当てられたユーザーがまだいません。",
    numUsers: "<b>0</b> 人のユーザー | <b>1</b> 人のユーザー | <b>{count}</b> 人のユーザー",
    assignedOn: "割り当てた日",
    certifiedBy: "認定者",
    progress: "進行状況",
    aletUserSuccessfullyRemoved: "ユーザーは正常に削除されました。",
    removeUser: "ユーザーを削除する",
    popupMsgRemoveUserFromSkill: "このスキルから <b>{name}</b> を削除しますか?",
    assign: "割り当てる",
    successfulAssigned: "正常に割り当てられました",
    AddedToSkill: "スキルに追加されました。",
    loadingSkills: "スキルを読み込んでいます...",
    searchingForSkills: "スキルを検索しています...",
    toDo: "やるべきこと",
    completed: "完了",
    assignedBy: "割り当て担当者",
    min: "分",
    nextPart: "次のパート",
    noSkillsAssignedMsg: "割り当てられているスキルはまだありません。",
    noSkillsAssignedMsg2: "何か新しい内容があればお知らせします。",
    noCompletedSkillsMsg: "完了したスキルはまだありません。",
    noCompletedSkillsMsg2: "スキルの習得を開始しましょう!",
    noDataSkillsMatrix: "スキルマトリックスにはまだデータがありません...",
    certificate: "認定証",
    certificateMsg: "このスキルを無事に終了すると、ユーザーに認定証が発行されます。",
    selectCertificateMsg: "認定証を承認できるユーザーを以下から選択してください。",
    validateSkill: "スキルを承認する",
    validateSkillMsg: "{skill} というスキルにおいて、<b>{name}</b>を承認しますか?<br />この操作を元に戻すことはできません。",
    yesValidate: "はい、承認します",
    noValidate: "いいえ",
    certifier: "認定者",
    signaturesOn: "承認日",
    teamProgress: "チームの進行状況",
    openTeam: "チームを開く",
    removeCertificate: "認定証を削除する",
    removeCertificateMsg: "認定証を承認できるすべてのユーザーを削除しますが、よろしいですか?",
    yesRemove: "はい、削除します",
    mustAddUserMsg: "* ユーザーが追加されていない場合、認定証はアクティブになりません。",
    skillCover: "スキルカバー",
    alertSuccessfullyUpdatedSkillCover: "スキルカバーが正常に更新されました。",
    alertFailedUpdatedSkillCover: "スキルカバーの更新に失敗しました。",
    closeSkill: "密接なスキル",
    allWorkflowsDeleted: "すべてのワークフローは、このスキルで削除されます。",
    waitingForValidation: "承認を待っています",
    validatedBy: "承認する：",
    skillWasUnpublished: "スキルは未発表でした",
    skillWasUnpublishedMsg: "このスキルが再び利用可能になったらお知らせします。とりあえず、あなたは皆良いです！",
    notAssignedToSkill: "あなたはこのスキルに割り当てられていません。",
    notAssignedToSkillMsg: "申し訳ありませんが、このページは、このスキルに割り当てられたユーザーにのみAvavaiableです。",
    remindPendingUsers: "保留中のユーザーを思い出させます",
    alertSuccessRemindPendingUsers: "保留中のユーザーを正常に思い出させました。",
    alertFailedRemindPendingUsers: "保留中のユーザーに思い出させることができませんでした。",
    alertNoPendingUsers: "保留中のユーザーは見つかりませんでした。",
    numVideo: "0ビデオ| 1つのビデオ| {count}個のビデオ",
    // Skill Deadline
    deadline: "締め切り",
    deadlineMsg: "ユーザーがこのスキルを視聴し終えて、世界的な期間を設定します。締め切りの後、保留中のユーザーに通知されます...",
    deadlineForThisSkill: "このスキルの締め切りをアクティブにします。",
    certificateForThisSkill: "このスキルの証明書をアクティブ化します。",
    deadlineStartsMsg: "ユーザーが割り当てられた日付を考慮して、期間がカウントされ始めます。",
    selectAPeriod: "ピリオドを選択します",
    finished: "終了した",
    editDeadline: "締め切りを編集します",
    editDeadlineMsg: "締め切りを編集することにより、前の締め切りはもはや有効ではなく、このスキルのすべてのユーザーに新しい期間が適用されます。 <br> <br>続けたいですか？",
    alertSuccessNewDeadline: "現在、すべてのユーザーに新しい締め切りが適用されます。",
    alertSuccessRemoveDeadline: "スキルの締め切りは正常に削除されました。",
    alertFailedDeadline: "スキルの締め切りを更新できませんでした。",
    dueToday: "今日が提出日",
    turnOffDeadlineMsg: "締め切りをオフにすることにより、以前のデータが失われます。次回オンになると、すべてのユーザーの開始日をリセットします。 <br> <br>続けたいですか？"
  },
  time: {
    numDays: "0日 | 1日 | {count}日",
    numWeeks: "0週間 | 1週間 | {count}週間",
    numMonths: "0ヶ月| 1ヶ月| {count}ヶ月",
    numDaysLeft: "左0日 | 左1日 | {count}残りの日",
    numDaysDelayed: "0日遅れ | 1日遅れ | {count}日が遅れました",
  },
  notification: {
    sharedWorkflow: "ワークフローを共有しました",
    assignedNewSkill: "スキルを共有しました",
    sentAnnouncement: "お知らせを送信しました",
    needValidation: "確認が必要です",
    noNotificationsMsg: "現在、通知はありません",
    justNow: "ちょうど今",
    updatedSkill: "スキルを更新しました",
    unpublishedSkill: "未発表のスキル",
    sentAReminder: "このスキルについてのリマインダーをお送りしました。彼があなたの進歩を追跡できるように見始めてください。",
    assignedNewSkillDue: "新しいスキルを割り当てました。あなたはそれを見終わらせる<span>{time}</span>を持っています。",
    delayedSkillMsg: "<span>スキルが遅</span>れているがあります。軌道に戻るために今見始めてください！",
    daysLeftMsg: "このスキルの視聴を終了するには、<span>残り{time}</span>しかありません。急いで遅れることはありません！",
    askToReviewWorkflow: "ワークフローを確認するように頼みました。",
    reactedWorkflowMsg: "<span>{user}</span>があなたのワークフローに反応しました。",
    reactedWorkflowMsgTwoUsers: "<span>{user1}と{user2}</span>がワークフローに反応しました。",
    reactedWorkflowMsgMoreUsers: "<span>{user1}さん、{user2}さん、他{count}人</span>があなたのワークフローに反応しました。",
  },
  mediaPicker: {
    insertFullWorkflow: "ワークフロー全体を挿入する",
    selectWorkflowStep: "ワークフローのステップを選択する",
    stepSelected: "選択したステップ",
  },
  zoomIntegration: {
    settingsTitle: "Zoom 設定",
    settingsSubtitle: "統合",
    deleteCopy: "録画したビデオのコピーをZoom Cloudから削除します",
    goToDeephow: "DeepHowに移動します",
    landingSuccess: "<b>DeepHowノウハウビデオアプリ</b>インストールが成功しました。",
    landingError: "<b>DeepHowノウハウビデオアプリ</b>のインストール中にエラーが発生しました。サポートについては、<a href = 'mailto: service@deephow.com'>service@deephow.com</a>にお問い合わせください。",
  },
  reactions: {
    likeIt: "いいね",
    greatContent: "素晴らしいコンテンツ",
    wellPresented: "明確な表現",
    niceProduction: "素敵なプロダクション",
  },
  maven: {
    // TODO: maven chat added on 03/15/2023
    mavenChat: "Chat",
    searchInWorkspace: "Search in {workspace}",
    showReferences: "Show references",
    hideReferences: "Hide references",
    helloMessage: "Hello {userName},",
    assistMessage: "How can I assist you today?",
    askMeAnything: "Ask me anything...",
    tryOtherQuestionMessage: "You can try asking other question.",
    audioIsReady: "Please recording your audio clip in English...",
    audioLimitationTime: "The audio limitation time is 3 mins.",
    clearChatHistory: "Clear chat history",
    newLineHint: "new line: shift + Enter",
    // TODO: maven map added on 04/14/2023
    map: "Map",
    resetMap: "reset map",
    filterWorkspaces: "filter workspaces",
    showQuestions: "show questions",
    hideQuestions: "hide questions",
    showClusters: "show clusters",
    hideClusters: "hide clusters",
    question: "Question",
    loadingMap: "Loading map...",
    clearChatHistoryConfirmation: "Clearing the chat history will start a new chat. Are you sure?",
    clearConfirm: "Yes, clear",
  },
  $vuetify: {
    dataIterator: {
      rowsPerPageText: "1 ページあたりのアイテム数",
      rowsPerPageAll: "すべて",
      pageText: "{2} の {0} から {1} まで",
      noResultsText: "一致するレコードが見つかりませんでした",
      nextPage: "次のページ",
      prevPage: "前のページ",
    },
    dataTable: {
      rowsPerPageText: "1 ページあたりの行数:"
    },
    noDataText: "データなし",
    carousel: {
      prev: "前のビジュアル",
      next: "次のビジュアル"
    }
  }
};
