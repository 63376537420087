import envConfig from "../../env";

export const IS_SUPPORT_WORKSPACE_GROUP = envConfig.workspaceGroup;
export const REDIRECT_TO_STUDIO_WORKSPACE = IS_SUPPORT_WORKSPACE_GROUP
  ? {
      name: "StudioWorkspace",
      i18nKey: "editor.goBack",
    }
  : {
      name: "EditorExplorer",
      i18nKey: "editor.goBackToEditorHome",
    };
