export const namespaced = true;
import { getOrganizationSettings, updateOrganizationSettings } from "@/server/organization-settings-server.js";

export const state = {
  enableNameChange: true,
  enableWorkflowComments: false,
  enableZoomIntegration: false,
  enableScormDownload: false,
  enableUlIntegration: false,
};

export const getters = {
  getters_enable_name_change(state) {
    return state.enableNameChange;
  },
  getters_enable_workflow_comments(state) {
    return state.enableWorkflowComments;
  },
  getters_enable_scorm_download(state) {
    return state.enableScormDownload;
  },
  getters_enable_ul_integration(state) {
    return state.enableUlIntegration;
  },
};

export const mutations = {
  SET_NAME_CHANGE_SETTINGS(state, enabled) {
    state.enableNameChange = enabled;
  },
  SET_WORKFLOW_COMMENTS_SETTINGS(state, enabled) {
    state.enableWorkflowComments = enabled;
  },
  SET_ZOOM_INTEGRATION_SETTINGS(state, enabled) {
    state.enableZoomIntegration = enabled;
  },
  SET_SCORM_DOWNLOAD_SETTINGS(state, enabled) {
    state.enableScormDownload = enabled;
  },
  SET_UL_INTEGRATION_SETTINGS(state, enabled) {
    state.enableUlIntegration = enabled;
  },
};

export const actions = {
  async getOrgPermissionsSettings({ commit }, organization) {
    const settings = await getOrganizationSettings(organization);

    // Set `allowNameChange` default to `true`.
    const changeNameEnabled =
      !settings || !settings.userPermissions || settings.userPermissions.allowNameChange ? true : false;
    commit("SET_NAME_CHANGE_SETTINGS", changeNameEnabled);

    const workflowCommentsEnabled =
      !settings || !settings.customizedPermissions || !settings.customizedPermissions.enableComments ? false : true;
    commit("SET_WORKFLOW_COMMENTS_SETTINGS", workflowCommentsEnabled);

    const customFeatures = settings.customFeatures;

    const zoomIntegrationEnabled = !settings || !customFeatures || !customFeatures.enableZoomIntegration ? false : true;
    commit("SET_ZOOM_INTEGRATION_SETTINGS", zoomIntegrationEnabled);

    const scormDownloadEnabled = !settings || !customFeatures || !customFeatures.enableSCORM ? false : true;
    commit("SET_SCORM_DOWNLOAD_SETTINGS", scormDownloadEnabled);

    const ulIntegrationEnabled = !settings || !customFeatures || !customFeatures.enableUlIntegration ? false : true;
    commit("SET_UL_INTEGRATION_SETTINGS", ulIntegrationEnabled);
  },
  async updateNameChangeSettings({ commit }, { organization, updateData }) {
    await updateOrganizationSettings({ organization, updateData });
    commit("SET_NAME_CHANGE_SETTINGS", !state.enableNameChange);
  },
  async updateWorkflowCommentsSettings({ commit }, { organization, updateData }) {
    await updateOrganizationSettings({ organization, updateData });
    commit("SET_WORKFLOW_COMMENTS_SETTINGS", !state.enableWorkflowComments);
  },
};
