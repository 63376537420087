import Heap from "./heap";
import Matomo from "./matomo";
import environment from "../../../env";

const anaLyticsMap = {
  heap: Heap,
  matomo: Matomo,
};

let currentAnalytics = null;
let secondAnalytics = null;
const Analytics = {
  initialize(type, options = {}) {
    currentAnalytics = anaLyticsMap[type];
    currentAnalytics.initialize(options);
    secondAnalytics = Heap;
    secondAnalytics.initialize({ clientId: environment.heap[environment.env] });
  },
  setUserUid(uid) {
    secondAnalytics.setUserUid(uid);
    currentAnalytics.setUserUid(uid);
  },
  setUserOrganization(organizationId) {
    secondAnalytics.setUserOrganization(organizationId);
  },
  setUserGroup(groupId) {
    secondAnalytics.setUserGroup(groupId);
  },
  setEnteredEditorSteps(workflowId, matomoParams) {
    secondAnalytics.setEnteredEditorSteps(workflowId);
    currentAnalytics.setTrack(matomoParams);
  },
  setEnteredEditorTranscription(workflowId, matomoParams) {
    secondAnalytics.setEnteredEditorTranscription(workflowId);
    currentAnalytics.setTrack(matomoParams);
  },
  setEventProperties(events = {}) {
    secondAnalytics.setEventProperties(events);
  },
  removeEventProperties(eventName = "") {
    secondAnalytics.removeEventProperties(eventName);
  },
  setTrack(trackName, options = {}, matomoParams) {
    secondAnalytics.setTrack(trackName, options);
    if (matomoParams) {
      currentAnalytics.setTrack(matomoParams);
    }
  },
  addUserProperties(options = {}) {
    secondAnalytics.addUserProperties(options);
  },
  getSessionId() {
    return secondAnalytics.getSessionId();
  },
  setInputChangeTrack(options = {}) {
    return currentAnalytics.setTrack(options);
  },
};
export default Analytics;
