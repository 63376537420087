<template>
  <div class="d-admin-permissions">
    <div class="content-title text-style">{{ $t("admin.permissions") }}</div>
    <div class="permissions-scroll">
      <!-- DeepHow Products -->
      <div class="products-div">
        <div class="products-title text-style">{{ $t("admin.products") }}</div>
        <p class="products-subtitle text-style">{{ $t("admin.roleAccessToProduct") }}</p>
        <div>
          <div class="product-container" v-for="product in displayProducts" :key="product">
            <p class="product-title text-style">{{ $t("admin." + product) }}</p>
            <div class="checkbox-container">
              <v-checkbox
                class="checkbox"
                color="#4689F3"
                v-for="(role, idx) in dbRoles"
                v-model="products[product]"
                :key="`${product}-${role}`"
                :label="role"
                :value="role"
                @change="onClickCheckbox(product, idx, role)"
                :disabled="checkCannotChange(product, role)"
                :class="{ 'disable-role': checkCannotChange(product, role) }"
                :autotest="getAutoTest(product, role)"
              >
                <div slot="label" class="checkbox-label text-style">{{ $t("admin." + roles[idx]) }}</div>
              </v-checkbox>
            </div>
          </div>
        </div>
      </div>

      <!-- Change Profile Name -->
      <admin-setting-change-profile-name :drawerOpenPopupStyle="drawerOpenPopupStyle" />

      <!-- Workflow Comments -->
      <admin-setting-workflow-comments v-if="showCommentsSetting" :drawerOpenPopupStyle="drawerOpenPopupStyle" />

      <!-- Workflow Sharing -->
      <div v-if="!getter_is_gen2" class="switch-control-div mt-3">
        <div class="products-title text-style">{{ $t("admin.workflowSharing") }}</div>
        <p class="products-subtitle text-style">{{ $t("admin.allowUserstoSharePublicLinks") }}</p>
        <div class="switch-div">
          <v-switch
            v-model="allowPublicLinks"
            color="#4689F3"
            class="vertical-center"
            :readonly="true"
            @click.stop="showConfirmation = 'public-links'"
            autotest="workflow-sharing-toggle"
          ></v-switch>
        </div>
      </div>

      <!-- Workflow Review -->
      <div class="switch-control-div mt-3">
        <div class="products-title text-style">{{ $t("admin.workflowReview") }}</div>
        <p class="products-subtitle text-style">{{ $t("admin.workflowReviewDescription") }}</p>
        <div class="switch-div">
          <v-switch
            v-model="allowReview"
            color="#4689F3"
            class="vertical-center"
            :readonly="true"
            @click.stop="showConfirmation = 'review'"
            autotest="workflow-download-toggle"
          ></v-switch>
        </div>
      </div>

      <!-- Workflow Download -->
      <div v-if="!getter_is_gen2" class="switch-control-div mt-3">
        <div class="products-title text-style">{{ $t("admin.workflowDownload") }}</div>
        <p class="products-subtitle text-style">{{ $t("admin.allowUsersToDownloadWorkflows") }}</p>
        <div class="switch-div">
          <v-switch
            v-model="allowDownloads"
            color="#4689F3"
            class="vertical-center"
            :readonly="true"
            @click.stop="showConfirmation = 'downloads'"
            autotest="workflow-download-toggle"
          ></v-switch>
        </div>
      </div>

      <!-- Auto Logout -->
      <div class="switch-control-div mt-3 pb-0">
        <div class="products-title text-style">{{ $t("admin.autoLogout") }}</div>
        <p class="products-subtitle text-style">{{ $t("admin.autoLogoutAfterSelectedTime") }}</p>
        <div class="logout-switch-div">
          <v-switch
            v-model="allowAutoLogout"
            color="#4689F3"
            :readonly="true"
            @click.stop="showConfirmation = 'auto-logout'"
            autotest="auto-logout-toggle"
          ></v-switch>
        </div>
        <v-radio-group v-model="autoLogoutTimeout" row>
          <v-radio
            :label="$tc('admin.numMinutes', 5)"
            value="5"
            color="#4689F3"
            :disabled="!allowAutoLogout"
            :readonly="true"
            @click.stop="(showConfirmation = 'logout-min'), (targetNum = '5')"
            autotest="logout-after-5-minutes-radio"
          ></v-radio>
          <v-radio
            :label="$tc('admin.numMinutes', 15)"
            value="15"
            color="#4689F3"
            :disabled="!allowAutoLogout"
            :readonly="true"
            @click.stop="(showConfirmation = 'logout-min'), (targetNum = '15')"
            autotest="logout-after-15-minutes-radio"
          ></v-radio>
          <v-radio
            :label="$tc('admin.numMinutes', 30)"
            value="30"
            color="#4689F3"
            :disabled="!allowAutoLogout"
            :readonly="true"
            @click.stop="(showConfirmation = 'logout-min'), (targetNum = '30')"
            autotest="logout-after-30-minutes-radio"
          ></v-radio>
        </v-radio-group>
      </div>

      <!-- Shared account -->
      <admin-setting-shared-account v-if="$employeeIdLogin" :drawerOpenPopupStyle="drawerOpenPopupStyle" />
    </div>

    <!-- confirmation: role permission -->
    <d-change-permission-confirmation
      v-if="showConfirmation == 'change-role-permission'"
      :style="drawerOpenPopupStyle"
      :title="$t('admin.rolesPermission')"
      :content="$t('admin.rolesPermissionMessage')"
      @close-popup="resetCheckboxStatus"
      @change-permission="changePermission(selectedCheckbox), (showConfirmation = '')"
    ></d-change-permission-confirmation>

    <!-- confirmation: workflow sharing -->
    <d-change-permission-confirmation
      v-if="showConfirmation === 'public-links'"
      :style="drawerOpenPopupStyle"
      :title="$t('admin.workflowSharing')"
      :content="
        allowPublicLinks
          ? $t('admin.preventWorkflowSharingMessage') + '<p>* ' + $t('admin.changeWorkflowSharingExplanation') + '</p>'
          : $t('admin.allowWorkflowSharingMessage') + '<br>' + $t('admin.changesMadeImmediately')
      "
      @close-popup="showConfirmation = ''"
      @change-permission="
        changePublicLinksPermission(!allowPublicLinks), (allowPublicLinks = !allowPublicLinks), (showConfirmation = '')
      "
    ></d-change-permission-confirmation>

    <!-- confirmation: workflow review -->
    <d-change-permission-confirmation
      v-if="showConfirmation == 'review'"
      :style="drawerOpenPopupStyle"
      :title="$t('admin.workflowReview')"
      :content="
        allowReview
          ? $t('admin.preventReviewWorkflowsMessage') + '<br>' + $t('admin.changesMadeImmediately')
          : $t('admin.allowReviewWorkflowsMessage') + '<br>' + $t('admin.changesMadeImmediately')
      "
      @close-popup="showConfirmation = ''"
      @change-permission="changeReviewPermission(!allowReview), (allowReview = !allowReview), (showConfirmation = '')"
    ></d-change-permission-confirmation>

    <!-- confirmation: workflow downloads -->
    <d-change-permission-confirmation
      v-if="showConfirmation == 'downloads'"
      :style="drawerOpenPopupStyle"
      :title="$t('admin.workflowDownload')"
      :content="
        allowDownloads
          ? $t('admin.preventDownloadWorkflowsMessage') + '<br>' + $t('admin.changesMadeImmediately')
          : $t('admin.allowDownloadWorkflowsMessage') + '<br>' + $t('admin.changesMadeImmediately')
      "
      @close-popup="showConfirmation = ''"
      @change-permission="
        changeDownloadPermission(!allowDownloads), (allowDownloads = !allowDownloads), (showConfirmation = '')
      "
    ></d-change-permission-confirmation>

    <!-- confirmation: account auto logout setting -->
    <d-change-permission-confirmation
      v-if="showConfirmation == 'auto-logout'"
      :style="drawerOpenPopupStyle"
      :title="$t('admin.autoLogout')"
      :content="
        allowAutoLogout
          ? $t('admin.disableAutoLogoutMessage') + '<br>' + $t('admin.changesMadeImmediately')
          : $t('admin.enableAutoLogoutMessage') + '<br>' + $t('admin.changesMadeImmediately')
      "
      @close-popup="showConfirmation = ''"
      @change-permission="
        changeAutoLogoutPermission(!allowAutoLogout), (allowAutoLogout = !allowAutoLogout), (showConfirmation = '')
      "
    ></d-change-permission-confirmation>

    <!-- confirmation: account auto logout time -->
    <d-change-permission-confirmation
      :style="drawerOpenPopupStyle"
      v-if="showConfirmation == 'logout-min'"
      :title="$t('admin.autoLogout')"
      :content="$t('admin.changeLogoutTimeMessage', { num: targetNum }) + '<br>' + $t('admin.changesMadeImmediately')"
      @close-popup="showConfirmation = ''"
      @change-permission="
        changeAutoLogoutTimeoutPermission(targetNum), (autoLogoutTimeout = targetNum), (showConfirmation = '')
      "
    ></d-change-permission-confirmation>
  </div>
</template>

<script>
import Vue from "vue";
import IconBase from "../IconBase.vue";
import DIconDone from "../icons/DIconDone.vue";
import DChangePermissionConfirmation from "@/components/ui_components/DChangePermissionConfirmation.vue";
import AdminSettingChangeProfileName from "@/components/DAdmin/Settings/AdminSettingChangeProfileName.vue";
import AdminSettingWorkflowComments from "@/components/DAdmin/Settings/AdminSettingWorkflowComments.vue";
import AdminSettingSharedAccount from "@/components/DAdmin/Settings/AdminSettingSharedAccount.vue";
import MixinUser from "../MixinUser.vue";
import MixinDB from "../MixinDB.vue";
import MixinIdle from "../MixinIdle.vue";
import environment from "@/../env.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DAdminPermissions",
  props: {
    drawerOpenPopupStyle: Object,
  },
  watch: {},
  mixins: [MixinUser, MixinDB, MixinIdle],
  created() {
    this.customizedPermissions = this.getCustomizedPermissions();
    this.allowPublicLinks = !this.customizedPermissions.disablePublicLinks;
    this.allowReview = this.customizedPermissions.enableWorkflowReview;
    this.allowDownloads = !this.customizedPermissions.disableDownloads;
    this.allowAutoLogout = this.customizedPermissions.allowAutoLogout;
    this.autoLogoutTimeout = this.customizedPermissions.autoLogoutTimeout;
  },
  async mounted() {
    this.getOrgPermissionsSettings({ organization: this.$organization });
    this.getPermissions();
  },
  data() {
    return {
      selected: null,
      showConfirmation: "",
      selectedCheckbox: {},
      roles: ["organizationAdmin", "groupAdmin", "publisher", "viewer"],
      dbRoles: ["admin-organization", "admin-group", "publisher", "viewer"],
      products: {
        analytics: [],
        // categoriesCreation: [],
        skillsManager: [],
      },
      disabledCheckbox: false,
      orgAdmin: ["orgAdmin"],
      defaultPermission: {
        analytics: ["admin-organization", "admin-group"],
        categoriesCreation: ["admin-organization"],
        skillsManager: ["admin-organization", "admin-group", "publisher"],
      },
      cannotChangePermission: {
        analytics: ["admin-organization"],
        categoriesCreation: ["admin-organization", "publisher", "viewer"],
        skillsManager: ["admin-organization", "viewer"],
      },
      allowPublicLinks: true,
      allowReview: true,
      allowDownloads: true,
      allowAutoLogout: false,
      autoLogoutTimeout: "15",
      targetNum: "",
      customizedPermissions: null,
    };
  },
  computed: {
    ...mapGetters(["getter_is_gen2"]),
    displayProducts() {
      return Object.keys(this.products).sort();
    },
    showCommentsSetting() {
      // comments part is not enabled for China version
      return environment.backendType !== "ali";
    },
  },
  components: {
    IconBase,
    DIconDone,
    DChangePermissionConfirmation,
    AdminSettingChangeProfileName,
    AdminSettingWorkflowComments,
    AdminSettingSharedAccount,
  },
  methods: {
    ...mapActions("permission", ["getOrgPermissionsSettings"]),
    getAutoTest(product, role) {
      if (product === "analytics") {
        switch (role) {
          case "admin-organization":
            return "analytics-org-admin-checkbox";
          case "admin-group":
            return "analytics-workspace-admin-checkbox";
          case "publisher":
            return "analytics-publishers-checkbox";
          case "viewer":
            return "analytics-viewers-checkbox";
          default:
            return "";
        }
      }
      if (product === "skillsManager") {
        switch (role) {
          case "admin-organization":
            return "skills-management-org-admin-checkbox";
          case "admin-group":
            return "skills-management-workspace-admin-checkbox";
          case "publisher":
            return "skills-management-publishers-checkbox";
          case "viewer":
            return "skills-management-viewers-checkbox";
          default:
            return "";
        }
      }
    },
    checkCannotChange(product, role) {
      if (!this.cannotChangePermission[product]) return true;
      return this.cannotChangePermission[product].includes(role);
    },
    updatePermissionsToDb() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .updateDocument("settings", self.$organization, {
            permissions: self.products,
          })
          .then(() => {
            Vue.prototype.$clientSettings.permissions = self.products;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPermissions() {
      if (this.$clientSettings.permissions) {
        this.products = this.$clientSettings.permissions;
        // add categoriesCreation if didn't set up before
        // if (!this.products["categoriesCreation"]) {
        //   this.products["categoriesCreation"] = this.defaultPermission[
        //     "categoriesCreation"
        //   ];
        // }
      } else {
        Object.keys(this.products).forEach((key) => {
          this.products[key] = this.defaultPermission[key];
        });
      }
    },
    onClickCheckbox(product, idx, role) {
      this.showConfirmation = "change-role-permission";
      this.selectedCheckbox = { product, idx, role };
    },
    resetCheckboxStatus() {
      const { product, role } = this.selectedCheckbox;
      const products = { ...this.products };
      const roleIndex = products[product].indexOf(role);

      // `change` event switch the checkbox status before confirm, so we need to revert the status.
      if (roleIndex < 0) {
        products[product].push(role);
      } else {
        products[product].splice(roleIndex, 1);
      }

      this.products = products;
      this.selectedCheckbox = {};
      this.showConfirmation = "";
    },
    changePermission({ product, idx, role }) {
      const products = { ...this.products };
      const endIdx = products[product].includes(role) ? idx + 1 : idx;
      const roles = this.dbRoles.slice(0, endIdx);

      products[product] = roles;
      this.products = products;
      this.updatePermissionsToDb();
    },
    async updateCustomizedPermissionsToDb() {
      try {
        await this.updateDocument("settings", this.$organization, {
          customizedPermissions: this.customizedPermissions,
        });
        Vue.prototype.$clientSettings.customizedPermissions = this.customizedPermissions;
        this.updateIdleSetting(
          this.customizedPermissions.allowAutoLogout,
          this.customizedPermissions.autoLogoutTimeout
        );
      } catch (e) {
        console.error(e);
      }
    },
    changePublicLinksPermission(allowPublicLinks) {
      this.customizedPermissions.disablePublicLinks = !allowPublicLinks;
      this.updateCustomizedPermissionsToDb();
    },
    changeReviewPermission(allowReview) {
      this.customizedPermissions.enableWorkflowReview = allowReview;
      this.updateCustomizedPermissionsToDb();
    },
    changeDownloadPermission(allowDownloads) {
      this.customizedPermissions.disableDownloads = !allowDownloads;
      this.updateCustomizedPermissionsToDb();
    },
    changeAutoLogoutPermission(allowAutoLogout) {
      this.customizedPermissions.allowAutoLogout = allowAutoLogout;
      this.updateCustomizedPermissionsToDb();
    },
    changeAutoLogoutTimeoutPermission(autoLogoutTimeout) {
      this.customizedPermissions.autoLogoutTimeout = autoLogoutTimeout;
      this.updateCustomizedPermissionsToDb();
    },
  },
};
</script>

<style scoped>
.d-admin-permissions {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  text-align: left;
}
.permissions-scroll {
  position: absolute;
  width: 100%;
  height: calc(100% - 232px);
  top: 84px;
  left: 0;
  overflow: auto;
  padding: 0 32px 32px 32px;
}
.permissions-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.text-style {
  font-family: Roboto;
  letter-spacing: 0;
}
.content-title {
  color: #3f3f3f;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 20px;
}
.products-div {
  position: relative;
  width: 100%;
  min-width: 760px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
  padding: 24px;
}
.products-title {
  color: #3f3f3f;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 4px;
}
.products-subtitle {
  color: #919191;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}
.product-title {
  color: #52545d;
  font-size: 16px;
  line-height: 16px;
  margin-top: 32px;
}
.product-container {
  padding-top: 28px;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
}
.checkbox {
  max-width: 180px;
  height: 20px;
  margin: 0 32px 0 0;
  padding: 0;
}
.checkbox-label {
  color: #8d909f;
  font-size: 16px;
  line-height: 19px;
}
.disabled-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #4689f3;
  opacity: 0.5;
  border-radius: 4px;
  padding-left: 6px;
  margin-right: 12px;
}
.default-role {
  width: 180px;
  margin: 3px 32px 0 0;
  display: flex;
  flex-direction: row;
}
.disable-role {
  opacity: 0.5;
}
.switch-control-div {
  position: relative;
  width: 100%;
  min-width: 760px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
  padding: 24px 88px 24px 24px;
}
.switch-div {
  position: absolute;
  right: 24px;
  top: 0;
  height: 100%;
  width: 36px;
}
.logout-switch-div {
  position: absolute;
  right: 24px;
  top: 12px;
  height: 50px;
  width: 36px;
}
.vertical-center {
  position: absolute;
  top: 50%;
  top: calc(50% - 6px);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
