<template>
  <div class="playlist-poster">
    <div v-if="posterNum==0" class="playlist-poster-box no-workflow-add">
      <div class="fullscreen-center">
        <icon-base class="mb-2" width="28%" height="28%">
          <d-icon-new-list />
        </icon-base>
      </div>
    </div>
    <div v-if="posterNum==1" class="playlist-poster-box">
      <div class="poster-one">
        <d-image :aspectRatio="16/9" :src="playlistPosters[0].poster" class="video-poster"></d-image>
      </div>
    </div>
    <div v-if="posterNum==2" class="playlist-poster-box">
      <div class="poster-two-1">
        <d-image :src="playlistPosters[0].poster" class="fullscreen-center"></d-image>
      </div>
      <div class="poster-two-2">
        <d-image :src="playlistPosters[1].poster" class="fullscreen-center"></d-image>
      </div>
    </div>
    <div v-if="posterNum==3" class="playlist-poster-box">
      <div class="poster-three-1">
        <d-image :src="playlistPosters[0].poster" class="fullscreen-center"></d-image>
      </div>
      <div class="poster-three-2">
        <d-image :src="playlistPosters[1].poster" class="fullscreen-center"></d-image>
      </div>
      <div class="poster-three-3">
        <d-image :src="playlistPosters[2].poster" class="fullscreen-center"></d-image>
      </div>
    </div>
    <div v-if="posterNum==4" class="playlist-poster-box">
      <div class="poster-four-1">
        <d-image :src="playlistPosters[0].poster" class="fullscreen-center"></d-image>
      </div>
      <div class="poster-four-2">
        <d-image :src="playlistPosters[1].poster" class="fullscreen-center"></d-image>
      </div>
      <div class="poster-four-3">
        <d-image :src="playlistPosters[2].poster" class="fullscreen-center"></d-image>
      </div>
      <div class="poster-four-4">
        <d-image :src="playlistPosters[3].poster" class="fullscreen-center"></d-image>
      </div>
      <div v-if="totalWorkflows>4" class="more-num-div">
        <div class="fullscreen-center more-num">+{{totalWorkflows-4}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import DImage from "../ui_components/DImage.vue";
import IconBase from "../IconBase.vue";
import DIconNewList from "../icons/colored/DIconNewList.vue";
export default {
  name: "DPlaylistPoster",
  props: {
    playlistPosters: Array,
  },
  components: {
    DImage,
    IconBase,
    DIconNewList,
  },
  watch: {
    playlistPosters: function () {
      this.posterNum = 0;
      this.getPosterNum();
    },
  },
  mounted() {},
  created() {
    this.getPosterNum();
  },
  // mixins: [MixinUser],
  data() {
    return {
      source: "",
      posterNum: 1,
      totalWorkflows: 0,
    };
  },
  methods: {
    getPosterNum() {
      this.totalWorkflows = this.playlistPosters.length;
      if (this.totalWorkflows > 4) {
        this.posterNum = 4;
      } else {
        this.posterNum = this.totalWorkflows;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.playlist-poster {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 */
  height: 0px;
  overflow: hidden;
  background-color: #0c0c0e;
}

.playlist-poster-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.no-workflow-add {
  border: #2c2d32 1px solid;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.more-num-div {
  position: absolute;
  height: 50%;
  width: 50%;
  bottom: 0;
  right: 0;
  opacity: 0.8;
  background-color: #17181a;
  z-index: 1;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.more-num {
  color: #ffffff;
  font-family: Roboto;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
}
.poster-one {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  overflow: hidden;
}
.poster-two-1 {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 0;
  right: 0;
  border-bottom: #0c0c0e 1px solid;
  overflow: hidden;
}
.poster-two-2 {
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0;
  border-top: #0c0c0e 1px solid;
  overflow: hidden;
}
.poster-three-1 {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: #0c0c0e 1px solid;
  overflow: hidden;
}
.poster-three-2 {
  position: absolute;
  height: 50%;
  width: 50%;
  bottom: 0;
  left: 0;
  border-top: #0c0c0e 1px solid;
  border-right: #0c0c0e 1px solid;
  overflow: hidden;
}
.poster-three-3 {
  position: absolute;
  height: 50%;
  width: 50%;
  bottom: 0;
  right: 0;
  border-top: #0c0c0e 1px solid;
  border-left: #0c0c0e 1px solid;
  overflow: hidden;
}
.poster-four-1 {
  position: absolute;
  height: 50%;
  width: 50%;
  top: 0;
  left: 0;
  border-bottom: #0c0c0e 1px solid;
  border-right: #0c0c0e 1px solid;
  overflow: hidden;
}
.poster-four-2 {
  position: absolute;
  height: 50%;
  width: 50%;
  top: 0;
  right: 0;
  border-bottom: #0c0c0e 1px solid;
  border-left: #0c0c0e 1px solid;
  overflow: hidden;
}
.poster-four-3 {
  position: absolute;
  height: 50%;
  width: 50%;
  bottom: 0;
  left: 0;
  border-top: #0c0c0e 1px solid;
  border-right: #0c0c0e 1px solid;
  overflow: hidden;
}
.poster-four-4 {
  position: absolute;
  height: 50%;
  width: 50%;
  bottom: 0;
  right: 0;
  border-top: #0c0c0e 1px solid;
  border-left: #0c0c0e 1px solid;
  overflow: hidden;
}
</style>
