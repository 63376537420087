<template>
  <section v-if="getter_show_attachments_popup_fullscreen" class="PlayerAttachments">
    <div class="PlayerAttachments__topBar">
      <button class="PlayerAttachments__topBar--closeBtn" @click="closeAttachments">
        <icon-base width="18" height="18" iconColor="#FFFFFF">
          <d-icon-arrow-back />
        </icon-base>
      </button>
      <div class="PlayerAttachments__topBar--title ml-3">
        <span>{{ $t("all.step") }} {{ stepNum }}.</span>
        {{ getter_selected_attachment_step.title }}
      </div>
    </div>

    <div class="PlayerAttachments__buttonsRow">
      <attachment-btn-group large :value="showPart" @input="updateShowPart($event)" />
      <d-carousel-paging-buttons
        :currentIndex="currentDiagramIdx"
        :total="getter_current_step_attachments_diagrams.length"
        @handlePrevClick="prevImage"
        @handleNextClick="nextImage"
      />
    </div>

    <div class="PlayerAttachments__content">
      <div class="PlayerAttachments__list">
        <div v-for="attachments in getter_current_step_attachments" :key="attachments.type">
          <div v-if="showPart === 'diagram'">
            <div
              v-for="(item, idx) in attachments.diagrams"
              :key="attachments.type + idx"
              class="mb-3"
              @click.stop="currentDiagramIdx = idx"
            >
              <attachment-diagram-card
                :imgURL="item"
                class="PlayerAttachments__diagramCard"
                :class="{ 'PlayerAttachments__diagramCard--current': idx === currentDiagramIdx }"
              />
            </div>
          </div>
          <div v-else-if="showPart === 'links'">
            <div v-for="(item, idx) in attachments.links" :key="attachments.type + idx">
              <attachment-link-card :title="item.label" :link="item.link" />
            </div>
          </div>
          <div v-else-if="showPart === 'pdf'">
            <div v-for="(item, idx) in attachments.pdfs" :key="attachments.type + idx">
              <attachment-pdf-card :item="item" />
            </div>
          </div>
        </div>
      </div>
      <div class="PlayerAttachments__diagram">
        <d-image :aspect-ratio="16 / 9" :src="imgURL" contain class="PlayerAttachments__diagram--img"></d-image>
      </div>
    </div>
  </section>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import AttachmentBtnGroup from "@/components/DWorkflowPlayer/attachments/AttachmentBtnGroup.vue";
import AttachmentDiagramCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentDiagramCard.vue";
import AttachmentLinkCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentLinkCard.vue";
import AttachmentPdfCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentPdfCard.vue";
import DImage from "@/components/ui_components/DImage.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
import DCarouselPagingButtons from "@/components/ui_components/DCarouselPagingButtons.vue";
import { getNumberWithZero } from "@/js/common/formatText.js";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "PlayerAttachments",
  props: {
    currentStepName: {
      type: String,
    },
    currentStep: {
      type: Number,
    },
    isDisabledPictureInPicture: {
      type: Boolean,
    }
  },
  components: {
    IconBase,
    AttachmentBtnGroup,
    AttachmentDiagramCard,
    AttachmentLinkCard,
    AttachmentPdfCard,
    DImage,
    DIconArrowBack,
    DCarouselPagingButtons,
  },
  watch: {
    "attachmentsPopupConfig.isOpen": {
      immediate: true,
      handler(isOpen) {
        if (isOpen) {
          this.currentDiagramIdx = this.attachmentsPopupConfig.selectedDiagramIdx;
        }
      },
    },
    getter_show_attachments_popup_fullscreen: {
      immediate: true,
      handler(getter_show_attachments_popup_fullscreen) {
        if (this.isDisabledPictureInPicture) return;
        if (!getter_show_attachments_popup_fullscreen) {
          this.exitPictureInPictureMode();
          return;
        }
        if (this.isDesktopFullscreen && !this.isTheaterMode) {
          // enter Picture In Picture Mode will auto exit fullscren on Chrome
          // so change to Theater Mode first
          this.enterTheaterMode();
        }
        this.enterPictureInPictureMode();
      },
    },
  },
  data() {
    return {
      currentDiagramIdx: 0,
      showPart: "diagram",
    };
  },
  methods: {
    ...mapActions("workflowPlayer", [
      "setSelectedAttachmentStep",
      "closeAttachmentsPopup",
      "enterPictureInPictureMode",
      "exitPictureInPictureMode",
      "enterTheaterMode",
    ]),
    updateShowPart(event) {
      this.showPart = event;
    },
    closeAttachments() {
      this.closeAttachmentsPopup();
      this.setSelectedAttachmentStep({ step: {} });
    },
    prevImage() {
      if (this.currentDiagramIdx === 0) {
        const lastIdx = this.getter_current_step_attachments_diagrams.length - 1;
        this.currentDiagramIdx = lastIdx;
      } else {
        this.currentDiagramIdx--;
      }
    },
    nextImage() {
      const lastIdx = this.getter_current_step_attachments_diagrams.length - 1;
      if (this.currentDiagramIdx === lastIdx) {
        this.currentDiagramIdx = 0;
      } else {
        this.currentDiagramIdx++;
      }
    },
  },
  computed: {
    ...mapState("workflowPlayer", ["attachmentsPopupConfig", "isDesktopFullscreen", "isTheaterMode"]),
    ...mapGetters("workflowPlayer", [
      "getter_selected_attachment_step",
      "getter_current_step_attachments",
      "getter_show_attachments_popup_fullscreen",
      "getter_current_step_attachments_diagrams",
    ]),
    stepNum() {
      return getNumberWithZero({ num: this.getter_selected_attachment_step.stepIndex + 1 });
    },
    imgURL() {
      const url = this.getter_current_step_attachments_diagrams[this.currentDiagramIdx];
      return url ? url : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.PlayerAttachments {
  z-index: 999;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #121314;
  &__topBar {
    position: relative;
    height: 72px;
    width: 100%;
    padding: 0 32px;
    background: #1d1e21;
    display: flex;
    align-items: center;
    &--closeBtn {
      margin-bottom: -6px;
    }
    &--title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      span {
        color: #9397a6;
        font-weight: 400;
      }
    }
  }
  &__buttonsRow {
    position: relative;
    height: 80px;
    width: 100%;
    padding: 24px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__content {
    position: relative;
    height: calc(100% - 152px);
    width: 100%;
  }
  &__list {
    position: absolute;
    width: 25%;
    max-width: 256px;
    height: calc(100% - 24px);
    top: 0;
    left: 0;
    padding: 0 24px 0 40px;
    overflow: auto;
    border-right: 1px solid #33353a;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: #585858;
    }
  }
  &__diagramCard {
    opacity: 0.6;
    border: 1px solid transparent;
    &--current {
      opacity: 1;
      border: 1px solid #ffffff;
    }
  }
  &__diagram {
    position: absolute;
    width: calc(75% - 64px);
    min-width: calc(100% - 306px);
    height: calc(100% - 24px);
    top: 0;
    right: 40px;
    border-radius: 4px;
    overflow: hidden;
    background: #1d1e21;
    &--img {
      position: relative;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
