<template>
  <section class="user-invite-create">
    <v-card width="466" color="#FFFFFF" class="invite-box fullscreen-center">
      <button class="close-btn" @click="$emit('closeAdminInvite')" autotest="invite-user-popup-close-btn">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>
      <!-- Add User Form -->
      <div v-if="addedUserSuccess == 'not-yet'">
        <div class="title-div">
          <icon-base width="120" height="120">
            <d-icon-add-user />
          </icon-base>
          <div class="title-text">{{ $t("admin.addUser") }}</div>
        </div>
        <d-tabs v-model="currentTab" tabWidth="144" :tabNames="tabNames" light height="64"></d-tabs>

        <v-form v-model="formValid" autocomplete="off" :style="{ margin: '28px 24px 24px 24px' }">
          <v-layout v-if="role === 'admin-organization'">
            <v-text-field
              ref="firstField"
              v-model="fullName"
              :label="$t('admin.fullName') + '*'"
              :rules="[rules.required, rules.noAllSpace, rules.displayNameAllowed]"
              autocomplete="chrome-off"
              autotest="invite-user-popup-name-input"
            ></v-text-field>
          </v-layout>
          <v-layout v-else>
            <v-flex xs6>
              <v-text-field
                ref="firstField"
                v-model="fullName"
                class="mr-2"
                :label="$t('admin.fullName') + '*'"
                :rules="[rules.required, rules.noAllSpace, rules.displayNameAllowed]"
                autocomplete="chrome-off"
                autotest="invite-user-popup-name-input"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="groupAddedName"
                :label="$t('admin.memberAt') + '*'"
                :rules="[rules.required]"
                disabled
                class="ml-2"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <v-text-field
            v-if="currentTab == 0"
            v-model="email"
            :label="$t('all.email') + '*'"
            :rules="[rules.required, rules.noWhiteSpace, rules.emailAllowed]"
            autocomplete="chrome-off"
            @keydown.native="noSpace($event)"
            @input="toLowerCase()"
            autotest="invite-user-popup-email-input"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="employeeId"
            :label="$t('all.employeeId') + '*'"
            :rules="[rules.invalidSharedAccount, rules.required, rules.validID, rules.noWhiteSpace]"
            autocomplete="chrome-off"
            @keydown.native="noSpace($event)"
          ></v-text-field>
          <v-select
            :items="roleOptions"
            v-model="role"
            dense
            :label="$t('all.role') + '*'"
            :rules="[rules.required]"
            :disabled="currentTab == 1"
            class="text-capitalize role-select"
          >
            <template slot="selection" slot-scope="props">
              <span class="text-capitalize">
                {{ roleDictionary[props.item] }}
              </span>
            </template>
            <template slot="item" slot-scope="props">
              <span class="text-capitalize">
                {{ roleDictionary[props.item] }}
              </span>
            </template>
          </v-select>
        </v-form>
        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentTab == 0"
            depressed
            round
            class="body-2 text-capitalize white--text btn-width"
            color="#4689f4"
            :disabled="!formValid || justClicked"
            @click="inviteEmailUser()"
            autotest="invite-user-popup-invite-btn"
            >{{ $t("all.add") }}</v-btn
          >
          <v-btn
            v-else
            depressed
            round
            class="body-2 text-capitalize white--text btn-width"
            color="#4689f4"
            :disabled="!formValid || justClicked"
            @click="createIDlUser()"
            >{{ $t("all.create") }}</v-btn
          >
        </v-card-actions>
      </div>
      <!-- Add User Success -->
      <div v-else>
        <div class="title-div">
          <d-icon-add-user-success width="120" height="120" />
          <div v-if="addedUserSuccess == 'has-email'">
            <div class="title-text">{{ $t("admin.popupTitleNewUserInvited") }}</div>
            <div class="subtitle-text lower-lineheight">
              <span class="text-capitalize">{{ fullName }}</span>
              ({{ email }})
              <span v-html="$t('admin.popupMsgNewUserInvitedOrg')"></span>
              <!-- was invited. <br>After validating the account he/she will have access to this Workspace. -->
            </div>
          </div>

          <div v-if="addedUserSuccess == 'no-email'">
            <div class="title-text">{{ $t("admin.popupTitleNewUserCreated") }}</div>
            <div class="subtitle-text">
              <span class="text-capitalize">{{ fullName }}</span>
              ({{ employeeId }})
              <br />
              {{ $t("admin.popupMsgExistingUserInvited") }}
              <!-- was added to this Workspace. -->
            </div>
          </div>

          <div v-if="addedUserSuccess == 'added-existing'">
            <div class="title-text">{{ $t("admin.popupTitleExistingUserInvited") }}</div>
            <div class="subtitle-text">
              <span class="text-capitalize">{{ fullName }}</span>
              ({{ email }}{{ employeeId }})
              <br />
              {{ $t("admin.popupMsgExistingUserInvitedOrg") }}
            </div>
          </div>
        </div>
        <v-card-actions class="pa-0">
          <v-layout xs12 mt-4>
            <v-flex xs6 mr-2>
              <v-btn
                outline
                depressed
                round
                block
                class="body-2 no-text-transform"
                color="#4689f4"
                @click="addAnoteruser()"
                autotest="invited-user-popup-add-another-user-btn"
                >{{ $t("admin.popupBtnAddAnotherUser") }}</v-btn
              >
            </v-flex>
            <v-flex xs6 ml-2>
              <v-btn
                depressed
                round
                block
                class="body-2 white--text no-text-transform"
                color="#4689F3"
                @click="$emit('closeAdminInvite')"
                autotest="invited-user-popup-done-btn"
                >{{ $t("builder.done") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card-actions>
      </div>
    </v-card>

    <d-alert v-model="isPendingInvitation" type="alert" :message="$t('admin.alertPendingInvitation')"></d-alert>
    <d-alert
      v-model="alertAlreadyOrganizationAdmin"
      type="alert"
      :message="$t('admin.alertUserAlreadyOrganizationAdmin')"
    ></d-alert>
    <d-alert
      v-model="alertSuccess"
      type="success"
      :message="$t('admin.alertAddExistingUserAsOrganizationAdmin')"
    ></d-alert>
    <d-alert v-model="alert.show" :type="alert.type" :message="alert.msg"></d-alert>
  </section>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DTabs from "@/components/ui_components/DTabs.vue";
import DIconAddUser from "@/components/icons/colored/DIconAddUser.vue";
import DIconAddUserSuccess from "@/components/icons/colored/DIconAddUserSuccess.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import {
  addUserToWorkspace,
  inviteNoEmailUser,
  formatUserDataToDisplayOnDataTableWhenInviteSuccess,
  updateVuexUserDataAndAddNewUserToTable,
} from "@/js/user-role/user-role.js";
import { getBaseDataForUserDataTable, USER_DATA_TABLE_TYPE } from "@/js/user-data-table/user-data-table.js";
import { checkDisplayNameCharacters, checkEmailCharacters } from "@/js/character-validator/character-validator.js";

export default {
  name: "AdminAddUserPopup",
  props: {
    showForm: String,
    groupDictionary: Object,
    groupsData: Array,
  },
  watch: {
    currentTab: function () {
      if (this.currentTab == 0) {
        // for email user
        this.role = "viewer";
        this.roleOptions = this.allRoleOptions;
        this.employeeId = "";
      } else {
        // for id user
        this.role = "viewer";
        this.roleOptions = ["viewer"];
        this.email = "";
      }
      this.$nextTick(() => this.$refs.firstField.focus());
    },
  },
  data() {
    return {
      fullName: "",
      email: "",
      employeeId: "",
      role: "viewer",
      groupOptions: [],
      group: "",
      groupAddedName: "",
      roleOptions: [],
      allRoleOptions: ["viewer", "publisher", "admin-group", "admin-organization"],
      formValid: false,
      rules: {
        noWhiteSpace: (value) => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        max: (v) => (v && v.length <= 16) || this.$t("rules.maxPassword"),
        min: (v) => (v && v.length >= 6) || this.$t("rules.min6"),
        validID: (value) => {
          const pattern = /^(?!.*@).*$/;
          const startWithShared = /^shared\..*/;
          return (!startWithShared.test(value.toLowerCase()) && pattern.test(value)) || this.$t("auth.alertInvalidId");
        },
        groupRequired: (v) => JSON.stringify(v) != "{}" || this.$t("rules.required"),
        required: (value) => !!value || this.$t("rules.required"),
        invalidSharedAccount: (value) => {
          return !value.toLowerCase().endsWith(".shared") || this.$t("rules.invalidEmployeeID");
        },
        displayNameAllowed: () => !this.displayNameNotAllowed || this.$t("rules.invalidName"),
        emailAllowed: () => !this.emailNotAllowed || this.$t("rules.invalidEmail"),
      },
      currentTab: 0,
      tabNames: [this.$t("admin.companyEmail")],
      addedUserSuccess: "not-yet",
      existingUserName: "",
      roleDictionary: {
        viewer: this.$t("all.viewer"),
        publisher: this.$t("all.publisher"),
        "admin-group": this.$t("all.workspaceAdmin"),
        "admin-organization": this.$t("all.organizationAdmin"),
      },
      isPendingInvitation: false,
      alertAlreadyOrganizationAdmin: false,
      alertSuccess: false,
      justClicked: false,
      alert: {
        show: false,
        type: "info",
        msg: "",
      },
    };
  },
  mixins: [MixinDB, MixinUser, MixinAdmin],
  components: {
    IconBase,
    DIconClose2,
    DTabs,
    DIconAddUser,
    DIconAddUserSuccess,
    DAlert,
  },
  created() {
    this.roleOptions = this.allRoleOptions;
  },
  computed: {
    displayNameNotAllowed() {
      return checkDisplayNameCharacters(this.fullName);
    },
    emailNotAllowed() {
      return checkEmailCharacters(this.email);
    },
  },
  mounted() {
    if (this.$employeeIdLogin) {
      this.tabNames.push(this.$t("admin.employeeIdOnly"));
    }
    this.$nextTick(() => this.$refs.firstField.focus());
    this.getDefaultGroup();
  },
  methods: {
    getDefaultGroup() {
      this.getGroupName(this.$defaultGroup);
      const self = this;
      const interval = setInterval(function () {
        if (!self.groupAddedName) {
          self.getGroupName(self.$defaultGroup);
        } else {
          clearInterval(interval);
        }
      }, 200);
    },
    getGroupName(id) {
      this.group = id;
      this.groupAddedName = this.groupDictionary[this.group];
    },
    toLowerCase() {
      this.email = this.email.toLowerCase();
      this.employeeId = this.employeeId.toLowerCase();
    },
    noSpace(event) {
      if (event.key === " ") {
        event.preventDefault();
      }
    },
    addAnoteruser() {
      this.resetForm();
      this.addedUserSuccess = "not-yet";
    },
    resetForm() {
      this.fullName = "";
      this.email = "";
      this.employeeId = "";
    },
    avoidMultiClicks() {
      this.justClicked = true;
      setTimeout(() => {
        this.justClicked = false;
      }, 2000);
    },
    createIDlUser() {
      this.avoidMultiClicks();
      inviteNoEmailUser({
        mixinUserRef: this,
        mixinDbRef: this,
        vueInstanceRef: this,
        formData: {
          employeeId: this.employeeId,
          groupId: this.group,
          role: this.role,
          fullName: this.fullName,
        },
        groupLocale: this.groupLocale,
        whenInviteSucessFn: (result) => {
          this.addedUserSuccess = result.type;
          this.$emit("success-add-user");
        },
        showAlertFn: (alert) => {
          this.showAlert(alert);
        },
      });
    },
    inviteEmailUser() {
      this.avoidMultiClicks();
      this.inviteUser({
        role: this.role,
      });
    },
    showAlert(alert = { msg: "", type: "" }) {
      if (alert.msg) {
        this.alert.type = alert.type;
        this.alert.msg = alert.msg;
        this.alert.show = true;
      }
    },
    checkUserAlreadyInOrg(email) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByFieldValue("users", "email", email)
          .then((data) => {
            if (data.length > 0) {
              self.existingUserName = data[0].displayName;
              resolve(data[0]);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async inviteUser({ role }) {
      if (role === "admin-organization") {
        this.inviteOrgAdmin();
        return;
      }
      const newUserData = {
        email: this.email,
        role: this.role,
        displayName: this.fullName,
        groupId: this.group,
      };
      const alertFn = ({ type, msg, params }) => {
        const i18nParams = params || {};
        this.showAlert({ type, msg: this.$t(msg, i18nParams) });
      };
      const result = await addUserToWorkspace({
        tableLevel: "org",
        mixinDbRef: this,
        mixinUserRef: this,
        vueInstanceRef: this,
        groupLocale: this.groupLocale,
        newUserData,
        alertFn,
      });
      if (result && result.successStatus) {
        this.addedUserSuccess = result.successStatus;
        this.$emit("success-add-user");
      }
    },
    async inviteOrgAdmin() {
      const findUserInOrg = async (errorInfo) => {
        try {
          const response = await this.checkUserAlreadyInOrg(this.email.toLowerCase());
          return response;
        } catch (e) {
          console.warn(e);
          this.showAlert({
            type: "alert",
            msg: this.$t(errorInfo),
          });
        }
      };
      try {
        const errorInfo = "admin.alertFailedCheckUserAlreadyInSystem";
        const user = await findUserInOrg(errorInfo);

        const response = await this.addOrgAdminUser(
          this.email.toLowerCase(),
          this.role,
          this.fullName,
          this.groupLocale ? this.groupLocale : this.$i18n.locale
        );
        const msg = response.data;
        const errorInfoForFailedAdded = "admin.alertFailedAddExistingUserAsOrganizationAdmin";
        const userWithOrgRole = await findUserInOrg(errorInfoForFailedAdded);
        if (msg.includes("Successfully changed user role.")) {
          const userActiveInfoData = getBaseDataForUserDataTable({
            user: userWithOrgRole,
            dataTableType: userWithOrgRole.disabled ? USER_DATA_TABLE_TYPE.INACTIVE : USER_DATA_TABLE_TYPE.ACTIVE,
          });
          const isToRemoveUser = false;
          this.$store.dispatch("updateUser", {
            user: user,
            action: "update",
            update: {
              ...userWithOrgRole,
              ...userActiveInfoData,
              disabled: isToRemoveUser,
            },
          });
          this.showAddUserSuccessMsgThenClosePopup();
        } else if (msg.includes("Invitation created for")) {
          /** Its only change frontend data, there is no request to API */
          formatUserDataToDisplayOnDataTableWhenInviteSuccess({
            userRef: userWithOrgRole,
            groupId: this.group,
            newRole: this.role,
          });
          updateVuexUserDataAndAddNewUserToTable({
            vueInstanceRef: this,
            user: userWithOrgRole,
            level: "org",
          });
          this.addedUserSuccess = "has-email";
        } else {
          console.warn(msg);
        }
      } catch (err) {
        const msg = err.response.data;
        if (msg.includes("already exists in the system as an org admin")) {
          this.alertAlreadyOrganizationAdmin = true;
        } else if (
          msg.includes("An invitation has already been sent to the user. Please wait for the user to accept.")
        ) {
          this.isPendingInvitation = true;
        } else {
          console.warn(msg);
          this.$emit("invitedFailed");
        }
      }
    },
    showAddUserSuccessMsgThenClosePopup() {
      this.alertSuccess = true;
      setTimeout(() => {
        this.$emit("closeAdminInvite");
      }, 3000);
    },
  },
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.invite-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px 24px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 24px;
  margin-bottom: 24px;
}
.title-text {
  color: #52545d;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 20px;
  line-height: 30px;
}
.subtitle-text {
  color: #8d909f;
  font-size: 16px;
  line-height: 30px;
}
.lower-lineheight {
  line-height: 24px;
}
.btn-width {
  min-width: 120px;
}
.no-text-transform {
  text-transform: none !important;
}
.dropdown-btn-menu {
  position: relative;
  width: 100%;
}
.dropdown-btn {
  position: relative;
  width: 100%;
  left: 0;
  height: 46px;
}
.hide-group-name {
  opacity: 0;
  pointer-events: none;
}
.group-list-div {
  position: relative;
  max-height: 240px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}
.dropdown-list-item:hover {
  background-color: rgba(245, 245, 245) !important;
}
.group-list-div:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.dropdown-list {
  width: 370px !important;
}
.added-group-blue {
  color: #4689f3;
}
</style>
