<template>
  <div class="admin-super">
    <!-- <template v-slot:extension>
      <v-tabs v-model="tabs" color="transparent" class="tabs-head">
        <v-tabs-slider color="#4689f4"></v-tabs-slider>
        <v-tab href="#tab-1" class="text-capitalize">Organizations</v-tab>
        <v-tab href="#tab-2" class="text-capitalize">Groups</v-tab>
        <v-tab href="#tab-3" class="text-capitalize">Roles</v-tab>
        <v-tab v-show="true" href="#tab-4" class="text-capitalize">Users</v-tab>
      </v-tabs>
    </template>-->

    <!-- <v-tabs-items light v-model="tabs" class="tabs-div">
    <v-tab-item :value="'tab-1'">-->

    <v-layout row wrap>
      <form autocomplete="off" class="serach-form">
        <v-text-field
          flat
          v-model="searchInput"
          placeholder="Search"
          single-line
          hide-details
          background-color="#FBFBFB"
          color="#4689f4"
          autocomplete="chrome-off"
          class="serach-input"
          solo
        >
          <template slot="prepend-inner">
            <icon-base color="grey" width="20" height="20">
              <d-icon-search/>
            </icon-base>
          </template>
        </v-text-field>
      </form>
      <v-spacer></v-spacer>
      <v-btn
        round
        depressed
        class="text-capitalize body-2 white--text px-4"
        color="#4689f4"
        @click="formType='Organization', createHidden=false"
      >
        A
        <span class="text-lowercase">dd organization</span>
      </v-btn>
    </v-layout>
    <v-data-table
      must-sort
      :calculate-widths="true"
      :headers="headers"
      :items="organizationsData"
      :search="searchInput"
      class="table-fixed-header"
      hide-actions
      :rows-per-page-items="[10, 25, 50, {'text':'$vuetify.dataIterator.rowsPerPageAll','value':-1}]"
    >
      <template slot="headers" slot-scope="props">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="text-xs-left font-weight-bold"
          >{{ header.text }}</th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <td class="text-xs-left">{{ props.item.organizationName }}</td>
        <td class="text-xs-left">{{ props.item.customerId }}</td>
        <td class="text-xs-left">{{ props.item.planType }}</td>
        <td class="text-xs-left">{{ props.item.adminName }}</td>
        <td class="text-xs-left">{{ props.item.adminEmail }}</td>
        <!-- <td class="text-xs-left">{{ props.item.adminPhone }}</td> -->
      </template>
    </v-data-table>

    <!-- </v-tab-item>
      <v-tab-item :value="'tab-2'"></v-tab-item>
      <v-tab-item :value="'tab-3'"></v-tab-item>
      <v-tab-item :value="'tab-4'"></v-tab-item>
    </v-tabs-items>-->

    <div class="admin-super-create-wrapper">
      <admin-super-create
        v-show="!createHidden"
        @closeAdminSuperCreate="createHidden=true"
        @createUserSuccess="handleCreateUserSuccess()"
        :createHidden="createHidden"
        :userType="userType"
        :formType="formType"
      ></admin-super-create>
    </div>
  </div>
</template>

<script>
// import { db } from "../main";
import Vue from "vue";
import AdminSuperCreate from "@/components/AdminSuperCreate";
// import { debug } from "util";
// import MixinDelete from "./MixinDelete.vue";
import MixinDB from "./MixinDB.vue";
import MixinAdmin from "./MixinAdmin.vue";
import MixinUser from "./MixinUser.vue";
import { setTimeout } from "timers";
import axios from "axios";
import IconBase from "./IconBase.vue";
import DIconSearch from "./icons/DIconSearch.vue";

export default {
  name: "AdminSuper",
  props: {},
  data() {
    return {
      searchField: "",
      createHidden: true,
      tabs: null,
      organizationAdminData: [], //for deephow admin only
      userType: "Deephow Admin",
      formType: "",
      allUsers: [], //store raw user data from db
      allGroups: [],
      allOrganizations: [],
      allRoles: [],
      organizationsData: [], //store joined, ready to display data
      groupsData: [],
      rolesData: [],
      usersData: [],
      searchInput: "",
      headers: [
        {
          text: "Organization Name",
          align: "left",
          sortable: false,
          value: "organizationName"
        },
        {
          text: "Customer ID",
          align: "left",
          sortable: false,
          value: "customerId"
        },
        {
          text: "Plan Type",
          align: "left",
          sortable: false,
          value: "planType"
        },
        {
          text: "Admin Name",
          align: "left",
          sortable: false,
          value: "adminName"
        },
        {
          text: "Admin Email",
          align: "left",
          sortable: false,
          value: "adminEmail"
        },
        // {
        //   text: "Admin Phone",
        //   align: "left",
        //   sortable: false,
        //   value: "adminPhone"
        // }
      ]
    };
  },
  components: {
    AdminSuperCreate,
    IconBase,
    DIconSearch
  },
  mixins: [MixinDB, MixinAdmin, MixinUser],
  created() {
    this.auth().onAuthStateChanged(user => {
      if (user) {
        this.getUserProfileFromVuex();
        this.checkIfSuperAdmin(user.uid)
          .then(() => {
            this.getSuperAdminData();
          })
          .catch(err => {
            //super admin access denied; sign out
            this.auth().signOut();
            location.reload();
          });
      }
    });
  },
  mounted() {
    // this.getOrganizationAdminData(); //get the list of organizations and their admins
  },

  updated() {},
  computed: {
    userProfile() {
      //get user profile from vuex
      return this.$store.state.userProfile;
    },
    // isDeepHowAdmin() {
    //   return this.userProfile.isDeepHowAdmin;
    // }
  },
  methods: {
    updateWorkflowMigration() {
      self = this;
      const array = ["uqeN7O20z016hpORpSdf"];
      array.forEach(id => {
        self.getDocument("workflows", id).then(workflow => {
          self
            .updateDocument("workflows", workflow.id, {
              title: "Migration " + workflow.title,
              published: false
            })
            .then(() => {
              console.log("succesfully migrated workflow: " + workflow.id);
            });
        });
      });
    },
    addRezConversionRequests() {
      self = this;
      const array = ["uqeN7O20z016hpORpSdf"];
      array.forEach(id => {
        self.getDocument("workflows", id).then(async (workflow) => {
          const docId = self.generateDocId("conversions");
          const conversion = {
            id: docId,
            workflowId: workflow.id,
            source: workflow.videoGsURL,
            sourceRez: 1080,
            targetRez: 360,
            type: "rez",
            status: "scheduled",
            timestamp: await self.getServerTimestamp()
          };
          self.addDocument("conversions", docId, conversion).then(() => {
            console.log(
              "succesfully added conversion for workflow: " + workflow.id
            );
          });
        });
      });
    },
    checkIfSuperAdmin(uid) {
      const self = this;
      return new Promise(async function(resolve, reject) {
        const data = {
          uid: uid,
          idToken: await self.getIdToken()
        };
        self.sendAdminRequest("check", "super-admin", data).then(response=>{
            if (response.data == "the user is super admin.") {
              resolve();
            } else {
              reject();
            }
        })
      });
    },
    handleCreateUserSuccess() {
      this.createHidden = true;
      this.getSuperAdminData();
    },
    getUserProfileFromVuex() {
      Vue.prototype.$user = this.auth().currentUser;
      this.$store.dispatch("getUserProfile", this.$user);
    },
    getOneUserData(userId) {
      for (const user of this.allUsers) {
        if (user.id == userId) {
          return user;
        }
      }
      return {
        email: "USER DATA IS MISSING",
        firstName: "USER DATA IS MISSING",
        lastName: "",
        phone: "USER DATA IS MISSING"
      }; //if the user is not available
    },
    getOrganizationsData() {
      for (const org of this.allOrganizations) {
        if (org.admins) {
          for (const userId of org.admins) {
            const user = this.getOneUserData(userId);
            this.organizationsData.push({
              organizationName: org.name.toUpperCase(),
              customerId: org.id,
              planType: org.planType ? org.planType : "N/A",
              adminName: user.displayName
                ? user.displayName
                : user.firstName + " " + user.lastName,
              adminEmail: user.email,
              adminPhone: user.phone ? user.phone : "N/A"
            });
          }
        }
      }
    },
    async getSuperAdminData() {
      const self = this;
      const allUsers = this.fetchUsers();
      const allOrganizations = this.fetchOrganizations();
      Promise.all([allUsers, allOrganizations]).then(
        function(values) {
          this.allUsers = values[0];
          this.allOrganizations = values[1];
          this.getOrganizationsData();
        }.bind(this)
      );
    },
    fetchGroups() {},
    fetchRoles() {},
    fetchUsers() {
      return new Promise(
        function(resolve, reject) {
          this.getAllDocument("users")
            .then(data => {
              resolve(data);
            })
            .catch(err => {
              reject(err);
            });
        }.bind(this)
      );
    },
    fetchOrganizations() {
      //for DeepHow Admin only
      return new Promise(
        function(resolve, reject) {
          this.getAllDocument("organizations")
            .then(data => {
              resolve(data);
            })
            .catch(err => {
              reject(err);
            });
        }.bind(this)
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.admin-super {
  position: fixed;
  left: 0;
  top: 70px;
  height: calc(100% - 70px);
  width: 100%;
  padding: 32px 8%;
  background-color: #FBFBFB;
}

.serach-form {
  width: calc(100% - 180px);
}
.serach-input {
  margin-left: -12px;
}
/* .tabs-head {
  position: fixed;
  top: 70px;
  padding-top: 14px;
  padding-bottom: 0;
  padding-left: 10%;
} */
/* .tabs-div {
  position: fixed;
  top: 130px;
  height: calc(100% - 130px);
  width: 100%;
  background-color: #FBFBFB;
  border-top: 2px solid #e8e8e8;
  z-index: -1;
  padding: 20px 10% 0 10%;
} */

.table-fixed-header ::v-deep .v-table__overflow {
  height: calc(100% - 160px);
  width: 84%;
  position: fixed;
  top: 160px;
  left: 8%;
  padding-bottom: 24px;
  overflow-y: auto !important;
}
.table-fixed-header ::v-deep .v-table__overflow:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.table-fixed-header ::v-deep th {
  background-color: #FBFBFB;
  position: sticky !important;
  top: 0;
  z-index: 1;
}
.table-fixed-header ::v-deep table {
  background-color: #FBFBFB;
  color: #757575;
}

.admin-super-create-wrapper {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
</style>
