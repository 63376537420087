<template>
  <d-player-setting-options
    :options="options"
    valueAttr="value"
    labelAttr="label"
    :defaultValue="isUseHlsRezOptions ? hlsCurrentLevel : videoResolution"
    @change-value="isUseHlsRezOptions ? setQualityHlsRez($event) : setQualityVideoResolution($event)"
  />
</template>

<script>
import DPlayerSettingOptions from "@/components/DWorkflowPlayer/setting/DPlayerSettingOptions.vue";
export default {
  name: "DPlayerSettingQuality",
  components: {
    DPlayerSettingOptions,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    isUseHlsRezOptions: {
      type: Boolean,
      default: false,
    },
    videos: {
      type: Object,
    },
    videoResolution: {
      type: String,
    },
    hlsRezOptions: {
      type: Array,
    },
    hlsCurrentLevel: {
      type: Number,
    },
  },
  computed: {
    options() {
      const list = this.isUseHlsRezOptions ? this.hlsRezOptions : Object.keys(this.videos);
      const FIXED_OPTION = { value: this.isUseHlsRezOptions ? 0 : "Auto", label: "Auto" };
      const hasAuto = list.indexOf(FIXED_OPTION.label) !== -1;
      const options = list
        .map((ele, idx) => ({
          value: this.isUseHlsRezOptions ? idx : ele,
          label: ele === FIXED_OPTION.label ? ele : `${ele}p`,
        }))
        .filter(({ label }) => label !== FIXED_OPTION.label)
        .sort((a, b) => {
          const aValue = Number(a.value);
          const bValue = Number(b.value);
          return aValue > bValue ? -1 : 1;
        });
      return hasAuto ? [FIXED_OPTION, ...options] : options;
    },
  },
  methods: {
    setQualityVideoResolution({ value }) {
      this.$emit("set-quality-video-resolution", { videoResolution: value });
    },
    setQualityHlsRez({ value }) {
      const qualityCurrentVal = this.hlsRezOptions.find((_, index) => index === value);
      this.$emit("set-quality-hls-rez", { level: value, qualityCurrentVal });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>