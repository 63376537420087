import axios from "axios";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  // NOTICE: this API is not support china version, so the value is null
  return environment.backendType === "firebase"
    ? environment.service[environment.env].azureSpeechServer
    : environment.service[environment.env]["china"].azureSpeechServer;
};

const azureSpeechServer = axios.create({
  baseURL: getBaseUrl(),
});

azureSpeechServer.interceptors.request.use(
  async config => {
    return config;
  },
  error => Promise.resolve(error)
);
const apiWrapper = apiWrapperGenerator(azureSpeechServer);

export const postFetchVoiceOutputSpeech = (payload = {
  oid,
  idToken,
  textList,
}) => apiWrapper("post", "/text-to-speech", payload);
