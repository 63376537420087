<template>
  <div v-if="isShowingTaskName" class="step-name-tag" :class="{'step-name-tag-sm':isMobileDevice}">
    <div class="blue-tag show-blue-tag"></div>
    <div class="white-tag show-white-tag"></div>
    <div class="text-tag show-text-tag" :class="{'text-tag-sm':isMobileDevice}">
      <p
        class="step-tag-text text-truncate"
        :class="{'step-tag-text-sm': isMobileDevice}"
      >
        <span class="step-tag-num" :class="{'step-tag-num-sm': isMobileDevice}">{{stepNum}}</span>
        {{currentStepName}}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "DStepNameTag",
  props: {
    isShowingTaskName: {
      type: Boolean
    },
    stepNum: {
      type: String
    },
    currentStepName: {
      type: String
    },
    isMobileDevice: {
      type: Boolean
    }
  }
};
</script>

<style scoped>
.step-name-tag {
  height: 60px;
  max-width: calc(100% - 108px);
  overflow: hidden;
  pointer-events: none;
}
.step-name-tag-sm {
  height: 40px;
}
.blue-tag {
  position: absolute;
  left: -100%;
  height: 100%;
  width: 100%;
  background-color: #4689f3;
}
.white-tag {
  position: absolute;
  left: -100%;
  height: 100%;
  width: 100%;
  background-color: rgb(244, 244, 244);
  border-right: #4689f3 8px solid;
}
.text-tag {
  position: relative;
  bottom: -100%;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 16px 32px 0 24px;
}
.text-tag-sm {
  padding: 10px 32px 0 24px;
}
.show-blue-tag {
  animation: bluetag 5s;
  animation-iteration-count: 1;
  /* Safari and Chrome: */
  -webkit-animation: bluetag 5s;
  -webkit-animation-iteration-count: 1;
}
@keyframes bluetag {
  0% {
    left: -100%;
  }
  10% {
    left: 0;
  }
  90% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-webkit-keyframes bluetag {
  0% {
    left: -100%;
  }
  10% {
    left: 0;
  }
  90% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.show-white-tag {
  animation: whitetag 5s;
  animation-iteration-count: 1;
  /* Safari and Chrome: */
  -webkit-animation: whitetag 5s;
  -webkit-animation-iteration-count: 1;
}
@keyframes whitetag {
  10% {
    left: -100%;
  }
  20% {
    left: 0;
  }
  80% {
    left: 0;
  }
  90% {
    left: -100%;
  }
}
@-webkit-keyframes whitetag {
  10% {
    left: -100%;
  }
  20% {
    left: 0;
  }
  80% {
    left: 0;
  }
  90% {
    left: -100%;
  }
}
.show-text-tag {
  animation: texttag 5s;
  animation-iteration-count: 1;
  /* Safari and Chrome: */
  -webkit-animation: texttag 5s;
  -webkit-animation-iteration-count: 1;
}
@keyframes texttag {
  20% {
    bottom: -100%;
    left: 0;
  }
  30% {
    bottom: 0;
    left: 0;
  }
  90% {
    bottom: 0;
    left: 0;
  }
  100% {
    bottom: 0;
    left: -100%;
  }
}
@-webkit-keyframes texttag {
  20% {
    bottom: -100%;
    left: 0;
  }
  30% {
    bottom: 0;
    left: 0;
  }
  90% {
    bottom: 0;
    left: 0;
  }
  100% {
    bottom: 0;
    left: -100%;
  }
}
.step-tag-text {
  position: relative;
  color: #1e1f22;
  font-size: 24px;
  font-weight: 200;
  text-align: left;
}
.step-tag-text-sm {
  font-size: 16px;
}
.step-tag-num {
  font-size: 28px;
  font-weight: 500;
}
.step-tag-num-sm {
  font-size: 20px;
}
</style>