import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";

const settingsServer = axios.create({
  baseURL:
    environment.backendType === "firebase"
      ? environment.service[environment.env].admin
      : environment.service[environment.env]["china"].admin,
});

const config = {
  url: "admin/organization/settings",
  token: "rG5kXk0CDbhgF4RBlNoV",
};

export async function updateOrganizationSettings({ updateData, organization }) {
  try {
    const requestBody = {
      organization,
      token: config.token,
      action: "update",
      userToken: await authChoice().currentUser.getIdToken(),
      data: updateData,
    };

    const { data } = await settingsServer.post(config.url, requestBody);

    return data;
  } catch (e) {
    console.log(e);
  }
}

export async function getOrganizationSettings(requestBodyOptions) {
  try {
    const requestBody = Object.assign(
      {
        token: config.token,
        action: "get",
      },
      requestBodyOptions
    );

    const { data } = await settingsServer.post(config.url, requestBody);

    return data;
  } catch (e) {
    console.log(e);
  }
}
