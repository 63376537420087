<template>
  <section 
    class="player-processed-menu" 
    :class="{'for-small-screen':!isMobileFullscreen, 'show-menu': !menuHidden}"
  >
    <v-toolbar dark color="#0c0c0e" height="64" class="elevation-0">
      <button class="mt-2 mr-2" @click="$emit('close-menu')">
        <icon-base color="white" height="24" width="24">
          <d-icon-arrow-back />
        </icon-base>
      </button>
      <v-toolbar-title
        class="video-title ml-2 mt-1"
        @click="$emit('close-menu')"
      >
        <span class="video-title-bold text-truncate">{{getWorkflowDisplayTitle()}}</span>
      </v-toolbar-title>
      <p
        class="video-title text-no-wrap mr-3 title-step-num"
        @click="$emit('close-menu')"
      >&nbsp;{{steps.length}} {{steps.length>1? $t('all.steps'):$t('all.step')}}</p>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="menu-wrapper">
      <v-layout row wrap>
        <v-flex v-for="(step, idx) in steps" :key="idx" lg2 md3 sm4 xs6 d-flex>
          <div
            class="video-step"
            :class="{'highlighted-border': currentStep === idx}"
            @click="$emit('setCurrentStepEvent', idx)"
          >
            <d-lazy-image :src="step.poster" :aspect-ratio="2/1" contain></d-lazy-image>
            <div class="video-name-div" :class="{'highlighted-name-div': currentStep === idx}">
              <div
                class="video-name"
                :class="{'highlighted-name': currentStep === idx}"
              >{{getStepNum(idx)}}.&nbsp;{{getStepDisplayTitle(step)}}</div>
              <div class="video-step-num">
                <span class="font-bold">{{getTime(step.duration)}}</span>
                <v-icon  
                  v-if="showAttachmentsIcon(idx)" 
                  small
                  color="#4689f3" 
                  class="attach-icon diagram-icon"
                >attach_file</v-icon>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </section>
</template>

<script>
import IconBase from "./IconBase.vue";
import DIconArrowBack from "./icons/DIconArrowBack.vue";
import DLazyImage from "@/components/ui_components/DLazyImage";
import { getDisplayTitle} from "@/js/video-player/title-display.js";

export default {
  name: "PlayerProcessedMenuPhone",
  watch: {},
  props: {
    subtitlesChoice: {
      type: String
    },
    steps: {
      type: Array,
    },
    workflow: {
      type: Object,
    },
    currentStep: {
      type: Number,
    },
    menuHidden: {
      type: Boolean,
    },
    attachmentsData: {
      type: Array,
      default: () => [],
    },
    isMobileFullscreen: {
      type: Boolean,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  components: {
    IconBase,
    DIconArrowBack,
    DLazyImage,
  },
  methods: {
    showAttachmentsIcon(idx) {
      if (this.attachmentsData[idx]) {
        return this.attachmentsData[idx].length > 0;
      } else {
        return false;
      }
    },
    getWorkflowDisplayTitle(){
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      return getDisplayTitle(this.workflow, displayLang);
    },
    getStepDisplayTitle(step) {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      return getDisplayTitle(step, displayLang);
    },
    getStepNum(index) {
      index++;
      if (index < 10) {
        return "0" + index.toString();
      } else {
        return index.toString();
      }
    },
    // setCurrentStepTo(step, idx) {
    //   this.$emit("setCurrentStepEvent", idx);
    // },
    getTime(time) {
      time = parseFloat(time).toFixed(0);
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;
      const hours = Math.floor(time / 3600);
      time = time - hours * 3600;
      function str_pad_left(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
      }
      const finalTime =
        str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
      return finalTime;
    },
  },
  computed: {},
};
</script>

<style scoped>
.player-processed-menu {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0c0c0e;
  transition: 0.3s;
  z-index: -2;
  opacity: 0;
  pointer-events: none;
}
.for-small-screen {
  position: fixed;
  height: calc(100% - 28px);
  top: 28px;
}
.show-menu {
  transition: 0.3s;
  z-index: 10;
  opacity: 1;
  pointer-events: auto;
}
.video-title {
  color: #c9c9c9;
  font-size: 16px;
  font-weight: 300;
}
.video-title-bold {
  font-size: 16px;
  font-weight: 500;
  color: white;
}
.title-step-num {
  margin-top: 20px;
}
.menu-wrapper {
  position: absolute;
  top: 64px;
  left: 0;
  height: calc(100% - 64px);
  width: 100%;
  overflow-y: auto;
  padding: 0px 9px 16px 9px;
}
.menu-wrapper::-webkit-scrollbar {
  display: none;
}
.video-step {
  text-align: left;
  margin: 0 7px 14px 7px;
  color: white;
  background-color: #0c0c0e;
}
.video-step:hover {
  cursor: pointer;
}
/* .video-step:not(.highlighted-border):hover {
  color: #84dbff;
} */
.highlighted-border {
  border: 2px solid #4689f3;
}
.video-name-div {
  position: relative;
  height: 70px;
  padding: 8px;
  background-color: #191919;
}
.highlighted-name-div {
  background-color: #ffffff;
}
.video-name {
  color: #ffffff;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 14px;
  overflow: hidden;
  height: 36px;
  line-height: 18px;
  word-wrap: break-word;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.highlighted-name {
  color: #1e1f22;
  font-weight: 600;
}
.video-step-num {
  color: #8d909f;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
}
.font-bold {
  font-weight: 500;
}
.diagram-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
.attach-icon {
  transform: rotate(45deg);
}
</style>
