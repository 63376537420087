<template>
  <div class="d-video-get-links-box">
    <div v-if="linksReady" class="video-div">
      <d-video-player
        :ref="'dVideoPlayer'+index"
        isThumbnailVideo
        :steps="steps"
        :poster="poster"
        :index="index"
      ></d-video-player>
    </div>
    <div class="play-btn-poster-div" :class="{'hide-btn-poster':isPlayingIndex==index}">
      <d-image class="video-poster" :aspect-ratio="16/9" :src="poster"></d-image>
      <div class="play-btn-div">
        <div class="fullscreen-center">
          <button @click="showPlayer()">
            <icon-base color="white" width="64" height="64">
              <d-icon-play />
            </icon-base>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconPlay from "../icons/DIconPlay.vue";
import DImage from "../ui_components/DImage.vue";
import DVideoPlayer from "../ui_components/DVideoPlayer.vue";
import MixinDB from "../MixinDB.vue";
export default {
  name: "DVideoGetLinksBox",
  props: {
    poster: String,
    index: Number,
    isPlayingIndex: Number,
    multiStepIds: Array,
  },
  mixins: [MixinDB],
  watch: {
    isPlayingIndex: function () {
      const ref = "dVideoPlayer" + this.index;
      if (this.$refs[ref]) {
        if (this.isPlayingIndex != this.index) {
          this.$refs[ref].restartVideo();
        } else {
          this.$refs[ref].playVideo();
        }
      }
    },
  },
  mounted() {},
  data() {
    return {
      linksReady: false,
      steps: [],
    };
  },
  components: {
    IconBase,
    DIconPlay,
    DImage,
    DVideoPlayer,
  },
  methods: {
    showPlayer() {
      if (this.linksReady) {
        this.$emit("set-playing-index");
      } else {
        this.getSteps();
      }
    },
    fetchSteps() {
      return new Promise(
        function (resolve, reject) {
          let promises = [];
          this.multiStepIds.forEach((id) => {
            promises.push(this.getDocument("steps", id));
          });
          Promise.all(promises)
            .then((steps) => {
              resolve(steps);
              this.linksReady = true;
              this.$nextTick(() => this.$emit("set-playing-index"));
            })
            .catch((err) => {
              reject(err);
            });
        }.bind(this)
      );
    },
    async getSteps() {
      if (this.multiStepIds.length > 0) {
        const self = this;
        this.steps = await self.fetchSteps();
      }
    },
  },
};
</script>

<style scoped>
.d-video-get-links-box {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* background-color: rgba(71, 7, 71, 0.5); */
  background-color: #0c0c0e;
}
.video-poster {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background-color: rgba(25, 139, 10, 0.5);
  border: red 3px solid; */
  opacity: 1;
}

.video-div {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 1;
}

.play-btn-poster-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 1;
}
.play-btn-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.play-btn-poster-div:hover .play-btn-div {
  opacity: 1;
}
.hide-btn-poster {
  opacity: 0;
  z-index: -1;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>