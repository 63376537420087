import environment from "../../env";
const generatePathname = () => {
  let pathname = window.location.pathname;
  if (pathname !== "/") return pathname;

  const urlParams = new URLSearchParams(window.location.search);
  const queryValue = urlParams.get("redirect");
  if (!queryValue) return "/";

  return decodeURIComponent(queryValue);
};
export const isIOSDevice = () => {
  return /iPhone|iPad/i.test(navigator.userAgent)
};
export const generateLinkToIOSApp = () => {
  const pathname = generatePathname();
  return environment.backendType === "ali"
    ? `deephowNavigatorChina:/${pathname}`
    : `deephowNavigator:/${pathname}`;
};
export const goToAppStore = () => {
  if (environment.backendType === "ali") {
    window.location.href = "https://apps.apple.com/th/app/deephow-navigator-china/id1547210451"
  } else {
    window.location = "https://apps.apple.com/us/app/deephow-navigator/id1494927486"
  }
};