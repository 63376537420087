import axios from "axios";
import WebSocketClient from '@/js/WebSocketClient'
import environment from "../../env.js"
import store from '../store/index.js'
import auth from "../../auth.js";

const env = environment.env;
const ObjectID = require('bson').ObjectID;

const databaseURL = (environment.service[env].china) ? environment.service[env].china.database : '';
const websocketURL = (environment.service[env].china) ? environment.service[env].china.websocket : '';

var dbListenerMap = {};

function getIdToken() {
  return new Promise(function(resolve, reject){
    auth().currentUser.getIdToken().then((idToken) => {
      resolve(idToken);
    }).catch((error) => {
      reject(error)
    });
  });
}

function convertDoc(doc) {
  if (doc['publishedDate']) {
    let seconds = doc['publishedDate'];
    doc['publishedDate'] = {
      seconds: seconds,
      toDate: function () {
        return new Date(seconds * 1000);
      },
      valueOf: () => new Date(seconds * 1000).valueOf(),
    };
  }

  if (doc['createdAt']) {
    let seconds = doc['createdAt'];
    doc['createdAt'] = {
      seconds: seconds,
      toDate: function () {
        return new Date(seconds * 1000);
      },
      valueOf: () => new Date(seconds * 1000).valueOf(),
    };
  }

  if (doc['enrolledAt']) {
    let seconds = doc['enrolledAt'];
    doc['enrolledAt'] = {
      seconds: seconds,
      toDate: function () {
        return new Date(seconds * 1000);
      },
      valueOf: () => new Date(seconds * 1000).valueOf(),
    };
  }

  if (doc['signature'] && doc['signature']['signDate']) {
    let seconds = doc['signature']['signDate'];
    doc['signature']['signDate'] = {
      seconds: seconds,
      nanoseconds: 326000000,
      toDate: function () {
        return new Date(seconds * 1000);
      },
      valueOf: () => new Date(seconds * 1000).valueOf(),
    };
  }

  return doc;
}

function convertDocs(docs) {
  let objs = [];
  docs.forEach((doc) => {
    convertDoc(doc);

    let obj = {};
    obj['id'] = doc.id;
    obj['data'] = () => {
      return doc;
    };
    objs.push(obj);
  });

  return objs;
}

function reconvertDoc(doc) {
  if (!doc) {
    return;
  }

  let now = Math.floor(new Date().getTime()/1000);

  if (typeof doc['timestamp'] == 'object') {
    console.error("typeof doc['timestamp'] == 'object'");
    if (typeof doc['timestamp'].seconds == 'number') {
      doc['timestamp'] = doc['timestamp'].seconds;
    } else {
      console.error("now !!!", now);
      doc['timestamp'] = now;
    }
  };

  if (typeof doc['publishedDate'] == 'object') {
    console.error("typeof doc['publishedDate'] == 'object'");
    if (typeof doc['publishedDate'].seconds == 'number') {
      doc['publishedDate'] = doc['publishedDate'].seconds;
    } else {
      console.error("now !!!", now);
      doc['publishedDate'] = now;
    }
  };

  if (typeof doc['createdAt'] == 'object') {
    console.error("typeof doc['publishedDate'] == 'object'");
    if (typeof doc['createdAt'].seconds == 'number') {
      doc['createdAt'] = doc['createdAt'].seconds;
    } else {
      console.error("now !!!", now);
      doc['createdAt'] = now;
    }
  };

  if (typeof doc['enrolledAt'] == 'object') {
    console.error("typeof doc['publishedDate'] == 'object'");
    if (typeof doc['enrolledAt'].seconds == 'number') {
      doc['enrolledAt'] = doc['enrolledAt'].seconds;
    } else {
      console.error("now !!!", now);
      doc['enrolledAt'] = now;
    }
  };

  return doc;
}

function reconvertWrites(writes) {
  let objs = [];
  writes.forEach((doc) => {
    reconvertDoc(doc.data);

    objs.push(doc);
  });

  return objs;
}

function convertConditions(collection, comparisons, methods) {
  let conditions = {
    compares: [],
    orderBy: []
  };

  comparisons.forEach(item => {
    conditions.compares.push({ operation: item.comparison, key: item.field, value: item.value });
  });

  if (methods) {
    methods.forEach(item => {
      //console.log("methods item", item);
      if (item.type == "orderBy") {
        let values = item.value.replace( /\s/g, '').split(',');
        conditions.orderBy.push({
          key: values[0].substring(1, values[0].length - 1),
          order: values[1] ? values[1].substring(1, values[1].length - 1) : 'desc'
        });
      } else if (item.type == "startAfter") {
        // client calculate skip index for startAfter !!
        let docs = store.state[collection];
        let lastDoc = item.value.data();
        //console.log(docs.length);
        let skipIndex = docs.findIndex((doc) => lastDoc['id'] === doc['id']);
        conditions[item.type] = skipIndex + 1;
      } else {
        conditions[item.type] = item.value;
      };
    });
  }
  //console.log(conditions);

  return conditions;
}


async function convertConditionsWithDocumentPosition(collection, comparisons, methods) {
  return new Promise(async(resolve, reject) => {
    try {
      let conditions = {
        compares: [],
        orderBy: []
      };

      for (let i = 0; i < comparisons.length; i++) {
        conditions.compares.push({ operation: comparisons[i].comparison, key: comparisons[i].field, value: comparisons[i].value });
      }

      if (methods) {console.log("method", methods);
        for (let i = 0; i < methods.length; i++) {
          if (methods[i].type == "startAfter") {
            continue;
          }

          if (methods[i].type == "orderBy") {
            let values = methods[i].value.replace( /\s/g, '').split(',');
            conditions.orderBy.push({
              key: values[0].substring(1, values[0].length - 1),
              order: values[1] ? values[1].substring(1, values[1].length - 1) : 'desc'
            });
          } else {
            conditions[methods[i].type] = methods[i].value;
          };
        }

        // startAfter need to in the end, because it need to get document positoin for full conditions
        for (let i = 0; i < methods.length; i++) {
          if (methods[i].type != "startAfter") {
            continue;
          }

          let lastDoc = methods[i].value.data();

          const idToken = await getIdToken() ;
          const documentPosition = await axios.post(`${databaseURL}getDocumentPosition`, {
            collection: collection,
            documentId: lastDoc['id'],
            conditions: conditions
          }, {
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          });

          conditions[methods[i].type] = documentPosition.data.sequence;
        }
      }

      return resolve(conditions);
    } catch(error) {
      return reject(error);
    }
  });
}

const databaseApi = {
    deleteDocument: function (collection, documentId) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken() ;
        if(idToken) {
          axios.delete(databaseURL + collection + "/" + documentId, {
              headers: {
                "id-token": idToken //the token is a variable which holds the token
              }
            }).then(function () {
            resolve();
          })
          .catch(function (error) {
            reject(error);
          });

        }
      });
    },
    duplicateDocument: function (collection, documentId) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken() ;
        if (idToken) {
          let data = {
            "source": {
              "collection": collection,
              "id": documentId
            }
          };

          axios.post(databaseURL + "duplicate", {
            data: data
          },{
              headers: {
                "id-token": idToken //the token is a variable which holds the token
              }
          }).then(function (data) {
              resolve(data.data.id);
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    duplicateDocumentToOrg: function (collection, documentId, organization) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken() ;
        if (idToken) {
          let data = {
            "source": {
              "collection": collection,
              "id": documentId
            },
            "organization": organization
          };

          axios.post(databaseURL + "duplicate", {
            data: data
          },{
              headers: {
                "id-token": idToken //the token is a variable which holds the token
              }
          }).then(function (data) {
              resolve(data.data.id);
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    getDocumentByQuery: function (collection, comparisons, methods, organization) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          let hasOrganization = false;
          // /searchDocuments/:collection  condition
          let conditions = convertConditions(collection, comparisons, methods);

          conditions.compares.forEach(item => {
            if (item.key == "organization") {
              hasOrganization = true;
            }
          });

          if (!hasOrganization) {
            conditions.compares.push({
              "key": "organization",
              "operation": "==",
              "value": organization
            });
          }

          axios.post(databaseURL + "searchDocuments/" + collection,{
            conditions: conditions
          },{
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          }).then(function (data) {
            let docuemnts = [];
            let convertDocuemnts = convertDocs(data.data);
            convertDocuemnts.forEach(function(doc) {
              docuemnts.push(doc.data());
            });
            resolve(docuemnts);
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    getDocumentByQuery2: function (collection, comparisons, methods, callback, organization) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          let hasOrganization = false;
          // /searchDocuments/:collection  condition
          let conditions = await convertConditionsWithDocumentPosition(collection, comparisons, methods);

          conditions.compares.forEach(item => {
            if (item.key == "organization") {
              hasOrganization = true;
            }
          });

          if (!hasOrganization) {
            conditions.compares.push({
              "key": "organization",
              "operation": "==",
              "value": organization
            });
          }

          axios.post(databaseURL + "searchDocuments/" + collection,{
            conditions: conditions
          },{
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          }).then(function (data) {
            callback(convertDocs(data.data));
            resolve();
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    getDocumentListener: function (collection, documentId, callback) {
      let conditions = {
        "compares": [{
          "key": "_id",
          "operation": "==",
          "value": documentId
        }]
      }

      let key = collection + '_' + documentId;
      if (dbListenerMap[key]) {
        let ws = dbListenerMap[key].ws;
        ws.closeListener();
        delete dbListenerMap[key];
      }

      let ws = new WebSocketClient(websocketURL);

      new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        //console.log('idToken', idToken != null);
        if (idToken) {
          try {
            //console.log('openListener', collection, conditions);
            //let ws = new WebSocketClient(websocketURL);
            ws.openListener(idToken, collection, conditions, (docs) => {
              callback(convertDocs(docs)[0]);
            });

            dbListenerMap[key] = { ws: ws };

            resolve();
          } catch (error) {
            reject(error);
          }
        }
      });

      return function () {
        ws.closeListener();
        delete dbListenerMap[key];
      };

    },
    getDocumentByFieldValue: function (collection, field, value, organization) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          if (field == "organization") {
            // /searchDocuments/:collection  condition
            let conditions = {
              "compares": [{
                "key": field,
                "operation": "==",
                "value": value
              }]
            }

            axios.post(databaseURL + "searchDocuments/" + collection,{
              conditions: conditions
            },{
              headers: {
                "id-token": idToken //the token is a variable which holds the token
              }
            }).then(function (data) {
              let docuemnts = [];
              let convertDocuemnts = convertDocs(data.data);
              convertDocuemnts.forEach(function(doc) {
                docuemnts.push(doc.data());
              });
              resolve(docuemnts);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            // /searchDocuments/:collection  condition
            let conditions = {
              "compares": [{
                "key": field,
                "operation": "==",
                "value": value
              },{
                "key": "organization",
                "operation": "==",
                "value": organization
              }]
            }

            axios.post(databaseURL + "searchDocuments/" + collection,{
              conditions: conditions
            },{
              headers: {
                "id-token": idToken //the token is a variable which holds the token
              }
            }).then(function (data) {
              let docuemnts = [];
              let convertDocuemnts = convertDocs(data.data);
              convertDocuemnts.forEach(function(doc) {
                docuemnts.push(doc.data());
              });
              resolve(docuemnts);
            }).catch(function (error) {
              reject(error);
            });
          }
        }
      });
    },
    getDocumentByArrayElement: function (collection, field, element, organization) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          let conditions = {
            "compares": [{
              "key": field,
              "operation": "array-contains",
              "value": element
            },{
              "key": "organization",
              "operation": "==",
              "value": organization
            }]
          }

          axios.post(databaseURL + "searchDocuments/" + collection,{
            conditions: conditions
          },{
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          }).then(function (data) {
              let docuemnts = [];
              let convertDocuemnts = convertDocs(data.data);
              convertDocuemnts.forEach(function(doc) {
                docuemnts.push(doc.data());
              });
              resolve(docuemnts);
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    getAllDocument: function (collection) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          let conditions = {
            "compares": []
          };

          axios.post(databaseURL + "searchDocuments/" + collection,{
            conditions: conditions
          },{
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          }).then(function (data) {
              let docuemnts = [];
              let convertDocuemnts = convertDocs(data.data);
              convertDocuemnts.forEach(function(doc) {
                docuemnts.push(doc.data());
              });
              resolve(docuemnts);
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    addDocument: function (collection, documentId, data) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          axios.post(databaseURL + collection + "/" + documentId, {
            "data" : reconvertDoc(data)
          },{
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          }).then(function () {
            resolve();
          })
          .catch(function (error) {
            reject(error);
          });
        }
      });
    },
    updateDocument: function (collection, documentId, data) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          axios.patch(databaseURL + collection + "/" + documentId, {
              "data" : reconvertDoc(data)
          },{
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          }).then(function () {
            resolve();
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    generateDocId: function (collection) {
      return new ObjectID().toHexString();
    },
    updateMap: function (collection, documentId, data) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          axios.patch(databaseURL + collection + "/" + documentId, {
              "data" : {
                ["transcriptions" + "." + data.language]: data.id
              }
          },{
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          }).then(function () {
            resolve();
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    getDocument: function (collection, documentId) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          axios.get(databaseURL + collection + "/" + documentId, {
              headers: {
                "id-token": idToken //the token is a variable which holds the token
              }
            }).then(function (data) {
            resolve(convertDoc(data.data));
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    getDocumentQueryListener: function (collection, comparisons, methods, callback) {
      let conditions = convertConditions(collection, comparisons, methods);

      let key = (collection === 'notifications') ?
        collection : collection + '_' + JSON.stringify(conditions);
      let ws = (dbListenerMap[key]) ? dbListenerMap[key].ws : new WebSocketClient(websocketURL);

      new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          try {
            if (dbListenerMap[key]) {
              ws.refreshListener(idToken, collection, conditions, (docs) => {
                callback(convertDocs(docs));
              });
            } else {
              dbListenerMap[key] = { ws: ws };
              ws.openListener(idToken, collection, conditions, (docs) => {
                callback(convertDocs(docs));
                /** TODO: ticket SD2-269 not finished, editorExplorer would be inflouced if doing the code below */
                // ws.closeListener();
                // delete dbListenerMap[key];
              });
            };

            resolve();
          } catch (error) {
            reject(error);
          }
        }
      });

      return function () {
        if (!ws) return;
        ws.closeListener();
        delete dbListenerMap[key];
      };

    },
    batchWrites: function(writes){
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken() ;
        if(idToken) {
          axios.post(databaseURL + "batchWrites", {
            "writes" : reconvertWrites(writes)
          },{
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          }).then(function (data) {
            resolve();
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    getServerTimestamp: function () {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          axios.get(databaseURL + "timestamp",{
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          }).then(function (data) {
            resolve(data.data.timestamp);
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    upsertDocument: function (collection, documentId, data) {
      return new Promise(async function (resolve, reject) {
        let idToken = await getIdToken();
        if (idToken) {
          axios.post(databaseURL + "upsertDocument/" + collection + "/" + documentId, {
              "data" : reconvertDoc(data)
          },{
            headers: {
              "id-token": idToken //the token is a variable which holds the token
            }
          }).then(function () {
            resolve();
          }).catch(function (error) {
            reject(error);
          });
        }
      });
    },
    updateDocumentCounter: function(collection, documentId, field, value) {
      return new Promise(async function(resolve, reject) {
        try {
          let idToken = await getIdToken();
          if (idToken) {
            await axios.patch(`${databaseURL}updateDocumentCounter/${collection}/${documentId}`, {
                "field": field,
                "value": value
            },{
              headers: {
                "id-token": idToken //the token is a variable which holds the token
              }
            });

            resolve();
          }
        } catch(error) {
          reject(error);
        }
      });
    },
    updateDocumentArrayUnion: function(collection, documentId, field, value) {
      return new Promise(async function(resolve, reject) {
        try {
          let idToken = await getIdToken();
          if (idToken) {
            await axios.patch(`${databaseURL}updateDocumentArrayUnion/${collection}/${documentId}`, {
                "field": field,
                "value": value
            },{
              headers: {
                "id-token": idToken //the token is a variable which holds the token
              }
            });

            resolve();
          }
        } catch(error) {
          reject(error);
        }
      });
    },
    updateDocumentArrayRemove: function(collection, documentId, field, value) {
      return new Promise(async function(resolve, reject) {
        try {
          let idToken = await getIdToken();
          if (idToken) {
            await axios.patch(`${databaseURL}updateDocumentArrayRemove/${collection}/${documentId}`, {
                "field": field,
                "value": value
            },{
              headers: {
                "id-token": idToken //the token is a variable which holds the token
              }
            });

            resolve();
          }
        } catch(error) {
          reject(error);
        }
      });
    }
  }
  export default databaseApi;

