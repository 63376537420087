<template>
  <div class="d-admin-sso-mapping">
    <div class="content-title">{{ $t("all.workspaces") }}</div>
    <div v-if="noData" class="no-data-div">
      <p class="no-data-text" v-html="$t('admin.ssoMappingNoData')"></p>
    </div>
    <div v-else>
      <div class="attributes-div">
        <div class="attributes-title">{{ $t("admin.attributes") }}</div>
        <p class="attributes-subtitle">{{ $t("admin.oneAttributeToMap") }}</p>
        <div class="select-attribute-div">
          <div class="select-attribute-btn-outter">
            <div
              class="select-attribute-btn"
              :class="{ 'disable-btn': !canChange }"
              @click="showAttributeList = !showAttributeList"
            >
              <p class="attribute-text">{{ selectedAttribute.key || $t("admin.selectAttribute") }}</p>
              <v-spacer></v-spacer>
              <v-icon color="#8d909f" class="dropdown-arrow pt-2">keyboard_arrow_down</v-icon>
            </div>
            <v-card v-if="showAttributeList" class="group-list-div">
              <v-list dense light class="dropdown-list">
                <v-list-tile
                  v-for="(attribute, i) in allAttributes"
                  :key="i"
                  class="dropdown-list-item"
                  @click="(selectedAttribute = attribute), (showAttributeList = false), (canChange = false)"
                >
                  <v-list-tile-title>{{ attribute.key }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
              <div class="close-bg" @click="showAttributeList = false"></div>
            </v-card>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            small
            round
            depressed
            outline
            class="body-2 change-btn px-4"
            color="dBlue"
            :disabled="canChange"
            @click="showConfirmation = 'change-attribute'"
            >{{ $t("admin.changeAttribute") }}</v-btn
          >
        </div>
      </div>
      <div v-if="madeChange" class="mapping-updated-div center-horizontally">
        <div class="updated-icon">
          <icon-base width="24" height="24" color="#4689F3">
            <d-icon-info />
          </icon-base>
        </div>
        <div class="mapping-updated-text">
          <p class="updated-text-title">{{ $t("admin.mappingValuesUpdated") }}</p>
          <p class="updated-text-subtitle">{{ $t("admin.mappingValuesUpdatedSubtitle") }}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          small
          round
          depressed
          outline
          class="body-2 no-text-transform px-4 ml-4"
          color="dBlue"
          @click="showConfirmation = 'discard-changes'"
          >{{ $t("admin.discardChanges") }}</v-btn
        >
        <v-btn
          small
          round
          depressed
          class="body-2 no-text-transform px-4 white--text"
          color="dBlue"
          @click="showConfirmation = 'apply-changes'"
          >{{ $t("admin.applyChanges") }}</v-btn
        >
      </div>
      <div class="table-shadow">
        <div class="search-box center-horizontally">
          <div class="search-icon">
            <icon-base color="#52545D" width="24" height="24">
              <d-icon-search />
            </icon-base>
          </div>
          <form autocomplete="off" style="width: 100%">
            <v-text-field
              ref="searchField"
              flat
              solo
              v-model="searchInput"
              :placeholder="$t('analytics.searchWorkspace')"
              single-line
              hide-details
              background-color="transparent"
              color="#4689f4"
              class="search-input"
            ></v-text-field>
          </form>
        </div>

        <v-data-table
          class="analytics-table"
          :headers="workspacesHeader"
          :items="workspacesData"
          :search="searchInput"
          :loading="isLoadingWorkflow"
          :rows-per-page-items="[10, 25, 50, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]"
          :rows-per-page-text="$t('analytics.workspacesPerPage')"
        >
          <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
          <template slot="headerCell" slot-scope="props">
            <span class="head-text">{{ props.header.text }}</span>
          </template>
          <template slot="items" slot-scope="props">
            <td class="text-xs-left text-no-wrap">
              <span class="workflow-name text-capitalize" @click="toSingleWorkflow(props.item)">{{
                props.item.name || "-"
              }}</span>
            </td>
            <td class="text-xs-left text-no-wrap">
              <span class="workflow-name text-capitalize">{{ props.item.workspaceGroupName }}</span>
            </td>
            <td class="text-xs-left text-capitalize">
              <div class="py-2">
                <span v-if="props.item.name.toLowerCase() == 'general'">N/A</span>
                <span v-else-if="props.item.valueList.length == 0" class="click-to-add-text">{{
                  $t("admin.clickPlusToAddValues")
                }}</span>
                <span v-else>
                  <span v-for="(value, idx) in props.item.valueList" :key="idx">
                    {{ value }},
                    <span></span>
                  </span>
                </span>
              </div>
            </td>
            <td class="text-xs-right" style="width: 110px">
              <div v-if="props.item.name.toLowerCase() != 'general'" class="btn-td">
                <button class="mr-3" @click="(editedWorkspaceId = props.item.id), addItems(props.item)">
                  <icon-base width="20" height="20" color="#52545D">
                    <d-icon-add-sm />
                  </icon-base>
                </button>
                <button
                  v-if="props.item.valueList.length > 0"
                  @click="(editedWorkspaceId = props.item.id), removeItems(props.item)"
                >
                  <icon-base width="20" height="20" color="#52545D">
                    <d-icon-remove-sm />
                  </icon-base>
                </button>
              </div>
            </td>
          </template>
          <template slot="no-data">
            <span>{{ $t("analytics.msgNoDataYet") }}</span>
          </template>
          <template slot="no-results">
            <span>{{ $t("analytics.msgNoSearchResults") }}</span>
          </template>
        </v-data-table>
      </div>
    </div>

    <d-admin-edit-values-popup
      v-if="showAddPopup"
      :style="drawerOpenPopupStyle"
      :popupType="popupType"
      :editedIndex="editedIndex"
      :editWorkspaceData="editWorkspaceData"
      :attributeValueList="attributeValueList"
      :selectedAttribute="selectedAttribute"
      @close-popup="(showAddPopup = false), (editedIndex = -1), (editWorkspaceData = {})"
      @add-attributes="editChengedWorkspace(editWorkspaceData), addAttributes($event)"
      @remove-attributes="editChengedWorkspace(editWorkspaceData), removeAttributes($event)"
    ></d-admin-edit-values-popup>

    <d-change-attribute-confirmation
      v-if="showConfirmation === 'change-attribute'"
      :style="drawerOpenPopupStyle"
      @close-popup="showConfirmation = ''"
      @change-attribute="(canChange = true), (showConfirmation = ''), clearMapping()"
    ></d-change-attribute-confirmation>

    <!-- DDiscardChangesConfirmation -->
    <d-discard-mapping-changes-confirmation
      v-if="showConfirmation === 'discard-changes'"
      :style="drawerOpenPopupStyle"
      @close-popup="showConfirmation = ''"
      @discard-changes="(showConfirmation = ''), discardChanges()"
    ></d-discard-mapping-changes-confirmation>

    <d-save-mapping-changes-confirmation
      v-if="showConfirmation == 'apply-changes'"
      :style="drawerOpenPopupStyle"
      :changedWorkspaces="changedWorkspaces"
      @close-popup="showConfirmation = ''"
      @apply-changes="(showConfirmation = ''), applyChanges()"
    ></d-save-mapping-changes-confirmation>

    <d-alert v-model="alert.discardedSuccess" type="success" :message="$t('admin.alertDiscardedSuccess')"></d-alert>
    <d-alert v-model="alert.appliedSuccess" type="success" :message="$t('admin.alertAppliedSuccess')"></d-alert>
    <d-alert v-model="alert.appliedFailed" type="error" :message="$t('admin.alertAppliedFailed')"></d-alert>
  </div>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconSearch from "../icons/DIconSearch.vue";
import DIconPen from "../icons/DIconPen.vue";
import DIconAddSm from "../icons/DIconAddSm.vue";
import DIconRemoveSm from "../icons/DIconRemoveSm.vue";
import DAdminEditValuesPopup from "@/components/ui_components/DAdminEditValuesPopup.vue";
import DChangeAttributeConfirmation from "@/components/ui_components/DChangeAttributeConfirmation.vue";
import DIconInfo from "../icons/DIconInfo.vue";
import DDiscardMappingChangesConfirmation from "@/components/ui_components/DDiscardMappingChangesConfirmation.vue";
import DSaveMappingChangesConfirmation from "@/components/ui_components/DSaveMappingChangesConfirmation.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import axios from "axios";
import MixinUser from "../MixinUser.vue";
import MixinDB from "../MixinDB.vue";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DAdminSsoMapping",
  props: {
    drawerOpenPopupStyle: Object,
  },
  watch: {
    selectedAttribute: function () {
      if (this.selectedAttribute) {
        this.getAllData();
      }
    },
  },
  mixins: [MixinUser, MixinDB],
  async mounted() {
    await this.getWorkspaceGroupList({ workspacesType: "admin" });
    const interval = setInterval(
      function () {
        if (this.$clientSettings) {
          clearInterval(interval);
          this.selectedAttribute.key = this.$clientSettings.userMappings.groupMapping[0];
          if (this.selectedAttribute.key) {
            this.canChange = false;
          }
          this.getAllData();
        }
      }.bind(this),
      10
    );
  },
  data() {
    return {
      noData: false,
      selectedAttribute: {},
      attributeValueList: [],
      showAttributeList: false,
      searchInput: "",
      showAddPopup: false,
      editWorkspaceData: {},
      editedIndex: -1,
      workspacesData: [],
      workspacesHeader: [
        {
          text: this.$t("all.workspace"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("all.workspaceGroup"),
          align: "left",
          sortable: true,
          value: "workspaceGroupName",
        },
        {
          text: this.$t("admin.values"),
          align: "left",
          sortable: true,
          value: "valueList",
        },
        {
          text: "",
          align: "right",
          sortable: true,
        },
      ],
      allAttributes: [
        // { attribute: "location_desc", display: "location" },
        // { attribute: "site_desc", display: "Site" },
        // { attribute: "user_id", display: "User Id" },
        // { attribute: "formatted_name", display: "Nickname" },
      ],
      popupType: "",
      canChange: true,
      showConfirmation: "",
      isLoadingWorkflow: true,
      madeChange: false,
      editedWorkspaceId: null,
      alert: {
        discardedSuccess: false,
        appliedSuccess: false,
        appliedFailed: false,
      },
      changedWorkspaces: [],
    };
  },
  components: {
    IconBase,
    DIconSearch,
    DIconPen,
    DIconAddSm,
    DIconRemoveSm,
    DAdminEditValuesPopup,
    DChangeAttributeConfirmation,
    DIconInfo,
    DDiscardMappingChangesConfirmation,
    DSaveMappingChangesConfirmation,
    DAlert,
  },
  methods: {
    ...mapActions("workspaceGroups", ["getWorkspaceGroupList"]),
    editChengedWorkspace(editWorkspaceData) {
      const inArrayIndex = this.changedWorkspaces.indexOf(editWorkspaceData.name);
      if (inArrayIndex === -1) {
        this.changedWorkspaces.push(this.editWorkspaceData.name);
      }
    },
    discardChanges() {
      this.selectedAttribute.key = this.$clientSettings.userMappings.groupMapping[0];
      this.getAllData();
      // alert("mapping successfully discarded");
      this.madeChange = false;
      this.alert.discardedSuccess = true;
    },
    deletePreviousMappings() {
      const self = this;
      const writes = [];
      return new Promise(async function (resolve, reject) {
        const mappings = await self.fetchGroupsMappings().catch((err) => {
          reject();
        });
        mappings.forEach((mapping) => {
          const write = {
            type: "delete",
            collection: "mappings",
            id: mapping.id,
          };
          writes.push(write);
        });
        resolve(writes);
      });
    },
    async writeMappings() {
      const writes = [];
      for (let i = 0; i < this.workspacesData.length; i++) {
        if (this.workspacesData[i].valueList && this.workspacesData[i].valueList.length > 0) {
          const id = this.generateDocId("mappings");
          const data = {
            id: id,
            mapId: this.workspacesData[i].id,
            organization: this.$organization,
            [this.selectedAttribute.key]: this.workspacesData[i].valueList,
            type: "groups",
            timestamp: await this.getServerTimestamp(),
          };
          const write = {
            type: "set",
            collection: "mappings",
            id: id,
            data: data,
          };
          writes.push(write);
        }
      }
      return writes;
    },
    writeSettings(adminProcess) {
      const write = {
        type: "update",
        collection: "settings",
        id: this.$organization,
        data: {
          "userMappings.groupMapping": this.selectedAttribute.key ? [this.selectedAttribute.key] : [],
        },
      };
      if (adminProcess.type == "set") {
        write.data["userMappings.adminProcess"] = adminProcess.id;
      }
      return write;
    },
    async writeAdminProcess() {
      //schedule adminProcess so the backend process the updated mapping for existing users
      let write, id;
      //get id if there's already a doc
      if (this.$clientSettings && this.$clientSettings.userMappings && this.$clientSettings.userMappings.adminProcess) {
        id = this.$clientSettings.userMappings.adminProcess;
      }
      if (id) {
        write = {
          type: "update",
          collection: "adminProcesses",
          id: id,
          data: {
            scheduledAt: await this.getServerTimestamp(),
          },
        };
      } else {
        const newId = this.generateDocId("adminProcesses");
        write = {
          id: newId,
          type: "set",
          collection: "adminProcesses",
          data: {
            id: newId,
            organization: this.$organization,
            scheduledAt: await this.getServerTimestamp(),
            type: "mappings",
          },
        };
      }
      return write;
    },
    async applyChanges() {
      const writes = [];
      //any change will update 3 collections: adminProcesses, settings, and mappings
      const adminProcess = await this.writeAdminProcess();
      writes.push(adminProcess);
      writes.push(this.writeSettings(adminProcess));
      const mappings = await this.writeMappings();
      writes.push(...mappings);
      //delete all previous mappings right before saving to avoid having more than one mapping for a workspace.
      const deletes = await this.deletePreviousMappings();
      writes.push(...deletes);

      this.batchWrites(writes)
        .then(() => {
          Vue.prototype.$clientSettings.userMappings.groupMapping = [this.selectedAttribute.key];
          // alert("mapping successfully saved");
          this.alert.appliedSuccess = true;
          this.madeChange = false;
        })
        .catch((err) => {
          // alert("error saving chages");
          this.alert.appliedFailed = true;
        });
    },
    clearMapping() {
      this.selectedAttribute = {};
      this.workspacesData.forEach((workspace) => {
        workspace.valueList.length = 0;
      });
      this.madeChange = false;
    },
    getAllData() {
      try {
        this.getAllAttributes()
          .then((data) => {
            this.allAttributes = data;
          })
          .catch((err) => {
            throw err;
          });

        if (this.selectedAttribute.key) {
          this.getAttributeValues().then((data) => {
            this.attributeValueList = data;
            this.attributeValueList.sort();
          });
        }

        this.getWorkspaceData()
          .then(() => {})
          .catch((err) => {
            throw err;
          });
      } catch (err) {
        console.log(err);
      }
    },
    async getWorkspaceData() {
      this.workspacesData = [];
      const mappings = await this.fetchGroupsMappings();
      const groups = await this.fetchGroups();

      this.workspacesData = groups.map((group) => {
        const workspaceGroupName = this.getter_admin_workspaceg_group_map[group.workspaceGroupId].name;
        const valueList = mappings
          .filter((mapping) => mapping.mapId === group.id)
          .flatMap((mapping) => (this.selectedAttribute.key ? mapping[this.selectedAttribute.key] || [] : []));

        const updatedGroup = { ...group, workspaceGroupName, valueList };
        if (valueList.length) {
          updatedGroup.mappingId = group.id;
        }
        return updatedGroup;
      });
      this.isLoadingWorkflow = false;
    },
    async fetchGroupsMappings() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByQuery("mappings", [
            {
              field: "organization",
              comparison: "==",
              value: self.$organization,
            },
            {
              field: "type",
              comparison: "==",
              value: "groups",
            },
          ])
          .then((data) => {
            resolve(data);
          });
      });
    },
    async getAttributeValues() {
      const self = this;
      const data = {
        token: "rG5kXk0CDbhgF4RBlNoV",
        field: self.selectedAttribute.key,
        data: {
          idToken: await self.getIdToken(),
        },
      };
      return new Promise(function (resolve, reject) {
        axios
          .post(self.$service.admin + "settings/get-values", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchGroups() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByQuery("groups", [
            {
              field: "organization",
              comparison: "==",
              value: self.$organization,
            },
          ])
          .then((data) => {
            resolve(data);
          });
      });
    },
    async getAllAttributes() {
      const self = this;
      const data = {
        token: "rG5kXk0CDbhgF4RBlNoV",
        data: {
          idToken: await self.getIdToken(),
        },
      };
      return new Promise(function (resolve, reject) {
        axios
          .post(self.$service.admin + "settings/get-fields", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addItems(item) {
      this.popupType = "add";
      this.editWorkspaceData = item;
      this.showAddPopup = true;
    },
    removeItems(item) {
      this.popupType = "remove";
      this.editWorkspaceData = item;
      this.showAddPopup = true;
    },
    removeAttributes(selectedItems) {
      if (selectedItems.length == 0) {
        return;
      }
      this.workspacesData.forEach((workspace) => {
        if (workspace.id == this.editedWorkspaceId) {
          selectedItems.forEach((item) => {
            const index = workspace.valueList.indexOf(item);
            if (workspace.valueList.includes(item)) {
              workspace.valueList.splice(index, 1);
            }
          });
          workspace.valueList.sort();
        }
      });
      this.workspacesData.splice();
      this.madeChange = true;
    },
    addAttributes(selectedItems) {
      if (selectedItems.length == 0) {
        return;
      }
      this.workspacesData.forEach((workspace) => {
        if (workspace.id == this.editedWorkspaceId) {
          selectedItems.forEach((item) => {
            if (!workspace.valueList.includes(item)) {
              workspace.valueList.push(item);
            }
          });
          workspace.valueList.sort();
        }
      });
      this.workspacesData.splice();
      this.madeChange = true;
    },
    isInArray(arr, item) {
      const pos = arr
        .map((e) => {
          return e.id;
        })
        .indexOf(item.id);
      return pos != -1;
    },
  },
  computed: {
    ...mapGetters("workspaceGroups", ["getter_admin_workspaceg_group_map"]),
  },
};
</script>

<style scoped>
.d-admin-sso-mapping {
  position: relative;
  width: 100%;
  height: calc(100% - 150px);
  overflow: auto;
  padding: 32px;
  text-align: left;
}
.d-admin-sso-mapping:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.content-title {
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 20px;
}
.no-data-div {
  height: 296px;
  width: 100%;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
}
.no-data-text {
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  margin-top: 116px;
}
.attributes-div {
  width: 100%;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
  padding: 24px;
}
.attributes-title {
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 12px;
}
.attributes-subtitle {
  color: #919191;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 20px;
}
.select-attribute-div {
  position: relative;
  height: 36px;
  display: flex;
  max-width: 650px;
}
.select-attribute-btn-outter {
  position: relative;
  width: 100%;
}
.select-attribute-btn {
  position: relative;
  width: 100%;
  height: 36px;
  display: flex;
  border-bottom: 1px solid #dadada;
}
.disable-btn {
  pointer-events: none !important;
  opacity: 0.6;
}
.change-btn {
  margin: 0;
  text-transform: none !important;
  margin-left: 32px;
}
.no-text-transform {
  text-transform: none !important;
}
.attribute-text {
  color: #52545d;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 36px;
}
.group-list-div {
  position: absolute;
  width: 100%;
  top: 36px;
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  z-index: 1;
}
.group-list-div:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.dropdown-list-item:hover {
  background-color: rgba(245, 245, 245) !important;
}
.close-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.dropdown-list {
  width: 100%;
}
.mapping-updated-div {
  border: 1px solid #4689f3;
  background-color: rgba(70, 137, 243, 0.2);
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
  padding: 20px 16px;
  margin-top: 32px;
  min-height: 80px;
}
.updated-icon {
  position: relative;
  height: 24px;
  width: 24px;
}
.mapping-updated-text {
  margin-left: 16px;
  position: relative;
  width: 100%;
}
.updated-text-title {
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
}
.updated-text-subtitle {
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 6px 0 0 0;
}
.table-shadow {
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
  margin-top: 32px;
}
.search-icon {
  height: 32px;
  width: 32px;
  padding: 4px;
}
.search-input {
  margin: 0;
  padding: 0;
}
.search-input ::v-deep .v-text-field__slot input {
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.search-input ::v-deep .v-text-field__slot input::placeholder {
  opacity: 0.5;
  color: #919191;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.search-box {
  height: 70px;
  padding: 0 22px;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.analytics-table ::v-deep thead tr:first-child {
  height: 40px !important;
  background-color: #eaeaea;
}
.analytics-table ::v-deep table {
  color: #52545d;
  font-family: Roboto;
  font-size: 16px;
  line-height: 20px;
}
.analytics-table ::v-deep td {
  font-size: 16px;
}
.btn-td {
  display: flex;
  padding-top: 4px;
}
.click-to-add-text {
  opacity: 0.5;
}
</style>
