<template>
  <section class="attachment">
    <div class="attachment__header">
      <div class="attachment__header--left">{{ $t("all.attachments") }} ({{ attachmentsNumbers }})</div>
    </div>

    <div class="attachment__body">
      <div class="attachment__block" v-for="{ title, buttonText, type, data, isLoading } in attachments" :key="type">
        <div class="attachment__block--title">{{ title }}</div>
        <div
          class="attachment__block--body"
          :class="[type]"
          :attachment-type="type"
          :ref="type"
          v-on="v_on_attachment_block_body"
        >
          <!--  diagram -->
          <template v-if="type === ADDON_TYPE.DIAGRAM">
            <div
              v-for="(imgURL, diagramIndex) in data"
              :diagram-index="diagramIndex"
              :key="imgURL"
              :draggable="!isPublished && diagramConfig.deleteDiagramIndex === -1"
              @dragstart="handleDragStart($event, diagramIndex, type)"
              ref="diagram"
            >
              <d-image
                :aspect-ratio="16 / 9"
                :src="imgURL"
                contain
                @click="
                  diagramConfig.isShowDiagramPopup = true;
                  diagramConfig.selectedDialogPath = imgURL;
                "
                class="image"
              />
              <button class="deleteDiagram" @click="diagramConfig.deleteDiagramIndex = diagramIndex">
                <icon-base width="26" height="24">
                  <d-icon-circle-trash />
                </icon-base>
              </button>
              <div class="confirmOverlay" :class="{ active: diagramConfig.deleteDiagramIndex === diagramIndex }">
                <p class="mb-0" v-html="$t('editor.areYouSureDelete')"></p>
                <div class="mb-auto">
                  <button class="mr-2" @click="diagramConfig.deleteDiagramIndex = -1">{{ $t("all.no") }}</button>
                  <button
                    @click="
                      handleDeleteAttachment(type, diagramIndex);
                      diagramConfig.deleteDiagramIndex = -1;
                    "
                  >
                    {{ $t("all.yes") }}
                  </button>
                </div>
              </div>
            </div>
          </template>

          <!--  links -->
          <template v-else-if="type === ADDON_TYPE.LINKS">
            <div
              v-for="({ link, label }, linkIndex) in data"
              :links-index="linkIndex"
              :key="link + label + linkIndex"
              :draggable="!isPublished && linksConfig.deleteLinkIndex === -1"
              @dragstart="handleDragStart($event, linkIndex, type)"
            >
              <d-icon-link class="icon" />
              <div class="text" @click="openNewTab(link)">
                <p class="mb-0 text--link">{{ link }}</p>
                <p class="mb-0">{{ label }}</p>
              </div>
              <div class="action">
                <button @click="openLinkPopup('edit', linkIndex)">
                  <icon-base width="18" height="18" color="white" class="action--pen">
                    <d-icon-pen />
                  </icon-base>
                </button>
                <button @click="linksConfig.deleteLinkIndex = linkIndex">
                  <icon-base width="26" height="24" class="mr-3 ml-1">
                    <d-icon-circle-trash />
                  </icon-base>
                </button>
              </div>
              <div class="confirmOverlay" :class="{ active: linksConfig.deleteLinkIndex === linkIndex }">
                <p class="mb-0" v-html="$t('editor.areYouSureDelete')"></p>
                <button class="mx-2" @click="linksConfig.deleteLinkIndex = -1">{{ $t("all.no") }}</button>
                <button
                  @click="
                    handleDeleteAttachment(type, linkIndex);
                    linksConfig.deleteLinkIndex = -1;
                  "
                >
                  {{ $t("all.yes") }}
                </button>
              </div>
            </div>
          </template>

          <!--  pdfs -->
          <template v-else-if="type === ADDON_TYPE.PDF">
            <div
              v-for="({ name, size, link }, pdfIndex) in data"
              :pdf-index="pdfIndex"
              :key="name + size + link + pdfIndex"
              :draggable="!isPublished && PDFConfig.deleteIndex === -1"
              @dragstart="handleDragStart($event, pdfIndex, type)"
            >
              <div
                @click="
                  PDFConfig.isShowPopup = true;
                  PDFConfig.pdfLink = link;
                "
              >
                <d-icon-p-d-f class="icon" />
                <div class="name">{{ name }}</div>
                <div class="size">{{ formatBytes(size) }}</div>
              </div>
              <button class="deletePDF" @click.capture="PDFConfig.deleteIndex = pdfIndex">
                <icon-base width="26" height="24">
                  <d-icon-circle-trash />
                </icon-base>
              </button>
              <div class="confirmOverlay" :class="{ active: PDFConfig.deleteIndex === pdfIndex }">
                <p class="mb-0" v-html="$t('editor.areYouSureDelete')"></p>
                <div class="mb-auto">
                  <button class="mr-2" @click.stop="PDFConfig.deleteIndex = -1">{{ $t("all.no") }}</button>
                  <button
                    @click.stop="
                      handleDeleteAttachment(type, pdfIndex);
                      PDFConfig.deleteIndex = -1;
                    "
                  >
                    {{ $t("all.yes") }}
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
        <button :disabled="isLoading" class="attachment__block--action" @click="handleClickAdd(type)">
          + {{ buttonText }}
          <v-progress-linear
            v-if="isLoading"
            class="attachment__block--action-progress"
            indeterminate
            color="primary"
            height="2"
          />
        </button>
      </div>
    </div>

    <!-- diagramPopup dialog -->
    <v-dialog v-model="diagramConfig.isShowDiagramPopup">
      <button class="popup-close" @click="diagramConfig.isShowDiagramPopup = false">
        <icon-base>
          <d-icon-close2 />
        </icon-base>
      </button>
      <div class="darken-layer-popup" @click="diagramConfig.isShowDiagramPopup = false"></div>
      <div class="popup-div">
        <d-image
          :aspect-ratio="16 / 9"
          :src="diagramConfig.selectedDialogPath"
          :key="diagramConfig.selectedDialogPath"
          contain
          class="popup-img"
        ></d-image>
      </div>
    </v-dialog>

    <input ref="inputFile" @change="handleFileUpload" type="file" :accept="inputFileAccept" hidden multiple />

    <d-builder-link-popup
      v-if="linksConfig.isShowPopupToEditLink"
      :showPopup="linksConfig.isShowPopupToEditLink"
      :popupType="linksConfig.popupType"
      :selectedLink="linksConfig.selectedLink"
      :editIdx="linksConfig.editIdx"
      @click-add-btn="handleLinkChange"
      @click-edit-btn="handleLinkChange"
      @close-popup="
        linksConfig.selectedLink = {};
        linksConfig.isShowPopupToEditLink = false;
      "
      @trackLinkInputBlurByHeap="trackLinkInputBlurByHeap"
    ></d-builder-link-popup>

    <d-p-d-f-popup
      v-model="PDFConfig.isShowPopup"
      :p-d-f-link="PDFConfig.pdfLink"
      @cleanPDFLink="PDFConfig.pdfLink = ''"
    />

    <d-alert v-model="errorConfig.isShow" :type="errorConfig.type" :message="errorConfig.message" />
  </section>
</template>

<script>
import MixinUpload from "../MixinUpload";
import DImage from "@/components/ui_components/DImage";
import IconBase from "../IconBase";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconCircleTrash from "@/components/icons/DIconCircleTrash.vue";
import DIconLink from "@/components/icons/builder/DIconLink";
import DIconPen from "@/components/icons/DIconPen.vue";
import DIconPDF from "@/components/icons/builder/DIconPDF";
import Analytics from "@/js/analytics/analytics";
import { checkUploadDiagramType, checkUploadPDFmType } from "../../js/upload/checkUpload";
import { ADDON_TYPE, ADDON_GET_DATA_TYPE } from "../../constants/attachmentStatus";
import { ATTACHMENT_TYPE } from "@/constants/heapEvents.js";
import DBuilderLinkPopup from "../DPopup/DBuilderLinkPopup";
import DPDFPopup from "../DPopup/DPDFPopup";
import { uploadToCloudStorageByFileType } from "@/server/upload-server";
import DAlert from "../ui_components/DAlert";
import { fetchAttachmentData, putUpdateAttachmentData, postCreateAttachmentData } from "../../server/attachment-server";

export default {
  name: "BuilderAttachments",
  mixins: [MixinUpload],
  components: {
    DImage,
    IconBase,
    DIconClose2,
    DIconCircleTrash,
    DIconLink,
    DIconPen,
    DBuilderLinkPopup,
    DIconPDF,
    DPDFPopup,
    DAlert,
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
    isPublished: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    const diagramConfig = {
      isShowDiagramPopup: false,
      selectedDialogPath: "",
      deleteDiagramIndex: -1,
      accept: "image/jpeg, image/gif, image/png, image/webp, image/bmp, image/avif, image/x-icon",
    };

    const linksConfig = {
      isShowPopupToEditLink: false,
      selectedLink: {},
      popupType: "new", // new, edit,
      editIdx: -1,
      deleteLinkIndex: -1,
    };

    const draggingConfig = {
      draggingIndex: -1,
      draggingMode: "uploadFile", // uploadFile, changeOrder
    };

    const PDFConfig = {
      accept: ".pdf",
      isShowPopup: false,
      pdfLink: "",
      deleteIndex: -1,
    };

    const errorConfig = {
      isShow: false,
      message: this.$t("builder.onlyImageAccepted"),
      type: "error",
    };

    return {
      ...draggingConfig,
      ADDON_TYPE,
      ADDON_GET_DATA_TYPE,
      diagramConfig,
      PDFConfig,
      linksConfig,
      errorConfig,
      inputFileAccept: diagramConfig.accept,
      selectedType: "",
      attachments: [
        {
          title: this.$t("all.diagram"),
          buttonText: this.$t("builder.addDiagram"),
          data: [],
          type: ADDON_TYPE.DIAGRAM,
          isLoading: false,
        },
        {
          title: this.$t("builder.links"),
          buttonText: this.$t("builder.addALink"),
          data: [],
          type: ADDON_TYPE.LINKS,
          isLoading: false,
        },
        {
          title: this.$t("builder.PDF"),
          buttonText: this.$t("builder.addPDF"),
          data: [],
          type: ADDON_TYPE.PDF,
          isLoading: false,
        },
      ],
    };
  },
  methods: {
    checkIsWorkflowPublished() {
      if (this.isPublished) {
        this.$emit("showEditWorkflowPopup");
      }
      return this.isPublished;
    },
    async refreshAttachmentData() {
      try {
        const stepId = this.step.id;
        const { ok, items, message } = await fetchAttachmentData(stepId);
        if (!ok) {
          console.error(message);
          return;
        }
        this.attachments.forEach((attachment) => {
          const { type } = attachment;
          const targetData = items.find((item) => item.type === type);
          attachment.data = targetData ? targetData[ADDON_GET_DATA_TYPE[type]] : [];
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleClickAdd(type) {
      if (this.checkIsWorkflowPublished()) return;
      this.selectedType = type;
      switch (type) {
        case ADDON_TYPE.DIAGRAM:
          this.inputFileAccept = this.diagramConfig.accept;
          this.$nextTick(() => {
            this.$refs.inputFile.click();
          });
          break;
        case ADDON_TYPE.LINKS:
          this.openLinkPopup("new");
          break;
        case ADDON_TYPE.PDF:
          this.inputFileAccept = this.PDFConfig.accept;
          this.$nextTick(() => {
            this.$refs.inputFile.click();
          });
          break;
      }
      this.trackUploadDataTypeByHeap(type);
    },
    async handleFileUpload(e) {
      const { files } = e.target;
      const types = Array.from(files).map((file) => file.type);
      const sizes = Array.from(files).map((file) => file.size);
      const targetType = this.selectedType;
      const target = this.attachments.find((attachment) => attachment.type === targetType);
      if (!this.checkUploadFileType(types)) return;
      if (!this.checkUploadFileSize(sizes)) return;
      try {
        target.isLoading = true;
        const data = await this.uploadFile(files, targetType);
        const type = ATTACHMENT_TYPE[targetType];
        Analytics.setTrack(`Web - Builder - Edit - Add ${type}`, null, {
          category: "BuilderMain",
          action: "Edit Attachment",
          name: `Upload ${type}`,
        });
        await this.updateAttachments(targetType, target.data.concat(data));
        await this.refreshAttachmentData();
      } catch (error) {
        console.log(error);
      } finally {
        target.isLoading = false;
        this.$refs.inputFile.value = null;
      }
    },
    openLinkPopup(type = "new", index) {
      if (this.checkIsWorkflowPublished()) return;
      if (type === "edit") {
        const targetLink = this.attachments.find((attach) => attach.type === ADDON_TYPE.LINKS);
        this.linksConfig.selectedLink = targetLink.data[index];
        this.linksConfig.editIdx = index;
      }
      this.linksConfig.isShowPopupToEditLink = true;
      this.linksConfig.popupType = type;
    },
    handleLinkChange(newLink) {
      const targetLink = this.attachments.find((attach) => attach.type === ADDON_TYPE.LINKS);
      targetLink.isLoading = true;
      try {
        if (this.linksConfig.popupType === "edit") {
          targetLink.data[this.linksConfig.editIdx] = newLink;
        } else {
          targetLink.data.push(newLink);
        }
        this.linksConfig.isShowPopupToEditLink = false;
        this.updateAttachments(ADDON_TYPE.LINKS, targetLink.data);
        Analytics.setTrack(`Web - Builder - Click - Confirm Add Link`, null, {
          category: "BuilderMain",
          action: "Edit Attachment",
          name: "Confirm Add Link",
        });
      } catch (error) {
        console.log(error);
      } finally {
        targetLink.isLoading = false;
      }
    },
    openNewTab(link) {
      window.open(link, "_blank");
    },
    async updateAttachments(type, data) {
      const addonId = this.step[type];
      const stepId = this.step.id;
      const requestBody = {
        type,
        [ADDON_GET_DATA_TYPE[type]]: data,
      };
      let response;
      if (addonId) {
        response = await putUpdateAttachmentData({ stepId, requestBody, addonId });
      } else {
        response = await postCreateAttachmentData({ stepId, requestBody });
      }
      if (!response.ok) {
        console.error(response.message);
      }
    },
    async handleDeleteAttachment(type, index) {
      if (this.checkIsWorkflowPublished()) return;
      const target = this.attachments.find((attachment) => attachment.type === type);
      target.data.splice(index, 1);
      await this.updateAttachments(type, target.data);
      await this.refreshAttachmentData();
    },
    async uploadFile(files, type = ADDON_TYPE.DIAGRAM) {
      const promises = [];
      try {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileType = file.type.replace(/\w+\//, "");
          const fileName = `${type}-` + this.uuidv4() + "." + fileType;
          const newFile = new File([file], fileName, { type: file.type });
          promises.push(
            new Promise(async (resolve) => {
              const url = await uploadToCloudStorageByFileType(type, newFile, {});
              resolve(type === ADDON_TYPE.DIAGRAM ? url : { name: file.name, link: url, size: file.size });
            })
          );
        }
        return await Promise.all(promises);
      } catch (error) {
        console.error(error);
      }
    },
    checkUploadFileType(types) {
      let checkResult = false;
      switch (this.selectedType) {
        case ADDON_TYPE.DIAGRAM:
          checkResult = checkUploadDiagramType(types);
          break;
        case ADDON_TYPE.PDF:
          checkResult = checkUploadPDFmType(types);
          break;
      }
      if (!checkResult) {
        const errorMessage =
          this.selectedType === ADDON_TYPE.PDF
            ? this.$t("builder.onlyPDFAccepted")
            : this.$t("builder.onlyImageAccepted");
        this.handleCheckedFileError(errorMessage);
      }
      return checkResult;
    },
    checkUploadFileSize(sizes) {
      if (this.selectedType !== ADDON_TYPE.PDF) return true;
      const PDF_LIMIT = 50 * 1024 * 1024;
      const overLimit = sizes.find((size) => size > PDF_LIMIT);
      if (overLimit) {
        this.handleCheckedFileError(this.$t("builder.PDFSizeLimit"));
        return false;
      }
      return true;
    },
    handleCheckedFileError(message) {
      this.errorConfig.isShow = true;
      this.errorConfig.message = message;
      this.$refs.inputFile.value = null;
      setTimeout(() => {
        this.errorConfig.isShow = false;
      }, 2000);
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    getAttributeByEvent(event, attributeName) {
      try {
        let targetSource = event.target;
        let attributeValue = "";
        while (!(attributeValue = targetSource.getAttribute(attributeName))) {
          targetSource = targetSource.parentElement;
        }
        return attributeValue;
      } catch (error) {
        // Not found target attribute
        return -1;
      }
    },
    setDefault() {
      this.draggingMode = "uploadFile";
      this.diagramConfig.deleteDiagramIndex = -1;
      this.linksConfig.deleteLinkIndex = -1;
      this.PDFConfig.deleteIndex = -1;
      this.selectedType = "";
    },
    handleDragStart(e, draggingIndex, type) {
      this.draggingMode = "changeOrder";
      this.draggingIndex = draggingIndex;
      this.selectedType = type;
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.effectAllowed = "move";
    },
    trackUploadDataTypeByHeap(dataType) {
      const type = ATTACHMENT_TYPE[dataType];
      Analytics.setTrack(`Web - Builder - Click - Enter Add ${type}`, null, {
        category: "BuilderMain",
        action: "Edit Attachment",
        name: `Click Add ${type}`,
      });
    },
    trackLinkInputBlurByHeap(type) {
      Analytics.setTrack(`Web - Builder - Edit - Link ${type}`, null, {
        category: "BuilderMain",
        action: "Edit Attachment",
        name: `Edit Link ${type}`,
      });
    },
  },
  watch: {
    step: {
      immediate: true,
      handler() {
        this.refreshAttachmentData();
      },
    },
  },
  computed: {
    attachmentsNumbers() {
      return this.attachments.reduce((pre, attachment) => {
        pre += attachment.data.length;
        return pre;
      }, 0);
    },
    v_on_attachment_block_body() {
      return {
        dragenter: (e) => {
          e.preventDefault();
          this.selectedType = this.getAttributeByEvent(e, "attachment-type");
        },
        dragover: (e) => {
          e.preventDefault();
          if (this.draggingMode === "uploadFile") return;
          const targetIndex = this.getAttributeByEvent(e, `${this.selectedType}-index`);
          const attachment = this.attachments.find((attachment) => attachment.type === this.selectedType);
          if (this.draggingIndex !== targetIndex && targetIndex !== -1) {
            // swap order
            attachment.data.splice(targetIndex, 0, attachment.data.splice(this.draggingIndex, 1)[0]);
            this.draggingIndex = targetIndex;
          }
        },
        drop: async (e) => {
          e.preventDefault();
          if (this.checkIsWorkflowPublished()) return;
          const { files } = e.dataTransfer;
          const targetType = this.selectedType;
          const attachment = this.attachments.find((attachment) => attachment.type === targetType);
          try {
            if (
              this.draggingMode === "uploadFile" &&
              files.length &&
              [ADDON_TYPE.PDF, ADDON_TYPE.DIAGRAM].includes(targetType)
            ) {
              attachment.isLoading = true;
              const types = Array.from(files).map((file) => file.type);
              const sizes = Array.from(files).map((file) => file.size);
              if (!this.checkUploadFileType(types)) return;
              if (!this.checkUploadFileSize(sizes)) return;
              const data = await this.uploadFile(files, targetType);
              attachment.data = attachment.data.concat(data);
            }
            await this.updateAttachments(targetType, attachment.data);
            await this.refreshAttachmentData();
          } catch (error) {
            console.error(error);
          } finally {
            attachment.isLoading = false;
            this.setDefault();
          }
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.attachment {
  --header-height: 40px;
  --block-body-height: 430px;
  --small-block-height: 180px;
  --small-block-width: 32%;
  --sub-background-color: rgb(44, 45, 50);
  --confirmOverlayBackgroundColor: #e03535;
  --pdf-icon-height: 50%;
  --pdf-text-bottom: 16px;

  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-height: 1010px) {
    --block-body-height: 500px;
  }

  @media (min-height: 1100px) {
    --block-body-height: 600px;
  }

  @media (min-height: 1200px) {
    --block-body-height: 700px;
  }

  @media (min-height: 1300px) {
    --block-body-height: 750px;
  }

  @media (max-width: 1580px) {
    --small-block-width: 31.5%;
  }

  @media (max-width: 1440px) {
    --small-block-height: 124px;
    --small-block-width: 31%;
    --pdf-icon-height: 40%;
    --pdf-text-bottom: 6px;
  }

  @media (max-width: 1024px) {
    --small-block-width: 45%;
  }

  &__header {
    flex-basis: var(--header-height);

    display: flex;
    justify-content: space-between;
    padding: 0 32px;
    align-items: center;

    &--left {
      font-size: 24px;
      color: #fff;
      text-transform: capitalize;
      margin-right: auto;
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    padding: 0 1rem;
    overflow: hidden;
  }

  &__block {
    flex: 1;
    border: 1px solid #33353a;
    margin: 1rem 1rem 4rem 1rem;

    display: flex;
    flex-direction: column;

    &--title {
      flex-basis: 40px;
      background-color: var(--sub-background-color);
      color: #fff;
      font-size: 16px;
      line-height: 40px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &--body {
      flex-grow: 1;
      max-height: var(--block-body-height);
      overflow-y: scroll;

      &.diagram {
        @extend %diagramBlockStyle;
      }

      &.links {
        @extend %linkBlockStyle;
      }

      &.pdf {
        @extend %pdfBlockStyle;
      }

      .confirmOverlay {
        transition: opacity 0.2s ease-in;
        opacity: 0;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--confirmOverlayBackgroundColor);
        color: white;
        font-size: 14px;
        line-height: 18px;
        cursor: initial;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        button {
          width: 52px;
          height: 28px;
          font-size: 12px;
          color: white;
          border-radius: 15px;
          border: 1px solid white;
          transition: all 0.2s ease-in;

          &:hover {
            color: #e03535;
            background-color: white;
          }
        }

        &.active {
          opacity: 1;
          z-index: 30;
        }
      }
    }

    &--action {
      border: 1px solid currentColor;
      width: calc(100% - 32px);
      color: #4689f3;
      font-size: 16px;
      padding: 10px 0;
      border-radius: 50px;
      margin: 0 16px 16px 16px;

      &-progress {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
}

%linkBlockStyle {
  & > div {
    background-color: var(--sub-background-color);
    color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    margin: 1rem;
    cursor: pointer;
    position: relative;

    .icon {
      margin: 1rem;
    }

    .text {
      font-size: 16px;
      font-weight: 400;
      margin-right: auto;
      &--link {
        color: #9e9e9e;
      }

      p {
        text-align: start;
        user-select: none;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.1;
        width: 200px;
      }
    }

    .action {
      transition: opacity 0.2s ease-in;
      opacity: 0;

      &--pen {
        height: 24px;
        width: 24px;
        background-color: #0c0c0e;
        border-radius: 50%;
        padding: 2px;
      }
    }

    .confirmOverlay {
      background-color: var(--confirmOverlayBackgroundColor);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;

      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        opacity: 1;
        z-index: 10;
        cursor: initial;
      }

      button {
        width: 52px;
        height: 28px;
        font-size: 12px;
        color: white;
        border-radius: 15px;
        border: 1px solid white;
        transition: all 0.2s ease-in;

        &:hover {
          color: #e03535;
          background-color: white;
        }
      }
    }

    &:hover {
      background-color: #2c2d3250;

      .action {
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

%diagramBlockStyle {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;

  & > div {
    flex-basis: var(--small-block-width);
    background: var(--sub-background-color);
    margin: 3px;
    flex-grow: 0;
    position: relative;
    cursor: pointer;
    height: var(--small-block-height);

    .image {
      height: 100%;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      transition: all 0.2s ease-in;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      pointer-events: none;
    }

    &:hover {
      &:before {
        background-color: transparent;
      }

      & .deleteDiagram {
        opacity: 0.7;
      }
    }

    .deleteDiagram {
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0;
      transition: opacity 0.2s ease-in;

      &:hover {
        opacity: 1;
      }
    }
  }
}

%pdfBlockStyle {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  & > div {
    flex-basis: var(--small-block-width);
    background: var(--sub-background-color);
    margin: 3px;
    flex-grow: 0;
    position: relative;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    color: #fff;
    height: var(--small-block-height);

    .icon {
      position: absolute;
      right: 25%;
      height: var(--pdf-icon-height);
      width: 50%;
      top: 16px;
    }

    .name,
    .size {
      position: absolute;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-indent: 1rem;
      user-select: none;
    }

    .name {
      bottom: calc(30px + var(--pdf-text-bottom));
      width: calc(100% - 1rem);
    }

    .size {
      text-align: start;
      bottom: var(--pdf-text-bottom);
      width: 100%;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      transition: all 0.2s ease-in;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      pointer-events: none;
    }

    &:hover {
      &:before {
        background-color: transparent;
      }

      & .deletePDF {
        opacity: 0.7;
      }
    }

    .deletePDF {
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0;
      transition: opacity 0.2s ease-in;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.popup-close {
  position: fixed;
  top: 24px;
  left: 26px;
  color: white;
  z-index: 2;
}

.darken-layer-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
}

.popup-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 60px 80px;
  pointer-events: none;
}

.popup-img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: auto;
}
</style>
