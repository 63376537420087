<template>
  <div class="admin-group">
    <div class="top-tabs">
      <d-tabs 
        v-model="currentTab" 
        autoWidth 
        light 
        height="64"
        :tabNames="tabNames" 
        :autotest="autotestTabs" 
      ></d-tabs>
    </div>
    <div v-if="showPart=='workspaces'" class="tab-div">
      <div class="tab-title-row">
        <p class="tab-title text-no-wrap" autotest="workspace-count">
          <b>{{groupsData.length}}</b>
          {{$tc("all.countWorkspaces", groupsData.length)}}
        </p>
        <v-spacer></v-spacer>
      </div>
      <div class="table-wrap">
          <d-data-table
            add-search
            local-search
            initial-sort
            :headers="groupTableHeaders"
            :loading="!loadingFinished"
            :items="groupsData"
            :placeholder="$t('admin.hintSearchWorkspaces')"
            searchAutotest="workspace-search-bar"
          >
            <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
            <template slot="no-data">
              <span>
                <!-- This account does not have Workspaces yet. -->
                {{$t('admin.msgNoRecordsWorkspacesGroupAdmin')}}
              </span>
            </template>
            <template slot="no-results">
              <span>
                <!-- No matching records found in Workspaces. -->
                {{$t('admin.msgNoRecordsWorkspaces')}}
              </span>
            </template>
            <template slot="headerCell" slot-scope="props">
              <span class="head-text">{{ props.header.text }}</span>
            </template>
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">
                <span
                  class="group-name-hover app-all-white-spaces"
                  @click="clickGroupName(props.item.name, props.item.id)"
                  autotest="name-display"
                >{{ props.item.name }}</span>
              </td>
              <td class="text-xs-left" autotest="number-of-users-display">{{props.item.numberOfUsers}}</td>
              <td class="text-xs-left text-capitalize" autotest="business-display">{{props.item.businessName}}</td>
              <td v-if="showPrivateColumn" autotest="private-display">
                <div v-if="props.item.private" class="private-btn private-btn-dark">
                  <span class="text-no-wrap text-capitalize">
                    <icon-base width="14" height="14" color="white" class="private-icon">
                      <d-icon-lock />
                    </icon-base>
                    {{$t("all.private")}}
                  </span>
                </div>
                <div v-else class="private-btn">
                  <span class="text-no-wrap text-capitalize">
                    <icon-base width="14" height="14" color="#3F3F3F" class="private-icon">
                      <d-icon-lock-open />
                    </icon-base>
                    {{$t("all.public")}}
                  </span>
                </div>
              </td>
            </template>
          </d-data-table>
      </div>
    </div>

    <!-- users in group table -->
    <admin-group-users
      v-if="showGroupUsers"
      ref="AdminGroupUsers"
      :groupName="groupName"
      :groupId="groupId"
      :userRole="userRole"
      :mouseoverSearch="mouseoverSearch"
      :groupUsersData="groupUsersData"
      :groupDictionary="groupDictionary"
      @closeGroupUsers="closeGroupUsers()"
    ></admin-group-users>

    <div v-if="$access.teams && showPart=='teams'" class="tab-div">
      <admin-teams v-if="!showSingleTeam" @go-to-single-team="goToSingleTeam($event)"></admin-teams>
      <admin-team-users
        v-if="showSingleTeam"
        :groupDictionary="groupDictionary"
        @close-single-team="closeSingleTeam()"
      ></admin-team-users>
    </div>

    <!-- <div v-if="$access.categoriesCreation && showPart=='settings'" class="tab-div">
        <d-admin-settings></d-admin-settings>
    </div> -->
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import IconBase from "./IconBase.vue";
import DIconSearch from "./icons/DIconSearch.vue";
import DIconTrash from "./icons/DIconTrash.vue";
import DIconPen from "./icons/DIconPen.vue";
import AdminGroupUsers from "@/components/AdminGroupUsers.vue";
import DTabs from "@/components/ui_components/DTabs.vue";
import AdminTeams from "./DAdmin/AdminTeams.vue";
import AdminTeamUsers from "./DAdmin/AdminTeamUsers.vue";
import DDataTable from "./ui_components/DDataTable.vue";
import DAdminSettings from "./ui_components/DAdminSettings.vue";
import DIconLock from "./icons/DIconLock.vue";
import DIconLockOpen from "./icons/DIconLockOpen.vue";
import MixinDB from "./MixinDB.vue";
import MixinUser from "./MixinUser.vue";
import MixinAdmin from "./MixinAdmin.vue";
export default {
  name: "AdminGroup",
  props: {
    userRole: String
  },
  data() {
    return {
      mouseoverSearch: false,
      groupUsersData: {},
      searchGroup: "",
      groupTableHeaders: [
        {
          text: this.$t("all.name"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "name-sort"
        },
        {
          text: this.$t("analytics.numberOfUsers"),
          align: "left",
          sortable: true,
          value: "numberOfUsers",
          autotest: "number-of-users-sort"
        },
        {
          text: this.$t("all.business"),
          align: "left",
          sortable: true,
          value: "businessName",
          autotest: "business-sort"
        }
      ],
      groupsData: [],
      showGroupUsers: false,
      groupName: "",
      formData: {},
      testTest: null,
      businessDictionary: {},
      loadingFinished: false,
      groupDictionary: {},
      currentTab: 0,
      tabNames: [this.$t("all.workspaces")],
      autotestTabs: ["workspaces-tab"],
      showPart: "workspaces",
      showSingleTeam: false,
      showPrivateColumn: false
    };
  },
  watch: {
    groupsData: function(groupsData) {
      this.checkPrivateColumn(groupsData);
    },
    currentTab: function() {
      this.searchGroup = "";
      this.showSingleTeam = false;
      this.showGroupUsers = false;
      switch (this.currentTab) {
        case 0:
          this.showPart = "workspaces";
          this.$router.push("/admin/main");
          break;
        case 1:
          this.showPart = "teams";
          this.$router.push("/admin/teams");
          break;
        case 2:
          this.showPart = "settings";
          this.$router.push("/admin/settings");
          break;
        default:
          this.showPart = "workspaces";
          this.$router.push("/admin/main");
      }
    }
  },
  components: {
    IconBase,
    DIconSearch,
    DIconTrash,
    DIconPen,
    AdminGroupUsers,
    DTabs,
    AdminTeams,
    AdminTeamUsers,
    DDataTable,
    DAdminSettings,
    DIconLock,
    DIconLockOpen,
  },
  mixins: [MixinDB, MixinUser, MixinAdmin],
  created() {
    if (this.$access.teams) {
      this.tabNames.push(this.$t("all.teams"));
      this.autotestTabs.push("teams-tab");
    }
    // if (this.$access.categoriesCreation) {
    //   this.tabNames.push(this.$t("admin.settings"));
    // }
    const self = this;
    this.getGroupsData()
      .then(() => self.getBusinessData())
      .then(() => self.updateGroupsUsersCount(self.groupsData))
      .then(groups => {
        self.$set(self.groupsData, groups);
        self.loadingFinished = true;
        self.setCurrentTab();
      })
      .catch(err => console.log(err));
    this.getGroupDictionary();
  },
  mounted() {},
  updated() {},
  computed: {},
  methods: {
    checkPrivateColumn(groupsData) {
      const addedPrivate = this.groupTableHeaders.find(
        group => group.value === "private"
      );
      const hasPrivate = groupsData.find(
        group => group.private === true
      );
      if (hasPrivate && !addedPrivate) {
        this.showPrivateColumn = true;
        const header = {
          text: this.$t("all.private"),
          align: "left",
          sortable: true,
          value: "private",
          autotest: "private-sort"
        }
        this.groupTableHeaders.splice(3, 0, header);
      } else if (!hasPrivate && addedPrivate) {
        this.showPrivateColumn = false;
        this.groupTableHeaders.splice(4, 1);
      } else if (hasPrivate) {
        this.showPrivateColumn = true;
      }
    },
    closeSingleTeam() {
      this.$router.push("/admin/teams");
      this.showSingleTeam = false;
    },
    goToSingleTeam(team) {
      team.tab = "team";
      this.$router.push({ name: "AdminTeam", params: team });
      this.showSingleTeam = true;
    },
    setCurrentTab() {
      const id = this.$route.params.id;
      const tab = this.$route.params.tab;
      if (id && tab == "main") {
        this.currentTab = 0;
        this.groupId = id;
        this.showGroupUsers = true;
        return;
      }
      if (id && tab == "team") {
        this.currentTab = 1;
        this.showSingleTeam = true;
        return;
      }
      switch (tab) {
        case "main":
          this.currentTab = 0;
          break;
        case "teams":
          this.currentTab = 1;
          break;
        default:
          this.currentTab = 0;
      }
    },
    closeGroupUsers() {
      const self = this;
      this.showGroupUsers = false;
      this.$router.push("/admin/main");
      // update group.numberOfUsers
      const groupIndex = this.groupsData.findIndex(x => x.id === this.groupId);
      const groupData = this.groupsData[groupIndex];
      this.updateGroupUsersCount(groupData)
        .then(group => {
          self.$set(self.groupsData, groupIndex, group);
        })
        .catch(err => console.log(err));
    },
    // get all group names in organization
    getGroupDictionary() {
      const self = this;
      this.fetchAllGroups().then(data => {
        data.forEach(group => {
          self.groupDictionary[group.id] = group.name;
        });
      });
    },
    fetchAllGroups() {
      const self = this;
      const userId = self.$userProfile.id;
      return new Promise(function(resolve, reject) {
        self
          .getDocumentByFieldValue("groups", "organization", self.$organization)
          .then(data => {
            resolve(data);
          });
      });
    },
    getBusinessName(id) {
      return this.businessDictionary[id] || "-";
    },
    getBusinessData() {
      const self = this;
      let promises = [];
      return new Promise(function(resolve, reject) {
        self.groupsData.forEach(group => {
          if (group.business) {
            promises.push(self.getDocument("businesses", group.business));
          }
        });
        Promise.all(promises)
          .then(all => {
            all.forEach(business => {
              self.businessDictionary[business.id] = business.name;
            });
            //add businessName to groupsData
            self.groupsData.forEach(group => {
              group.businessName = self.getBusinessName(group.business);
            });
            resolve();
          })
          .catch(err => console.log(err));
      });
    },
    getGroupsData() {
      const self = this;
      return new Promise(function(resolve, reject) {
        self
          .fetchGroups()
          .then(data => {
            data.forEach(group => {
              //don't include default group for admin access
              if (!group.default) {
                self.groupsData.push(group);
              }
            });
            // data.forEach(group => {
            //   self.groupDictionary[group.id] = group.name;
            // });
            resolve();
            // debugger
            // data.forEach(group => {
            //   self
            //     .fetchUsers(group.id)
            //     .then(data => {
            //       self.groupUsersData[group.id] = data;
            //     })
            //     .catch(err => {});
            // });
          })
          .catch(err => {
            reject();
          });
      });
    },
    fetchUsers(groupId) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self
          .getDocumentByArrayElement("users", "groups", groupId)
          .then(data => {
            resolve(data);
          })
          .catch(err => console.log(err));
      });
    },
    fetchGroups() {
      const self = this;
      const userId = self.$userProfile.id;
      return new Promise(function(resolve, reject) {
        self
          .getDocumentByArrayElement("groups", "admins", userId)
          .then(data => {
            resolve(data);
          })
          .catch(err => console.log(err));
      });
    },
    clickGroupName(name, id) {
      this.groupName = name;
      this.groupId = id;
      this.showGroupUsers = true;
      this.$router.push("/admin/main/" + id);
    }
  }
};
</script>

<style scoped>
.admin-group {
  position: fixed;
  left: 0;
  top: 70px;
  height: calc(100% - 70px);
  width: 100%;
  padding: 0;
  background-color: #fbfbfb;
}
.top-tabs {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 28px;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}
.tab-div {
  position: fixed;
  height: calc(100% - 134px);
  top: 134px;
  left: 0;
  width: 100%;
}
.tab-title-row {
  position: relative;
  height: 95px;
  width: 100%;
  display: flex;
  padding: 30px 28px 0 28px;
}
.tab-title {
  height: 44px;
  width: 145px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 44px;
}
.table-wrap {
  position: fixed;
  top: 224px;
  left: 0;
  width: 100%;
  height: calc(100% - 224px);
  padding: 0 28px;
  overflow: scroll;
}
.table-wrap:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.head-text {
  text-transform: uppercase;
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
.group-name-hover:hover {
  color: #2d2e32;
  cursor: pointer;
  font-weight: 500;
}
.private-btn{
  display: inline-block;
  height: 32px;
  min-width: 100px;
  padding: 6px 8px 0 8px;
  border: 1px solid #E7E7E7;
  border-radius: 16px;
  background-color: #FFFFFF;
  color: #3F3F3F;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
}
.private-btn-dark {
  border: 1px solid #E7E7E7;
  border-radius: 19px;
  background-color: #52545D;
  color: #FFFFFF;
}
.private-icon {
  margin: 0 2px -2px 4px;
}
@media screen and (max-width: 959px) {
  .top-tabs {
    padding: 0 20px;
  }
  .tab-title-row {
    padding: 30px 20px 0 20px;
  }
  .table-wrap {
    padding: 0 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1903px) {
  .top-tabs {
    padding: 0 28px;
  }
  .tab-title-row {
    padding: 30px 28px 0 28px;
  }
}
</style>
