<template>
  <div class="annotationNotes">
    <div v-if="sortedDataArray.length === 0" class="annotationNotes--empty">
      {{ $t("builder.noNotesAddedYet") }}
    </div>
    <div v-else class="annotationNotes__list">
      <div class="annotationNotes__note-text">{{ $t("builder.allNotes") }}</div>
      <div class="annotationNotes__list-container" ref="listContainer">
        <div
          v-for="(data, idx) in sortedDataArray"
          :key="data.id"
          class="annotationNotes__row-item"
        >
          <div class="annotationNotes__note-text annotationNotes__note-no">
            {{ idx + 1 }}.
          </div>
          <div
            :class="[
              'annotationNotes__note-detail',
              {
                'annotationNotes__note-detail--active': data.id === selectedId,
              },
            ]"
            @click="$emit('handleNoteSelect', data)"
          >
            <div class="annotationNotes__note-time-info">
              <div class="annotationNotes__note-icon">
                <component :is="iconTypeMap[data.type]" />
              </div>
              <input
                :disabled="isPublished"
                name="startTime"
                class="annotationNotes__note-time-input"
                type="text"
                :origin-time-text="getTimeFormat(data.startTime)"
                :value="getTimeFormat(data.startTime)"
                maxlength="9"
                @change="handleNoteTimeUpdate($event, data)"
              />
              <div>-</div>
              <input
                :disabled="isPublished"
                name="endTime"
                class="annotationNotes__note-time-input"
                type="text"
                :origin-time-text="getTimeFormat(data.endTime)"
                :value="getTimeFormat(data.endTime)"
                maxlength="9"
                @change="handleNoteTimeUpdate($event, data)"
              />
            </div>
            <div
              class="annotationNotes__note-delete"
              @click="handleNoteDelete($event, data)"
            >
              <d-icon-delete />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isValidTimeRange,
  isValidSelectedTimeRange,
  isValidCentiTimeText,
  toCentiSecondTimeFormat,
  toCentiSeconds,
} from "@/js/video-player/video-player-time.js";
import DIconArrow from "@/components/icons/annotation/DIconArrow.vue";
import DIconCursor from "@/components/icons/annotation/DIconCursor.vue";
import DIconDelete from "@/components/icons/annotation/DIconDelete.vue";
import DIconLine from "@/components/icons/annotation/DIconCursor.vue";
import DIconPen from "@/components/icons/annotation/DIconPen.vue";
import DIconRectangle from "@/components/icons/annotation/DIconRectangle.vue";
import DIconSticker from "@/components/icons/annotation/DIconSticker.vue";
import DIconText from "@/components/icons/annotation/DIconText.vue";
import { ANNOTATION_TYPE } from "@/constants/annotationStatus";
export default {
  name: "BuilderAnnotationNotes",
  props: {
    isPublished: Boolean,
    selectedId: String,
    sortedDataArray: Array,
    videoEndTime: Number,
  },
  data() {
    return {
      iconTypeMap: {
        [ANNOTATION_TYPE.PEN]: "DIconPen",
        [ANNOTATION_TYPE.TEXT]: "DIconText",
        [ANNOTATION_TYPE.ARROW]: "DIconArrow",
        // NOT_SUPPORT_YET
        // [ANNOTATION_TYPE.LINE]: 'DIconLine',
        // [ANNOTATION_TYPE.CIRCLE]: 'DIconRectangle',
        // [ANNOTATION_TYPE.STICKER]: 'DIconSticker',
      },
    };
  },
  components: {
    DIconArrow,
    DIconCursor,
    DIconDelete,
    DIconLine,
    DIconPen,
    DIconRectangle,
    DIconSticker,
    DIconText,
  },
  computed: {},
  methods: {
    getTimeFormat(time) {
      return toCentiSecondTimeFormat(time);
    },
    handleNoteDelete(e, targetData) {
      e.preventDefault();
      this.$emit("handleNoteDelete", targetData.id);
    },
    handleNoteTimeUpdate(e, targetData) {
      e.preventDefault();
      const { name, value } = e.target;
      const originTimeText = e.target.getAttribute("origin-time-text");

      const isValidForamt = isValidCentiTimeText(value);
      if (!isValidForamt) {
        e.target.value = originTimeText;
        return;
      }
      const timeSeconds = toCentiSeconds(value);

      const [minTime, maxTime] =
        name === "startTime" ? [0, targetData.endTime] : [targetData.startTime, this.videoEndTime];

      const isValidRange = isValidTimeRange(timeSeconds, minTime, maxTime);
      const isValidSelectedRange = isValidSelectedTimeRange(name, timeSeconds, minTime, maxTime);
      if (!isValidRange || !isValidSelectedRange) {
        e.target.value = originTimeText;
        return;
      }

      const payload = {
        id: targetData.id,
        key: name,
        value: timeSeconds,
      };
      this.$emit("handleNoteTimeUpdate", payload);
    },
  },
  watch: {
    selectedId: {
      handler(selectedId) {
        if (!selectedId) {
          return;
        }
        this.$nextTick(() => {
          if (!this.$refs.listContainer) return;
          const matchedIndex = this.sortedDataArray.findIndex(item => selectedId === item.id);
          const matchedElm = this.$refs.listContainer.childNodes[matchedIndex];
          if (matchedElm) {
            matchedElm.scrollIntoView({ block: "center" });
          }
        });
      },
    },
  },
};
</script>

<style scoped>
.annotationNotes {
  --note-text-color: #9b9b9b;
  --icon-size: 32px;
  width: 100%;
  height: 100%;
}

.annotationNotes--empty {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100%;
  font-size: 16px;
  border: 1px dashed rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.annotationNotes__list {
  text-align: left;
}

.annotationNotes__list-container {
  margin-top: 16px;
  padding-top: 1px;
  overflow-y: scroll;
  /* height = videoHeight - titleHeight - marginTop = 360 - 24 - 16 */
  height: 320px;
}

.annotationNotes__note-text {
  user-select: none;
  color: var(--note-text-color);
  font-size: 16px;
}

.annotationNotes__row-item {
  height: 48px;
  width: 100%;
  color: var(--note-text-color);
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.annotationNotes__note-no {
  width: 35px;
}

.annotationNotes__note-detail {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  background: #2c2d32;
  width: 100%;
  border-radius: 4px;
}

.annotationNotes__note-detail--active {
  border: 1px solid #4a90e2;
}

.annotationNotes__note-time-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.annotationNotes__note-icon {
  background: #000000;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 4px;
  margin-right: 10px;
}

.annotationNotes__note-time-input {
  color: #ffffff;
  width: 65px;
  font-size: 14px;
  outline: none;
  text-align: center;
}

.annotationNotes__note-delete {
  width: var(--icon-size);
  height: var(--icon-size);
}
</style>
