<template>
  <g stroke-width="1" fill-rule="evenodd">
    <path
      d="M13.4999992,19.1249985 C16.6052092,19.1249985 19.1227392,16.608886 19.125,13.5041856 C19.1272578,10.4045574 16.5996495,7.875 13.4999771,7.875 C11.9466523,7.875 10.5406461,8.50478675 9.5228161,9.5228161 L8.33963745,8.33963745 C8.16817544,8.16817544 7.875,8.28962308 7.875,8.53209751 L7.875,11.7762092 C7.875,11.926518 7.99686849,12.0483865 8.14717738,12.0483865 L11.391289,12.0483865 C11.6337635,12.0483865 11.7552111,11.7552332 11.5837269,11.5837491 L10.3560271,10.3560271 C11.1602439,9.55167736 12.271502,9.05443533 13.4999992,9.05443533 C15.9572595,9.05443533 17.9455632,11.0431155 17.9455632,13.4999992 C17.9455632,15.9572595 15.956883,17.9455632 13.4999992,17.9455632 C12.3635346,17.9455632 11.3274088,17.5201974 10.5418643,16.8198634 C10.4341718,16.7238438 10.2696205,16.7303558 10.1675983,16.832378 L9.71990548,17.2800709 C9.60942202,17.3905765 9.61487088,17.5707212 9.73084751,17.6754679 C10.7282775,18.5764131 12.0500699,19.1249985 13.4999992,19.1249985 Z"
      fill="currentColor"
      fill-rule="nonzero"
    ></path>
  </g>
</template>

<script>
export default {
  name: 'DIconReset'
}
</script>

<style scoped>
</style>
