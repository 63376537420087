import { render, staticRenderFns } from "./DIconFontSize.vue?vue&type=template&id=6ba9c72d&scoped=true&"
import script from "./DIconFontSize.vue?vue&type=script&lang=js&"
export * from "./DIconFontSize.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba9c72d",
  null
  
)

export default component.exports