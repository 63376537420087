import {
  getWorkspaceGroups,
  createWorkspaceGroup,
  patchWorkspaceGroup,
  deleteWorkspaceGroup,
} from "@/server/workspace-group-server";
import Vue from "vue";

export const namespaced = true;

export const state = {
  canViewWorkspaceGroups: [],
  canEditWorkspaceGroups: [],
  adminWorkspaceGroups: [],
};

export const getters = {
  getter_can_edit_skills_workspace_groups: (state) =>
    state.canEditWorkspaceGroups.map((group) => ({
      ...group,
      workspaces: group.workspaces.filter((workspace) => !workspace.private),
    })),
  getter_can_view_workspace_groups: (state) => state.canViewWorkspaceGroups,
  getter_can_view_workspace_group_map: (state) =>
    state.canViewWorkspaceGroups.reduce(
      (result, workspaceGroup) => ({ ...result, [workspaceGroup.id]: workspaceGroup }),
      {}
    ),
  getter_get_view_workspace_group_by_workspace_id: (state) => (workspaceId) =>
    state.canViewWorkspaceGroups.find(({ workspaces }) => Boolean(workspaces.find(({ id }) => id === workspaceId))),
  getter_get_workspace_name_by_workspace_id: (state) => (workspaceId) =>
    state.canViewWorkspaceGroups.flatMap((group) => group.workspaces).find((workspace) => workspace.id === workspaceId)
      ?.name,

  getter_can_edit_workspaceg_group_map: (state) =>
    state.canEditWorkspaceGroups.reduce(
      (result, workspaceGroup) => ({ ...result, [workspaceGroup.id]: workspaceGroup }),
      {}
    ),
  getter_get_edit_workspace_group_by_workspace_id: (state) => (workspaceId) =>
    state.canEditWorkspaceGroups.find(({ workspaces }) => Boolean(workspaces.find(({ id }) => id === workspaceId))),

  getter_admin_workspace_groups: (state) => state.adminWorkspaceGroups,
  getter_admin_workspaceg_group_map: (state) =>
    state.adminWorkspaceGroups.reduce(
      (result, workspaceGroup) => ({ ...result, [workspaceGroup.id]: workspaceGroup }),
      {}
    ),
  getter_get_admin_workspace_group_by_workspace_id: (state) => (workspaceId) =>
    state.adminWorkspaceGroups.find(({ workspaces }) => Boolean(workspaces.find(({ id }) => id === workspaceId))),
};

export const actions = {
  getWorkspaceGroupList: async ({ commit }, { workspacesType }) => {
    const mutationMap = {
      view: "SET_CAN_VIEW_WORKSPACE_GROUPS",
      edit: "SET_CAN_EDIT_WORKSPACE_GROUPS",
      admin: "SET_ADMIN_WORKSPACE_GROUPS",
    };
    const { ok, data } = await getWorkspaceGroups({ needWorkspaces: true, workspacesType, all: true });
    if (!ok) {
      return;
    }
    sortWorkspaceGroupsAndWorkspaces(data);
    moveDefaultWorkspaceGroupToTop(data);
    commit(mutationMap[workspacesType], data);
  },
  addWorkspaceGroup: async ({}, { name, locale }) => {
    try {
      return await createWorkspaceGroup({ name, locale });
    } catch (error) {
      console.log(error);
    }
  },
  editWorkspaceGroup: async ({}, { id, name, locale }) => {
    try {
      return await patchWorkspaceGroup({ id, name, locale });
    } catch (error) {
      console.log(error);
    }
  },
  removeWorkspaceGroup: async ({}, id) => {
    try {
      return await deleteWorkspaceGroup(id);
    } catch (error) {
      console.log(error);
    }
  },
};

export const mutations = {
  SET_CAN_VIEW_WORKSPACE_GROUPS(state, { items }) {
    state.canViewWorkspaceGroups = items;
  },
  SET_CAN_EDIT_WORKSPACE_GROUPS(state, { items }) {
    state.canEditWorkspaceGroups = items;
  },
  SET_ADMIN_WORKSPACE_GROUPS(state, { items }) {
    state.adminWorkspaceGroups = items;
  },
};

const moveDefaultWorkspaceGroupToTop = (data) => {
  const defaultWorkspaceGroupId = Vue.prototype.$defaultWorkspaceGroupId;
  const index = data.items.findIndex((item) => item.id === defaultWorkspaceGroupId);
  if (index !== -1) {
    const defaultItem = data.items.splice(index, 1)[0];
    data.items.unshift(defaultItem);
  }
};

const sortWorkspaceGroupsAndWorkspaces = (data) => {
  if (!data || !data.items) return;
  const sortRule = (first, second) => {
    const getType = (char) => {
      if (/[^a-zA-Z0-9]/.test(char)) {
        return "symbol";
      } else if (/[0-9]/.test(char)) {
        return "digit";
      } else {
        return "letter";
      }
    };

    const firstName = first.name.trim().toLowerCase();
    const secondName = second.name.trim().toLowerCase();

    const firstType = getType(firstName.charAt(0));
    const secondType = getType(secondName.charAt(0));

    if (firstType === secondType) {
      return firstName.localeCompare(secondName);
    }
    // symbols > digits > letters
    if (firstType === "symbol") {
      return -1;
    }
    if (secondType === "symbol") {
      return 1;
    }
    if (firstType === "digit") {
      return -1;
    }
    if (secondType === "digit") {
      return 1;
    }
    return firstName.localeCompare(secondName);
  };

  data.items.sort(sortRule);
  data.items.forEach((item) => {
    if (item.workspaces && item.workspaces.length > 0) {
      item.workspaces.sort(sortRule);
    }
  });
};
