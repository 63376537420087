<template>
  <div class="admin-organization">
    <div class="workspaceGroupMain__title">
      <workspace-group-breadcrumb
        :workspaceGroupName="workspaceGroupName"
        isShowWorkspaceGroup
        hideArrow
        size="large"
        mode="light"
      />
      <div class="workspaceGroupMain__tabs">
        <d-tabs v-model="currentTab" autoWidth :tabNames="tabNames" light height="48" :autotest="autotestTabs"></d-tabs>
      </div>
    </div>

    <admin-workspace-group-workspaces
      v-if="showPart === 'workspaces'"
      :key="workspaceGroupId"
      :drawerOpenPopupStyle="drawerOpenPopupStyle"
    ></admin-workspace-group-workspaces>

    <!-- TODO: enable `Admins` when we enter Phase 2. -->
    <!-- <admin-workspace-group-admins
      v-else-if="showPart === 'admins'"
      :groupDictionary="groupDictionary"
    ></admin-workspace-group-admins> -->
  </div>
</template>

<script>
import DTabs from "@/components/ui_components/DTabs.vue";
import WorkspaceGroupBreadcrumb from "@/components/DAdmin/workspaceGroup/WorkspaceGroupBreadcrumb.vue";
import AdminWorkspaceGroupWorkspaces from "@/components/DAdmin/workspaceGroup/AdminWorkspaceGroupWorkspaces.vue";
import AdminWorkspaceGroupAdmins from "@/components/DAdmin/workspaceGroup/AdminWorkspaceGroupAdmins.vue";
import { mapGetters } from "vuex";

export default {
  name: "AdminWorkspaceGroupMain",
  components: {
    DTabs,
    WorkspaceGroupBreadcrumb,
    AdminWorkspaceGroupWorkspaces,
    AdminWorkspaceGroupAdmins,
  },
  props: {
    drawerOpenPopupStyle: Object,
  },
  data() {
    return {
      groupDictionary: {},
      currentTab: 0,
      showPart: "workspaces",
      tabNames: [this.$t("all.workspaces")],
      autotestTabs: ["workspaces-tab"],
    };
  },
  computed: {
    ...mapGetters("workspaceGroups", ["getter_admin_workspaceg_group_map"]),
    workspaceGroupId() {
      return this.$route.params.workspaceGroupId;
    },
    workspaceGroupName() {
      const workspaceGroup = this.getter_admin_workspaceg_group_map[this.workspaceGroupId];
      return (workspaceGroup && workspaceGroup.name) || "";
    },
  },
  mounted() {
    this.setCurrentTab();
  },
  methods: {
    setCurrentTab() {
      // TODO: enable this when we enter phase 2.

      // const { tab } = this.$route.params;
      // this.currentTab = tab === "admins" ? 1 : 0;

      this.currentTab = 0;
    },
  },
  watch: {
    // TODO: enable watch `currentTab` and `$route` when we enter phase 2.
    // currentTab: function () {
    //   if (Number(this.currentTab) === 0) {
    //     this.showPart = "workspaces";
    //     return this.$router.push({ name: "AdminWorkspaceGroupWorkspaces", params: { tab: "workspaces" } });
    //   }
    //   this.showPart = "admins";
    //   this.$router.push({ name: "AdminWorkspaceGroupAdmins", params: { tab: "admins" } });
    // },
    // $route: function (to, from) {
    //   // when the user clicks on the side menu's Workspace Groups, we need to update the currentTab.
    //   if (from.params.tab === this.showPart) {
    //     this.setCurrentTab();
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
.workspaceGroupMain {
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;
  &__title {
    // height: $topHeight;
    height: 148px;
    padding: 44px 28px 0 28px;
    text-align: left;
  }
  &__tabs {
    margin-top: 30px;
  }
}
.admin-organization {
  position: fixed;
  height: calc(100% - 70px);
  width: 100%;
  padding: 0;
  background-color: #fbfbfb;
}
.top-tabs {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 28px;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  text-align: left;
  min-width: 600px;
}
.tab-div {
  position: fixed;
  height: calc(100% - 134px);
  width: 100%;
}
.tab-title-row {
  position: relative;
  height: 90px;
  width: 100%;
  display: flex;
  padding: 30px 28px 0 28px;
}
.tab-title {
  height: 44px;
  width: 145px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 44px;
}
.search-div {
  height: 44px;
  width: auto;
  display: flex;
  padding-top: 8px;
}
.search-icon {
  height: 32px;
  padding: 4px;
  margin-right: 4px;
}
.serach-form {
  width: 0px;
  transition: 0.3s;
  overflow: hidden;
  padding: 0 16px 0 0;
}
.show-search-input {
  width: 180px;
  transition: 0.3s;
  padding: 0 24px 0 0;
}
.serach-input {
  margin: 0;
  padding: 0;
}

.create-btn {
  min-width: 140px;
  text-transform: none !important;
}

.avatar-td {
  width: 32px;
}
.new-btns-td {
  text-align: left;
  min-width: 172px;
  padding: 0 24px;
  height: 100%;
  position: relative;
}
.new-btns-td-div {
  display: flex;
}
.btns-td {
  min-width: 104px;
  display: flex;
}
.avatar-placeholder {
  height: 32px;
  width: 32px;
  background-color: #8d909f;
  color: white;
  text-align: center;
  font-size: 12px;
  padding-top: 7px;
  border-radius: 16px;
}
.view-button {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-top: 13px;
  margin-right: 20px;
  margin-left: 20px;
  color: #78767a;
}
.tooltip-btn {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-top: 14px;
}
.view-button-new {
  position: relative;
  height: 22px;
  width: 22px;
  color: #78767a;
  margin-right: 20px;
}
.hide-view-btn {
  opacity: 0.3;
  opacity: 0;
  pointer-events: none;
}
.tooltip-btn-new {
  position: relative;
  height: 22px;
  width: 22px;
  margin-right: 20px;
}
.cancel-invite-btn-new {
  position: relative;
  height: 18px;
  width: 18px;
}
.cancel-invite-btn {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-top: 14px;
  padding: 2px;
}
.tooltip-btn-btn {
  color: #78767a;
  opacity: 0.8;
}
.table-editor-btn {
  padding-top: 8px;
  color: #78767a;
  margin-right: 8px;
}
.table-delete-btn {
  padding-top: 8px;
  color: #78767a;
}
.table-delete-btn:hover {
  opacity: 1;
}
.btn-hover:hover {
  color: #2d2e32;
  cursor: pointer;
}
.group-name-hover:hover {
  color: #2d2e32;
  cursor: pointer;
  font-weight: 500;
}
.admin-setting-div {
  position: relative;
  height: calc(100% - 64px);
  width: 100%;
}
/* //// d-data-table ///// */
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.tab-title-new {
  height: 44px;
  width: 145px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 44px;
}
.table-div {
  position: fixed;
  top: 294px;
  left: 0;
  width: 100%;
  height: calc(100% - 374px);
  padding: 0 28px;
}
.table-search-row {
  position: relative;
  width: 100%;
  padding: 0 28px;
}
.d-data-table {
  position: relative;
  height: 100%;
  width: 100%;
}
.filter-div {
  position: relative;
}
.avatar-div {
  position: relative;
  display: inline-block;
  height: 32px;
  width: 32px;
}
.org-admin-icon {
  position: absolute;
  right: -10px;
  bottom: -4px;
  height: 20px;
  width: 20px;
  background-color: #ffbf18;
  border-radius: 10px;
  padding: 3px;
}
.shared-icon {
  background-color: #a9a9a9;
}
.table-wrap {
  position: fixed;
  top: 224px;
  left: 0;
  width: 100%;
  height: calc(100% - 232px);
  padding: 0 28px;
}
.table-wrap:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.private-btn {
  display: inline-block;
  height: 32px;
  min-width: 100px;
  padding: 6px 8px 0 8px;
  border: 1px solid #e7e7e7;
  border-radius: 16px;
  background-color: #ffffff;
  color: #3f3f3f;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
}
.private-btn-dark {
  border: 1px solid #e7e7e7;
  border-radius: 19px;
  background-color: #52545d;
  color: #ffffff;
}
.private-icon {
  margin: 0 2px -2px 4px;
}
@media screen and (max-width: 959px) {
  .top-tabs {
    padding: 0 20px;
  }
  .tab-title-row {
    padding: 30px 20px 0 20px;
  }
  .table-div {
    padding: 0 20px;
  }
  .table-search-row {
    padding: 0 20px;
  }
  .table-wrap {
    padding: 0 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1903px) {
  .top-tabs {
    padding: 0 28px;
  }
  .tab-title-row {
    padding: 30px 28px 0 28px;
  }
}
</style>
