import JSZip from "jszip";
import { saveAs } from "file-saver";

const getStepTitle = (step = { title: "" }, index = -1) => {
  const stepNumber = String(index + 1).padStart(2, "0");
  // encode specific char to HTML allowed format
  const titleAfterEncodeHTMLString = step.title
    ? String(step.title).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;")
    : "";
  const title = titleAfterEncodeHTMLString ? `${stepNumber} ${titleAfterEncodeHTMLString}` : stepNumber;
  return title;
};
const getSubtitleVttFileName = (languageCode = "") => {
  return `subtitle_${languageCode}.vtt`;
};
/**
 * make <track> elements by language code
 * @param Object[] subtitles
 * @param {*} subtitleLabelMap
 * @returns String
 */
const makeTrackHtmlElements = (subtitles = [], subtitleLabelMap = {}) => {
  const trackElements = subtitles.reduce((acc, subtitle) => {
    const languageCode = subtitle.language === "en" ? "en-us" : subtitle.language;
    const fileName = getSubtitleVttFileName(languageCode);
    acc += `
      <track src="${fileName}" kind="subtitles" srclang="${languageCode}" label="${subtitleLabelMap[languageCode]}">
    `;
    return acc;
  }, "");
  return trackElements;
};

/**
 * make index.html by step
 * @param {*} param0
 * @returns { ok: Boolean, data: String }
 */
export const makeCourseHtmlFile = ({ step = { title: "", subtitles: [] }, stepIndex = 0, subtitleLabelMap = {} }) => {
  try {
    const stepTitle = getStepTitle(step, stepIndex);
    const trackElements = makeTrackHtmlElements(step.subtitles, subtitleLabelMap);
    const htmlContent = `
    <!DOCTYPE html
      PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" dir="ltr" lang="en-US">
    <head>
      <title>${stepTitle}</title>
      <meta charset="utf-8">
      <style type="text/css" media="screen">
        body {
          margin: 0;
          padding: 0;
        }
        .video {
          width: 100%;
          height: 100vh;
        }
      </style>
    </head>
    <body>
      <video class="video" controls>
        <source src="video.mp4" type="video/mp4">
        Your browser does not support the video tag.
        ${trackElements}
      </video>
    </body>
    `;
    return { ok: true, data: htmlContent };
  } catch (errorMessage) {
    return { ok: false, errorMessage };
  }
};

/**
 * make <item> elements by steps
 * @param {*} steps = { title: "" }
 * @returns String
 */
const makeItemXmlElements = (steps = []) => {
  const itemElements = steps.reduce((acc, step, index) => {
    const stepId = `step${index + 1}`;
    const stepTitle = getStepTitle(step, index);
    acc += `
      <item identifier="${stepId}_item" identifierref="${stepId}_resource" isvisible="true">
        <title>${stepTitle}</title>
      </item>`;
    return acc;
  }, "");
  return itemElements;
};

/**
 * make <resource> elements by steps
 * @param {*} steps = { title: "" }
 * @returns String
 */
const makeResourceXmlElements = (steps = []) => {
  const resourceElements = steps.reduce((acc, __, index) => {
    const stepId = `step${index + 1}`;
    acc += `
      <resource identifier="${stepId}_resource" type="webcontent" adlcp:scormType="asset" href="${stepId}/index.html">
        <file href="${stepId}/index.html"/>
      </resource>`;
    return acc;
  }, "");
  return resourceElements;
};

/**
 * make imsmanifest.xml
 * @param { title: String } workflow
 * @param Object[] steps
 * @returns { ok: Boolean, data: String }
 */
export const makeImsmanifestXmlFile = (workflow = { title: "" }, steps = []) => {
  try {
    const workflowTitle = workflow.title;
    const itemElements = makeItemXmlElements(steps);
    const resourceElements = makeResourceXmlElements(steps);
    const xmlContent = `
    <?xml version="1.0" standalone="no" ?>
    <!-- 
    Manifest template to demonstrate the proper XML namespace declarations for
    SCORM 2004 4th Edition manifests that do not use metadata. 

    Provided by Rustici Software - www.scorm.com.
    -->
    <manifest identifier="com.scorm.manifesttemplates.scorm2004.4thEd.nometadata" version="1"
    xmlns = "http://www.imsglobal.org/xsd/imscp_v1p1"
    xmlns:adlcp = "http://www.adlnet.org/xsd/adlcp_v1p3"
    xmlns:adlseq = "http://www.adlnet.org/xsd/adlseq_v1p3"
    xmlns:adlnav = "http://www.adlnet.org/xsd/adlnav_v1p3"
    xmlns:imsss = "http://www.imsglobal.org/xsd/imsss"
    xmlns:xsi = "http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation = "http://www.imsglobal.org/xsd/imscp_v1p1 imscp_v1p1.xsd
                          http://www.adlnet.org/xsd/adlcp_v1p3 adlcp_v1p3.xsd
                          http://www.adlnet.org/xsd/adlseq_v1p3 adlseq_v1p3.xsd
                          http://www.adlnet.org/xsd/adlnav_v1p3 adlnav_v1p3.xsd
                          http://www.imsglobal.org/xsd/imsss imsss_v1p0.xsd">
      <metadata>
        <schema>ADL SCORM</schema>
        <schemaversion>2004 4th Edition</schemaversion>
      </metadata>
      <organizations default="B0">
        <organization identifier="B0" adlseq:objectivesGlobalToSystem="false">
          <title>${workflowTitle}</title>
          ${itemElements}
        </organization>
      </organizations>
      <resources>
        ${resourceElements}
      </resources>
    </manifest>
    `;
    return { ok: true, data: xmlContent };
  } catch (errorMessage) {
    console.error("error in makeImsmanifestXmlFile");
    return { ok: false, errorMessage };
  }
};

/**
 * fetch all scorm-schema files from project
 * @returns { ok: Boolean, data: Object[{ name: String, file: blob }] }
 */
export const fetchSCORMSchemas = async () => {
  try {
    const schemaList = [
      "adlcp_v1p3.xsd",
      "adlnav_v1p3.xsd",
      "adlseq_v1p3.xsd",
      "datatypes.dtd",
      "imscp_v1p1.xsd",
      "imsss_v1p0.xsd",
      "imsss_v1p0auxresource.xsd",
      "imsss_v1p0control.xsd",
      "imsss_v1p0delivery.xsd",
      "imsss_v1p0limit.xsd",
      "imsss_v1p0objective.xsd",
      "imsss_v1p0random.xsd",
      "imsss_v1p0rollup.xsd",
      "imsss_v1p0seqrule.xsd",
      "imsss_v1p0util.xsd",
      "xml.xsd",
      "XMLSchema.dtd",
    ];
    const data = [];
    for (let i = 0; i < schemaList.length; i++) {
      const schema = schemaList[i];
      const response = await fetch(`./scorm/${schema}`);
      const file = await response.blob();
      data.push({
        name: schema,
        file,
      });
    }
    return { ok: true, data };
  } catch (errorMessage) {
    console.error("error in fetchSCORMSchemas");
    return { ok: false, errorMessage };
  }
};
/**
 * Get file name and replace space with _
 * @param {*} title
 * @returns
 */
export const getSCORMFileName = (title = "") => {
  return title.replace(/\s+/g, "_");
};
/**
 * make a zip of SCORM package
 * @param {*} schemaFiles
 * @param {*} imsmanifestXmlFile
 * @param {*} stepFiles
 * @returns
 */
export const makeSCORMZip = async (workflow = { title: "" }, schemaFiles = [], imsmanifestXmlFile, stepFiles = []) => {
  try {
    const zip = new JSZip();
    zip.file("imsmanifest.xml", imsmanifestXmlFile);
    schemaFiles.forEach(({ name, file }) => {
      zip.file(name, file);
    });

    stepFiles.forEach(({ video, html, subtitles = [] }, index) => {
      const stepFolderName = `step${index + 1}`;
      const stepFolder = zip.folder(stepFolderName);
      stepFolder.file("video.mp4", video);
      stepFolder.file("index.html", html);
      subtitles.forEach(({ languageCode, data }) => {
        const subtitleFileName = getSubtitleVttFileName(languageCode);
        stepFolder.file(subtitleFileName, data);
      });
    });

    const blob = await zip.generateAsync({ type: "blob" });
    return { ok: true, data: blob };
  } catch (e) {
    console.error("error in makeSCORMZip");
    const errorMessage = e || "Failed to make SCORM zip!";
    return { ok: false, errorMessage };
  }
};

/**
 * download SCORM zip file
 * @param {*} zipBlob
 * @returns
 */
export const downloadSCORMZip = async (workflow = { title: "" }, zipBlob) => {
  try {
    const zipName = getSCORMFileName(workflow.title);
    saveAs(zipBlob, `${zipName}.zip`);
    return { ok: true };
  } catch (errorMessage) {
    console.error("error in downloadSCORMZip");
    return { ok: false, errorMessage };
  }
};
/**
 * Check if workflow total duration is not over 40 mins
 * @param {*} steps
 * @returns
 */
export const isAllowedDownloadSCORM = (steps = [], resolution = 720) => {
  const maxDuration = getMaxSCORMDurationSecByResolution(resolution);
  const totalDuration = steps.reduce((acc, { duration = 0 }) => {
    acc += duration;
    return acc;
  }, 0);
  return totalDuration <= maxDuration;
};
const SCORM_RESOLUTION_OPTIONS = [
  {
    value: 720,
    durationSec: 1800, // sec is equal to 30 min
  },
  {
    value: 1080,
    durationSec: 1200, // sec is equal to 20 min
  },
];
export const getSCORMResolutionOptions = () => {
  return SCORM_RESOLUTION_OPTIONS;
};
export const getMaxSCORMDurationSecByResolution = (resolution = 720) => {
  const result = SCORM_RESOLUTION_OPTIONS.find(({ value }) => value === resolution);
  if (!result) console.warn(`Not found mapping duration by resolution - ${resolution}`);
  return result ? result.durationSec : 0;
};
